import React from 'react';
import styles from './notfound.module.css';
import { APP_CONFIG, CUSTOM_MESSAGES } from '@constants';
import { Link } from 'react-router-dom';

const notFound = () => {
  return (
    <div className="row mx-0">
      <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12">
        <div className="d-flex flex-column justify-content-center align-items-center mFullHeight pt-50">
          <div id="notfound">
            <div className={styles.notfound}>
              <div className={styles.notfound404}>
                <h3>
                  {CUSTOM_MESSAGES.PAGE_NOT_FOUND}
                </h3>
                <h1>
                  <span>
                    4
                  </span>
                  <span>
                    0
                  </span>
                  <span>
                    4
                  </span>
                </h1>
              </div>
              <h2>{CUSTOM_MESSAGES.ERROR_MESSAGE}</h2>
              <Link to={`${APP_CONFIG.BASE_URL}/`} className="btn btn-primary od-btn-primary">
                {CUSTOM_MESSAGES.GO_TO_HOME}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default notFound;
