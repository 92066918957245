
import React, { Fragment } from 'react';
import { Form } from 'formik';
import Textfield from '@formElements/TextFieldFormik';
import Button from '@formElements/Button';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import styles from './generateOtpForm.module.scss';
import cn from 'classnames';
import CountryCode from '@formElements/CountryCode';
import 'react-phone-input-2/lib/style.css';
import { getObjectValue } from '../../utility/utills';

const GenerateMobileForm = ({ loading, formProps }) => {
  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  return (
    <Form>
      <div className="row mt-3">
        <div className="col-12">
          <div className="mb-3">
            <div className="row col-12 mx-0 px-0">
              <div className={cn(styles.phonenr_input, 'col-4 mb-20 mx-0 px-0')}>
                <CountryCode
                  name="countryCode"
                />
              </div>
              <div className="col-8 form-group mx-0 px-0" id="phoneNumberDiv">
                <Textfield
                  name="phoneNumber"
                  type={getObjectValue(() => formProps.values.countryCode, '+1').indexOf('1') !== -1 ? 'number' : 'text'}
                  label={LABEL().ENTER_MOBILE_NUMBER_LOGIN}
                  ariaLabel="phoneNumber"
                  ariaLabelBy="phoneNumberDiv"
                  title="phoneNumber"
                  pattern="[0-9]*"
                  maxLength={getObjectValue(() => formProps.values.countryCode, '+1').indexOf('1') !== -1 ? '10' : '15'}
                  htmlFor={LABEL().ENTER_MOBILE_NUMBER_LOGIN}
                />
              </div>
            </div>
            <div className="fs-12 text-center mt-10">
              <i className="fas fa-exclamation-circle text-warning icon-align mr-1" /> {CUSTOM_MESSAGES().STANDARD_TEXT_RATES}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 p-0">
        {btnImgUrl ? (
          <div className="position-relative d-flex justify-content-center">
            <Button type="submit" loading={loading} disabled={loading} customClass="pl-20" className="border-0 bg-transparent">
              <Fragment>
                <img src={btnImgUrl} className={cn(styles.btnImgUrlSize, 'od-app-btn-primary')} alt="appDefaultButtonImage" />
                <span className={cn(styles.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().GET_MOBILE_OTP}</span>
              </Fragment>
              <Fragment>
                {LABEL().SENDING_OTP}
              </Fragment>
            </Button>
          </div>
        ) : (
          <Button
            type="submit"
            className="btn btn-primary btn-block btn-md od-btn-primary"
            customClass="pl-20"
            loading={loading}
            disabled={loading}
            ariaLabel="mobileOtp"
          >
            <Fragment>
              {LABEL().GET_MOBILE_OTP}
            </Fragment>
            <Fragment>
              {LABEL().SENDING_OTP}
            </Fragment>
          </Button>
        )}
      </div>
    </Form>
  );
};
export default GenerateMobileForm;
