import React, {
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react';
import cn from 'classnames';
import menuDefaultImg from '@assets/images/NoProfileImage/NoProfileImage@3x.png';
import { userInfo, sessionOperations } from '@utility';
import { urlSearchParams } from '../../../utility/utills';
import OneDineLogo from '@assets/images/oneDineLogo.png';
import restaurantImg from '@assets/images/navigationIcons/Restaurant/icon-Restaurant@2x.png';
import orderHistory from '@assets/images/navigationIcons/OrderHistory/icon-OrderHistory@2x.png';
import logoutImg from '@assets/images/LogOut@3x.png';
import { APP_CONFIG, APIConfig } from '@constants';
import { LABEL } from '@veriables';
import Profile from '@assets/images/profile.png';
import style from './header.module.css';
import Modal from '@components/common/Modal';
import { getObjectValue, onKeyDown } from '@utility/utills';

const HOSTNAME = APIConfig.hostname;

const useOutsideAlerter = (ref, cb) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

const HeaderModel = (props) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setOpen(false));
  return (
    <div ref={wrapperRef} role="button" aria-label={props.ariaLabel || ''} tabIndex={0} onKeyDown={ev => onKeyDown(ev, setOpen, !isOpen)} onClick={() => setOpen(!isOpen)}>
      {props.children[0]}
      <div className={cn(isOpen ? 'fade-opacity-1' : 'fade-opacity-0')}>
        {props.children[1]}
      </div>
    </div>
  );
};

export const SideBar = ({
  onToggleNavBar,
  isNavOpen,
  onNavigate,
  headerTitle
}) => {
  const userData = userInfo.getUserBasicDetails() || {};
  return (
    <Fragment>
      {isNavOpen !== null && (
        <div className={`${isNavOpen ? 'slide-in' : 'slide-out'} slider-side-bar position-fixed`}>
          <div className={cn(style.nav, style.sideNav)}>
            <div className={cn('bg-primary p-20', style.menuProfile)}>
              <div>
                <img src={menuDefaultImg} alt="menuDefaultImage" className={cn(style.menuImg)} />
              </div>
              <div className="color-white pt-10 fs-14">
                <span className="fs-16 fw-600">
                  {`${userData.firstName ? userData.firstName : ''} ${userData.lastName ? userData.lastName : 'My profile'}`}
                </span>
                <br />
                <span className="">
                  {userData.emailAddress && userData.emailAddress.split('@')[0]}test@test.com
                </span>
              </div>
            </div>
            <div className={style.menuScroll}>
              <div className={cn(style.subMenuDiv, headerTitle === 'Restaurants' ? style.subMenuDivActive : '')} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onNavigate, '/dashboard')} onClick={() => onNavigate('/dashboard')} aria-label={LABEL().HOME}>
                <img src={restaurantImg} alt="restaurantImage" className={style.subMenuImg} />
                <span className={style.subMenu}>
                  {LABEL().HOME}
                </span>
              </div>
              <div className={cn(style.subMenuDiv, headerTitle === 'Order History' ? style.subMenuDivActive : '')} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onNavigate, '/orders-history')} onClick={() => onNavigate('/orders-history')} aria-label={LABEL().ORDER_HISTORY}>
                <img src={orderHistory} alt="orderHistoryImage" className={style.subMenuImg} />
                <span className={style.subMenu}>
                  {LABEL().ORDER_HISTORY}
                </span>
              </div>
              <div className={cn(style.subMenuDiv, headerTitle === 'Profile' ? style.subMenuDivActive : '')} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onNavigate, '/profile/basicInfo')} onClick={() => onNavigate('/profile/basicInfo')} aria-label={LABEL().PROFILE}>
                <img src={Profile} alt="ProfileImage" className={style.subMenuImg} />
                <span className={style.subMenu}>
                  {LABEL().PROFILE}
                </span>
              </div>
              <div className={cn(style.subMenuDiv, headerTitle === 'My Account' ? style.subMenuDivActive : '')} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onNavigate, '/account')} onClick={() => onNavigate('/account')} aria-label={LABEL().ACCOUNT}>
                <span className={cn(style.subMenu, style.accountMenu)}>
                  {LABEL().ACCOUNT}
                </span>
              </div>
              <div className={cn(style.subMenuDiv, headerTitle === 'Subscription' ? style.subMenuDivActive : '')} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onNavigate, '/subscription')} onClick={() => onNavigate('/subscription')} aria-label={LABEL().SUBSCRIPTION}>
                <span className={cn(style.subMenu, style.accountMenu)}>
                  {LABEL().SUBSCRIPTION}
                </span>
              </div>
              {/* <MenuNavBar onNavigate={onNavigate} /> */}
            </div>
            <div className={style.subMenuFooter}>
              <div className={cn(style.subMenuDiv, style.logOut)}>
                <img src={logoutImg} alt="logoutImage" className={style.subMenuImg} />
                <span className={style.subMenu} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onNavigate, '/logout')} onClick={() => onNavigate('/logout')} aria-label={LABEL().SIGN_OUT}>
                  {LABEL().SIGN_OUT}
                </span>
              </div>
              <div className="text-muted od-text-muted text-right pr-10 fs-12">
                <small>Version: {APP_CONFIG.VERSION}</small>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={cn(style.nav, style.menuMask, isNavOpen ? 'd-block' : 'd-none')} role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, onToggleNavBar, '')} onClick={onToggleNavBar} />
    </Fragment>
  );
};

export const Header = ({
  headerBar,
  menu,
  closeButton,
  closeOnSamePage,
  // isFooterOpen,
  onNavigate,
  popInData,
  redirect,
  sessionTrack,
  onAccountTrack,
  cart,
  menusList,
  menuIndex,
  themeProperties,
  handleLanguageSelection,
  selectedLanguageId,
  setSelectedLanguageId,
  history
  // isOders,
  // isBrowse
}) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  // const [isContinueDisabled, setIsContinueDisabled] = useState(themeProperties && themeProperties.languageId === selectedLanguageId);
  const renderLeftIcon = (type) => {
    switch (type) {
      case 2:
        return (
          userInfo.isUserLogin() ? (
            <button type="button" className="header-btn btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center" aria-label="account" onClick={() => { onNavigate(closeButton.controlUrl, true); onAccountTrack(); }}>
              <i className="cursor-pointer fas fa-user-circle " />
            </button>
          ) : (
            <button type="button" className="header-btn btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center" aria-label="left" onClick={() => onNavigate(closeButton.controlUrl, true)}>
              <i className="cursor-pointer fas fa-chevron-left fa-lg od-tab-icon" />
            </button>
          )
        );
      default:
        // return (
        //   <HeaderModel>
        //     <i className="fas fa-map-marker-alt text-primary" />
        //     <div className={cn(style.headerModel, 'p-10 fs-14 text-white')}>
        //       <div className="fs-12 pl-2">CHECKED IN AT:</div>
        //       <div className="pl-2">
        //         <div className="fw-600">
        //           {popInData.store && popInData.store.name}
        //         </div>
        //         <div>
        //           <span>{popInData.store && popInData.store.city}</span>
        //           {' '}
        //           <span>{popInData.store && popInData.store.state}</span>
        //         </div>
        //       </div>
        //       <hr className={style.menuHr} />
        //       {((isBrowse || isOders) && !isFooterOpen) && (
        //         <div role="presentation" onClick={() => redirect(isBrowse ? '/browse-category' : '/orders')}>
        //           <i className="fas fa-utensils fa-lg pl-2" />
        //           <span className="pl-15">ORDER</span>
        //           <hr className={style.menuHr} />
        //         </div>
        //       )}
        //       <div role="presentation" onClick={() => redirect('/dashboard')}>
        //         <i className="fas fa-sign-out-alt pl-2 fs-18" />
        //         <span className="pl-15">LEAVE</span>
        //       </div>
        //     </div>
        //   </HeaderModel>
        // );
        return (
          userInfo.isUserLogin() ? (
            <button type="button" className="header-btn btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center" aria-label="account" onClick={() => { redirect('/account'); onAccountTrack(); }}>
              <i className="cursor-pointer fas fa-user-circle user-icon od-tab-icon" />
            </button>
          ) : (
            <span>
              {' '}
            </span>
            // <i role="presentation" className="cursor-pointer fas fa-unlock" onClick={() => redirect('/login')} />
          )
        );
    }
  };
  const handlePaymentPageCloseSession = () => {
    const dataToSend = {
      activityType: 'payment_cancel',
      data: {
        amount: Number(Number(popInData.totalAmount + ((sessionOperations.getItem('tip') || {}).tips || 0)).toFixed(2)),
      }
    };
    sessionTrack(dataToSend);
  };

  const getCartItems = () => {
    const idArray = [];
    cart.forEach((item) => {
      idArray.push({
        menuId: menusList[menuIndex].id,
        menuItemId: item.menuItemId,
        menuScreenId: item.menuScreenId
      });
    });
    return idArray;
  };

  const getTotal = () => {
    let total = 0;
    cart.forEach((item) => {
      if (!item.refill) total += (item.price * item.quantity);
    });
    return Number(total.toFixed(2));
  };

  const getCount = () => {
    let count = 0;
    cart.forEach((item) => {
      count += item.quantity;
    });
    return count;
  };

  const handleCartPageCloseSession = () => {
    const dataToSend = {
      activityType: 'cart_cancel',
      data: {
        count: getCount(),
        amount: getTotal(),
        items: getCartItems()
      }
    };
    sessionTrack(dataToSend);
  };

  const navigateUrl = (controlUrl) => {
    // let url = controlUrl;
    // if (window.location.pathname === '/lookup/1') {
    //   const lookUpType = window.sessionStorage.getItem('lookupType');
    //   if (lookUpType === 'scan') url = '/lookup/1';
    //   window.sessionStorage.removeItem('lookupType');
    // }
    if (window.location.pathname.includes('/tip')) {
      handlePaymentPageCloseSession();
    }
    if (window.location.pathname === '/selected-cart/0') {
      handleCartPageCloseSession();
    }
    onNavigate(controlUrl, true);
  };

  const renderRightIcon = (type) => {
    const previousScreen = window.sessionStorage.getItem('accountPageToRedirect') || '';
    switch (type) {
      case 1:
        return (
          <Fragment>
            {
              (!userInfo.isUserLogin() && (HOSTNAME.indexOf('.dev.') !== -1 || HOSTNAME.indexOf('.uat.') !== -1)) ? (
                <button type="button" aria-label="login" className={cn(style.logoutLink, 'header-btn btn-outline-none fw-600 border-0 bg-secondary-light p-0')} onClick={() => onNavigate('/login', true)}>
                  <i className="navbar-icon fas fa-sign-in-alt fa-lg" />
                </button>
              ) : (
                <span>
                  {' '}
                </span>
              )
            }
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            {
              !userInfo.isUserLogin() ? (
                <button type="button" aria-label="signIn" className={cn(style.logoutLink, 'header-btn btn-outline-none fw-600 border-0 bg-secondary-light p-0')} onClick={() => onNavigate('/login', true)}>
                  <i className="navbar-icon fas fa-sign-in-alt fa-lg od-tab-icon" />
                </button>
              ) : (
                <button type="button" className={cn(window.innerWidth < 1023 ? 'ml-5' : '', 'ml-5 header-btn btn-outline-none border-0 bg-secondary-light p-0 close-btn')} aria-label="close" onClick={() => onNavigate(closeButton.controlUrl, true)}>
                  <i className="navbar-icon fas fa-times fa-lg cursor-pointer od-tab-icon" />
                </button>
              )
            }
          </Fragment>
        );
      case 3:
        return <button type="button" className={cn(window.innerWidth < 1023 ? 'ml-5' : '', 'header-btn border-0 bg-secondary-light pr-0 pl-0 close-btn')} aria-label="headerClose" onClick={() => navigateUrl(closeButton.controlUrl)} onKeyDown={(e) => { if (e.key === 'Escape') navigateUrl(closeButton.controlUrl); }}><i className="navbar-icon fas fa-times fa-lg cursor-pointer od-tab-icon" /></button>;
      case 4:
        return <button type="button" className={cn(window.innerWidth < 1023 ? 'ml-5' : '', 'header-btn border-0 bg-secondary-light pr-0 pl-0 close-btn')} aria-label="headerClose" onClick={() => closeOnSamePage()} onKeyDown={(e) => { if (e.key === 'Escape') closeOnSamePage(); }}><i className="navbar-icon fas fa-times fa-lg cursor-pointer od-tab-icon" /></button>;
      case 5:
        return <button type="button" className={cn(window.innerWidth < 1023 ? 'ml-5' : '', 'header-btn border-0 bg-secondary-light pr-0 pl-0 close-btn')} aria-label="headerClose" onClick={() => onNavigate(previousScreen, true)} onKeyDown={(e) => { if (e.key === 'Escape') onNavigate(previousScreen, true); }}><i className="navbar-icon fas fa-times fa-lg cursor-pointer od-tab-icon" /></button>;
      default:
        return <button type="button" className={cn(window.innerWidth < 1023 ? 'ml-5' : '', 'header-btn border-0 bg-secondary-light pr-0 pl-0 close-btn')} aria-label="headerClose" onClick={() => onNavigate(closeButton.controlUrl)} onKeyDown={(e) => { if (e.key === 'Escape') onNavigate(closeButton.controlUrl); }}><i className="navbar-icon fas fa-times fa-lg cursor-pointer od-tab-icon" /></button>;
    }
  };

  const handleTitle = () => {
    const { tittle } = headerBar;
    let logo = '';
    switch (tittle) {
      case 'Logo':
        if (popInData && popInData.venue) {
          logo = (popInData && popInData.venue && popInData.venue.logoImageUrl) || OneDineLogo;
        } else {
          logo = (popInData && popInData.store && popInData.store.logoImageUrl) || OneDineLogo;
        }
        return <img src={logo} alt={`${getObjectValue(() => popInData.store.name, '')} Logo`} className={cn(popInData && popInData.store && popInData.store.logoImageUrl ? cn(style.logoSize, 'mw-100') : 'h-px-25')} />;
      case 'OneDineLogo':
        logo = (popInData && popInData.store && popInData.store.logoImageUrl) || OneDineLogo;
        return <img src={logo} alt={`${getObjectValue(() => popInData.store.name, '')} Logo`} className="h-px-25" />;
      case 'StoreLogo':
        logo = (popInData && popInData.store && popInData.store.logoImageUrl) || OneDineLogo;
        return <img src={logo} alt={`${getObjectValue(() => popInData.store.name, '')} Logo`} className={cn(popInData && popInData.store && popInData.store.logoImageUrl ? cn(style.logoSize, 'mw-100') : 'h-px-25')} />;
      case 'MallLogo':
        logo = (popInData && popInData.venue && popInData.venue.logoImageUrl) || OneDineLogo;
        return <img src={logo} alt={`${getObjectValue(() => popInData.store.name, '')} Logo`} className={cn(popInData && popInData.store && popInData.store.logoImageUrl ? cn(style.logoSize, 'mw-100') : 'h-px-25')} />;
      case 'BrandLogo':
        logo = (themeProperties && themeProperties.logoImageUrl) || OneDineLogo;
        return <img src={logo} alt={`${getObjectValue(() => popInData.store.brandName, '')} Logo`} className={cn(themeProperties && themeProperties.logoImageUrl ? cn(style.logoSize, 'mw-100') : 'h-px-25')} />;
      default:
        return tittle;
    }
  };

  const displayStoreInfo = () => {
    return (
      <HeaderModel ariaLabel="location">
        {/* <button type="button" className="bg-transparent btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center" aria-label="location icon"> */}
        <i className="fas fa-map-marker-alt text-primary od-tab-icon" />
        {/* </button> */}
        <div className={cn(style.headerModel, 'fs-14 od-color od-app-background-color')}>
          <div className={cn('pl-2', style.checkInInfo)}>
            {popInData.store && popInData.store.name && (
              <div className="fw-400  ">
                <span className="fw-600">{LABEL().HEADER_LOCATION}:  </span>
                <span>{popInData.store && popInData.store.name}</span>
              </div>
            )}
            {/* {popInData.stationNumber && (
              <div className="fw-400 ">
                <span className="fw-600">Table: </span>
                <span>{popInData.stationNumber}</span>
              </div>
            )} */}
            {popInData && popInData.venue && popInData.venue.consolidateMenu && (
              <>
                {popInData.sensorName && (
                  <div className="fw-400 ">
                    <span>{popInData.sensorName}</span>
                  </div>
                )}
              </>
            )}
            {popInData && !popInData.venue && (popInData.flowType === 2) && (popInData.stationId === 0 || popInData.stationId === null) ? (
              <>
                {popInData.sensorName && (
                  <div className="fw-400 ">
                    <span>{popInData.sensorName}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                {popInData.stationNumber && (
                  <div className="fw-400 ">
                    <span className="fw-600">{LABEL().HEADER_TABLE}: </span>
                    <span>{popInData.stationNumber}</span>
                  </div>
                )}
              </>
            )}
            {popInData.checkHeaders && popInData.checkHeaders.length > 0 ? (
              popInData.checkHeaders.map((checks) => {
                return (
                  <div className="fw-400 ">
                    <span className="fw-600">{LABEL().HEADER_CHECK}: </span>
                    <span>{checks.number}</span>
                  </div>
                );
              })
            ) : (
              <>
                {popInData.number && (
                  <div className="fw-400 ">
                    <span className="fw-600">{LABEL().HEADER_CHECK}: </span>
                    <span>{popInData.number}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </HeaderModel>
    );
  };

  // useEffect(() => {
  //   if (selectedLanguageId === themeProperties.languageId) {
  //     setIsContinueDisabled(true);
  //   } else {
  //     setIsContinueDisabled(false);
  //   }
  // }, [selectedLanguageId, isLanguageModalOpen]);
  const getSelectedLanguage = () => {
    const langId = window.sessionStorage.getItem('languageId');
    let result = getObjectValue(() => themeProperties.languageId, null);
    if (langId) {
      result = Number(langId);
    }
    return result;
  };

  useEffect(() => {
    setSelectedLanguageId(getSelectedLanguage());
  }, [isLanguageModalOpen]);

  useEffect(() => {
    const brandInfoId = JSON.parse(window.sessionStorage.getItem('brandId')) || null;
    if (!brandInfoId) {
      handleLanguageSelection(selectedLanguageId);
    }
  }, []);
  useEffect(() => {
    if (!isLanguageModalOpen) {
      window.sessionStorage.removeItem('languageModal');
    }
  }, [isLanguageModalOpen]);

  const { type } = menu;
  const closeType = closeButton.type;
  // const restrictODheader = window.location.pathname === '/account' || window.location.pathname === '/orders-history' || window.location.pathname === '/profile/basicInfo';
  const isIos = JSON.parse(window.localStorage.getItem('ios'));
  // const noHeader = window.location.pathname === '/orders' || window.location.pathname === '/selected-cart/0';
  // console.log('restrictODheader', restrictODheader);
  const handleBrandStoreBackListing = () => {
    const brandId = window.location.pathname.split('/')[2];
    history.replace({
      pathname: `${APP_CONFIG.BASE_URL}/brand/${brandId}/locator`
    });
  };

  const element = document.querySelector('.od-btn-primary');
  const iconColor = element ? getComputedStyle(element).backgroundColor : '#ad00248';

  return (
    <>
      {window.innerWidth > 1023 ? '' : (
        <>
          <nav className={cn('navbar navbar-light position-fixed w-100 text-dark od-header', `${style.nav}`, isIos ? 'd-none' : '')}>
            <div>
              {window.location.pathname.includes('/brand') && window.location.pathname.includes('listing') && urlSearchParams('backTo') === 'locator' && (
              <button type="button" className={cn('pl-2 border-0 od-btn-primary p-0 d-flex flex-column justify-content-center align-items-center position-absolute')} onClick={() => { handleBrandStoreBackListing(); }} ariaLabel="go back to locator">
                <i className="fa fa-arrow-left position-absolute fa-2x" style={{ color: iconColor }} />
              </button>
              )}
            </div>
            <div className="col-2 col-sm-2 col-md-4 p-0 d-flex">
              {(type !== null) && renderLeftIcon(type)}
            </div>
            <div className="col-8 col-md-4 pl-0 pr-0">
              {(
                <Fragment>
                  <span className={cn('fw-600 d-flex justify-content-center', headerBar.style, (handleTitle || '').length > 20 ? style.headerTitle : 'fs-18')}>
                    {handleTitle()}
                  </span>
                </Fragment>
              )}
            </div>
            <div className={cn('col-2 col-sm-2 col-md-4 p-0 text-right d-flex align-items-center', !(themeProperties && themeProperties.languages && themeProperties.languages.length > 1) ? cn(style.contentEnd) : '', style.spaceEvenly)}>
              {themeProperties && themeProperties.languages && themeProperties.languages.length > 1 && (
                <button type="button" onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); window.sessionStorage.setItem('languageModal', true); }} className="bg-transparent btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center" aria-label=" select language">
                  <i className="fa fa-language text-primary od-tab-icon fs-20" aria-hidden="true" />
                </button>
              )}
              {(closeType !== null) ? (
                <Fragment>
                  {renderRightIcon(closeType)}
                </Fragment>
              ) : (
                <span>
                  {' '}
                </span>
              )}
              {(closeType === null && popInData && (popInData.flowType === 2 || popInData.flowType === 3 || popInData.flowType === 4 || popInData.flowType === 5 || popInData.flowType === 16)) ? (
                <Fragment>
                  {displayStoreInfo()}
                </Fragment>
              ) : (
                <span>
                  {' '}
                </span>
              )}
            </div>
          </nav>
          <Modal isOpen={isLanguageModalOpen} toggleModal={() => setIsLanguageModalOpen(!isLanguageModalOpen)}>
            <div className="card mb-0 od-app-background-color">
              <div className="p-10 w-100">
                <div className="col text-right btn-as-text color-secondary p-10">
                  <button type="button" className={cn('bg-transparent border-0 icon-od fs-12 icon-od-close od-color pr-3', style.modalCloseIcon)} tabIndex={0} onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); window.sessionStorage.removeItem('languageModal'); }} aria-label="close" />
                </div>
              </div>
              <div className="mx-0 justify-content-center align-items-center text-center">
                <div className={cn('d-flex flex-column text-center m-2 align-items-center', style.languageListHeight)}>
                  {themeProperties && themeProperties.languages && themeProperties.languages.map((language) => {
                    return (
                      <button key={language.languageName} type="button" className={cn('btn btn-primary od-btn-primary rounded my-3 col-10', language.languageId === selectedLanguageId ? `${style.selectedLanguage} od-selectedLanguage` : '')} onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); handleLanguageSelection(language.languageId); window.sessionStorage.removeItem('languageModal'); }}>
                        {language.languageName}
                      </button>);
                  })}
                </div>
                {/* <div className="row mb-20 justify-content-center align-items-center text-center">
                  <button className="ml-20 btn btn-primary od-btn-primary col-4" type="button" disabled={isContinueDisabled} onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); handleLanguageSelection(selectedLanguageId); }}>
                    {LABEL().CONTINUE}
                  </button>
                </div> */}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
export default Header;

export const RestaurantHeader = ({
  customStyle,
}) => {
  console.log('customStyle', customStyle);
  return (
    <span />
  );
};
