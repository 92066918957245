import _ from 'lodash';
import { arrayOperations } from '@utility';

export const GET_OFFERS_REQUEST = 'offers/GET_OFFERS_REQUEST';
export const GET_OFFERS_SUCCESS = 'offers/GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILURE = 'offers/GET_OFFERS_FAILURE';

export const SUBSCRIBE_TO_OFFERS_REQUEST = 'offers/SUBSCRIBE_TO_OFFERS_REQUEST';
export const SUBSCRIBE_TO_OFFERS_SUCCESS = 'offers/SUBSCRIBE_TO_OFFERS_SUCCESS';
export const SUBSCRIBE_TO_OFFERS_FAILURE = 'offers/SUBSCRIBE_TO_OFFERS_FAILURE';

export const GET_LOYALTY_OFFERS_REQUEST = 'offers/GET_LOYALTY_OFFERS_REQUEST';
export const GET_LOYALTY_OFFERS_SUCCESS = 'offers/GET_LOYALTY_OFFERS_SUCCESS';
export const GET_LOYALTY_OFFERS_FAILURE = 'offers/GET_LOYALTY_OFFERS_FAILURE';

export const GET_LOYALTY_ACCOUNT_BY_LINK_REQUEST = 'offers/GET_LOYALTY_ACCOUNT_BY_LINK_REQUEST';
export const GET_LOYALTY_ACCOUNT_BY_LINK_SUCCESS = 'offers/GET_LOYALTY_ACCOUNT_BY_LINK_SUCCESS';
export const GET_LOYALTY_ACCOUNT_BY_LINK_FAILURE = 'offers/GET_LOYALTY_ACCOUNT_BY_LINK_FAILURE';

export const CHECK_LOYALTY_OFFER = 'offers/CHECK_LOYALTY_OFFER';

const initailValues = {
  loading: false,
  loaded: true,
  error: false,
  isAccepting: false,
  offersList: [],
  pendingOffers: [],
  acceptedOffers: [],
  loyaltyOffersList: [],
  isLoyaltyOffer: false,
};

export default (state = initailValues, action) => {
  let filteredData = [];
  switch (action.type) {
    case GET_OFFERS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_OFFERS_SUCCESS:
      filteredData = arrayOperations.groupElemetsByKey(action.result, 'accepted');
      console.log('filteredData.false', action.result);
      return {
        ...state,
        loading: false,
        loaded: true,
        offersList: action.result,
        pendingOffers: filteredData.true || [],
        acceptedOffers: filteredData.true || [],
      };
    case GET_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case SUBSCRIBE_TO_OFFERS_REQUEST:
      return {
        ...state,
        isAccepting: true,
        loaded: false
      };
    case SUBSCRIBE_TO_OFFERS_SUCCESS:
      console.log('action.payload', action.payload);
      return {
        ...state,
        isAccepting: false,
        loaded: true,
        acceptedOffers: action.result,
        pendingOffers: _.filter(state.pendingOffers, (f) => { return !action.payload.includes(f.externalId); }),
      };
    case SUBSCRIBE_TO_OFFERS_FAILURE:
      return {
        ...state,
        isAccepting: false,
        loaded: true,
        error: true
      };
    case GET_LOYALTY_OFFERS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_LOYALTY_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loyaltyOffersList: action.result,
      };
    case GET_LOYALTY_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case GET_LOYALTY_ACCOUNT_BY_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_LOYALTY_ACCOUNT_BY_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loyaltyOffersList: action.result
      };
    case GET_LOYALTY_ACCOUNT_BY_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case CHECK_LOYALTY_OFFER: {
      if (!state.isLoyaltyOffer) {
        const nextPopIn = { ...JSON.parse(window.sessionStorage.getItem('popIn')), accountNumber: null };
        window.sessionStorage.setItem('popIn', JSON.stringify(nextPopIn));
      }
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
        isLoyaltyOffer: action.payload
      };
    }
    default:
      return state;
  }
};

export const getOffers = (data) => {
  return {
    types: [GET_OFFERS_REQUEST, GET_OFFERS_SUCCESS, GET_OFFERS_FAILURE],
    promise: client => client.post('fetchOffers', {
      data
    })
  };
};

export const getLoyaltyOffers = (data) => {
  return {
    types: [GET_LOYALTY_OFFERS_REQUEST, GET_LOYALTY_OFFERS_SUCCESS, GET_LOYALTY_OFFERS_FAILURE],
    promise: client => client.post('fetchOffers', {
      data
    })
  };
};

export const subscribeToOffers = (idList) => {
  const data = {
    offerExternalIds: idList
  };
  return {
    types: [SUBSCRIBE_TO_OFFERS_REQUEST, SUBSCRIBE_TO_OFFERS_SUCCESS, SUBSCRIBE_TO_OFFERS_FAILURE],
    promise: client => client.post('suscribeToOffers', {
      data
    }),
    payload: data.offerExternalIds
  };
};

export const isCheckLoyalty = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: CHECK_LOYALTY_OFFER,
        payload: data
      }
    );
  };
};

export const getLoyaltyAccountByLink = (externalBrandId) => {
  return {
    types: [GET_LOYALTY_ACCOUNT_BY_LINK_REQUEST, GET_LOYALTY_ACCOUNT_BY_LINK_SUCCESS, GET_LOYALTY_ACCOUNT_BY_LINK_FAILURE],
    promise: client => client.post(`getLoyaltyAccountByLink/${externalBrandId}`),
    payload: externalBrandId
  };
};
