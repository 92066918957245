import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form } from 'formik';
import Button from '@formElements/Button';
import Textfield from '@formElements/TextFieldFormik';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import cn from 'classnames';
import styles from './verifyMobileForm.module.css';
import 'react-phone-input-2/lib/style.css';
import CountryCode from '@formElements/CountryCode';
import { getObjectValue } from '../../utility/utills';
import { setNotification } from '@reducers/notification';

const VerifyMobileForm = ({
  loading,
  formProps,
  restaurantInfoConfig,
  ...props
}) => {
  const captchaEnabled = getObjectValue(() => restaurantInfoConfig.enabled, false);
  const captchaApiKey = getObjectValue(() => restaurantInfoConfig.apiKey, null);
  const captchaProviderId = getObjectValue(() => restaurantInfoConfig.providerId, null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  const countryCode = getObjectValue(() => formProps.values.countryCode, '+1').replace('+', '');
  const verifyButtonEnabled = captchaEnabled ? captchaToken : true;

  const onCaptchaChange = (value) => {
    // window.console.log('onCaptchaChange', value);
    setCaptchaToken(value);
    formProps.setFieldValue('token', value, false);
  };

  useEffect(() => {
    let cloudflareTurnstileScript = null;
    if (captchaEnabled && captchaProviderId === 2) {
      window.console.log('[OD Cloudflare Turnstile] Loading script');

      const head = document.getElementsByTagName('head')[0];
      cloudflareTurnstileScript = document.createElement('script');
      cloudflareTurnstileScript.setAttribute('src', 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onCloudflareTurnstileLoaded');
      cloudflareTurnstileScript.setAttribute('type', 'text/javascript');
      cloudflareTurnstileScript.setAttribute('id', 'cloudflare-turnstile-script');

      window.onCloudflareTurnstileLoaded = () => {
        window.console.log('[OD Cloudflare Turnstile] Script loaded');

        try {
          window.turnstile.render('.cloudflare-turnstile', {
            sitekey: captchaApiKey,
            theme: 'light',
            callback: (token) => {
              window.console.log('[OD Cloudflare Turnstile] Success!');
              onCaptchaChange(token);
            },
            'error-callback': (error) => {
              window.console.error('[OD Cloudflare Turnstile] Error callback', error);
              onCaptchaChange(null);
            },
            'expired-callback': (error) => {
              window.console.warn('[OD Cloudflare Turnstile] Expired callback', error);
              onCaptchaChange(null);
            },
            'unsupported-callback': (error) => {
              window.console.error('[OD Cloudflare Turnstile] Unsupported callback', error);
              props.setNotification({
                type: 'danger',
                message: CUSTOM_MESSAGES().BROWSER_NOT_SUPPORTED
              });
            }
          });
        } catch (error) {
          window.console.log('[OD Cloudflare Turnstile] Error rendering', error);
          props.setNotification({
            type: 'danger',
            message: CUSTOM_MESSAGES().OOPS_ERROR_OCCURRED_TRY_AGAIN
          });
        }
      };

      head.appendChild(cloudflareTurnstileScript);
    }

    return () => {
      if (cloudflareTurnstileScript && cloudflareTurnstileScript.parentNode) {
        cloudflareTurnstileScript.parentNode.removeChild(cloudflareTurnstileScript);
      }
    };
  }, [captchaEnabled, captchaProviderId]);

  return (
    <Fragment>
      <div className="text-center py-2">
        {CUSTOM_MESSAGES().SIGN_IN_PHONE_NUMBER}
      </div>
      <Form>
        <div className="row">
          <div className={cn(styles.phonenr_input, 'col-4', window.innerWidth > 1023 ? 'pl-0' : 'px-0')}>
            <CountryCode
              name="countryCode"
            />
          </div>
          <div className={cn('col-8', window.innerWidth > 1023 ? 'pr-0' : 'px-0')}>
            <div className={cn(styles.inputField, 'form-group')} id="mobileNumberDiv">
              <Textfield
                name="mobileNumber"
                type={getObjectValue(() => formProps.values.countryCode, '+1') === '+1' ? 'text' : 'text'}
                pattern="[0-9]*"
                label={LABEL().ENTER_MOBILE_NUMBER_LOGIN}
                maxLength={countryCode === '1' ? '10' : '15'}
                ariaLabel="mobileNumber"
                ariaLabelBy="mobileNumberDiv"
                title="mobileNumber"
                errorMessageCustomClass="align-top"
                htmlFor={LABEL().ENTER_MOBILE_NUMBER_LOGIN}
              />
            </div>
          </div>
          <div className="fs-12 text-center">
            <i className="fas fa-exclamation-circle text-warning icon-align mr-1" /> {CUSTOM_MESSAGES().STANDARD_TEXT_RATES}
          </div>
        </div>
        <>
          {captchaEnabled && captchaProviderId === 1 ? (
            <div className="row pt-2 d-flex justify-content-center">
              <ReCAPTCHA
                sitekey={captchaApiKey}
                onChange={onCaptchaChange}
              />
            </div>
          ) : (
            <></>
          )}
        </>
        <>
          {captchaEnabled && captchaProviderId === 2 ? (
            <div className="row pt-2 d-flex justify-content-center">
              <div className="cloudflare-turnstile" />
            </div>
          ) : (
            <></>
          )}
        </>
        <div className="col-12 p-0 pt-2">
          {btnImgUrl ? (
            <div className="position-relative d-flex justify-content-center">
              <Button type="submit" aria-label="verify" disabled={!verifyButtonEnabled} loading={loading} customClass="pl-45" className="border-0 bg-transparent">
                <Fragment>
                  <img src={btnImgUrl} className={cn(styles.btnImgUrlSize, 'od-app-btn-primary')} alt="verify" />
                  <span className={cn(styles.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().VERIFY}</span>
                </Fragment>
                <Fragment>
                  Loading
                </Fragment>
              </Button>
            </div>
          ) : (
            <Button type="submit" aria-label="verify" disabled={!verifyButtonEnabled} loading={loading} customClass="pl-45" className="btn btn-primary btn-block btn-md od-btn-primary">
              <Fragment>
                {LABEL().VERIFY}
              </Fragment>
              <Fragment>
                Loading
              </Fragment>
            </Button>
          )}
        </div>
      </Form>
    </Fragment>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setNotification
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMobileForm);
