import { localStorageOperations, userInfo } from '@utility';
import { getObjectValue } from '@utility/utills';

export const GET_OAUTH_REQUESTED = 'controller/GET_OAUTH_REQUESTED';
export const GET_OAUTH_SUCCESS = 'controller/GET_OAUTH_SUCCESS';
export const GET_OAUTH_FAILURE = 'controller/GET_OAUTH_FAILURE';

export const POP_IN_REQUESTED = 'controller/POP_IN_REQUESTED';
export const POP_IN_SUCCESS = 'controller/POP_IN_SUCCESS';
export const POP_IN_FAILURE = 'controller/POP_IN_FAILURE';

export const SENSOR_CHECK_IN_REQUESTED = 'controller/SENSOR_CHECK_IN_REQUESTED';
export const SENSOR_CHECK_IN_SUCCESS = 'controller/SENSOR_CHECK_IN_SUCCESS';
export const SENSOR_CHECK_IN_FAILURE = 'controller/SENSOR_CHECK_IN_FAILURE';

export const UPDATE_POP_IN_REQUESTED = 'controller/UPDATE_POP_IN_REQUESTED';
export const UPDATE_POP_IN_SUCCESS = 'controller/UPDATE_POP_IN_SUCCESS';

export const POP_IN_BLANK_REQUESTED = 'controller/POP_IN_BLANK_REQUESTED';
export const POP_IN_BLANK_SUCCESS = 'controller/POP_IN_BLANK_SUCCESS';
export const POP_IN_BLANK_FAILURE = 'controller/POP_IN_BLANK_FAILURE';

export const LEAVE_TABLE_REQUESTED = 'controller/LEAVE_TABLE_REQUEST';
export const LEAVE_TABLE_SUCCESS = 'controller/LEAVE_TABLE_SUCCESS';
export const LEAVE_TABLE_FAILURE = 'controller/LEAVE_TABLE_FAILURE';

export const ASSIGN_SPOT_REQUESTED = 'controller/ASSIGN_SPOT_REQUEST';
export const ASSIGN_SPOT_SUCCESS = 'controller/ASSIGN_SPOT_SUCCESS';
export const ASSIGN_SPOT_FAILURE = 'controller/ASSIGN_SPOT_FAILURE';

export const SESSION_TRACK_REQUESTED = 'controller/SESSION_TRACK_REQUEST';
export const SESSION_TRACK_SUCCESS = 'controller/SESSION_TRACK_SUCCESS';
export const SESSION_TRACK_FAILURE = 'controller/SESSION_TRACK_FAILURE';

export const REVALIDATION_DATA_REQUESTED = 'controller/REVALIDATION_DATA_REQUESTED';
export const REVALIDATION_DATA_SUCCESS = 'controller/REVALIDATION_DATA_SUCCESS';
export const REVALIDATION_DATA_FAILURE = 'controller/REVALIDATION_DATA_FAILURE';

export const TABLE_ASSIGNMENT_REQUESTED = 'controller/TABLE_ASSIGNMENT_REQUESTED';
export const TABLE_ASSIGNMENT_SUCCESS = 'controller/TABLE_ASSIGNMENT_SUCCESS';
export const TABLE_ASSIGNMENT_FAILURE = 'controller/TABLE_ASSIGNMENT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  popInData: JSON.parse(window.sessionStorage.getItem('popIn')),
  popInRequestInfo: JSON.parse(window.localStorage.getItem('popInRequest')),
  userdata: null,
  isUserLogin: false,
  checkInStoreInfo: null,
  revalidationResponse: null
};

const getCheckHeaderId = (data) => {
  let result = null;
  const popInData = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('popIn'), null));
  if (popInData && popInData.checkHeaderId && popInData.checkHeaderId === 0 && popInData.checkHeader) {
    result = null;
  } else if (popInData && popInData.checkHeader) {
    result = popInData.checkHeader.id;
  } else if (popInData && popInData.checkHeaderId) {
    result = popInData.checkHeaderId;
  } else if (data && data.data && data.data.checkHeader) {
    result = data.data.checkHeader.id;
  }
  return result;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OAUTH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OAUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result,
      };
    }
    case GET_OAUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case ASSIGN_SPOT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ASSIGN_SPOT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ASSIGN_SPOT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SENSOR_CHECK_IN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SENSOR_CHECK_IN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkInStoreInfo: action.result,
      };
    }
    case SENSOR_CHECK_IN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case POP_IN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case POP_IN_SUCCESS: {
      window.sessionStorage.setItem('popIn', JSON.stringify(action.result));
      // window.localStorage.setItem('isUserLogin', true);
      localStorageOperations.removeItem('tip');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        popInData: action.result,
        popInRequestInfo: action.payload
      };
    }
    case POP_IN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_POP_IN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_POP_IN_SUCCESS: {
      const nextPopIn = { ...JSON.parse(window.sessionStorage.getItem('popIn')), ...action.payload };
      window.sessionStorage.setItem('popIn', JSON.stringify(nextPopIn));
      action.resolve();
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        popInData: nextPopIn,
        popInRequestInfo: action.payload
      };
    }
    case POP_IN_BLANK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case POP_IN_BLANK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case POP_IN_BLANK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case LEAVE_TABLE_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LEAVE_TABLE_SUCCESS:
      window.sessionStorage.removeItem('popIn');
      window.sessionStorage.removeItem('themeStyle');
      return {
        ...state,
        popInData: null,
        popInRequestInfo: null,
        loading: false,
        loaded: true,
      };
    case LEAVE_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case SESSION_TRACK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SESSION_TRACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case SESSION_TRACK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case REVALIDATION_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case REVALIDATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        revalidationResponse: action.result,
      };
    }
    case REVALIDATION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case TABLE_ASSIGNMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TABLE_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case TABLE_ASSIGNMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};


export const getOauth = (data) => {
  return {
    types: [GET_OAUTH_REQUESTED, GET_OAUTH_SUCCESS, GET_OAUTH_FAILURE],
    promise: client => client.post('getOauth', {
      data
    })
  };
};

export const getPopIn = (data) => {
  return {
    types: [POP_IN_REQUESTED, POP_IN_SUCCESS, POP_IN_FAILURE],
    promise: client => client.post('getPopIn', {
      data
    }),
    payload: data
  };
};

export const getPreviewPopIn = (data) => {
  return {
    types: [POP_IN_REQUESTED, POP_IN_SUCCESS, POP_IN_FAILURE],
    promise: client => client.post('getPreviewPopIn', {
      data
    }),
    payload: data
  };
};

export const sensorCheckIn = (data) => {
  return {
    types: [SENSOR_CHECK_IN_REQUESTED, SENSOR_CHECK_IN_SUCCESS, SENSOR_CHECK_IN_FAILURE],
    promise: client => client.post('sensorCheckIn', {
      data
    }),
    payload: data
  };
};


export const getPopInBlank = (data) => {
  return {
    types: [POP_IN_REQUESTED, POP_IN_SUCCESS, POP_IN_FAILURE],
    promise: client => client.post('getPopInblank', {
      data
    }),
    payload: data
  };
};

export const updatePopInData = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_POP_IN_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: UPDATE_POP_IN_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const leaveTable = (checkInId) => {
  return {
    types: [LEAVE_TABLE_REQUESTED, LEAVE_TABLE_SUCCESS, LEAVE_TABLE_FAILURE],
    promise: client => client.post(`checkoutTable/${checkInId}`)
  };
};

export const assignSpot = (data) => {
  return {
    types: [ASSIGN_SPOT_REQUESTED, ASSIGN_SPOT_SUCCESS, ASSIGN_SPOT_FAILURE],
    promise: client => client.post('assignSpot', {
      data
    })
  };
};

export const clearPopInData = () => {
  return (dispatch) => {
    dispatch({
      type: LEAVE_TABLE_REQUESTED
    });
    setTimeout(() => {
      dispatch({
        type: LEAVE_TABLE_SUCCESS,
      });
    }, 300);
  };
};

export const loyaltyAccountUnAssign = (data) => {
  return {
    types: [GET_OAUTH_REQUESTED, GET_OAUTH_SUCCESS, GET_OAUTH_FAILURE],
    promise: client => client.post('loyaltyAccountUnAssign', {
      data
    })
  };
};

export const sessionTrack = (data) => {
  const dataToSend = {
    sessionId: window.sessionStorage.getItem('sessionId'),
    storeId: (getObjectValue(() => JSON.parse(window.sessionStorage.getItem('popIn')).store.id, 0)) || null,
    guestId: getObjectValue(() => window.localStorage.getItem('guestId'), null),
    memberId: (userInfo.getUserBasicDetails() && userInfo.getUserBasicDetails().id) || null,
    checkHeaderId: getCheckHeaderId(data),
    brandId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('popIn')).store.brandId, 0),
    ...data
  };
  return {
    types: [SESSION_TRACK_REQUESTED, SESSION_TRACK_SUCCESS, SESSION_TRACK_FAILURE],
    promise: client => client.post('sessionTrack', {
      data: dataToSend,
    })
  };
};

export const getRevalidationData = (data) => {
  return {
    types: [REVALIDATION_DATA_REQUESTED, REVALIDATION_DATA_SUCCESS, REVALIDATION_DATA_FAILURE],
    promise: client => client.post('getRevalidationData', {
      data
    }),
    payload: data
  };
};

export const tableAssignment = (data) => {
  return {
    types: [TABLE_ASSIGNMENT_REQUESTED, TABLE_ASSIGNMENT_SUCCESS, TABLE_ASSIGNMENT_SUCCESS],
    promise: client => client.post('tableAssignment', {
      data
    }),
    payload: data
  };
};
