export const UPDATE_TABLE_NUMBER_REQUEST = 'kiosk/UPDATE_TABLE_NUMBER_REQUEST';
export const UPDATE_TABLE_NUMBER_SUCCESS = 'kiosk/UPDATE_TABLE_NUMBER_SUCCESS';
export const UPDATE_TABLE_NUMBER_FAILURE = 'kiosk/UPDATE_TABLE_NUMBER_FAILURE';

const initialValues = {
  loading: false,
  loaded: false,
  error: false
};
export default (state = initialValues, action) => {
  switch (action.type) {
    case UPDATE_TABLE_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    case UPDATE_TABLE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case UPDATE_TABLE_NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    default:
      return state;
  }
};

export const updateTable = (data) => {
  return {
    types: [UPDATE_TABLE_NUMBER_REQUEST, UPDATE_TABLE_NUMBER_SUCCESS, UPDATE_TABLE_NUMBER_FAILURE],
    promise: client => client.post('updateTable', {
      data
    })
  };
};
