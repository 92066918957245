export const LEAVE_TABLE_REQUESTED = 'leaveTable/LEAVE_TABLE_REQUEST';
export const LEAVE_TABLE_SUCCESS = 'leaveTable/LEAVE_TABLE_SUCCESS';
export const LEAVE_TABLE_FAILURE = 'leaveTable/LEAVE_TABLE_FAILURE';

const initailValues = {
  loading: false,
  loaded: true,
  error: false
};

export default (state = initailValues, action) => {
  switch (action.type) {
    case LEAVE_TABLE_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LEAVE_TABLE_SUCCESS:
      window.sessionStorage.sessionStorage('popIn');
      window.sessionStorage.removeItem('themeStyle');
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case LEAVE_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    default:
      return state;
  }
};

export const leaveTable = (checkInId) => {
  return {
    types: [LEAVE_TABLE_REQUESTED, LEAVE_TABLE_SUCCESS, LEAVE_TABLE_FAILURE],
    promise: client => client.post(`checkoutTable/${checkInId}`)
  };
};
