export const OAUTH_REQUESTED = 'oauth/OAUTH_REQUESTED';
export const OAUTH_SUCCESS = 'oauth/OAUTH_SUCCESS';
export const OAUTH_FAILURE = 'oauth/OAUTH_FAILURE';

export const GOOGLE_OAUTH_REQUESTED = 'oauth/GOOGLE_OAUTH_REQUESTED';
export const GOOGLE_OAUTH_SUCCESS = 'oauth/GOOGLE_OAUTH_SUCCESS';
export const GOOGLE_OAUTH_FAILURE = 'oauth/GOOGLE_OAUTH_FAILURE';

export const GOOGLE_CLIENT_ID_REQUESTED = 'CLIENT_ID/GOOGLE_CLIENT_ID_REQUESTED';
export const GOOGLE_CLIENT_ID_SUCCESS = 'CLIENT_ID/GOOGLE_CLIENT_ID_SUCCESS';
export const GOOGLE_CLIENT_ID_FAILURE = 'CLIENT_ID/GOOGLE_CLIENT_ID_FAILURE';

export const FACEBOOK_OAUTH_REQUESTED = 'oauth/FACEBOOK_OAUTH_REQUESTED';
export const FACEBOOK_OAUTH_SUCCESS = 'oauth/FACEBOOK_OAUTH_SUCCESS';
export const FACEBOOK_OAUTH_FAILURE = 'oauth/FACEBOOK_OAUTH_FAILURE';

export const LOGOUT_REQUEST = 'oauth/LOGOUT_REQUEST';

const initialState = {
  loading: false,
  loaded: false,
  gApiLoading: false,
  gApiLoaded: true,
  fbApiLoading: false,
  fbApiLoaded: true,
  error: false,
  userdata: null,
  isUserLogin: false,
  googleClientId: null
};

export default (state = initialState, action) => {
  console.log('initialState, action', action);
  switch (action.type) {
    case OAUTH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OAUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result,
        isUserLogin: true,
      };
    }
    case OAUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GOOGLE_OAUTH_REQUESTED: {
      return {
        ...state,
        gApiLoading: true,
        gApiLaded: false
      };
    }
    case GOOGLE_OAUTH_SUCCESS: {
      return {
        ...state,
        gApiLoading: false,
        error: false,
        gApiLoaded: true,
        userdata: action.result,
      };
    }
    case GOOGLE_OAUTH_FAILURE: {
      return {
        ...state,
        gApiloading: false,
        error: false,
        gApiloaded: true
      };
    }
    case GOOGLE_CLIENT_ID_REQUESTED: {
      return {
        ...state,
        gApiLoading: true,
        gApiLaded: false
      };
    }
    case GOOGLE_CLIENT_ID_SUCCESS: {
      return {
        ...state,
        gApiLoading: false,
        error: false,
        gApiLoaded: true,
        googleClientId: action.result.token,
      };
    }
    case GOOGLE_CLIENT_ID_FAILURE: {
      return {
        ...state,
        gApiloading: false,
        error: false,
        gApiloaded: true
      };
    }
    case FACEBOOK_OAUTH_REQUESTED: {
      return {
        ...state,
        fbApiLoading: true,
        fbApiLaded: false
      };
    }
    case FACEBOOK_OAUTH_SUCCESS: {
      return {
        ...state,
        fbApiLoading: false,
        error: false,
        fbApiLoaded: true,
        userdata: action.result,
      };
    }
    case FACEBOOK_OAUTH_FAILURE: {
      return {
        ...state,
        fbApiloading: false,
        error: false,
        fbApiloaded: true
      };
    }
    default:
      return state;
  }
};


export const login = (data) => {
  return {
    types: [OAUTH_REQUESTED, OAUTH_SUCCESS, OAUTH_FAILURE],
    promise: client => client.post('login', {
      data
    })
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
  };
};

export const signInByGoogle = (data) => {
  return {
    types: [GOOGLE_OAUTH_REQUESTED, GOOGLE_OAUTH_SUCCESS, GOOGLE_OAUTH_FAILURE],
    promise: client => client.post('googleSignIn', {
      data
    })
  };
};

export const getGoogleClientId = () => {
  return {
    types: [GOOGLE_CLIENT_ID_REQUESTED, GOOGLE_CLIENT_ID_SUCCESS, GOOGLE_CLIENT_ID_FAILURE],
    promise: client => client.get('googleClientId')
  };
};

export const signInByFacebook = (data) => {
  return {
    types: [FACEBOOK_OAUTH_REQUESTED, FACEBOOK_OAUTH_SUCCESS, FACEBOOK_OAUTH_FAILURE],
    promise: client => client.post('facebookSignIn', {
      data
    })
  };
};
