import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import VerifyEmailForm from '@components/VerifyEmailForm';
import { withRouter } from 'react-router-dom';
import { APP_CONFIG } from '@constants';
import * as Yup from 'yup';

/*
**** State Description ****
* initialData: for setting default values of form
* email: email of user
*/
const VerifyEmail = ({
  getotp,
  goBack,
  initialValues,
  loading,
  ...props
}) => {
  const onHandleClick = (data) => {
    window.sessionStorage.setItem('mobileNumber', data.mobileNumber);
    props.history.replace(`${APP_CONFIG.BASE_URL}/s/${data.mobileNumber}`);
  };

  const validationSchemaemail = () => (
    Yup.object().shape({
      emailAddress: Yup.string().email('Please enter a valid email').required('Please enter an email'),
    })
  );

  return (
    <div>
      <Formik
        render={formProps => <VerifyEmailForm formProps={formProps} dirty={formProps.dirty} onHandleClick={onHandleClick} onSubmit={getotp} onClickNavigation={goBack} loading={loading} />}
        validationSchema={validationSchemaemail}
        initialValues={initialValues}
        onSubmit={getotp}
      />
    </div>
  );
};

VerifyEmail.propTypes = {
  getotp: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};
// export default VerifyMobile;
export default withRouter(VerifyEmail);
