import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setNotification } from '@reducers/notification';
import GenerateOtpForm from '@components/GenerateOtpForm';
import GenerateMobileOtpForm from '@components/GenerateOtpForm/mobileOtp';
import { sendotp } from '@reducers/otp';
import { Link } from 'react-router-dom';
import './generate-otp.css';
import { CUSTOM_MESSAGES, LABEL } from '@veriables';
import {
  APP_CONFIG,
  SUCCESS_MESSAGE
} from '@constants';

/*
**** State Description ****
* initialData: for setting default values of form
* email: email of user
*/
const GenerateOtp = ({
  getotp,
  goBack,
  initialValues,
  loading,
  mobileLoading,
  ...props
}) => {
  const validationSchema = () => (
    Yup.object().shape({
      emailAddress: Yup.string().required(CUSTOM_MESSAGES().GENERATE_OTP_SCREEN_REQ_EMAIL).email(CUSTOM_MESSAGES().GENERATE_OTP_SCREEN_INVAL_EMAIL),
    })
  );
  const phoneNumberValid = Yup.string().required(CUSTOM_MESSAGES().GENERATE_OTP_SCREEN_INVAL_MOBILE_NUMBER).min(7, CUSTOM_MESSAGES().GENERATE_OTP_SCREEN_INVAL_MOBILE_NUMBER)
    .max(15, CUSTOM_MESSAGES().GENERATE_OTP_SCREEN_INVAL_MOBILE_NUMBER)
    .matches(/^[0-9]*$/, CUSTOM_MESSAGES().GENERATE_OTP_SCREEN_INVAL_MOBILE_NUMBER);

  const validationMobileSchema = () => (
    Yup.object().shape({
      phoneNumber: phoneNumberValid,
      countryCode: Yup.string().required('Please enter Country Code').min(1, 'Invalid Country Code').max(5, 'Invalid Country Code')
        .matches(/^[0-9+]*$/, 'Invalid Country Code'),
    })
  );
  const onSubmit = (values, type) => {
    props.sendotp(values, type).then(() => {
      props.setNotification({
        type: 'success',
        message: type === 1 ? SUCCESS_MESSAGE.MAIL_SENT_SUCCESS : SUCCESS_MESSAGE.OTP_SENT_TO_MOBILE
      });
      getotp(values);
    }).catch((err) => {
      props.setNotification({
        type: 'danger',
        message: err.message
      });
    });
  };

  return (
    <div>
      <div className="row mx-0 full-height-forgotPassword justify-content-center align-items-center">
        <div className="card-wrapper">
          <div className="custom-card fat od-app-background-color">
            <div className="card-body p-2">
              {/* <div className="row mx-0 fs-12">
                <button
                  type="button"
                  className="col text-right btn-as-text color-secondary"
                  onClick={goBack}
                >
                  <div className="icon-od icon-od-close" />
                </button>
              </div> */}
              <div className="primary text-center pt-20 mb-3">
                {CUSTOM_MESSAGES().FORGOT_PASSWORD}
              </div>
              {/* <div className="color-secondary text-center fs-14 p-20">
                {CUSTOM_MESSAGES().PLEASE_ENTER_EMAIL}
              </div> */}
              <Formik
                render={formProps => <GenerateOtpForm dirty={formProps.dirty} loading={loading} />}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(value) => { onSubmit(value, 1); }}
              />
              <Formik
                render={formProps => <GenerateMobileOtpForm dirty={formProps.dirty} loading={mobileLoading} formProps={formProps} />}
                validationSchema={validationMobileSchema}
                initialValues={initialValues}
                onSubmit={(value) => { onSubmit(value, 2); }}
              />
              <div className="col py-3 od-color text-center">
                {CUSTOM_MESSAGES().SUDDENLY_REMEMBERED_YOUR_PASSWORD}
                <Link className="od-color" to={`${APP_CONFIG.BASE_URL}/login`}>
                  {LABEL().LOGIN}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GenerateOtp.propTypes = {
  getotp: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

const mapStateToProps = ({ otp }) => ({
  loading: otp.loading,
  mobileLoading: otp.mobileLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  sendotp,
  setNotification
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateOtp);
