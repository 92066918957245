export const ADD_WAIT_LIST_REQUESTED = 'waitlist/ADD_WAIT_LIST_REQUESTED';
export const ADD_WAIT_LIST_SUCCESS = 'waitlist/ADD_WAIT_LIST_SUCCESS';
export const ADD_WAIT_LIST_FAILURE = 'waitlist/ADD_WAIT_LIST_FAILURE';

export const UPDATE_WAIT_LIST_REQUESTED = 'waitlist/UPDATE_WAIT_LIST_REQUESTED';
export const UPDATE_WAIT_LIST_SUCCESS = 'waitlist/UPDATE_WAIT_LIST_SUCCESS';
export const UPDATE_WAIT_LIST_FAILURE = 'waitlist/UPDATE_WAIT_LIST_FAILURE';

export const DELETE_WAIT_LIST_REQUESTED = 'waitlist/DELETE_WAIT_LIST_REQUESTED';
export const DELETE_WAIT_LIST_SUCCESS = 'waitlist/DELETE_WAIT_LIST_SUCCESS';
export const DELETE_WAIT_LIST_FAILURE = 'waitlist/DELETE_WAIT_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  waitList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_WAIT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_WAIT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        waitList: action.result || {}
      };
    }
    case ADD_WAIT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_WAIT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_WAIT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        waitList: action.result || {}
      };
    }
    case UPDATE_WAIT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_WAIT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_WAIT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        waitList: action.result || {}
      };
    }
    case DELETE_WAIT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const addWaitList = (data) => {
  return {
    types: [ADD_WAIT_LIST_REQUESTED, ADD_WAIT_LIST_SUCCESS, ADD_WAIT_LIST_FAILURE],
    promise: client => client.post('addWaitList', {
      data,
    }),
  };
};

export const deleteWaitList = (bookingId) => {
  return {
    types: [DELETE_WAIT_LIST_REQUESTED, DELETE_WAIT_LIST_SUCCESS, DELETE_WAIT_LIST_FAILURE],
    promise: client => client.post(`deleteWaitList/${bookingId}`),
    payload: bookingId
  };
};

export const updateWaitList = (data) => {
  return {
    types: [UPDATE_WAIT_LIST_REQUESTED, UPDATE_WAIT_LIST_SUCCESS, UPDATE_WAIT_LIST_FAILURE],
    promise: client => client.post('updateWaitList', {
      data,
    }),
  };
};
