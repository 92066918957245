import React, {
  // useRef,
  useEffect,
  Fragment,
  useState
} from 'react';
import cn from 'classnames';
import styles from './footer.module.css';
// import { formatPhoneNumber } from '@utility/utills';
import userInfo from '@utility';
// import PickMenu from '@containers/PickMenu';
import OneDineLogo from '@assets/images/oneDineLogo.png';
import { LABEL } from '@veriables';
import Modal from '@components/common/Modal';
import { getObjectValue, urlSearchParams } from '@utility/utills';

const DesktopMenu = (props) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const getSelectedLanguage = () => {
    const langId = window.sessionStorage.getItem('languageId');
    let result = getObjectValue(() => props.themeProperties.languageId, null);
    if (langId) {
      result = Number(langId);
    }
    return result;
  };

  useEffect(() => {
    props.setSelectedLanguageId(getSelectedLanguage());
  }, [isLanguageModalOpen]);

  useEffect(() => {
    const brandInfoId = JSON.parse(window.sessionStorage.getItem('brandId')) || null;
    if (!brandInfoId) {
      props.handleLanguageSelection(props.selectedLanguageId);
    }
  }, [props.selectedLanguageId]);

  useEffect(() => {
    if (!isLanguageModalOpen) {
      window.sessionStorage.removeItem('languageModal');
    }
  }, [isLanguageModalOpen]);

  const handleTitle = () => {
    const { tittle } = props.headerBar;
    let logo = '';
    switch (tittle) {
      case 'Logo':
        if (props.popInData && props.popInData.venue) {
          logo = (props.popInData && props.popInData.venue && props.popInData.venue.desktopLogoImageUrl) || OneDineLogo;
        } else {
          logo = (props.popInData && props.popInData.store && props.popInData.store.logoImageUrl) || OneDineLogo;
        }
        return <img src={logo} alt="notFound" className={cn(window.innerWidth > 1023 ? `${styles.logoWidth}` : 'w-100', props.popInData && props.popInData.store && props.popInData.store.logoImageUrl ? styles.logoSize : 'h-px-25')} />;
      case 'OneDineLogo':
        logo = (
          props.popInData && props.popInData.store && props.popInData.store.desktopImageUrl
            ? ((props.popInData && props.popInData.store && props.popInData.store.desktopImageUrl) || OneDineLogo)
            : ((props.popInData && props.popInData.store && props.popInData.store.logoImageUrl) || OneDineLogo)
        );
        return <img src={logo} alt="notFound" className={cn(window.innerWidth > 1023 ? `${styles.logoWidth}` : 'w-100', props.popInData && props.popInData.store && props.popInData.store.logoImageUrl ? styles.logoSize : 'h-px-25')} />;
      case 'StoreLogo':
        logo = (
          props.popInData && props.popInData.store && props.popInData.store.desktopImageUrl
            ? ((props.popInData && props.popInData.store && props.popInData.store.desktopImageUrl) || OneDineLogo)
            : ((props.popInData && props.popInData.store && props.popInData.store.logoImageUrl) || OneDineLogo)
        );
        return <img src={logo} alt="notFound" className={cn(window.innerWidth > 1023 ? `${styles.logoWidth}` : 'w-100', props.popInData && props.popInData.store && props.popInData.store.logoImageUrl ? styles.logoSize : 'h-px-25')} />;
      case 'MallLogo':
        logo = (props.popInData && props.popInData.venue && props.popInData.venue.desktopLogoImageUrl) || OneDineLogo;
        return <img src={logo} alt="notFound" className={cn(window.innerWidth > 1023 ? `${styles.logoWidth}` : 'w-100', props.popInData && props.popInData.store && props.popInData.store.logoImageUrl ? styles.logoSize : 'h-px-25')} />;
      case 'BrandLogo':
        logo = (props.themeProperties && props.themeProperties.logoImageUrl) || OneDineLogo;
        return <img src={logo} alt="notFound" className={cn(window.innerWidth > 1023 ? `${styles.logoWidth}` : 'w-100', props.themeProperties && props.themeProperties.logoImageUrl ? cn(styles.logoSize, 'mw-100') : 'h-px-25')} />;
      default:
        return tittle;
    }
  };

  const restrictProfileIcon = ['/wallet', '/orders-history', '/profile/basicInfo', '/profile/changePassword', '/account', '/wallet/address', '/wallet/loyalties', '/login', '/KDS'];
  console.log('props.closeButton.controlUrl', props.closeButton);
  const handleRightIcon = (iconType) => {
    switch (iconType) {
      case 1:
        return (
          <div className="d-flex justify-content-end">
            {!restrictProfileIcon.includes(window.location.pathname)
              ? (
                <div className="mr-3" aria-label="account" onClick={() => props.navigateTo('/account')} role="presentation">
                  <button type="button" className={cn(window.innerWidth > 1023 ? `${styles.size35}` : 'header-btn', 'btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center')} aria-label="account">
                    {window.innerWidth > 1023 ? (
                      <i className="cursor-pointer fa-user-circle user-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className={cn(`${styles.size35} fa-user-circle`)} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle" viewBox="0 0 496 512" data-fa-i2svg>
                          <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" />
                        </svg>
                      </i>
                    ) : (
                      <i className="cursor-pointer fas fa-user-circle user-icon" />
                    )}
                  </button>
                </div>
              ) : '' }
            { !(window.location.pathname === '/account')
              ? (
                <div className={cn(window.innerWidth > 1023 ? 'd-flex justify-content-center align-items-center' : '')} aria-label="close">
                  <button type="button" tabIndex="0" className="header-btn border-0 bg-secondary-light p-0 ml-auto" aria-label="close" onClick={() => { props.onNavigate(props.closeButton.controlUrl, true); console.log('ErrPressed'); }} onKeyDown={(ev) => { if (ev.key === 'Escape') { props.onNavigate(props.closeButton.controlUrl, true); } }}>
                    <i className="navbar-icon fas fa-times fa-lg cursor-pointer od-text-primary" />
                  </button>
                </div>
              ) : '' }
          </div>
        );
      default:
        return (
          <>
            <div className="d-flex justify-content-end">
              <button type="button" className={cn(window.innerWidth > 1023 ? `${styles.size35}` : 'header-btn', 'btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center')} aria-label="account" onClick={() => props.navigateTo('/account')} onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Spacebar') { e.target.click(); } }}>
                {window.innerWidth > 1023 ? (
                  <i className="cursor-pointer user-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" className={cn(`${styles.size35} fa-user-circle`)} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle" viewBox="0 0 496 512" data-fa-i2svg>
                      <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" />
                    </svg>
                  </i>
                ) : (
                  <i className="cursor-pointer fas fa-user-circle user-icon" />
                )}
              </button>
            </div>
          </>
        );
    }
  };

  const isGuestUserLogin = false;
  const pagesWithoutLogin = ['/upsell'];
  const sensorCode = getObjectValue(() => JSON.parse(window.localStorage.getItem('popInTokenRequest')).sensorCode, '');
  const isSensorCheckWithQRCode = window.location.pathname.includes('/sensor-check') && urlSearchParams('sensorcode') === sensorCode;
  const restrictPagesWithoutLogin = pagesWithoutLogin.includes(window.location.pathname) || window.location.pathname.includes('/order-item') || window.location.pathname.includes('/KDS') || window.location.pathname.includes('/selected-cart') || window.location.pathname.includes('/login') || isSensorCheckWithQRCode ? !isGuestUserLogin : isGuestUserLogin;
  const renderCancelIconWithoutLogin = () => {
    return (
      <div className="" aria-label="close">
        <button type="button" aria-label="close" tabIndex="0" className="header-btn border-0 bg-secondary-light p-0 ml-auto" onClick={() => props.onNavigate(props.closeButton.controlUrl, true)} onKeyDown={(ev) => { if (ev.key === 'Escape') { props.onNavigate(props.closeButton.controlUrl, true); } }}>
          <i className="navbar-icon fas fa-times fa-lg cursor-pointer" />
        </button>
      </div>
    );
  };
  // const closeType = closeButton.type;
  // const renderDesktopMenus = props.footer.show ? renderDesktopMenuItem(menuItem) : '';
  // const restrictMenuItems = window.location.pathname === '/dashboard' || window.location.pathname === '/spot-screen' || window.location.pathname === '/guest-spot' || window.location.pathname === '/pickup-time';
  const restrictODheader = window.location.pathname === '/account' || window.location.pathname === '/orders-history' || window.location.pathname === '/profile/basicInfo';
  const closePages = ['/order-item/', '/orders-history', '/profile/basicInfo', '/tip', '/wallet', '/upsell', '/loyalty', '/profile/changePassword', '/account', '/scan-qr/1', '/payment-option', '/loyalty-offers', '/wallet/address', '/wallet/loyalties', '/login', '/gift-card', '/brandStoreDetails', '/coupon', '/KDS'];
  const rightIconType = closePages.includes(window.location.pathname) || window.location.pathname.includes('/order-item') || window.location.pathname.includes('/selected-cart') || window.location.pathname.includes('/lookup') ? 1 : 2;
  return (
    <div className={cn(styles.footerFixed, styles.footerList)}>
      <div className={cn('container', styles.desktopFooterContainer)}>
        <div className="row">
          <div className={cn('d-flex justify-content-around align-items-center w-100 p-0 m-0', styles.desktopFooterItems)}>
            <nav className={cn(window.innerWidth > 1023 ? `${styles.top0}` : '', 'navbar navbar-light position-fixed w-100 text-dark pb-0 p-0 container', `${styles.nav} ${restrictODheader ? '' : 'od-header'}`)}>
              <div className={cn(window.innerWidth > 1023 ? '' : 'mt-20', `${styles.padding18} container`)}>
                {/* <div className={cn(window.innerWidth > 1023 ? 'col-md-3 pl-0' : 'col-md-2')}> */}
                <div className={cn(window.innerWidth > 1023 ? '' : 'col-md-2')}>
                  {(
                    <Fragment>
                      <span className={cn('fw-600 d-flex justify-content-start', props.headerBar.style, (handleTitle || '').length > 20 ? styles.headerTitle : 'fs-18')}>
                        {handleTitle()}
                      </span>
                    </Fragment>
                  )}
                </div>
                { (window.location.pathname === '/orders') && (
                  <div className={cn(window.innerWidth > 1023 ? styles.margin5 : '', 'offset-md-1 col-md-6')}>
                    <div className={cn('d-flex align-items-center flex-row pt-1 mx-1')}>
                      <div className="w-75">
                        <label className="d-none" htmlFor="search">Search: </label>
                        <i className="fa fa-search fa-rotate-90 position-absolute" aria-hidden="true" style={{ paddingTop: '5px', paddingLeft: '10px' }} />
                        <input type="text" value={props.searchString} className={cn(styles.searchBoxCalendar, 'od-input-box od-input-background-color od-input-font-color')} placeholder={LABEL().ITEM_SEARCH} onChange={e => props.onSearchChange(e.target.value)} aria-label="Search" aria-labelledby="search" title="search" />
                        {props.searchString && (
                        <span role="presentation" className={styles.searchCloseCalendar} onClick={() => { props.onSearchChange(''); }} onKeyDown={(ev) => { if (ev.key === 'Escape') { props.onSearchChange(''); } }}>
                          {/* <img src={closeIcon} alt="" className="d-flex" width="20px" height="20px" /> */}
                          <button type="button" aria-label="close" className={cn(window.innerWidth > 1023 ? 'bg-transparent border-0 icon-od fs-12 icon-od-close od-color d-flex m-1 mt-2' : 'bg-transparent border-0 icon-od fs-12 icon-od-close od-color d-flex m-1')} onKeyDown={e => e.stopPropagation()} />
                        </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={cn(window.innerWidth > 1023 ? 'pr-0' : '', '')}>
                  {/* (closeType === null && popInData && popInData.flowType === 2) ? (
                    <Fragment>
                      {displayStoreInfo()}
                    </Fragment>
                  ) : (
                    <span>
                      {' '}
                    </span>
                  ) */}
                  {
                    !userInfo.isUserLogin() ? (
                      <>
                        {/* <div className={cn(styles.logoutLink, 'd-flex justify-content-end')} onClick={() => props.onNavigate('/login')} role="presentation">
                        Login
                        </div>
                        <span>
                          { restrictPagesWithoutLogin ? <span className="ml-3">{renderCancelIconWithoutLogin()}</span> : ''}
                        </span> */}
                        <div className="d-flex flex-row justify-content-end">
                          {!isSensorCheckWithQRCode && (
                            <div className={cn(styles.logoutLink)} onClick={() => props.onNavigate(`/login?backTo=${window.location.pathname}`)} role="presentation">
                              {window.location.pathname === '/login' || window.location.pathname === '/KDS' ? '' : LABEL().DESKTOP_LOGIN}
                            </div>
                          )}
                          <div className="ml-2">
                            { restrictPagesWithoutLogin ? renderCancelIconWithoutLogin() : ''}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={cn('d-flex justify-content-between')}>
                        {!(window.location.pathname.includes('/order-item/')) && (
                          <div className={cn('mt-1 mr-3')}>
                            {props.themeProperties && props.themeProperties.languages && props.themeProperties.languages.length > 1 && (
                            <button type="button" className="bg-transparent btn-outline-none border-0 bg-secondary-light p-0 d-flex justify-content-center align-items-center" aria-label="select language" onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); window.sessionStorage.setItem('languageModal', true); }}>
                              <i className="fa fa-language text-primary mr-2 od-tab-icon fa-lg fs-20" role="presentation" />
                            </button>
                            )}
                          </div>
                        )}
                        {handleRightIcon(rightIconType)}
                      </div>
                    )
                  }
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <Modal isOpen={isLanguageModalOpen} toggleModal={() => setIsLanguageModalOpen(!isLanguageModalOpen)}>
        <div className="card mb-0 od-app-background-color">
          <div className="p-10">
            <div className="col text-right btn-as-text color-secondary p-10">
              <button className="bg-transparent btn-outline-none border-0 bg-secondary-light p-0 d-flex icon-od fs-12 icon-od-close pull-right" type="button" aria-label="close" onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); window.sessionStorage.removeItem('languageModal'); }} />
            </div>
          </div>
          <div className="mx-0 justify-content-center align-items-center text-center">
            <div className={cn('d-flex flex-column text-center m-2 align-items-center', styles.languageListHeight)}>
              {props.themeProperties && props.themeProperties.languages && props.themeProperties.languages.map((language) => {
                return (
                  <button type="button" className={cn('btn btn-primary od-btn-primary rounded my-3 col-10', language.languageId === props.selectedLanguageId ? `${styles.selectedLanguage} od-selectedLanguage` : '')} onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); props.handleLanguageSelection(language.languageId); window.sessionStorage.removeItem('languageModal'); }}>
                    {language.languageName}
                  </button>);
              })}
            </div>
            {/* <div className="row mb-20 justify-content-center align-items-center text-center">
                  <button className="ml-20 btn btn-primary od-btn-primary col-4" type="button" disabled={isContinueDisabled} onClick={() => { setIsLanguageModalOpen(!isLanguageModalOpen); handleLanguageSelection(selectedLanguageId); }}>
                    {LABEL().CONTINUE}
                  </button>
                </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DesktopMenu;
