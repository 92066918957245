import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import Textfield from '@formElements/TextFieldFormik';
import Button from '@formElements/Button';
// import OneDineLogo from '@assets/images/oneDineLogoM.png';
// import FaceBook from '@assets/images/Facebook.png';
// import GooglePuls from '@assets/images/Google+.png';
// import GoogleLogin from './GoogleLogin';
// import FacebookLoginBtn from './FacebookLoginBtn';
import { APP_CONFIG } from '@constants';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import Loader from '@components/common/Loader/index';
import cn from 'classnames';
import style from './loginForm.module.css';
import { getObjectValue } from '../../utility/utills';

const LoginForm = (props) => {
  const {
    loading,
    setState,
    // setLink,
    // onSignIn,
    // clientId,
    // onFbSignIn
  } = props;
  const [store, setStore] = useState({});
  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  const isIos = JSON.parse(window.localStorage.getItem('ios'));
  useEffect(() => {
    let storeList = (props.checkInStoreInfo || props.popInData || {}).store || {};
    if ((props.checkInStoreInfo || {}).venue) {
      storeList = props.checkInStoreInfo.venue || {};
    }
    setStore(storeList);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div className="row content">
        {props.loading && <Loader />}
        <div className={cn('w-full pt-10 pl-20 pr-20 od-app-background-color', style.loginOutLook)}>
          {(store !== null && store.name) && (
            <div className="col-12 mt-2 p-0">
              <div className={cn('row mx-0 od-app-location-background', style.backgroundBlue, style.border)}>
                <div className="col-12 p-0">
                  <div className="row mx-0">
                    <div className="col-2 p-0 d-flex justify-content-center align-items-start">
                      <i className={cn('fas fa-map-marker-alt fs-24 od-color', style.colorWhite)} aria-hidden="true" />
                    </div>
                    <div className="col-10 p-0">
                      <span className={cn('fs-12 od-color mb-1', style.colorWhite)}>
                        {CUSTOM_MESSAGES().YOUR_CHECKED_LOCATION}
                      </span>
                      <div className={cn('fs-12 fw-600 od-color mb-1', style.colorWhite)}>{store !== null && store.name}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="text-center fs-20 fw-700 p-10">
            {CUSTOM_MESSAGES().SIGN_IN_TO_ACCOUNT}
          </div>
          <Form>
            <div className="form-group" id="usernameDiv">
              <Textfield
                name="username"
                type="text"
                label={LABEL().ENTER_USERNAME_EMAIL}
                ariaLabel="username"
                ariaLabelBy="usernameDiv"
                title="username"
                htmlFor={LABEL().ENTER_USERNAME_EMAIL}
              />
            </div>
            <div className="form-group mb-10" id="passwordDiv">
              <Textfield
                name="password"
                type="password"
                label={LABEL().ENTER_PASSWORD}
                ariaLabel="password"
                ariaLabelBy="passwordDiv"
                title="password"
                htmlFor={LABEL().ENTER_PASSWORD}
              />
            </div>
            <div className="mb-15 mt-15 text-center">
              <Link
                to={`${APP_CONFIG.BASE_URL}/forgotpassword`}
                className={cn('d-flex od-color justify-content-center fs-14 od-text-primary', style.colorBlue)}
              >
                {CUSTOM_MESSAGES().FORGOT_YOUR_PASSWORD}
              </Link>
            </div>
            <div className="no-margin ">
              {btnImgUrl ? (
                <div className="position-relative d-flex justify-content-center">
                  <Button type="submit" aria-label="Signin" loading={loading} customClass="pl-45" className="border-0 bg-transparent">
                    <Fragment>
                      <img src={btnImgUrl} className={cn(style.btnImgUrlSize, 'od-app-btn-primary')} alt="appDefaultButtonImage" />
                      <span className={cn(style.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().LOGIN}</span>
                    </Fragment>
                    <Fragment>
                      Loading
                    </Fragment>
                  </Button>
                </div>
              ) : (
                <Button type="submit" loading={loading} aria-label="signin" customClass="pl-45" className="btn btn-primary btn-block btn-md od-btn-primary">
                  <Fragment>
                    {LABEL().LOGIN}
                  </Fragment>
                  <Fragment>
                    Loading
                  </Fragment>
                </Button>
              )}
            </div>
            <div aria-label={CUSTOM_MESSAGES().LOGIN_WITH_CODE} className={cn('fs-14 my-3 text-center od-text-primary', style.colorBlue)}>
              <a href={() => setState(0)} className={cn('fs-14 my-3 text-center od-text-primary', style.colorBlue)} tabIndex="0" onClick={() => setState(0)} onKeyDown={(e) => { if (e.key === 'Enter') setState(0); }}>{CUSTOM_MESSAGES().LOGIN_WITH_CODE} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} /></a>
            </div>
            {/* <div className="mb-10 mb-10 text-center">
              <Link
                to={`${APP_CONFIG.BASE_URL}/signup`}
                className="d-flux justify-content-center fs-10"
              >
                {LABEL.SIGN_UP}
              </Link>
            </div> */}
            {/* <div className="margin-top20 text-center color-secondary fs-12">
              {CUSTOM_MESSAGES.CONNECT_WITH}
            </div> */}
            {/* <div className="text-center d-flex justify-content-center">
              <FacebookLoginBtn onFbSignIn={onFbSignIn} />
              <GoogleLogin onSignIn={onSignIn} clientId={clientId} />
            </div> */}
          </Form>
        </div>
      </div>
      <div className={cn('row footer fixed-bottom justify-content-center align-items-center pb-0', window.innerWidth > 1023 ? 'container d-flex m-auto' : 'row', style.footerStyle, isIos ? 'd-none' : '')}>
        {/* <button type="button" aria-label="eulaBtn" className={cn(style.linksPadding, 'btn-outline-none text-white bg-secondary-color')} onClick={() => setLink('eula')}>EULA</button>
        <button type="button" aria-label="termsBtn" className={cn(style.linksPadding, 'btn-outline-none text-white bg-secondary-color')} onClick={() => setLink('termsandconditions')}>TERMS</button>
        <button type="button" aria-label="privacyPolicyBtn" className={cn(style.linksPadding, 'btn-outline-none text-white bg-secondary-color')} onClick={() => setLink('privacyPolicy')}>PRIVACY POLICY</button>
        <button type="button" aria-label="myDataBtn" className="btn-outline-none border-0 text-white bg-secondary-color" onClick={() => setLink('mydata')}>MY DATA</button> */}

        <a href="https://onedine.com/privacy-policy/" rel="noopener noreferrer" target="_blank" style={{ color: 'white' }} className={cn(style.linksPadding)}>{LABEL().PRIVACY_POLICY}</a>
        <a href="https://onedine.com/terms-conditions/" rel="noopener noreferrer" target="_blank" style={{ color: 'white' }} className={cn(style.linksPadding)}>{LABEL().TERMS_AND_CONDITIONS}</a>
        <a href="https://onedine.com/cookies/" rel="noopener noreferrer" target="_blank" style={{ color: 'white' }} className={cn(style.linksPadding)}>{LABEL().COOKIES}</a>
        <button type="button" aria-label="my data" className="btn-outline-none border-0 text-white bg-secondary-color" onClick={() => { props.onCheckInMyData(); }}>{LABEL().MY_DATA}</button>
      </div>
    </Fragment>
  );
};

export default LoginForm;
