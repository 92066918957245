export default {
  LOGIN_SUCCESS: 'Succesfully Logged In',
  PASSWORD_MISMATCH: "Password and Confirm Password doesn't match!",
  OTP_SUCCESS: 'Code Sent successfully to your registered Mobile Number',

  // Notification_message
  SERVER_CALL: 'Notification sent',
  SERVER_SUCCESS_REQUEST: 'Server has received the request',
  MAIL_SENT_SUCCESS: 'Mail has been successfully sent',
  BASIC_UPDATE: 'Basic details successfully updated',
  NEW_PASSWORD_UPDATE: 'New Password successfully updated',
  MESSAGE_SENT: 'Message has been successfully sent',
  LOGIN_PASSWORD: 'please login with your new password',
  OTP_SENT_TO_MOBILE: 'A verification code has been sent to your mobile number.',
  SURVEY_SAVE: 'Thank you, survey has saved successfully',
  TABLE_UPDATE: 'Table Inforamtion updated successfully',
  LEAVE_TABLE: 'Leave Table request accepted successfully',
  NEW_CARD_ADDED: 'New card has been added successfully',
  CARD_DELETED_SUCCESSFULLY: 'Card has been deleted successfully',
  CARD_UPDATED_SUCCESSFULLY: 'Card has been updated successfully',
  SMS_CODE: 'Successfully verified your SMS code',
  LOYALTY_ACCOUNT_UPDATED_SUCCESSFULLY: 'Loyalty account has been updated successfully',
  LOYALTY_ACCOUNT_DELETED_SUCCESSFULLY: 'Loyalty account has been deleted successfully',
  MEMBER_ADDRESS_UPDATED_SUCCESSFULLY: 'Member address has been updated successfully',
  MEMBER_ADDRESS_ADDED_SUCCESSFULLY: 'Member address has been added successfully',
  MEMBER_ADDRESS_DELETED_SUCCESSFULLY: 'Member address has been deleted successfully'

};
