import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoginForm from '@components/LoginForm';
import Modal from '@components/common/Modal';
import {
  login,
  signInByGoogle,
  getGoogleClientId,
  signInByFacebook
} from '@reducers/oauth';
import SineUp from '@containers/Signup';
import { getregisteruserinfo } from '@reducers/otp';
import { setNotification } from '@reducers/notification';
import { APP_CONFIG } from '@constants';
import { toggleCancelButtonSamePage } from '@reducers/header';
// import EulaForGuest from '@components/EulaForGuest';
// import PrivacyPolicyForGuest from '@components/PrivacyPolicyForGuest';
// import TermsAndCondtionsForGuest from '@components/TermsAndCondtionsForGuest';
import MyData from '@components/MyData';
import { urlSearchParams } from '@utility/utills';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import { userInfo } from '@utility';

/*
**** State Description ****
* error: true/false based on it message will be shown
* message:  Message to show once any action is perfomed
*/
const Login = (props) => {
  const [state, setPageState] = useState(0);
  const [pageName, setPageName] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [toggleMyData, setToggleMyData] = useState(false);
  /* sucess of login function */
  const successLogin = (data, isRegistration) => {
    window.localStorage.setItem('userData', JSON.stringify(data));
    window.localStorage.setItem('isUserLogin', true);
    // window.location.href="/dashboard"
    if (props.isNotLoginPage) {
      props.onCallBack(data.user);
    } else if (urlSearchParams('backTo')) {
      props.history.replace(`${APP_CONFIG.BASE_URL}${urlSearchParams('backTo')}`);
    } else if (!isRegistration || (isRegistration && data.user.userName)) {
      props.history.replace(`${APP_CONFIG.BASE_URL}/dashboard`);
    } else {
      props.history.replace(`${APP_CONFIG.BASE_URL}/update-profile`);
    }
  };

  useEffect(() => {
    if (['eula', 'privacyPolicy', 'termsandconditions', 'mydata'].includes(pageName)) {
      setModalOpen(!modalOpen);
    }
  }, [pageName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigation = (e) => {
    if (e.key === 'Escape') {
      props.history.push('/dashboard');
    }
  };
  useEffect(() => {
    if (window.location.pathname === ('/login')) {
      document.addEventListener('keydown', navigation);
    }
    return () => document.removeEventListener('keydown', navigation);
  }, []);

  /* Failure of login function */
  const failureLogin = (err) => {
    props.setNotification({
      type: 'danger',
      message: err.message
    });
  };

  /* Login */
  const onSubmit = (data) => {
    const jsondata = JSON.stringify(data);
    /* login API */
    props.login(jsondata).then(successLogin).catch(failureLogin);
  };

  const validationSchemaLogin = () => (
    Yup.object().shape({
      username: Yup.string().required('Please enter your username.'),
      password: Yup.string().required('Please enter your password.').min(8)
    })
  );

  const handleToggle = (status) => {
    setModalOpen(status);
    setPageName('');
  };

  const onCheckInMyData = () => {
    if (userInfo.isUserLogin()) {
      setPageName('mydata');
    } else {
      setToggleMyData(true);
    }
  };

  const handleCheckIn = () => {
    props.history.push(`${APP_CONFIG.BASE_URL}/sensor-check?backTo=dashboard&pageName=mydata`);
  };

  const renderFormComponent = (renderProps) => {
    return (
      <>
        <Modal
          isOpen={modalOpen}
          toggleModal={() => { handleToggle(false); }}
        >
          {/* {pageName === 'eula' && (
            <div>
              <EulaForGuest handleToggle={handleToggle} />
            </div>
          )}
          {pageName === 'termsandconditions' && (
            <div>
              <TermsAndCondtionsForGuest handleToggle={handleToggle} />
            </div>
          )}
          {pageName === 'privacyPolicy' && (
            <div>
              <PrivacyPolicyForGuest handleToggle={handleToggle} />
            </div>
          )} */}
          {pageName === 'mydata' && (
            <div>
              <MyData handleToggle={handleToggle} />
            </div>
          )}
        </Modal>
        <Fragment>
          {state === 1 ? (
            <div className="container page">
              <LoginForm
                props={renderProps}
                loading={props.loading}
                // onSignIn={onSignIn}
                clientId={props.googleClientId}
                // onFbSignIn={onFbSignIn}
                state={state}
                setState={nState => setPageState(nState)}
                setLink={links => setPageName(links)}
                checkInStoreInfo={props.checkInStoreInfo}
                popInData={props.popInData}
                onCheckInMyData={onCheckInMyData}
              />
            </div>
          ) : (
            <SineUp setPageState={nState => setPageState(nState)} successLogin={successLogin} history={props.history} isNotLoginPage={props.isNotLoginPage} />
          )}
        </Fragment>
      </>
    );
  };

  return (
    <>
      <div className="row mx-0">
        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 p-0">
          <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchemaLogin}
            render={renderFormComponent}
          />
        </div>
      </div>
      <Modal isOpen={toggleMyData} toggleModal={() => { setToggleMyData(!toggleMyData); }}>
        <div className="card mb-0 od-app-background-color">
          <div className="p-10">
            <div className="col text-right btn-as-text color-secondary">
              <button type="button" className="bg-transparent border-0 icon-od icon-od-close od-color" aria-label="close" onClick={() => { setToggleMyData(false); }} />
            </div>
          </div>
          <div className="mx-0 justify-content-center align-items-center text-center">
            <div className="fs-18 fw-600 text-center p-1">
              {CUSTOM_MESSAGES().MY_DATA_CHECK_IN_MES}
            </div>
            <div className="row mt-20 mb-20 justify-content-center align-items-center text-center">
              <button className="mr-20 btn btn-secondary od-btn-secondary col-4" type="button" onClick={() => { setToggleMyData(false); }}>
                {LABEL().MYDATA_CANCEL}
              </button>
              <button className="ml-20 btn btn-primary od-btn-primary col-4" type="button" onClick={() => { handleCheckIn(); }}>
                {LABEL().MYDATA_OK}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = ({ oauth, controller, header }) => ({
  loading: oauth.loading,
  googleClientId: oauth.googleClientId,
  cancelOnPage: header.cancelOnPage,
  popInData: controller.popInData,
  checkInStoreInfo: controller.checkInStoreInfo,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  getregisteruserinfo,
  setNotification,
  signInByGoogle,
  getGoogleClientId,
  signInByFacebook,
  toggleCancelButtonSamePage,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
