import pako from 'pako';
import { userInfo } from '@utility';
import CUSTOM_MESSAGES from '../constants/customMessage';

export const getObjectValue = (cb, defaultValue) => {
  try {
    return cb();
  } catch (e) {
    // console.log(e);
    return defaultValue;
  }
};
export const urlSearchParams = (name) => {
  // eslint-disable-next-line
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
};
const decriptRespose = (base64Data) => {
  const strData = atob(base64Data);
  // Convert binary string to character-number array
  const charData = strData.split('').map((x) => {
    return x.charCodeAt(0);
  });
  // Turn number array into byte-array
  const binData = new Uint8Array(charData);
  // Pako magic
  const data = pako.inflate(binData);
  // Convert gunzipped byteArray back to ascii string:
  // const strDataResult = String.fromCharCode.apply(null, new Uint16Array(data));
  return JSON.parse(new TextDecoder('utf-8').decode(data));
};

const getSmallCircularImages = (menuItem, circularImages) => {
  let imageUrl = '';
  if (circularImages && menuItem.smallCircleImageUrl) {
    imageUrl = menuItem.smallCircleImageUrl;
  } else {
    imageUrl = menuItem.smallImageUrl;
  }
  return imageUrl;
};

const getMediumCircularImages = (menuItem, circularImages) => {
  let imageUrl = '';
  if (circularImages && menuItem.mediumCircleImageUrl) {
    imageUrl = menuItem.mediumCircleImageUrl;
  } else {
    imageUrl = menuItem.mediumImageUrl;
  }
  return imageUrl;
};

// const getLargeCircularImages = (menuItem, circularImages) => {
//   let imageUrl = '';
//   if (circularImages && menuItem.largeCircleImageUrl) {
//     imageUrl = menuItem.largeCircleImageUrl;
//   } else {
//     imageUrl = menuItem.largeImageUrl;
//   }
//   return imageUrl;
// };

const getThumnailCircularImages = (menuItem, circularImages) => {
  let imageUrl = '';
  if (circularImages && menuItem.thumbnailCircleImageUrl) {
    imageUrl = menuItem.thumbnailCircleImageUrl;
  } else {
    imageUrl = menuItem.thumbnailImageUrl;
  }
  return imageUrl;
};

export const getImageUrl = (menuItem, screen, screenWidth, defaultImageUrl, columnStyle, circularImages) => {
  let imageUrl = '';
  if (screen === 'order') {
    if (screenWidth < 1024) {
      if (columnStyle && columnStyle.doubleWidth) {
        imageUrl = menuItem.mediumImageUrl || menuItem.mediumCircleImageUrl ? getMediumCircularImages(menuItem, circularImages) : menuItem.imageUrl;
      } else {
        imageUrl = menuItem.smallImageUrl || menuItem.smallCircleImageUrl ? getSmallCircularImages(menuItem, circularImages) : menuItem.imageUrl;
      }
    } else {
      imageUrl = menuItem.mediumImageUrl || menuItem.mediumCircleImageUrl ? getMediumCircularImages(menuItem, circularImages) : menuItem.imageUrl;
    }
  } else if (screen === 'itemDetail') {
    if (screenWidth < 1024) {
      imageUrl = menuItem.mediumImageUrl || menuItem.mediumCircleImageUrl ? getMediumCircularImages(menuItem, circularImages) : menuItem.imageUrl;
    } else {
      imageUrl = menuItem.largeImageUrl ? menuItem.largeImageUrl : menuItem.imageUrl;
    }
  } else if (screen === 'cart') {
    imageUrl = menuItem.thumbnailImageUrl || menuItem.thumbnailCircleImageUrl ? getThumnailCircularImages(menuItem, circularImages) : menuItem.imageUrl;
  }
  // console.log('Image', imageUrl);
  return imageUrl || defaultImageUrl;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

const isNumericInput = (event) => {
  const key = event.keyCode;
  return ((key >= 48 && key <= 57) || (key >= 96 && key <= 105));
};

const isModifierKey = (event) => {
  const key = event.keyCode;
  return (event.shiftKey === true || key === 35 || key === 36) || (key === 8 || key === 9 || key === 13 || key === 46) || (key > 36 && key < 41) || ((event.ctrlKey === true || event.metaKey === true) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90));
};

export const enforceFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

export const formatInputPhoneNumber = (event) => {
  if (isModifierKey(event)) { return; }
  const target = event.target;
  const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip}`;
  }
};

export const validateCountryCode = (countryCode) => {
  if (!countryCode || countryCode.length === 0) {
    return CUSTOM_MESSAGES.UTILLS_SCREEN_REQ_COUNTRY_CODE;
  }

  if (countryCode.length > 5 || !/^[0-9+]*$/.test(countryCode)) {
    return CUSTOM_MESSAGES.UTILLS_SCREEN_INVALID_COUNTRY_CODE;
  }

  return null;
};

export const validatePhoneNumber = (countryCode, phoneNumber) => {
  let minLength = 10;
  let maxLength = 10;

  const countryCodeStr = countryCode ? countryCode.toString() : '';
  if (countryCodeStr && countryCodeStr !== '+1' && countryCodeStr !== '1') {
    minLength = 7;
    maxLength = 15;
  }

  const phoneNumberStr = phoneNumber ? phoneNumber.toString() : '';
  if (!phoneNumberStr
    || phoneNumberStr.length === 0
    || phoneNumberStr.length < minLength
    || phoneNumberStr.length > maxLength
    || !/^[0-9]*$/.test(phoneNumberStr)) {
    return CUSTOM_MESSAGES.UTIL_SCREEN_INVAL_MOBILE_NUMBER;
  }

  return null;
};

export const renderHeaderData = () => {
  const popInData = JSON.parse(window.sessionStorage.getItem('popIn'));
  const userData = userInfo.getUserBasicDetails();
  return ({
    checkHeaderId: popInData && popInData.checkHeaderId ? popInData.checkHeaderId : null,
    brandId: popInData && popInData.store && popInData.store.brandId ? popInData.store.brandId : null,
    storeId: popInData && popInData.store && popInData.store.id ? popInData.store.id : null,
    memberId: userData && userData.id ? userData.id : null,
    sessionId: window.sessionStorage.getItem('sessionId') || null
  });
};

export const deconstructStringUrl = (string) => {
  let message = '';
  let link = '';
  const splitText = string.split('');
  let linkCheck = false;
  let linkIndex = false;
  splitText.forEach((char, i) => {
    if (char === '<') {
      linkCheck = true;
      message += '¿';
    }
    if (char === '>') {
      linkIndex = i + 1;
    }
    if (linkIndex === i && char !== '<') {
      linkCheck = false;
      linkIndex = false;
    }
    if (linkCheck) {
      link += char;
    } else {
      message += char;
    }
  });
  link = link.replaceAll('<', '').split('>').filter(d => d);
  link = link.map((i) => {
    return i.split('|');
  }).flat();
  const splitMessage = message.split('');
  let concatChar = '';
  let index = 0;
  splitMessage.forEach((char) => {
    const replaceCheck = ['¿'].includes(char);
    if (replaceCheck) {
      concatChar += `¿#?${link[index]}¿`;
      index += 2;
    } else {
      concatChar += char;
    }
  });
  return [link, concatChar];
};

export const onKeyDown = (event, func, arg) => {
  if (event.key === 'Enter' || event.key === ' ') {
    func(arg);
  }
};

export const getTokenInfoSharedKey = () => '4A0A74E140134173A0997A8F593D5170';

/**
 * Pseudo-random string generator
 * http://stackoverflow.com/a/27872144/383904
 * @param {Integer} length Desired length
 * @param {String} alphaOrNumeric (Optional) Options: "alpha", "numeric"
 * @return {String}
 */
/* eslint-disable */
export const randomString = (length, alphaOrNumeric) => {
  alphaOrNumeric = alphaOrNumeric && alphaOrNumeric.toLowerCase();
  var str = "",
    i = 0,
    min = alphaOrNumeric == "alpha" ? 10 : 0,
    max = alphaOrNumeric == "numeric" ? 10 : 62;
  for (; i++ < length;) {
    var r = Math.random() * (max - min) + min << 0;
    str += String.fromCharCode(r += r > 9 ? r < 36 ? 55 : 61 : 48);
  }
  return str;
};
/* eslint-enable */

// Source: https://stackoverflow.com/a/60467595/670028
/* eslint-disable */
export const md5 = (inputString) => {
  var hc = '0123456789abcdef';
  function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
  function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
  function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
  function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
  function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
  function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
  function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
  function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
  function sb(x) {
      var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
      for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
      blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
  }
  var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
  for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
      a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
      b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
      c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
      d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
      a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
      b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
      c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
      d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
      a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
      b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
      c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
      d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
      a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
      b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
      c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
      d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
      a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
      b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
      c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
      d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
      a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
      b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
  }
  return rh(a)+rh(b)+rh(c)+rh(d);
};

// Source: https://dirask.com/posts/JavaScript-base64-with-Unicode-support-D6BdmD
export const toUtf8 = function(text) {
  	var surrogate = encodeURIComponent(text);
  	var result = '';
    for (var i = 0; i < surrogate.length;) {
        var character = surrogate[i];
		i += 1;
        if (character == '%') {
        	var hex = surrogate.substring(i, i += 2);
			if (hex) {
				result += String.fromCharCode(parseInt(hex, 16));
			}
        } else {
        	result += character;
        }
    }
    return result;
};

// Source: https://dirask.com/posts/JavaScript-base64-with-Unicode-support-D6BdmD
export const fromUtf8 = function(text) {
  	var result = '';
    for (var i = 0; i < text.length; ++i) {
        var code = text.charCodeAt(i);
        result += '%';
        if (code < 16) {
          	result += '0';
        }
        result += code.toString(16);
    }
    return decodeURIComponent(result);
};

// Source: https://dirask.com/posts/JavaScript-base64-with-Unicode-support-D6BdmD
export const base64Encode = (text) => {
  return window.btoa(toUtf8(text));
};

// Source: https://dirask.com/posts/JavaScript-base64-with-Unicode-support-D6BdmD
export const base64Decode = (base64) => {
  return fromUtf8(window.atob(base64));
};

// Source: https://stackoverflow.com/a/8809472/670028
export const generateUUID = () => {
  let
    d = new Date().getTime(),
    d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
  });
};

export const accessibleDiv = (id) => {
  const presentationTags = document.querySelectorAll('div[role="presentation"]') || [];
  if(presentationTags.length) {
    presentationTags.forEach((btn) => {
      if (!btn.classList.contains('d-none') && !btn.classList.contains('ignoreDivs') && typeof btn.onclick === 'function') {
        btn.setAttribute('role', 'button');
        btn.setAttribute('tabindex', '0');
      }
    });
    const parentDiv = document.getElementById(id);
    parentDiv && parentDiv.addEventListener('keydown', (e) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        if (e.target.nodeName !== "BUTTON") {
          e.target.click();
        }
      }
    });
  }
};

export const getCardType = (number) => {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) { return 'Visa'; }

  // Mastercard
  if (/^5[1-5][0-9]{14}$/.test(number)) { return 'Mastercard'; }

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) { return 'AMEX'; }

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null) { return 'Discover'; }

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) { return 'Diners'; }

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null) { return 'Diners - Carte Blanche'; }

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null) { return 'JCB'; }

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null) { return 'Visa Electron'; }

  return '';
};

const luhnCheck = (val) => {
  let sum = 0;
  for (let i = 0; i < val.length; i += 1) {
    // eslint-disable-next-line radix
    let intVal = parseInt(val.substr(i, 1));
    if (i % 2 === 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal = 1 + (intVal % 10);
      }
    }
    sum += intVal;
  }
  return (sum % 10) === 0;
};

export const validateCardNumber = (number) => {
  const regex = new RegExp('^[0-9]{14,19}$');
  if (!regex.test(number)) { return false; }
  return luhnCheck(number);
};

// eslint-disable-next-line func-names
export const ValidateExpiryDate = (function () {
  function Expiration() { }
  Expiration.prototype.validate = (exp) => {
    let m; let
      y;
    if (!exp) {
      return false;
    }
    const split = exp.split('/');
    // eslint-disable-next-line no-unused-expressions, no-sequences
    (m = split[0]), (y = split[1]);
    if (!m || !y) {
      return false;
    }
    m = m.replace(/^\s+|\s+$/g, '');
    y = y.replace(/^\s+|\s+$/g, '');
    if (y.length === 5) {
      return false;
    }
    if (!/^\d+$/.test(m)) {
      return false;
    }
    if (!/^\d+$/.test(y)) {
      return false;
    }
    if (y.length === 2) {
      y = new Date()
        .getFullYear()
        .toString()
        .slice(0, 2) + y;
    }
    const month = parseInt(m, 10);
    const year = parseInt(y, 10);
    if (!(month >= 1 && month <= 12)) {
      return false;
    }
    // creates date as 1 day past end of
    // expiration month since JS months
    // are 0 indexed
    return new Date(year, month, 1) > new Date();
  };
  return Expiration;
}());

/* eslint-enable */

export default {
  decriptRespose,
  getObjectValue,
  urlSearchParams,
  getImageUrl,
  formatPhoneNumber,
  validateCountryCode,
  formatInputPhoneNumber,
  validatePhoneNumber,
  enforceFormat,
  renderHeaderData,
  onKeyDown,
  deconstructStringUrl,
  getTokenInfoSharedKey,
  randomString,
  md5,
  accessibleDiv,
  validateCardNumber,
  ValidateExpiryDate,
  getCardType
};
