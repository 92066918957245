import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Loadable from 'react-loadable';
import OnRouteChange from '@containers/common/OnRouteChange/index';
import PageLoader from '@components/common/PageLoader';
import Login from '@containers/Login';
import ForgotPassword from '@containers/ForgotPassword';
import Header from '@containers/common/Header';
import Footer from '@containers/common/Footer';
import Notification from '@components/common/notification';
import notFound from '@components/404NotFound/index';
import Div100vh from 'react-div-100vh';
import { APP_CONFIG } from '@constants';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (window.sessionStorage.getItem('popIn') ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/',
        state: {
          from: props.location
        }
      }}
      />
    )
    )}
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (!window.localStorage.getItem('isUserLogin') ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/dashboard',
        state: {
          from: props.location
        }
      }}
      />
    )
    )}
  />
);

const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return (
      <div>
          Sorry, unable to load the page
      </div>
    );
  }
  return null;
};

const DashBoard = Loadable({
  loader: () => import('@containers/DashBoard'),
  loading: LoadingComponent,
});

const Profile = Loadable({
  loader: () => import('@containers/Profile'),
  loading: LoadingComponent,
});

const Offers = Loadable({
  loader: () => import('@containers/Offers'),
  loading: LoadingComponent,
});

const Wallet = Loadable({
  loader: () => import('@containers/Wallet'),
  loading: LoadingComponent,
});

const LoyaltyAccounts = Loadable({
  loader: () => import('@containers/LoyaltyAccounts'),
  loading: LoadingComponent,
});

const OrderHistory = Loadable({
  loader: () => import('@containers/OrderHistory'),
  loading: LoadingComponent,
});

const OrderDetails = Loadable({
  loader: () => import('@containers/OrderDetails'),
  loading: LoadingComponent,
});
const ProfileFeatureDetails = Loadable({
  loader: () => import('@containers/ProfileFeatureDetails'),
  loading: LoadingComponent,
});

const SignUp = Loadable({
  loader: () => import('@containers/Signup'),
  loading: LoadingComponent,
});

const UpdateProfile = Loadable({
  loader: () => import('@containers/Signup/profileInfo'),
  loading: LoadingComponent,
});

const QrScanner = Loadable({
  loader: () => import('@containers/QrScanner'),
  loading: LoadingComponent,
});

const SplitCheckList = Loadable({
  loader: () => import('@containers/SplitCheck'),
  loading: LoadingComponent,
});

const ItemList = Loadable({
  loader: () => import('@components/itemList'),
  loading: LoadingComponent,
});

const SplitOption = Loadable({
  loader: () => import('@components/splitOption'),
  loading: LoadingComponent,
});

const KioskText = Loadable({
  loader: () => import('@components/KioskText'),
  loading: LoadingComponent,
});

const Survey = Loadable({
  loader: () => import('@containers/survey'),
  loading: LoadingComponent,
});

const Receipt = Loadable({
  loader: () => import('@containers/Receipt'),
  loading: LoadingComponent,
});

const ServerError = Loadable({
  loader: () => import('@components/ServerError'),
  loading: LoadingComponent,
});

const CustomersOpinion = Loadable({
  loader: () => import('@components/CustomersOpinion'),
  loading: LoadingComponent,
});

const SplitedCheckList = Loadable({
  loader: () => import('@containers/SplitCheck'),
  loading: LoadingComponent,
});

const Home = Loadable({
  loader: () => import('@containers/Home'),
  loading: LoadingComponent,
});

const CheckIn = Loadable({
  loader: () => import('@containers/CheckIn'),
  loading: LoadingComponent,
});

const TableNumber = Loadable({
  loader: () => import('@containers/TableEntry'),
  loading: LoadingComponent,
});

const ReceiptConfirmationMessage = Loadable({
  loader: () => import('@components/ReceiptConfirmationMessage'),
  loading: LoadingComponent,
});
const CallServer = Loadable({
  loader: () => import('@containers/CallServer'),
  loading: LoadingComponent,
});
const Tips = Loadable({
  loader: () => import('@containers/Tips'),
  loading: LoadingComponent,
});
const ViewCheck = Loadable({
  loader: () => import('@containers/ViewCheck'),
  loading: LoadingComponent,
});
const LeaveTable = Loadable({
  loader: () => import('@containers/LeaveTable'),
  loading: LoadingComponent,
});
const Addcard = Loadable({
  loader: () => import('@containers/AddCard'),
  loading: LoadingComponent
});
const PlanetPayment = Loadable({
  loader: () => import('@containers/PlanetPayment'),
  loading: LoadingComponent
});
const PaymentSuccess = Loadable({
  loader: () => import('@containers/PaymentSuccess'),
  loading: LoadingComponent
});
const PayonlyMode = Loadable({
  loader: () => import('@containers/PayOnlyMode'),
  loading: LoadingComponent
});
const Orders = Loadable({
  loader: () => import('@containers/MenuOrder'),
  loading: LoadingComponent
});

const SelectedCart = Loadable({
  loader: () => import('@containers/SelectedCart'),
  loading: LoadingComponent
});

const SensorCheckIn = Loadable({
  loader: () => import('@containers/SensorCheckIn'),
  loading: LoadingComponent
});

const ApplePay = Loadable({
  loader: () => import('@containers/ApplePay'),
  loading: LoadingComponent
});

const OrderItemDetail = Loadable({
  loader: () => import('@containers/OrderItemDetail'),
  loading: LoadingComponent
});
const ModifyItem = Loadable({
  loader: () => import('@components/ModifyItem'),
  loading: LoadingComponent
});
const TableData = Loadable({
  loader: () => import('@containers/TableData'),
  loading: LoadingComponent
});

const LoyaltyAction = Loadable({
  loader: () => import('@containers/LoyaltyAction'),
  loading: LoadingComponent
});

const LookUp = Loadable({
  loader: () => import('@containers/LookUp'),
  loading: LoadingComponent
});

const TermsAndCondtions = Loadable({
  loader: () => import('@components/TermsAndCondtions'),
  loading: LoadingComponent
});
const PrivacyPolicy = Loadable({
  loader: () => import('@components/PrivacyPolicy'),
  loading: LoadingComponent
});
const Eula = Loadable({
  loader: () => import('@components/Eula'),
  loading: LoadingComponent
});
const MyData = Loadable({
  loader: () => import('@components/MyData'),
  loading: LoadingComponent
});
const StoreSelection = Loadable({
  loader: () => import('@containers/StoreSelection'),
  loading: LoadingComponent
});
const SensorAssignment = Loadable({
  loader: () => import('@containers/SensorAssignment'),
  loading: LoadingComponent
});
const PickUpTime = Loadable({
  loader: () => import('@components/PickUpTime'),
  loading: LoadingComponent
});

const SpotScreen = Loadable({
  loader: () => import('@containers/SpotScreen'),
  loading: LoadingComponent
});

const PickStore = Loadable({
  loader: () => import('@containers/PickStore'),
  loading: LoadingComponent
});

const GuestScreen = Loadable({
  loader: () => import('@containers/SpotScreen/guestSpot'),
  loading: LoadingComponent
});

const GroupOrder = Loadable({
  loader: () => import('@containers/GroupOrder/groupOrder'),
  loading: LoadingComponent
});

const GroupOrderJoin = Loadable({
  loader: () => import('@containers/GroupOrder/groupOrderNewCheck'),
  loading: LoadingComponent
});

const GroupOrderGuestJoin = Loadable({
  loader: () => import('@containers/GroupOrder/groupOrderGuestJoinCheck'),
  loading: LoadingComponent
});

const SpotCode = Loadable({
  loader: () => import('@components/SpotCode'),
  loading: LoadingComponent
});

const SplitCheck = Loadable({
  loader: () => import('@containers/SplitEvenly'),
  loading: LoadingComponent
});

const PayAnAmount = Loadable({
  loader: () => import('@containers/PayAnAmount'),
  loading: LoadingComponent
});

// const MultiCart = Loadable({
//   loader: () => import('@containers/MultiCart'),
//   loading: LoadingComponent
// });

const MenuCategoryItems = Loadable({
  loader: () => import('@containers/MenuCategoryItems'),
  loading: LoadingComponent
});

const MenuCategoryDetail = Loadable({
  loader: () => import('@containers/MenuCategoryDetail'),
  loading: LoadingComponent
});

const MenuItemDetail = Loadable({
  loader: () => import('@containers/WishList/menuItemDetail'),
  loading: LoadingComponent
});

const WishList = Loadable({
  loader: () => import('@containers/WishList'),
  loading: LoadingComponent
});

const Account = Loadable({
  loader: () => import('@containers/Account'),
  loading: LoadingComponent
});

const Subsciption = Loadable({
  loader: () => import('@containers/Subscription'),
  loading: LoadingComponent
});

const UpSell = Loadable({
  loader: () => import('@containers/UpSell'),
  loading: LoadingComponent
});

const Refill = Loadable({
  loader: () => import('@containers/Refill'),
  loading: LoadingComponent
});

// const MallStore = Loadable({
//   loader: () => import('@containers/MenuOrder'),
//   loading: LoadingComponent
// });

const PickMenu = Loadable({
  loader: () => import('@containers/PickMenu'),
  loading: LoadingComponent
});

const BrowseFilter = Loadable({
  loader: () => import('@components/BrowseFilter'),
  loading: LoadingComponent
});

const ProfileFeature = Loadable({
  loader: () => import('@containers/ProfileFeature'),
  loading: LoadingComponent
});

const ProfileOrderHistory = Loadable({
  loader: () => import('@containers/ProfileOrderHistory'),
  loading: LoadingComponent
});

const Pdf = Loadable({
  loader: () => import('@containers/Pdf'),
  loading: LoadingComponent
});

const CheckNumber = Loadable({
  loader: () => import('@containers/CheckNumber'),
  loading: LoadingComponent
});

const PaymentSelectItems = Loadable({
  loader: () => import('@containers/PaymentSelectItems'),
  loading: LoadingComponent
});


const PaymentOption = Loadable({
  loader: () => import('@containers/PaymentOption'),
  loading: LoadingComponent
});

const TableBooking = Loadable({
  loader: () => import('@containers/TableBooking'),
  loading: LoadingComponent
});

const TableBookingList = Loadable({
  loader: () => import('@containers/TableBookingList'),
  loading: LoadingComponent
});

const ReactPdf = Loadable({
  loader: () => import('@components/ReactPdfComponent'),
  loading: LoadingComponent
});

const TableConfirmation = Loadable({
  loader: () => import('@containers/TableConfirmation'),
  loading: LoadingComponent
});

const WalkupSensorBookingContainer = Loadable({
  loader: () => import('@containers/WalkupSensorBookingContainer'),
  loading: LoadingComponent
});

const FutureOrderScreen = Loadable({
  loader: () => import('@containers/FutureOrderScreen'),
  loading: LoadingComponent
});

const UsaEpay = Loadable({
  loader: () => import('@containers/UsaEpay'),
  loading: LoadingComponent
});

const LoyaltyOffers = Loadable({
  loader: () => import('@containers/LoyaltyOffers'),
  loading: LoadingComponent
});

const ViewReceipt = Loadable({
  loader: () => import('@containers/ViewReceipt'),
  loading: LoadingComponent
});

const GiftCardViewReceipt = Loadable({
  loader: () => import('@containers/ViewReceipt/giftCardVeiwReceipt'),
  loading: LoadingComponent
});

const Preview = Loadable({
  loader: () => import('@containers/Preview'),
  loading: LoadingComponent
});

const OrderSuccess = Loadable({
  loader: () => import('@containers/OrderSuccess'),
  loading: LoadingComponent
});

const AcceptTerms = Loadable({
  loader: () => import('@containers/AcceptTerms'),
  loading: LoadingComponent
});

const PayMyItems = Loadable({
  loader: () => import('@containers/PayMyItems'),
  loading: LoadingComponent
});

const PayByStage = Loadable({
  loader: () => import('@containers/PayByStage'),
  loading: LoadingComponent
});

const LinkedItem = Loadable({
  loader: () => import('@containers/LinkedItem'),
  loading: LoadingComponent
});

const MultiCartReceipt = Loadable({
  loader: () => import('@containers/MultiCartReceipt'),
  loading: LoadingComponent
});

const Stripe = Loadable({
  loader: () => import('@containers/Stripe'),
  loading: LoadingComponent
});

const ManageAddress = Loadable({
  loader: () => import('@containers/ManageAddress'),
  loading: LoadingComponent,
});


const Spreedly = Loadable({
  loader: () => import('@containers/Spreedly'),
  loading: LoadingComponent
});

const PayByGuest = Loadable({
  loader: () => import('@containers/PayByGuest'),
  loading: LoadingComponent
});

const KioskPaymentInfo = Loadable({
  loader: () => import('@containers/KioskPaymentInfo'),
  loading: LoadingComponent
});

const KioskPaymentMethod = Loadable({
  loader: () => import('@containers/KioskPaymentMethod'),
  loading: LoadingComponent
});

const KioskReceiptPage = Loadable({
  loader: () => import('@containers/KioskReceiptPage'),
  loading: LoadingComponent
});

const KioskReceiptMethod = Loadable({
  loader: () => import('@containers/KioskReceiptMethod'),
  loading: LoadingComponent
});

const KioskGuestInfoPage = Loadable({
  loader: () => import('@containers/KioskGuestInfoPage'),
  loading: LoadingComponent
});

const KioskLandingPage = Loadable({
  loader: () => import('@components/KioskLandingPage'),
  loading: LoadingComponent
});

const KioskPaymentPage = Loadable({
  loader: () => import('@containers/KioskPaymentPage'),
  loading: LoadingComponent
});

const ContactRestaurant = Loadable({
  loader: () => import('@containers/ContactRestaurant'),
  loading: LoadingComponent
});

const TestPage = Loadable({
  loader: () => import('@containers/TestContainer'),
  loading: LoadingComponent
});

const LoyaltyOfferDetail = Loadable({
  loader: () => import('@containers/LoyaltyOfferDetails'),
  loading: LoadingComponent
});

const VideoOnScroll = Loadable({
  loader: () => import('@components/PlayVideoOnScroll'),
  loading: LoadingComponent
});

const BookMyVoucher = Loadable({
  loader: () => import('@components/BookMyVoucher'),
  loading: LoadingComponent
});

const LoyaltyLink = Loadable({
  loader: () => import('@containers/LoyaltyLink'),
  loading: LoadingComponent
});

const MyCheck = Loadable({
  loader: () => import('@containers/MyCheck'),
  loading: LoadingComponent
});

const CurrentUserLocation = Loadable({
  loader: () => import('@containers/CurrentLocation'),
  loading: LoadingComponent
});

const LocationReset = Loadable({
  loader: () => import('@containers/CurrentLocation/resetInfo'),
  loading: LoadingComponent
});

const LocationRequest = Loadable({
  loader: () => import('@containers/LocationRequest'),
  loading: LoadingComponent
});

const LocationConfirmation = Loadable({
  loader: () => import('@components/LocationConfirmation'),
  loading: LoadingComponent
});

const BrandStoreLocator = Loadable({
  loader: () => import('@components/BrandStoreLocator'),
  loading: LoadingComponent
});

const BrandStoreLocationList = Loadable({
  loader: () => import('@containers/BrandStoreLocationList'),
  loading: LoadingComponent
});

const BrandStoreDetails = Loadable({
  loader: () => import('@components/BrandStoreLocationList/BrandStoreDetails'),
  loading: LoadingComponent
});

const EmployeeTableReassignment = Loadable({
  loader: () => import('@containers/EmployeeTableReassignment'),
  loading: LoadingComponent
});

const WaitList = Loadable({
  loader: () => import('@containers/WaitList'),
  loading: LoadingComponent
});

const WaitlistConfirmation = Loadable({
  loader: () => import('@containers/WaitlistConfirmation'),
  loading: LoadingComponent
});

const ManageWaitList = Loadable({
  loader: () => import('@containers/ManageWaitList'),
  loading: LoadingComponent
});

const ManageGiftCards = Loadable({
  loader: () => import('@containers/GiftCard'),
  loading: LoadingComponent
});

const ManageCoupon = Loadable({
  loader: () => import('@containers/Coupon'),
  loading: LoadingComponent
});

const KDS = Loadable({
  loader: () => import('@components/KDS/index.jsx'),
  loading: LoadingComponent
});

const ManageCharity = Loadable({
  loader: () => import('@containers/Charity'),
  loading: LoadingComponent,
});

const OptIn = Loadable({
  loader: () => import('@containers/OptIn'),
  loading: LoadingComponent,
});

const OptinTileAndPayment = Loadable({
  loader: () => import('@containers/OptIn/optinTileAndPayment'),
  loading: LoadingComponent,
});

const SpotConfirmation = Loadable({
  loader: () => import('@components/SpotConfirmation'),
  loading: LoadingComponent
});

export default (
  <Div100vh>
    <Router>
      <div className="od-font-family od-color">
        <Header />
        <Notification />
        <OnRouteChange>
          <Switch>
            {/* dont add any routers bellow */}
            <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/`} component={DashBoard} />
            <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/login`} component={Login} />
            <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/forgotpassword`} component={ForgotPassword} />
            <PublicRoute exact path={`${APP_CONFIG.BASE_URL}/signup`} component={SignUp} />
            {/* Private Routes */}
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/home`} component={Home} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/stripe`} component={Stripe} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spreedly`} component={Spreedly} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/dashboard`} component={DashBoard} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/scan-qr/:id`} component={QrScanner} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spot-code`} component={SpotCode} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/orders`} component={Orders} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/linked-item`} component={LinkedItem} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/profile/:key`} component={Profile} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/offers`} component={Offers} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/wallet`} component={Wallet} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/wallet/loyalties`} component={LoyaltyAccounts} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/wallet/address`} component={ManageAddress} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/add-card`} component={Addcard} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/planet/payment`} component={PlanetPayment} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/orders-history`} component={OrderHistory} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/orders/details/:ticketId`} component={OrderDetails} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/profile-feature/details/:ticketId`} component={ProfileFeatureDetails} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk`} component={KioskText} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/get-table`} component={TableNumber} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/apple-pay`} component={ApplePay} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/tip`} component={Tips} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/selected-cart/:menuItemId`} component={SelectedCart} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/order-item/:menuTypeId/:menuItemId/:internalId/:type`} component={OrderItemDetail} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/itemList`} component={ItemList} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/splitOption`} component={SplitOption} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/splitCheck-list`} component={SplitCheckList} />
            {/* <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/survey/:surveyType`} component={Survey} /> */}
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/survey`} component={Survey} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/receipt`} component={Receipt} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/receipt/:id`} component={Receipt} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/restaurant-home/:tableCode`} component={QrScanner} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/customeropinion`} component={CustomersOpinion} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/split-check`} component={SplitedCheckList} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/serverError`} component={ServerError} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/callserver`} component={CallServer} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/receiptconfirmation`} component={ReceiptConfirmationMessage} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/leavetable`} component={LeaveTable} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/viewcheck`} component={ViewCheck} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/payment-success`} component={PaymentSuccess} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/check-paid`} component={PayonlyMode} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/order-item/:menuTypeId/:menuItemId`} component={OrderItemDetail} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/order-child/:menuTypeId/:menuItemId`} component={OrderItemDetail} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/loyalty`} component={LoyaltyAction} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/gift-card`} component={ManageGiftCards} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/coupon`} component={ManageCoupon} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/lookup/:stage`} component={LookUp} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/modify-item`} component={ModifyItem} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/table-data/:id`} component={TableData} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/termsandconditions`} component={TermsAndCondtions} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/privacy-policy`} component={PrivacyPolicy} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/eula`} component={Eula} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/mydata`} component={MyData} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/store-selection`} component={StoreSelection} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/sensor-assignment/:storeId`} component={SensorAssignment} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/update-profile`} component={UpdateProfile} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/spot-screen`} component={SpotScreen} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pick-store`} component={PickStore} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/guest-spot`} component={GuestScreen} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/group-order`} component={GroupOrder} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/group-order-join`} component={GroupOrderJoin} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/group-order-guest-join`} component={GroupOrderGuestJoin} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pickup-time`} component={PickUpTime} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/bookmyvoucher`} component={BookMyVoucher} />
            {/* <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/multi-cart`} component={MultiCart} /> */}
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/upsell`} component={UpSell} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/refill`} component={Refill} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/browse-category`} component={MenuCategoryItems} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/category-detail/:screenId`} component={MenuCategoryDetail} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/menuItem-detail`} component={MenuItemDetail} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/wishList`} component={WishList} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/account`} component={Account} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/subscription`} component={Subsciption} />
            {/* <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pick-store`} component={MallStore} /> */}
            {/* <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pick-menu`} component={MallStore} /> */}
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/browse-filter`} component={BrowseFilter} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/profile-feature`} component={ProfileFeature} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/profile-orders/history`} component={ProfileOrderHistory} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/specials`} component={Pdf} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pick-menu`} component={PickMenu} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/check-number`} component={CheckNumber} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/split-evenly`} component={SplitCheck} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pay-an-amount`} component={PayAnAmount} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/selected-items`} component={PaymentSelectItems} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/table-booking`} component={TableBooking} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/wait-list`} component={WaitList} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/manage-wait-list`} component={ManageWaitList} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/edit-wait-list/:bookingId`} component={WaitList} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/edit-booking/:bookingId`} component={TableBooking} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/booking-list`} component={TableBookingList} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pdfComponent`} component={ReactPdf} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/table-confirmation`} component={TableConfirmation} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/order-success`} component={OrderSuccess} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/accept-terms`} component={AcceptTerms} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pay-my-items/:seatId`} component={PayMyItems} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pay-by-stage`} component={PayByStage} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/multiCartReceipt`} component={MultiCartReceipt} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/pay-by-guest`} component={PayByGuest} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-payment-info`} component={KioskPaymentInfo} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-payment-method`} component={KioskPaymentMethod} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-receipt-page`} component={KioskReceiptPage} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-receipt-method`} component={KioskReceiptMethod} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-guest-info`} component={KioskGuestInfoPage} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-landing-page`} component={KioskLandingPage} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/kiosk-payment-page`} component={KioskPaymentPage} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/contact-restaurant`} component={ContactRestaurant} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/current-location`} component={CurrentUserLocation} />
            <PrivateRoute exact path={`${APP_CONFIG.BASE_URL}/mycheck`} component={MyCheck} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/loyalty-offer-details`} component={LoyaltyOfferDetail} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/planet/payment/test`} component={TestPage} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/sensor-check`} component={SensorCheckIn} />
            {/* Redirect routes */}
            <Route exact path={`${APP_CONFIG.BASE_URL}/checkIn/:type/:tiketId/:referenceCode/:extraFields`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/checkIn/:type/:tiketId/:referenceCode`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/checkIn/:type/:referenceCode`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/kioskOrder/:tiketId/:referenceCode`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/code`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/s/:referenceCode`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/s/:bookingId/:referenceCode`} component={CheckIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/checkIn/:type/:bookingId/:referenceCode`} component={CheckIn} />
            <Route exact path="/tsep" render={() => <Redirect to="/tsep.html" />} />
            <Route exact path="/.well-known/apple-developer-merchantid-domain-association.txt" render={() => <Redirect to="/apple-developer-merchantid-domain-association.txt" />} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/termsandconditions/terms`} component={TermsAndCondtions} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/privacy-policy/terms`} component={PrivacyPolicy} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/eula/terms`} component={Eula} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spotCode/termsandconditions`} component={TermsAndCondtions} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spotCode/privacy-policy`} component={PrivacyPolicy} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spotCode/eula`} component={Eula} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spotCode/mydata`} component={MyData} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/payment-option`} component={PaymentOption} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/waitlist-confirmation`} component={WaitlistConfirmation} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/walkup-booking`} component={WalkupSensorBookingContainer} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/future-order`} component={FutureOrderScreen} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/usa-epay`} component={UsaEpay} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/loyalty-offers`} component={LoyaltyOffers} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/video`} component={VideoOnScroll} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/preview/orders`} component={Preview} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/wallet/loyalties/:brandExternalId/`} component={LoyaltyLink} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/locationRequest/:checkHeaderId/:referenceId`} component={LocationRequest} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/:receiptType/display/:ticketId/:referenceCode`} component={ViewReceipt} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/:receiptType/:giftCardType/display/:ticketId/:referenceCode`} component={GiftCardViewReceipt} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/location-confirmation`} component={LocationConfirmation} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/spotnumber-confirmation`} component={SpotConfirmation} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/reset-info`} component={LocationReset} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/brand/:brandstorelocatorid/locator`} component={BrandStoreLocator} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/brand/:brandstorelocatorid/listing`} component={BrandStoreLocationList} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/brandStoreDetails`} component={BrandStoreDetails} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/employee-table-reassignment`} component={EmployeeTableReassignment} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/KDS`} component={KDS} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/charity`} component={ManageCharity} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/optin`} component={OptIn} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/optin/tile/payment`} component={OptinTileAndPayment} />
            <Route path="*" component={notFound} />
          </Switch>
        </OnRouteChange>
        <Footer />
      </div>
    </Router>
  </Div100vh>
);
