import filter from 'lodash/filter';

export const OTP_REQUEST = 'loyalty/OTP_REQUEST';
export const OTP_SUCCESS = 'loyalty/OTP_SUCCESS';
export const OTP_FAILURE = 'loyalty/OTP_FAILURE';

export const ACCOUNT_REQUEST = 'loyalty/ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'loyalty/ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'loyalty/ACCOUNT_FAILURE';

export const GIFT_CARD_BALANCE_REQUEST = 'loyalty/GIFT_CARD_BALANCE_REQUEST';
export const GIFT_CARD_BALANCE_SUCCESS = 'loyalty/GIFT_CARD_BALANCE_SUCCESS';
export const GIFT_CARD_BALANCE_FAILURE = 'loyalty/GIFT_CARD_BALANCE_FAILURE';

export const ACCRUE_LOYALTY_REQUEST = 'loyalty/ACCRUE_LOYALTY_REQUEST';
export const ACCRUE_LOYALTY_SUCCESS = 'loyalty/ACCRUE_LOYALTY_SUCCESS';
export const ACCRUE_LOYALTY_FAILURE = 'loyalty/ACCRUE_LOYALTY_FAILURE';

export const GET_LOYALTY_ACCOUNTS_REQUEST = 'loyalty/GET_LOYALTY_ACCOUNTS_REQUEST_REQUEST';
export const GET_LOYALTY_ACCOUNTS_SUCCESS = 'loyalty/GET_LOYALTY_ACCOUNTS_REQUEST_SUCCESS';
export const GET_LOYALTY_ACCOUNTS_FAILURE = 'loyalty/GET_LOYALTY_ACCOUNTS_REQUEST_FAILURE';

export const UPDATE_LOYALTY_ACCOUNTS_DETAILS_REQUEST = 'loyalty/UPDATE_LOYALTY_ACCOUNTS_DETAILS_REQUEST';
export const UPDATE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS = 'loyalty/UPDATE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS';
export const UPDATE_LOYALTY_ACCOUNTS_DETAILS_FAILURE = 'loyalty/UPDATE_LOYALTY_ACCOUNTS_DETAILS_FAILURE';

export const DELETE_LOYALTY_ACCOUNTS_DETAILS_REQUEST = 'loyalty/DELETE_LOYALTY_ACCOUNTS_DETAILS_REQUEST';
export const DELETE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS = 'loyalty/DELETE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS';
export const DELETE_LOYALTY_ACCOUNTS_DETAILS_FAILURE = 'loyalty/DELETE_LOYALTY_ACCOUNTS_DETAILS_FAILURE';

export const RESET_LOYALTY_PASSWORD_REQUEST = 'loyalty/RESET_LOYALTY_PASSWORD_REQUEST';
export const RESET_LOYALTY_PASSWORD_SUCCESS = 'loyalty/RESET_LOYALTY_PASSWORD_SUCCESS';
export const RESET_LOYALTY_PASSWORD_FAILURE = 'loyalty/RESET_LOYALTY_PASSWORD_FAILURE';

export const OPTIN_LOYALTY_REQUEST = 'loyalty/OPTIN_LOYALTY_REQUEST';
export const OPTIN_LOYALTY_SUCCESS = 'loyalty/OPTIN_LOYALTY_SUCCESS';
export const OPTIN_LOYALTY_FAILURE = 'loyalty/OPTIN_LOYALTY_FAILURE';

export const GIFT_CARD_CAPTURE_REQUEST = 'loyalty/GIFT_CARD_CAPTURE_REQUEST';
export const GIFT_CARD_CAPTURE_SUCCESS = 'loyalty/GIFT_CARD_CAPTURE_SUCCESS';
export const GIFT_CARD_CAPTURE_FAILURE = 'loyalty/GIFT_CARD_CAPTURE_FAILURE';

export const GET_OPTIN_INFO_REQUEST = 'loyalty/GET_OPTIN_INFO_REQUEST';
export const GET_OPTIN_INFO_SUCCESS = 'loyalty/GET_OPTIN_INFO_SUCCESS';
export const GET_OPTIN_INFO_FAILURE = 'loyalty/GET_OPTIN_INFO_FAILURE';

export const UPDATE_OPTIN_INFO_REQUEST = 'loyalty/UPDATE_OPTIN_INFO_REQUEST';
export const UPDATE_OPTIN_INFO_SUCCESS = 'loyalty/UPDATE_OPTIN_INFO_SUCCESS';
export const UPDATE_OPTIN_INFO_FAILURE = 'loyalty/UPDATE_OPTIN_INFO_FAILURE';

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
  accrueLoyaltyLoading: false,
  loyaltyAccountsList: [],
  loyaltyAccountDetails: null,
  giftCardDetails: null,
  optInInfo: null
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case OTP_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case OTP_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GIFT_CARD_BALANCE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GIFT_CARD_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GIFT_CARD_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case ACCRUE_LOYALTY_REQUEST:
      return {
        ...state,
        accrueLoyaltyLoading: true,
        loaded: false,
      };
    case ACCRUE_LOYALTY_SUCCESS:
      return {
        ...state,
        accrueLoyaltyLoading: false,
        loaded: true,
      };
    case ACCRUE_LOYALTY_FAILURE:
      return {
        ...state,
        accrueLoyaltyLoading: false,
        loaded: false,
        error: false
      };
    case GET_LOYALTY_ACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_LOYALTY_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        loyaltyAccountsList: action.result
      };
    case GET_LOYALTY_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case UPDATE_LOYALTY_ACCOUNTS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case UPDATE_LOYALTY_ACCOUNTS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case DELETE_LOYALTY_ACCOUNTS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case DELETE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        cardList: filter(state.cardList, d => d.id !== action.payload)
      };
    case DELETE_LOYALTY_ACCOUNTS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case RESET_LOYALTY_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case RESET_LOYALTY_PASSWORD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case RESET_LOYALTY_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case OPTIN_LOYALTY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case OPTIN_LOYALTY_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case OPTIN_LOYALTY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GIFT_CARD_CAPTURE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GIFT_CARD_CAPTURE_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        giftCardDetails: action.result || null
      };
    case GIFT_CARD_CAPTURE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_OPTIN_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_OPTIN_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        optInInfo: action.result
      };
    case GET_OPTIN_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case UPDATE_OPTIN_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_OPTIN_INFO_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case UPDATE_OPTIN_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    default:
      return state;
  }
};

export const getLoyaltyOtp = (data) => {
  return {
    types: [OTP_REQUEST, OTP_SUCCESS, OTP_FAILURE],
    promise: client => client.post('getLoyaltyOtp', {
      data
    })
  };
};

export const signUpComplete = (data) => {
  return {
    types: [OTP_REQUEST, OTP_SUCCESS, OTP_FAILURE],
    promise: client => client.post('loyaltyAccountSignUpComplete', {
      data
    })
  };
};
export const signUp = (data) => {
  return {
    types: [ACCOUNT_REQUEST, ACCOUNT_SUCCESS, ACCOUNT_FAILURE],
    promise: client => client.post('loyaltyAccountSignUp', {
      data
    })
  };
};

export const fetchLoyaltyAccount = (data) => {
  return {
    types: [OTP_REQUEST, OTP_SUCCESS, OTP_FAILURE],
    promise: client => client.post('loyaltyAccountLookUp', {
      data
    })
  };
};

export const loyaltyAccountAssign = (data) => {
  return {
    types: [OTP_REQUEST, OTP_SUCCESS, OTP_FAILURE],
    promise: client => client.post('loyaltyAccountAssign', {
      data
    })
  };
};

export const giftCardBalance = (data) => {
  return {
    types: [GIFT_CARD_BALANCE_REQUEST, GIFT_CARD_BALANCE_SUCCESS, GIFT_CARD_BALANCE_FAILURE],
    promise: client => client.post('getGiftCardBalance', {
      data
    })
  };
};

export const accrueLoyalty = (data) => {
  return {
    types: [ACCRUE_LOYALTY_REQUEST, ACCRUE_LOYALTY_SUCCESS, ACCRUE_LOYALTY_FAILURE],
    promise: client => client.post('accrueLoyalty', {
      data
    })
  };
};

export const getLoyaltyAccountsList = (brandId) => {
  return {
    types: [GET_LOYALTY_ACCOUNTS_REQUEST, GET_LOYALTY_ACCOUNTS_SUCCESS, GET_LOYALTY_ACCOUNTS_FAILURE],
    promise: client => client.get(`fetchLoyaltyAccountsList/${brandId}`)
  };
};

export const updateLoyaltyAccountDetails = (data) => {
  return {
    types: [UPDATE_LOYALTY_ACCOUNTS_DETAILS_REQUEST, UPDATE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS, UPDATE_LOYALTY_ACCOUNTS_DETAILS_FAILURE],
    promise: client => client.post('renameLoyaltyAccountName', {
      data
    })
  };
};

export const deleteLoyaltyAccountDetails = (cardId) => {
  return {
    types: [DELETE_LOYALTY_ACCOUNTS_DETAILS_REQUEST, DELETE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS, DELETE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS],
    promise: client => client.post(`deleteLoyaltyAccountCard/${cardId}`),
    payload: cardId
  };
};

export const resetLoyaltyPassword = (data) => {
  return {
    types: [UPDATE_LOYALTY_ACCOUNTS_DETAILS_REQUEST, UPDATE_LOYALTY_ACCOUNTS_DETAILS_SUCCESS, UPDATE_LOYALTY_ACCOUNTS_DETAILS_FAILURE],
    promise: client => client.post('resetLoyaltyPassword', {
      data
    })
  };
};

export const optInLoyalty = (data) => {
  return {
    types: [OPTIN_LOYALTY_REQUEST, OPTIN_LOYALTY_SUCCESS, OPTIN_LOYALTY_FAILURE],
    promise: client => client.post('optInLoyalty', {
      data
    })
  };
};

export const giftCardCapture = (data) => {
  return {
    types: [OPTIN_LOYALTY_REQUEST, OPTIN_LOYALTY_SUCCESS, OPTIN_LOYALTY_FAILURE],
    promise: client => client.post('giftCardOcr', {
      data
    })
  };
};

export const getOptInfo = (brandId) => {
  return {
    types: [GET_OPTIN_INFO_FAILURE, GET_OPTIN_INFO_SUCCESS, GET_OPTIN_INFO_FAILURE],
    promise: client => client.get(`getOptInInfo/${brandId}`)
  };
};

export const updateOptInfo = (data) => {
  return {
    types: [OPTIN_LOYALTY_REQUEST, OPTIN_LOYALTY_SUCCESS, OPTIN_LOYALTY_FAILURE],
    promise: client => client.post('updateOptInfo', {
      data
    })
  };
};
