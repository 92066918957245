export const GET_STORE_LOCATOR_LIST_REQUEST = 'brandstore/GET_STORE_LOCATOR_LIST_REQUEST';
export const GET_STORE_LOCATOR_LIST_SUCCESS = 'brandstore/GET_STORE_LOCATOR_LIST_SUCCESS';
export const GET_STORE_LOCATOR_LIST_FAILURE = 'brandstore/GET_STORE_LOCATOR_LIST_FAILURE';

export const GET_BRAND_INFO_REQUEST = 'brandstore/GET_BRAND_INFO_REQUEST';
export const GET_BRAND_INFO_SUCCESS = 'brandstore/GET_BRAND_INFO_SUCCESS';
export const GET_BRAND_INFO_FAILURE = 'brandstore/GET_BRAND_INFO_FAILURE';

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
  storeLocations: []
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_STORE_LOCATOR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_STORE_LOCATOR_LIST_SUCCESS:
      window.sessionStorage.setItem('storeLocations', JSON.stringify(action.result.storeLocations));
      return {
        ...state,
        loading: false,
        loaded: true,
        storeLocations: action.result
      };
    case GET_STORE_LOCATOR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_BRAND_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_BRAND_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        storeLocations: action.result
      };
    case GET_BRAND_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    default:
      return state;
  }
};

export const getStoreLocator = (data) => {
  return {
    types: [GET_STORE_LOCATOR_LIST_REQUEST, GET_STORE_LOCATOR_LIST_SUCCESS, GET_STORE_LOCATOR_LIST_FAILURE],
    promise: client => client.post('storeLocator', {
      data
    })
  };
};

export const getBrandInfo = (brandId) => {
  return {
    types: [GET_BRAND_INFO_REQUEST, GET_BRAND_INFO_SUCCESS, GET_BRAND_INFO_FAILURE],
    promise: client => client.get(`getBrandInfo/${brandId}`),
  };
};
