import store from './store';
import LABEL_CONST from './constants/label';
import CUSTOM_MESSAGES_CONST from './constants/customMessage';
import { getObjectValue } from '@utility/utills';

const veriables = (type) => {
  const newState = store.getState();
  switch (type) {
    case 'LABEL':
      return { ...LABEL_CONST, ...getObjectValue(() => newState.theme.variableProperties, {}).label || {}, CURRENCY_SYMBOL: getObjectValue(() => newState.controller.popInData.store.country === 4, false) ? '£' : '$' };
    case 'CUSTOM_MESSAGES':
      return { ...CUSTOM_MESSAGES_CONST, ...getObjectValue(() => newState.theme.variableProperties, {}).customMessage || {} };
    default:
      return LABEL_CONST;
  }
};
store.subscribe(veriables);
export const LABEL = () => veriables('LABEL');
export const CUSTOM_MESSAGES = () => veriables('CUSTOM_MESSAGES');
export default veriables;
