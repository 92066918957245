import filter from 'lodash/filter';

export const GET_CARD_DETAILS_REQUEST = 'wallet/GET_CARD_DETAILS_REQUEST';
export const GET_CARD_DETAILS_SUCCESS = 'wallet/GET_CARD_DETAILS_SUCCESS';
export const GET_CARD_DETAILS_FAILURE = 'wallet/GET_CARD_DETAILS_FAILURE';

export const GET_MANIFEST_DETAILS_REQUEST = 'wallet/GET_MANIFEST_DETAILS_REQUEST';
export const GET_MANIFEST_DETAILS_SUCCESS = 'wallet/GET_MANIFEST_DETAILS_SUCCESS';
export const GET_SHIFT4_DETAILS_SUCCESS = 'wallet/GET_SHIFT4_DETAILS_SUCCESS';
export const GET_PAYPAL_DETAILS_SUCCESS = 'wallet/GET_PAYPAL_DETAILS_SUCCESS';
export const GET_MANIFEST_DETAILS_FAILURE = 'wallet/GET_MANIFEST_DETAILS_FAILURE';

export const SAVE_CARD_DETAILS_REQUEST = 'wallet/SAVE_CARD_DETAILS_REQUEST';
export const SAVE_CARD_DETAILS_SUCCESS = 'wallet/SAVE_CARD_DETAILS_SUCCESS';
export const SAVE_CARD_DETAILS_FAILURE = 'wallet/SAVE_CARD_DETAILS_FAILURE';

export const DELETE_CARD_DETAILS_REQUEST = 'wallet/DELETE_CARD_DETAILS_REQUEST';
export const DELETE_CARD_DETAILS_SUCCESS = 'wallet/DELETE_CARD_DETAILS_SUCCESS';
export const DELETE_CARD_DETAILS_FAILURE = 'wallet/DELETE_CARD_DETAILS_FAILURE';

export const UPDATE_CARD_NAME_REQUEST = 'wallet/UPDATE_CARD_NAME_REQUEST';
export const UPDATE_CARD_NAME_SUCCESS = 'wallet/UPDATE_CARD_NAME_SUCCESS';
export const UPDATE_CARD_NAME_FAILURE = 'wallet/UPDATE_CARD_NAME_FAILURE';

export const GET_CARD_FROM_SESSION = 'wallet/GET_CARD_FROM_SESSION';

export const UPDATE_SELECTED_CARD_REQUESTED = 'wallet/UPDATE_SELECTED_CARD_REQUESTED';
export const UPDATE_SELECTED_CARD_SUCCESS = 'wallet/UPDATE_SELECTED_CARD_SUCCESS';

export const GET_CARD_DETAILS_BY_STOREID_REQUEST = 'wallet/GET_CARD_DETAILS_BY_STOREID_REQUEST';
export const GET_CARD_DETAILS_BY_STOREID_SUCCESS = 'wallet/GET_CARD_DETAILS_BY_STOREID_SUCCESS';
export const GET_CARD_DETAILS_BY_STOREID_FAILURE = 'wallet/GET_CARD_DETAILS_BY_STOREID_FAILURE';

export const GET_AMAZONPAY_MANIFEST_DETAILS_REQUEST = 'wallet/GET_AMAZONPAY_MANIFEST_DETAILS_REQUEST';
export const GET_AMAZONPAY_MANIFEST_DETAILS_SUCCESS = 'wallet/GET_AMAZONPAY_MANIFEST_DETAILS_SUCCESS';
export const GET_AMAZONPAY_MANIFEST_DETAILS_FAILURE = 'wallet/GET_AMAZONPAY_MANIFEST_DETAILS_FAILURE';

export const GET_CABBAGEPAY_MANIFEST_DETAILS_REQUEST = 'wallet/GET_CABBAGEPAY_MANIFEST_DETAILS_REQUEST';
export const GET_CABBAGEPAY_MANIFEST_DETAILS_SUCCESS = 'wallet/GET_CABBAGEPAY_MANIFEST_DETAILS_SUCCESS';
export const GET_CABBAGEPAY_MANIFEST_DETAILS_FAILURE = 'wallet/GET_CABBAGEPAY_MANIFEST_DETAILS_FAILURE';

export const GET_DOJO_MANIFEST_DETAILS_REQUEST = 'wallet/GET_DOJO_MANIFEST_DETAILS_REQUEST';
export const GET_DOJO_MANIFEST_DETAILS_SUCCESS = 'wallet/GET_DOJO_MANIFEST_DETAILS_SUCCESS';
export const GET_DOJO_MANIFEST_DETAILS_FAILURE = 'wallet/GET_DOJO_MANIFEST_DETAILS_FAILURE';

export const GP_MANIFEST_DETAILS_REQUEST = 'wallet/GP_MANIFEST_DETAILS_REQUEST';
export const GP_MANIFEST_DETAILS_SUCCESS = 'wallet/GP_MANIFEST_DETAILS_SUCCESS';
export const GP_MANIFEST_DETAILS_FAILURE = 'wallet/GP_MANIFEST_DETAILS_FAILURE';

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
  cardList: JSON.parse(window.sessionStorage.getItem('cardList')) || [],
  manifestUrl: null,
  manifestLoading: false,
  manifestLoaded: true,
  cardDetailes: null,
  cardListByStore: JSON.parse(window.sessionStorage.getItem('cardListBystore')) || [],
  payPalInfo: {}
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_CARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_CARD_DETAILS_SUCCESS:
      window.sessionStorage.setItem('cardList', JSON.stringify(action.result));
      return {
        ...state,
        loading: false,
        loaded: true,
        cardList: action.result
      };
    case GET_CARD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_MANIFEST_DETAILS_REQUEST:
      return {
        ...state,
        manifestLoading: true,
        manifestLoaded: false
      };
    case GET_MANIFEST_DETAILS_SUCCESS:
      return {
        ...state,
        manifestLoading: false,
        manifestLoaded: true,
        manifestUrl: action.result
      };
    case GET_SHIFT4_DETAILS_SUCCESS:
      return {
        ...state,
        manifestLoading: false,
        manifestLoaded: true
      };
    case GET_PAYPAL_DETAILS_SUCCESS:
      return {
        ...state,
        manifestLoading: false,
        manifestLoaded: true,
        payPalInfo: action.result
      };
    case GET_MANIFEST_DETAILS_FAILURE:
      return {
        ...state,
        manifestLoading: false,
        manifestLoaded: true,
        error: true
      };
    case SAVE_CARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case SAVE_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: true
      };
    case SAVE_CARD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case DELETE_CARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case DELETE_CARD_DETAILS_SUCCESS:
      window.sessionStorage.setItem('cardList', JSON.stringify(filter(state.cardList, d => d.id !== action.payload)));
      return {
        ...state,
        loaded: true,
        loading: false,
        cardList: filter(state.cardList, d => d.id !== action.payload)
      };
    case DELETE_CARD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_CARD_FROM_SESSION:
      action.resolve(state.cardList);
      return {
        ...state
      };
    case UPDATE_CARD_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_CARD_NAME_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case UPDATE_CARD_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case UPDATE_SELECTED_CARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_SELECTED_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        cardDetailes: action.payload,
        popInRequestInfo: action.payload
      };
    }
    case GET_CARD_DETAILS_BY_STOREID_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_CARD_DETAILS_BY_STOREID_SUCCESS:
      window.sessionStorage.setItem('cardListBystore', JSON.stringify(action.result));
      return {
        ...state,
        loading: false,
        loaded: true,
        cardListByStore: action.result
      };
    case GET_CARD_DETAILS_BY_STOREID_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_AMAZONPAY_MANIFEST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_AMAZONPAY_MANIFEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_AMAZONPAY_MANIFEST_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_CABBAGEPAY_MANIFEST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_CABBAGEPAY_MANIFEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_CABBAGEPAY_MANIFEST_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_DOJO_MANIFEST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_DOJO_MANIFEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_DOJO_MANIFEST_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GP_MANIFEST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GP_MANIFEST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GP_MANIFEST_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    default:
      return state;
  }
};

// export const getCardList = (isRequired) => {
//   if (!window.sessionStorage.getItem('cardList') || isRequired) {
//     return {
//       types: [GET_CARD_DETAILS_REQUEST, GET_CARD_DETAILS_SUCCESS, GET_CARD_DETAILS_FAILURE],
//       promise: client => client.get('featchWallet')
//     };
//   }
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       dispatch({
//         type: GET_CARD_FROM_SESSION,
//         resolve,
//         reject
//       });
//     });
//   };
// };

export const getCardList = (brandId) => {
  return {
    types: [GET_CARD_DETAILS_REQUEST, GET_CARD_DETAILS_SUCCESS, GET_CARD_DETAILS_FAILURE],
    promise: client => client.get(`featchWallet/${brandId}`)
  };
};

export const getMenifest = (storeId) => {
  return {
    types: [GET_MANIFEST_DETAILS_REQUEST, GET_MANIFEST_DETAILS_SUCCESS, GET_MANIFEST_DETAILS_FAILURE],
    promise: client => client.get(`getManifest/${storeId}`)
  };
};

export const getShift4Manifest = (data) => {
  return {
    types: [GET_MANIFEST_DETAILS_REQUEST, GET_SHIFT4_DETAILS_SUCCESS, GET_MANIFEST_DETAILS_FAILURE],
    promise: client => client.post('getShift4Manifest', { data })
  };
};

export const getPayPalManifest = (data) => {
  return {
    types: [GET_MANIFEST_DETAILS_REQUEST, GET_PAYPAL_DETAILS_SUCCESS, GET_MANIFEST_DETAILS_FAILURE],
    promise: client => client.post('getPayPalManifest', { data })
  };
};

export const getAmazonPayManifest = (data) => {
  return {
    types: [GET_AMAZONPAY_MANIFEST_DETAILS_REQUEST, GET_AMAZONPAY_MANIFEST_DETAILS_SUCCESS, GET_AMAZONPAY_MANIFEST_DETAILS_FAILURE],
    promise: client => client.post('getAmazonPayManifest', { data })
  };
};

export const getCabbagePayManifest = (data) => {
  return {
    types: [GET_CABBAGEPAY_MANIFEST_DETAILS_REQUEST, GET_CABBAGEPAY_MANIFEST_DETAILS_SUCCESS, GET_CABBAGEPAY_MANIFEST_DETAILS_FAILURE],
    promise: client => client.post('getCabbagePayManifest', { data })
  };
};

export const getDojoManifest = (data) => {
  return {
    types: [GET_DOJO_MANIFEST_DETAILS_REQUEST, GET_DOJO_MANIFEST_DETAILS_SUCCESS, GET_DOJO_MANIFEST_DETAILS_FAILURE],
    promise: client => client.post('getDojoManifest', { data })
  };
};

export const gPManifest = (data) => {
  return {
    types: [GP_MANIFEST_DETAILS_REQUEST, GP_MANIFEST_DETAILS_SUCCESS, GP_MANIFEST_DETAILS_FAILURE],
    promise: client => client.post('getGPManifest', { data })
  };
};

export const saveCardDetails = (data) => {
  return {
    types: [SAVE_CARD_DETAILS_REQUEST, SAVE_CARD_DETAILS_SUCCESS, SAVE_CARD_DETAILS_FAILURE],
    promise: client => client.post('addCard', {
      data
    })
  };
};

export const updateCardDetails = (data) => {
  return {
    types: [UPDATE_CARD_NAME_REQUEST, UPDATE_CARD_NAME_SUCCESS, UPDATE_CARD_NAME_FAILURE],
    promise: client => client.post('renameCard', {
      data
    })
  };
};

export const deleteCardDetails = (cardId) => {
  return {
    types: [DELETE_CARD_DETAILS_REQUEST, DELETE_CARD_DETAILS_SUCCESS, DELETE_CARD_DETAILS_FAILURE],
    promise: client => client.post(`deleteCard/${cardId}`),
    payload: cardId
  };
};

export const updateSelectedCard = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_CARD_REQUESTED
    });
    setTimeout(() => {
      // window.sessionStorage.setItem('CardData', JSON.stringify(data));
      dispatch({
        type: UPDATE_SELECTED_CARD_SUCCESS,
        payload: data
      });
    }, 0);
  };
};

export const getCardListByStore = (storeId) => {
  return {
    types: [GET_CARD_DETAILS_BY_STOREID_REQUEST, GET_CARD_DETAILS_BY_STOREID_SUCCESS, GET_CARD_DETAILS_BY_STOREID_FAILURE],
    promise: client => client.get(`featchWalletByStoreID/${storeId}`)
  };
};
