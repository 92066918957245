export const GET_OCCASSION_LIST_REQUESTED = 'tableManagment/GET_OCCASSION_LIST_REQUESTED';
export const GET_OCCASSION_LIST_SUCCESS = 'tableManagment/GET_OCCASSION_LIST_SUCCESS';
export const GET_OCCASSION_LIST_FAILURE = 'tableManagment/GET_OCCASSION_LIST_FAILURE';

export const GET_PREFERENCES_LIST_REQUESTED = 'tableManagment/GET_PREFERENCES_LIST_REQUESTED';
export const GET_PREFERENCES_LIST_SUCCESS = 'tableManagment/GET_PREFERENCES_LIST_SUCCESS';
export const GET_PREFERENCES_LIST_FAILURE = 'tableManagment/GET_PREFERENCES_LIST_FAILURE';

export const GET_AVAILABLE_SLOTS_REQUESTED = 'tableManagment/GET_AVAILABLE_SLOTS_REQUESTED';
export const GET_AVAILABLE_SLOTS_SUCCESS = 'tableManagment/GET_AVAILABLE_SLOTS_SUCCESS';
export const GET_AVAILABLE_SLOTS_FAILURE = 'tableManagment/GET_AVAILABLE_SLOTS_FAILURE';

export const GET_FIRST_AVAILABLE_TIME_REQUESTED = 'tableManagment/GET_FIRST_AVAILABLE_TIME_REQUESTED';
export const GET_FIRST_AVAILABLE_TIME_SUCCESS = 'tableManagment/GET_FIRST_AVAILABLE_TIME_SUCCESS';
export const GET_FIRST_AVAILABLE_TIME_FAILURE = 'tableManagment/GET_FIRST_AVAILABLE_TIME_FAILURE';

export const ADD_TABLE_BOOKING_REQUESTED = 'tableManagment/ADD_TABLE_BOOKING__REQUESTED';
export const ADD_TABLE_BOOKING_SUCCESS = 'tableManagment/ADD_TABLE_BOOKING_SUCCESS';
export const ADD_TABLE_BOOKING_FAILURE = 'tableManagment/ADD_TABLE_BOOKING_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  occasionList: [],
  prefrencesList: [],
  availableSlots: [],
  firstAvailableTime: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OCCASSION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OCCASSION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        occasionList: action.result,
      };
    }
    case GET_OCCASSION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_PREFERENCES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PREFERENCES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        prefrencesList: action.result,
      };
    }
    case GET_PREFERENCES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_AVAILABLE_SLOTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_AVAILABLE_SLOTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        availableSlots: action.result.waitTimes,
      };
    }
    case GET_AVAILABLE_SLOTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_FIRST_AVAILABLE_TIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_FIRST_AVAILABLE_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        firstAvailableTime: action.result,
      };
    }
    case GET_FIRST_AVAILABLE_TIME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_TABLE_BOOKING_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_TABLE_BOOKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_TABLE_BOOKING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const getOccasionList = (storeId) => {
  return {
    types: [GET_OCCASSION_LIST_REQUESTED, GET_OCCASSION_LIST_SUCCESS, GET_OCCASSION_LIST_FAILURE],
    promise: client => client.get(`storeOccasionList/${storeId}`),
  };
};

export const getPreferencesList = (storeId) => {
  return {
    types: [GET_PREFERENCES_LIST_REQUESTED, GET_PREFERENCES_LIST_SUCCESS, GET_PREFERENCES_LIST_FAILURE],
    promise: client => client.get(`storeTag/${storeId}`)
  };
};

export const getFirstAvailableTime = (data) => {
  return {
    types: [GET_FIRST_AVAILABLE_TIME_REQUESTED, GET_FIRST_AVAILABLE_TIME_SUCCESS, GET_FIRST_AVAILABLE_TIME_FAILURE],
    promise: client => client.post('firstAvailableTime', {
      data,
    }),
  };
};
export const getAvailableSlots = (data) => {
  return {
    types: [GET_AVAILABLE_SLOTS_REQUESTED, GET_AVAILABLE_SLOTS_SUCCESS, GET_AVAILABLE_SLOTS_FAILURE],
    promise: client => client.post('availableTime', {
      data,
    }),
  };
};

export const addTableBooking = (data) => {
  return {
    types: [ADD_TABLE_BOOKING_REQUESTED, ADD_TABLE_BOOKING_SUCCESS, ADD_TABLE_BOOKING_FAILURE],
    promise: client => client.post('createWaitList', {
      data,
    }),
  };
};
