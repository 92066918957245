export const GET_ORDER_HOSTORY_REQUEST = 'orders/GET_ORDER_HOSTORY_REQUEST';
export const GET_ORDER_HOSTORY_SUCCESS = 'orders/GET_ORDER_HOSTORY_SUCCESS';
export const GET_ORDER_HOSTORY_FAILURE = 'orders/GET_ORDER_HOSTORY_FAILURE';

export const GET_ORDER_DETAILS_REQUEST = 'orders/GET_ORDER_DETAILS_REQUEST';
export const GET_ORDER_DETAILS_SUCCESS = 'orders/GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'orders/GET_ORDER_DETAILS_FAILURE';

export const GET_PROFILE_ORDER_HISTORY_REQUEST = 'orders/GET_PROFILE_ORDER_HISTORY_REQUEST';
export const GET_PROFILE_ORDER_HISTORY_SUCCESS = 'orders/GET_PROFILE_ORDER_HISTORY_SUCCESS';
export const GET_PROFILE_ORDER_HISTORY_FAILURE = 'orders/GET_PROFILE_ORDER_HISTORY_FAILURE';

const initailValues = {
  loading: false,
  loaded: true,
  error: false,
  orderHistoryList: [],
  orderProfileHistoryList: [],
  orderDetails: {},
};

export default (state = initailValues, action) => {
  switch (action.type) {
    case GET_ORDER_HOSTORY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_ORDER_HOSTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        orderHistoryList: action.result,
      };
    case GET_ORDER_HOSTORY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        orderDetails: action.result,
      };
    case GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case GET_PROFILE_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_PROFILE_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        orderProfileHistoryList: action.result,
      };
    case GET_PROFILE_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    default:
      return state;
  }
};

export const getOrderHistory = (brandId) => {
  return {
    types: [GET_ORDER_HOSTORY_REQUEST, GET_ORDER_HOSTORY_SUCCESS, GET_ORDER_HOSTORY_FAILURE],
    promise: client => client.get(`getOrderHistory/${brandId}/list`)
  };
};

export const getOrderDetails = (ticketId) => {
  return {
    types: [GET_ORDER_DETAILS_REQUEST, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_DETAILS_FAILURE],
    promise: client => client.get(`getOrderDetails/${ticketId}`)
  };
};

export const getProfileOrderHistory = (storeId) => {
  return {
    types: [GET_PROFILE_ORDER_HISTORY_REQUEST, GET_PROFILE_ORDER_HISTORY_SUCCESS, GET_PROFILE_ORDER_HISTORY_FAILURE],
    promise: client => client.get(`getProfileOrderHistory/${storeId}/list`)
  };
};
