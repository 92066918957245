export default {
  // Defaults
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Please try again in sometime.',

  // Messages
  ORDER_AND_PAY_MESSAGE: 'Scan QR code using your phone camera',
  PARTICIPATING_RESTAURANT_MESSAGE: 'Scan the OneDine QR code at any participating restaurant.',
  PAGE_NOT_FOUND: 'Oops! Page not found',
  ERROR_MESSAGE: 'We are sorry, but the page you requested was not found',
  USER_INFO: 'User Info',
  CONNECT_WITH: 'OR CONNECT WITH',
  YOUR_SERVER_IS: 'Your server is',
  SERVER_CALL_MESSAGE: 'A notification has been sent to your server.',
  THANK_YOU: 'Thank you!',
  ACTION_PAGE_TITLE: 'What would you like to do?',
  CONFIRM_LEAVE_TABLE: 'Please confirm that you want to leave table',
  LEAVE_TABLE_CONFIRMATION: 'Are you sure, you want to leave table?',
  POPIN_CONFIRM_MSG: 'Are you sure you want to cancel?',
  MODAL_CONIRM_MESSAGE: 'This will clear the order, Do you want to continue?',
  RECEIPT_SENT_TO: 'Receipt has been sent to your',
  YOUR_RECEIPT: 'Your Receipt',
  PLEASE_SELECT_SPLIT_CHECK: 'Please select how many people will split this check:',
  PLEASE_ENTER_AMOUNT: 'Please enter the amount you want pay',
  SPLITED_CHECK_LIST_TITLE: 'Please select a check to pay',
  HOW_YOU_SPLIT_CHECK: 'How would you like to split the check',
  SURVEY_TITLE: 'We would appreciate if you can take 1 minute to answer a survey',
  SIGN_IN_PHONE_NUMBER: 'Sign in with your phone number:',
  ENTER_TABLE_NUMBER: 'Enter Table Number',
  VERIFY_OTP_TITLE: 'We sent you a text with your verification code.',
  PLEASE_ENTER_CODE: 'Please enter the code below.',
  YOU_ARE_LOCATED_AT_TABLE: 'You are located at table ',
  MORE_ITEMS: 'more items',
  YOUR_REQUEST_FOR_LEAVE_TABLE: 'Your request for leave table is recorded.',
  PROFILE_HEADER: 'OneDine',
  YOUR_PAYMENT_WAS_SUCCESSFUL: 'Your Payment was successful',
  THANK_YOU_MESSAGE: 'Thank you and come see us again soon',
  HOW_WOULD_YOU_LIKE_YOUR_RECEIPT: 'How would you like your receipt',
  AT_LEAST_ONE_SPECIAL_CASE: 'At least 1 special case, uppercase & number',
  TERMS_AND_CONDITIONS: 'I agree to terms and conditions',
  PASSWORD_MUST_MEET_THE_FOLLOWING: 'Your password must meet the following:',
  EIGHT_CHARACTERS_LONG: '  be 8 to 15 characters long',
  AT_LEAST_ONE_NUMBER: 'contain at least one number',
  AT_LEAST_ONE_SPECIAL_CHARACTER: 'contain at least one special character',
  HAVE_BOTH_UPPER_AND_LOWER_CASE: 'contain a mix of upper and lower case letters',
  SWIPE_LEFT_REMOVE_CARD: 'Swipe left to edit / remove a card',
  WARNING_MESSAGE: 'Ugh! Something went wrong.',
  WARNING_MESSAGE_QRCODE: 'Ugh! Invalid sensor code',
  PLEASE_ENTER_EMAIL: 'Please enter the email you use to sign in to OneDine.',
  SUDDENLY_REMEMBERED_YOUR_PASSWORD: 'Suddenly remembered your Password? ',
  OVERALL_EXPERIENCE: 'How was your overall experience?',
  TABLE_NOT_SELECTED: 'Table not selected',
  PAYMENT_SUCCESS: 'Payment Success!',
  APPLY_PAYMENT: 'Select or add a card to apply towards payment',
  PAYMENT: 'Payment',
  TOTAL: 'Total',
  ASSIGN_SENSOR: 'This will assign the sensor to table',
  HIGH_QUALITY: 'Were the ingredients of high quality?',
  SPEED_OF_FOOD_SERVICE: 'What do you think of the speed of food service?',
  STANDARD_TEXT_RATES: 'By entering your mobile number you agree to receive a one time password and order status alerts via text message. Message and data rates may apply.',
  WANT_TO_DELETE: 'Are you sure want to delete?',
  PICKUP_YOUR_ORDER: 'When would you like to pickup your order?',
  DELIVER_YOUR_ORDER: 'When would you like your order delivered?',
  DELETE_ITEM_CONFIRMATION: 'Are you sure, you want to delete this item?',
  TABLE_ORDER_VALIDATION: 'There is no open check at this time. Please contact your server to open a check before placing an order',
  PAYMENT_CONFIRMATION: 'If you proceed, your card will be charged.',
  ORDER_CONFIRMATION: 'If you proceed, your order will be placed.',
  PAY_LATER_MESSAGE: 'Do you want pay now or at the restaurant?',
  // PROVIDE_PAYMENT_AT_ARRIVAL_TO_RESTAURANT: 'Please provide payment at arrival to restaurant.',
  PROVIDE_PAYMENT_AT_ARRIVAL_TO_RESTAURANT: '',
  CREATE_YOUR_PROFILE: 'Create your OneDine profile:',
  NO_ORDER_HISTORY: 'You haven\'t placed any orders yet.',
  ENTER_SPOT_NUMBER: 'Enter your spot number:',
  YOUR_SPOT_NUMBER: 'What\'s your spot number?',
  ENTER_CAR_DESCRIPTION: 'Provide a short description of your vehicle:',
  RECEIPT_RESEND_OPTION: 'Reciept has been sent. Resend?',
  // Other_tips page message
  TIPS_PERCENTAGE_MSG: 'Tip this percentage of the subtotal:',
  TIPS_AMOUNT_MSG: 'Tip this amount:',
  TOTAL_TIPS_MSG: 'Including tip, make the total:',

  // links
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  SIGN_UP: 'SIGN UP',
  LOGIN_WITH_CODE: 'Sign in with your phone number',
  LOGIN_WITH_PASSWORD: 'Sign in with a password',
  SIGN_IN_TO_ACCOUNT: 'Sign in to your account',
  CHANGE_YOUR_PASSWORD: 'Change Your Password',
  CHANGE_BASIC_INFO: 'Change Your Basic Informations',
  VIEW_DETAILS: 'View Details',
  EDIT: 'Edit',
  GO_TO_HOME: 'Go to Home',
  VIEW_CHECK: 'View Check',
  EDIT_PROFILE: 'Edit Your Profile',
  CHANGE: 'Change',

  // OTHER-LINKS
  TAP_SENSOR_NO_STAGE: 'Tap Sensor / No Stage',
  TAP_SENSOR_STAGE: 'Tap Sensor / Staged',
  TAP_SENSOR_NO_CHECK: 'Tap Sensor / No Check',
  KIOSK_TEXT: 'Kiosk Text',
  ONEDINE_MAIL: 'www.onedineapp.com',
  CPK_ONEDINE: 'cpk.onedineapp.com',
  CONFIRM_TERMS_CONDITION: 'By clicking continue, I agree to the Terms and Privacy Policy.',

  // Payment screen
  TOTAL_DUE: 'Total Due',
  ORDER_COMPLETE_MSG: 'Orders are currently taking',
  MINUTES: 'minutes',

  // Checked_IN
  CHECKED_IN_HEADER: 'You checked in at:',
  YOU_NEED_SIGN_IN: 'You`ll need to sign in before you can order.',
  CANCEL_CHECKIN: 'Cancel check-In',

  // dashBoard
  SCAN_QR_CODE: 'Scan QR Code',
  ENTER_CODE_INSTEAD: 'Or enter the 7-digit code instead:',

  // Login
  YOUR_CHECKED_LOCATION: 'You`re checking in at:',
  ENTER_VERIFICATION_CODE: 'Enter your verification code:',
  ENTER_LAST_FOUR_OF_PHONE_NUMBER: 'Enter last four of phone number',
  ENTER_EMAIL_ADDRESS: 'Enter Email Address',
  ENTER_EMAIL_ADDRESS_TO_RECIEVE_CODE: 'Enter email address to receive code',
  LAST_FOUR_OF_PHONE_NUMBER: 'Last four of phone number',
  USE_DIFFERENT_NUMBER: 'Use a different phone number',
  VERIFY_PHONE_NUMBER_MSG: 'You`ll need to verify your phone number before you can order or pay.',

  // OTHER ORDERS
  SINGLE_PERSON_ORDERING: 'Is anyone else using their phone to place an order at this table',

  // Spot Screens
  ENTER_NAME_BELOW: 'Enter your name below',

  // Up sell
  MAY_WE_SUGGEST: 'May we suggest?',

  // Daisy Chain Screens
  DC_ORDER_MSG: 'Are you the last person to add to this order?',
  DC_ORDER_PLACE_MSG: 'Are you the only person sending an order right now?',

  SELECT_STORE_ORDER_FROM: 'Select a restaurant',

  ENTER_MOBILE_NUMBER: 'Please Enter Your Mobile Number',
  ENTER_EMAIL_ID: 'Please Enter Your Email Address',

  STORE_CHECK_OUT: 'Please select the store to checkout from?',

  WELCOME_MSG: '',

  // Coupon
  ENTER_COUPON_NUMBER: 'Enter coupon number',

  LOYALTY_LOOKUP_ACCOUNT: 'How would you like to look up your account?',
  LOYALTY_LOOKUP_JOIN_NOW: 'How would you like to Signup your account?',
  LOYALTY_REWARDS_MESSAGE: 'Would you like to join rewards program?',


  LOYALTY_ACC_NUMBER_LABEL: 'Please enter your account number',
  LOYALTY_PHONE_NUMBER_LABEL: 'Please enter your phone number',
  LOYALTY_EMAIL_ADDRESS_LABEL: 'Please enter your email address',
  LOYALTY_QR_CODE_LABEL: 'QR code',

  COULD_NOT_LOCATE_YOUR_ACCOUNT: 'Could not locate your account. Would you like to register?',
  MOBILE_NUMBER_MESSAGE: 'Mobile number must be exactly 10 numbers',

  SELECT_ALL_CONFIRMATION: 'You have not selected all items for payment. Please confirm how would you like to proceed.',
  NO_CHECK_MESSAGE: 'Checks are not available',

  PRE_AUTH_LOCATION_CREDIT_CARD: 'This location requires a credit card hold before placing an order. You must settle your tab for the final check amount before departing this establishment.',
  PRE_AUTH_HOLD: 'A hold will be placed on your card. I agree to settle my tab for the final check amount before departing this establishment,',

  // Table Grouping Order
  // QR_CODE_MESSAGE: 'Please request a QR from your party host to join this table. The host can generate a QR by clicking on the Invite Guest tile on their app. Ensure that you fit the QR code completely into the box below.',
  // QR_CODE_JOIN_MESSAGE: 'Please present this QR code to any other member of your party who would like to join this check.',
  // JOIN_THROUGH_SMS_MESSAGE: 'Want to invite over SMS? Copy the link below and send it using your SMS app.',
  WANT_TO_INVITE_OVER_SMS: 'Want to invite over SMS? Copy the link below and send it using your SMS app.',
  PLEASE_PRESENT_THIS_QR: 'Please present this QR code to any other member of your party who would like to join this check.',
  PLEASE_REQUEST_QR_PARTY_HOST: 'Please request a QR from your party host to join this table. The host can generate a QR by clicking on the Invite Guest tile on their app. Ensure that you fit the QR code completely into the box below.',
  CHECK_TABLE_OPEN: 'There is a check open on this table. Would you like to join this check?',
  CHECK_JOIN_ORDERING_EXP_MSG: 'To enhance your ordering experience, please select from one of the following options',
  AUTOMATIC_SERVICE_CHARGE_ADDED: 'An automatic Service Charge has been added to you bill. 100% of our Service Charge benefits our team. To mantain a safe and contactless environment, we do not accept tips or gratuities. Thank you!',

  // Immediate order
  IMMEDIATE_ORDER_SEND: 'Would you like it sent right away?',
  IMMEDIATE_ORDER_FAILURE: 'There was an error sending your order. Would you like to add to cart instead?',

  // Pay-my-guests
  PAY_SELECTED_SEATS: 'Select seats for payment',
  PAY_SELECTED_ITEMS: 'Select items for payment',

  UNAVAILABLE_TOAST_MESSAGE: 'the highlighted items are not available at the selected time. please remove these items from your cart or select a new time',
  EDIT_ADDRESS_MESSAGE: 'if you change the address, your cart will be cleared and you will have to restart your order. do you want to continue?',

  // Pickup and delivery
  PICKUP_TIME_NOT_AVAILABLE: 'Pickup time not available for selected date',
  DELIVERY_TIME_NOT_AVAILABLE: 'Delivery time not available for selected date',

  TIP_SELECTION_REQUIRED_MESSAGE: 'Please select a tip to complete payment',
  TIP_INVALID_PERCETAGE_AMOUNT: 'enter a valid percent or zero',
  TIP_INVALID__AMOUNT: 'enter a valid amount or zero',
  TIP_INVALID_TOTAL_AMOUNT: 'enter a valid amount',
  PRICE_CHECK_ERROR_MESSAGE: 'We’re sorry we are unable to collect payment online at the moment. Would you like to submit your order and pay at the restaurant?',
  STORE_OFFLINE_ERROR_MESSAGE: 'We’re sorry we are unable to process your order because the restaurant is offline. Please call the restaurant directly. Click the continue button to view your order',

  // Payment Success
  CURB_WALK_CONFIRMATION_MESSAGE_ORDER_PAID: 'Payment has been received. Your order should be out shortly.',
  CURB_WALK_CONFIRMATION_MESSAGE_ORDER_NOT_PAID: 'Your order has been received. A team member will be with you shortly to collect payment.',
  ONLINE_ORDER_CONFIRMATION_MESSAGE: 'Your order has been received. You will receive a text message with a link to check-in when you arrive at the location.',
  ONLINE_ORDER_PAID_SUPRESSONLINEORDERCHECKIN_CONFIRMATION_MESSAGE: 'Your order has been received. You will receive a text message with a confirmation link.',
  ONLINE_ORDER_NOT_PAID_SUPRESSONLINEORDERCHECKIN_CONFIRMATION_MESSAGE: 'Your order has been received. You will receive a text message with a confirmation link.',

  DELIVERY_CONSMENU_CONF_MESSAGE: 'Your order will be delivered from the following locations.',
  PICKUP_CONSMENU_CONF_MESSAGE: 'Please pickup your order from the following locations. You will recieve a text message when your order is ready for pickup',

  // Change location
  PLEASE_CONFIRM_TABLE: 'Please scan the QR code on your table to confirm your location',
  YOUR_CART_EMPTY: 'Your cart is empty.',
  ADD_SOMETHING: 'Go ahead and add something!',

  // Item Not Found
  ITEM_NOT_FOUND: 'Search item not found. Please select a different item or contact the restaurant.',
  MENU_NOT_AVAILABLE: 'Menu is currently not available. Please select a different menu or contact the restaurant.',

  // Account
  NO_ADDRESS_FOUND: 'No addresses found',
  NO_RESULTS_FOUND: 'No results found',

  // Payment page
  ORDER_LOADING_MESSAGE: 'Your order is being processed and may take a moment. Please do not close this window or click the back or refresh button on your browser.',
  CHECK_MODIFIED_MESSAGE: 'Check has been modified on the POS. Please click to refresh',

  WALLET_EMPTY: 'Your wallet is empty',
  WALLET_PLEASE_ADD_CARD: 'Please save some card to wallet',
  PLEASE_SELECT_CARD: 'Please select a card',
  PLEASE_ADD_CARD: 'Please add a card',

  // ADD CARD
  UNABLE_PROCEED_WITH_PAYMENT: 'Unable to proceed with payment',

  // Payments, Loyalty accounts
  ENTER_CARD_NAME: 'Enter the card name',
  TERMS_CONDITION: 'Please agree to terms of service to continue',

  NO_CHECKS_AVAILABLE: 'No checks are available',
  YOUR_ORDER_HAS_BEEN_PLACED: 'Your order has been placed.',

  OFFER_WANT_TO_DELETE: 'Are you sure, You want to delete this Offer?',

  LOYALTY_WANT_TO_DELETE: 'Are you sure, You want to delete Loyalty?',

  // GIFT CARD
  GC_NUMBER: 'Enter gift card number',
  GC_SECURITY_CODE: 'Enter security code',
  GC_DELETE: 'Are you sure, You want to delete gift card?',

  DATA_REQUEST_MESSAGE: 'If you would like to make a request about your data, please complete this form or contact us at (888) 551-3463. Before we can review your request, we must first verify your identity and ownership of the data you would like to manage.',

  // Offer screen
  REMOVE_OFFER_MESSAGE: 'The selected offer is valid only with PayPal payments. If you want to pay a different way, please remove the offer and try again.',
  CANCEL_ORDER_MESSAGE: 'Are you sure you want to cancel your order?',

  ELIGIBLE_OFFER_MESSAGE: 'Congratulations -you have qualified for this offer',
  ADD_TO_BAG_OFFER_MESSAGE: 'Add to your bag to qualify for this offer',

  MY_DATA_MESSAGE: 'What would you like to do?',

  RECEIPT_PAID_TO: 'Paid to',
  RECEIPT_PAID_GIFTCARD: 'Paid to Gift Card ending with',
  RECEIPT_PAID_WITH_PAYPAL: ' Paid with PayPal',

  INVALID_SENSOR_LABEL_MES: 'Order & pay from your phone!',
  INVALID_SCAN_QR_CODE_PARTICIPATE_MESSAGE: 'Scan or tap the OneDine QR code at any participating restaurant.',
  INVALID_SCAN_QR_ERROR_MESSAGE: 'The QR or NFC code was not scanned successfully. Please try again using one of the options below.',

  ENTER_CHECK_NUMBER: 'Enter check number below',
  ENTER_CHARITY_AMOUNT: 'Enter charity amount',
  ENTER_ACCOUNT_NUMBER: 'Enter Account Number',

  VOUCHER_ERROR_MESSAGE: 'Cannot proceed further because voucher is not setup.',

  PLEASE_CONTACT_THE_RESTAURANT: 'Please contact the restaurant to make any changes to your orders.',
  SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE: 'SMS verification is currently offline. Please enter the last four digits of the phone number and get four hour access to the system.',
  SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_NO_TOAST: 'SMS verification is currently offline. Please enter the last four digits of the phone number and get four hour access to the system.',
  SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_OFFLINE_MODE: 'We are unable to send an SMS to this location as the network is offline. Please enter valid email address to receive your code. Please ensure that your connected to a wi-fi network.',

  PLEASE_SELECT_CHECK: 'Please select a check to join',

  // Voucher SMS
  STILL_NOT_RECIEVED_VOUCHER: 'Didn\'t receive ticket SMS? Click here to send',
  VOUCHER_ENTER_MOBILE_NUMBER: 'Enter Mobile Number',
  VOUCHER_SUCCESS_MESSAGE: 'Voucher has beeen sent sucessfully to the registered mobile number.',

  // PAYMENT ONLY MODE
  PAYMENT_ONLY_MODE: 'Please give us just a moment to load this check. Press Retry to try again.',

  // Current Location Page
  CURRENT_LOCATION_ORDER_MSG: 'Your order has been placed.Please click the continue button to send your current location to the restaurant so they can deliver your order.',
  GET_LOCATION_ERROR_MSG: 'Unable to get your current location. please click continue and the restaurant will contact you about your order.',
  THANK_YOU_FOR_UPDATING_YOUR_LOCATION: 'Thank you for updating your location. Your order will be delivered shortly.',
  PLEASE_KEEP_THIS_TAB_OPEN: 'Please keep this tab open so we can continue locating you.',
  CLOSING_THIS_TAB_WILL_STOP_TRACKING: 'Closing this tab will stop location tracking.',
  SORRY_YOUR_LOCATION_COULD_NOT_BE_TRANSMITTED: 'Sorry, your location could not be transmitted to the restaurant. Please contact the restaurant directly.',

  // Online TimeStamp Error msg
  DATE_AND_TIME_ERROR: 'Selection should be later than current date and time',
  VOUCHER_TIME_ERROR: 'Please select different time slot and Voucher is sold out',

  GUEST_EVENT_JOIN_CHECK_MESSAGE: 'You are joining the following event',

  ENTER_LOYALTY_FIRSTNAME: 'Enter First Name',
  ENTER_LOYALTY_LASTNAME: 'Enter Last Name',
  LOOKUP_FORGOT_PASSWORD: 'Forgot password',
  OTP_SENT_TO_EMAIL: 'A verification code has been sent to your email address.',

  // Spot Screen for validation messages
  SPOT_SCREEN_VAL_ENTER_SPOT_NUMBER: 'Please enter your spot number.',
  SPOT_SCREEN_VAL_SPOT_NUMBER_LENGTH: 'Your spot number should be 10 digits or less.',
  OFFERS_CANNOT_BE_APPLIED: 'Offers cannot be applied to this check because the check has been split by persons.',
  SPLIT_BY_PERSON_POPUP_MSG: 'If check is split by persons or amount, then no guest in your party can apply offers to this check. Please request the server to split the check if anybody in your party want to redeem an offer. Do you want to continue with this form of payment?',
  EMP_TABLE_REASSIGNMENT_MSG: 'Order cannot be placed because this section is not open. Please contact a server for assistance.',
  SORRY_ORDERING_IS_NOT_SUPPORTED_IN_THIS_LOCATION: 'Sorry, online ordering is not supported at this location',
  CHOOSE_ORDER_METHOD: 'Choose Order Method',

  // Util screen for validation messages
  UTIL_SCREEN_INVAL_MOBILE_NUMBER: 'Invalid Mobile Number',
  // VERIFY OTP SCREEN for validation messages
  VERIFY_OTP_SCREEN_VAL_VERIFICATION_CODE: 'Please enter the verification code',
  // Generate Otp screen for validation messages
  GENERATE_OTP_SCREEN_REQ_EMAIL: 'Email required',
  GENERATE_OTP_SCREEN_INVAL_EMAIL: 'Invalid email',
  GENERATE_OTP_SCREEN_INVAL_MOBILE_NUMBER: 'Invalid Mobile Number',

  // Reset password screen for validation messages

  RESET_PASSWORD_SCREEN_INVALID_PASSWORD_REFER: 'invalid password (please refer to the notes below)',
  RESET_PASSWORD_SCREEN_REQ_CODE: 'code is required',
  RESET_PASSWORD_SCREEN_REQ_NEW_PASSWORD: 'new password is required',
  RESET_PASSWORD_SCREEN_REQ_CONFIRM_PASSWORD: 'confirm password is required',
  RESET_PASSWORD_SCREEN_PASSWORD: 'password',
  RESET_PASSWORD_SCREEN_PASSWORD_CONFIRMPASSWORD_SAME: 'password & confirm password must be same',

  // profile screen for validation messages

  PROFILE_SCREEN_PASSWORD_REQUIRED: 'password is required',
  PROFILE_SCREEN_NEW_PASSWORD_REQUIRED: 'new password is required',
  PROFILE_SCREEN_CONFIRM_PASSWORD_REQUIRED: 'confirm password is required',
  PROFILE_SCREEN_REFER_NOTES_INVALID_PASSWORD: 'invalid password (please refer to the notes below)',
  PROFILE_SCREEN_PASSWORD_CONFIRM_PASSWORD_SAME: 'password & confirm password must be same',

  PROFILE_SCREEN_FIRST_REQUIRED: 'First name is required',
  PROFILE_SCREEN_LAST_REQUIRED: 'Last name is required',
  PROFILE_SCREEN_EMAIL_REQUIRED: 'Email is required',
  UTILLS__SCREEN_ENTER_COUNTRY_CODE: 'Please enter Country Code',

  // util screen for validation messages
  UTILLS_SCREEN_INVALID_COUNTRY_CODE: 'Invalid Country Code',
  UTILLS_SCREEN_REQ_COUNTRY_CODE: 'Country Code is required',

  // WALLET screen for validation messages

  WALLET_SCREEN_ENTER_NAME: 'Please enter the name',
  LOYALTY_SCREEN_ENTER_NAME: 'Please enter the name',
  // Select Cart for Validation
  SELECTED_CART_NAME_LIMIT: 'Name field is required',

  // PaymentSelectItemComponent,
  PAYMENT_SELECT_ITEM_OF: 'of',
  PAYMENT_SELECT_ITEM_SELECTED_FOR_PAYMENT: 'selected for payment',
  PAYMENT_REQUIRES_SERVER_ERROR: 'Payment could not be processed - please contact a server for assistance',
  PAYMENT_HOLD_SERVER_ERROR: 'Unable to place a hold on this card - please try again or use a different card [Payment could not be processed - please contact a server for assistance]',
  ITEMS_HELD_PENDING_AGE_VERIFICATION: 'Items held pending age verification',
  AGE_VERIFICATION_CHECKING: 'Order contains items that require age verification and will be held. Do you want to send the other items anyway?',
  PAY_SELECTED_STAGES: 'Select a split',
  NEGATIVE_BALANCE: 'Cannot make payment when one of the stages has a negative balance',
  PRE_ORDER_MESSAGE: 'Save time and pre-order - select time and number of rounds, or click skip to just order this round.',
  PRE_ORDER_ONE_DRINK: 'Only want one drink? Select "Order One Drink"',
  PRE_ADD_THIS_ITEM: 'Add this item to cart and then',
  PRE_ADD_MULTIPLE_DRINK: 'Want more than one drink? You can now pre-order rounds. You\'ll get ONE to start, then select how MANY rounds and how OFTEN you\'d like them.',
  PRE_MORE_DRINKS_DELIVERED: 'MORE rounds delivered',

  // Stripe
  STRIPE_NAME_IS_REQUIRED: 'Name is required',
  STRIPE_NAME_REQ_MSG: 'nameRequiredMessage',
  STRIPE_ERROR_MSG: 'stripeErrorMessage',

  // USA

  USA_EPAY_CARD_NAME_IS_REQUIRED: 'Card Name is required',
  USA_EPAY_INVALID_ZIPCODE: 'Invalid Zip code',

  // payment validations
  EMPTY_CARD_NUMBER: 'Enter card number',
  CARD_VALID: 'Enter valid card details',
  EMPTY_EXPIRY_DATE: 'Enter expiry date',
  EXPIRY_VALID: 'Enter valid expiry date',
  EMPTY_CVV: 'Enter CVV',
  CVV_VALID: 'Enter valid CVV',
  EMPTY_CARD_NAME: 'Enter name on card',
  EMPTY_ZIP_CODE: 'Enter Zip Code',
  INVALID_ZIP: 'Invalid ZIP Code',

  // spreedly
  SPREEDLY_STATE_NOT_BLANK: 'State can\'t be blank',
  SPREEDLY_ZIPCODE_NOT_BLANK: 'Zipcode can\'t be blank',
  SPREEDLY_CITY_NOT_BLANK: 'City can\'t be blank',
  SPREEDLY_ADDRESS1_NOT_BLANK: 'Address1 can\'t be blank',
  SPREEDLY_CREDIT_CARD_INVALID: 'Credit card number entered is not valid',
  SPREEDLY_CVV_INVALID: 'Enter valid cvv',
  SPREEDLY_ZIPCODE_INVALID: 'Enter valid zip code',

  // ODP-2687 Removed Keys
  AUTO_GRATUITY_MESSAGE: 'Gratuity',
  CLICK_TO_CHANGE_RESTAURANT: 'click to change restaurant',
  MY_DATA_CHECK_IN_MES: 'Please complete this form if you would like to request and manage your data. Before we can review your request, we must verify your identity and ownership of your account.',

  // WAIT-LIST
  WAIT_LIST_ENTER_MOBILE_NUMBER: 'Enter mobile number',
  WAIT_LIST_PARTIES_OF_AHEAD: 'Parties ahead of you',
  WAIT_LIST_INFO: 'Wait List Confirmation',
  WAIT_LIST_DELETE_MESSAGE: 'Are you sure you want to cancel your wait list reservation',
  WAIT_LIST_ADDED_MESSAGE: 'You have been added to the wait list',
  WAIT_LIST_CANCEL_MESSAGE: 'You have been removed from the wait list',
  WAIT_LIST_UPDATE_MESSAGE: 'Your wait list has been modified successfully',
  WAIT_LIST_NAME_IS_REQUIRED: 'Name is required',
  WAIT_LIST_PLEASE_SELECT_PARTY_SIZE: 'Please select party size',
  WAIT_LIST_INVALID_EMAIL: 'Invalid email',
  WAIT_LIST_CANCEL_FROM_SMS: 'Wait list has been cancelled - please contact the location directly If you have any questions',

  TIP_DISCLAIMER: '',

  // amazon pay
  CHECKOUT: 'Checkout',
  PAYMENT_METHOD: 'Payment Method',
  CHANGE_PAYMENT: 'Change Card',
  TOTAL_DUE_Amount: 'Total Due',
  PLACE_ORDER: 'Place Order',
  DELETE_PREORDER_CONF_MESSAGE: 'This will delete your pre order request. do you want to continue?',
  COUPON_DELETE: 'Are you sure, You want to delete Coupon?',
  NO_OPEN_CHECK_AVAILABLE: 'No Open check available to place order. Please contact your server to open a check or try again.',

  // GLOBAL PAYMENT
  GP_EMP_POSTAL_CODE: 'Enter Postal Code',
  GP_INVALID_POSTAL_CODE: 'Invalid Postal Code',

  // SURVEY CONTACT
  SURVEY_CONTACT_ME_EMAIL_ADDRESS: 'Enter Email Address',
  SURVEY_CONTACT_ME_MOBILE_NUMBER: 'Enter Phone Number',
  SURVEY_IN_VALID_EMAID: 'Invalid Email Address.',
  SURVEY_IN_VALID_PHONE: 'Invalid Mobile Number.',
  SURVEY_EMAIL_ADDRESS_REQUIRED: 'Email address is required.',
  SURVEY_MOBILE_NUMBER_REQUIRED: 'Mobile number is required.',
  SURVEY_CONTACT_MAY_WE_CONTACT: 'May we contact you about your experience?',
  SUVREY_CONTACT_BEST_EMAIL: ' What is the best email address to reach you?',
  SURVEY_CONTACT_BY_PHONE: ' Yes, also contact by me phone',
  // CHARITY
  CHARITY_DELETE: 'Are you sure, You want to delete Charity?',
  CHARITY_VALID_AMOUNT: 'Please enter Valid amount',

  CHOOSE_SMS_OPTIN: 'Opt In To SMS marketing',
  CHOOSE_EMAIL_OPTION: 'Opt In To Email marketing',

  OPT_IN_VALID_EMAIL_ADDRESS: 'Please enter valid email address',
  OPT_IN_VALID_PHONE_NUMBER: 'Please enter valid phone number',

  OPT_IN_PHONE_REQUIRED: 'Phone number must be required',
  OPT_IN_EMAIL_REQUIRED: 'Email address must be required',

  OPT_INTO_RECIEVE_COMMUNICATION: 'Opt in to recieve communication',

  PLEASE_CONFIRM_LOCATION: 'Please confirm that you are at the location',
  SPOT_CONFIRMATION_MESSAGE: 'Your check-in request has been received.',
  PLEASE_CLICK_BUTTON: 'Please click the button when you are at the location.',

  DOJO_CHOOSE_YOUR_PAYMENT_METHOD: 'Choose your payment method',
  DOJO_PAYMENT_CANCELLED: 'Payment was cancelled',

  OOPS_ERROR_OCCURRED_TRY_AGAIN: 'Oops, an error occurred. Please try again.',

  BROWSER_NOT_SUPPORTED: 'Sorry, this browser is not supported'
};
