import React, { Fragment } from 'react';
import FocusTrap from 'focus-trap-react';
import cn from 'classnames';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import styles from './modal.module.css';
import { onKeyDown } from '../../../utility/utills';

const Modal = ({
  isOpen, toggleModal, onConfirm, children, customClass, retainModal, modalHideOverFlow
}) => {
  return (
    <FocusTrap
      active={isOpen || false}
      focusTrapOptions={{
        initialFocus: '#modal-container',
        escapeDeactivates: false,
        fallbackFocus: () => {
          return (
            <div tabIndex="-1" />
          );
        }
      }}
    >
      {/* eslint-disable-next-line */}
        <div className="container" id="modal-container" tabIndex={-1} onKeyDown={(ev) => { if (toggleModal && ev.key === 'Escape') toggleModal(); }}>
          {!children ? (
            <div className={cn('modal', isOpen ? 'fade show d-block' : 'fade')}>
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="card">
                    <div className="p-10 w-100">
                      <div className="col text-right btn-as-text color-secondary">
                        <div className={cn('icon-od fs-12 icon-od-close', styles.modalCloseIcon)} role="button" tabIndex={0} onKeyDown={e => onKeyDown(e, toggleModal)} onClick={toggleModal} aria-label="close" />
                      </div>
                    </div>
                    <div className="mx-0 justify-content-center align-items-center text-center">
                      <div className="fs-18 fw-600 text-center mt-10 p-10 pt-0">
                        {CUSTOM_MESSAGES().POPIN_CONFIRM_MSG}
                      </div>
                      <div className="row mt-20 mb-20 justify-content-center align-items-center text-center">
                        <button type="button" className="mr-20 btn btn-secondary col-4" onClick={toggleModal}>
                          {LABEL().NO}
                        </button>
                        <button type="button" className="ml-20 btn btn-primary col-4" onClick={onConfirm}>
                          {LABEL().YES}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className={cn('modal', isOpen ? 'fade show d-block' : 'fade', customClass, retainModal ? styles.retainModal : styles.popupFlow)}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className={cn('modal-content od-app-background-color', modalHideOverFlow ? styles.modalOverFlow : styles.modalContent)}>
                    {children}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
    </FocusTrap>
  );
};
export default Modal;
