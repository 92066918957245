import React from 'react';
import { onKeyDown } from '../../utility/utills';
import styles from './storeMenuModal.module.scss';

const StoreMenuModal = (props) => {
  const { menusListData, menuIndex, onMenuChangeHandler } = props;
  return (
    <div className="">
      {menusListData && menusListData.map((store, index) => {
        const isSelected = index === menuIndex ? 1 : 0;
        return (
          <div className="text-white" role="button" tabIndex={0} onKeyDown={e => onKeyDown(e, onMenuChangeHandler, index)} onClick={() => onMenuChangeHandler(index)}>
            <div className="col-12 text-left p-10">
              {isSelected ? <i className="fas fa-check-circle fs-20" /> : <i className="far fa-circle fs-20" />}
              <span className="pl-2 fs-18 ">
                {store.name}
              </span>
            </div>
            {(index !== menusListData.length - 1) && <hr className={styles.menuHr} />}
          </div>
        );
      })}
    </div>
  );
};

export default StoreMenuModal;
