import React, {
  useRef,
  useEffect,
  useState
} from 'react';
import cn from 'classnames';
import styles from './footer.module.css';
import { getObjectValue, accessibleDiv } from '@utility/utills';
import PickMenu from '@containers/PickMenu';
import { LABEL } from '@veriables';
import { onKeyDown } from '../../../utility/utills';

const useOutsideAlerter = (ref, cb) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const svg = document.getElementById('menuImageId');
        // const style = 'od-selected-footer-icon-color';
        const style1 = 'od-unselected-footer-icon-color';
        if (svg) {
          svg.setAttribute('class', `${style1} ${styles.storeImg}`);
        }
        cb();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleClickOutside(event);
      }
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, [ref]);
};

const FooterModel = ({ id, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const svg = document.getElementById('menuImageId');
  useOutsideAlerter(wrapperRef, () => { setOpen(false); });
  props.handleMenuPopup(isOpen);
  return (
    <div ref={wrapperRef} role="button" tabIndex={0} onKeyDown={(ev) => { if (ev.key === 'Enter' || ev.key === ' ') { if (svg) svg.setAttribute('class', `od-selected-footer-icon-color ${styles.storeImg}`); setOpen(!isOpen); } }} className={`tab-icon ${isOpen ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'}`} onClick={() => { if (svg) svg.setAttribute('class', `od-selected-footer-icon-color ${styles.storeImg}`); setOpen(!isOpen); }}>
      {isOpen ? props.children : props.children[0]}
    </div>
  );
};
const RenderMenuItem = ({ menuItem, ...props }) => {
  const {
    navigateTo,
    index,
    popInData,
    closeOnSamePage,
    wishListCount,
    menusList,
    getCartQuantity,
    onClickCartHandler,
    onPreviewSync
  } = props;
  const path = window.location.pathname;
  const [menuPopup, setMenuPopup] = useState(false);

  const handleMenuPopup = (status) => {
    setMenuPopup(status);
  };
  const storeMenu = () => {
    window.sessionStorage.setItem('storeMenu', 1);
  };
  useEffect(() => {
    window.sessionStorage.removeItem('storeMenu', true);
  }, [storeMenu]);

  const handleStyle = (id, isActive) => {
    const svg = document.getElementById(id);
    const style = 'od-selected-footer-icon-color';
    const style1 = 'od-unselected-footer-icon-color';
    if (svg && isActive) {
      svg.setAttribute('class', `${style} ${styles.storeImg}`);
    } else if (svg && !isActive) {
      svg.setAttribute('class', `${style1} ${styles.storeImg}`);
    }
  };

  const supportsDisplayCartAsButton = getObjectValue(() => popInData.store.guestAppFeatures.supportsDisplayCartAsButton, false);

  const getThemeIcons = (imageUrl, imageId, activePath) => {
    return (
      <>
        <svg id={imageId} className={cn(styles.storeImg, activePath ? 'od-selected-footer-icon-color' : 'od-unselected-footer-icon-color', handleStyle(imageId, activePath))}>
          <image href={imageUrl} width="48" height="24" alt="store" />
        </svg>
      </>
    );
  };

  // const getFavStyles = () => {
  //   const favouriteStyle = props.themeProperties && props.themeProperties.style && JSON.parse(props.themeProperties.style);
  //   const favouriteIcon = favouriteStyle && favouriteStyle.odFavIcon;
  //   if (favouriteIcon && favouriteIcon === 'none') {
  //     return true;
  //   }
  //   return false;
  // };

  const myBagComp = (path === '/home') ? (
    <>
      <div key={index} className={`${menuItem.activeLinks.filter(d => path.includes(d)).length > 0 ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { onClickCartHandler(); }}>
        {getObjectValue(() => props.themeProperties.appCartButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appCartButtonImageUrl, 'cartImageId', menuItem.activeLinks.filter(d => path.includes(d)).length > 0) : (<i className="fa fa-shopping-bag fa-lg" aria-hidden="true" />)}
        {getObjectValue(() => getCartQuantity() > 0, false) && (
        <span className={cn(styles.cartQuantity, getObjectValue(() => getCartQuantity() > 9, false) ? styles.right8 : '', getObjectValue(() => getCartQuantity() > 0, false) ? 'od-cart-button badge badge-success text-white' : '')}>
          {getObjectValue(() => getCartQuantity(), 0)}
        </span>
        )}
        {/* <div className="">{LABEL().FOOTER_MY_BAG}</div> */}
        <div className="">{LABEL().FOOTER_MY_BAG}</div>
      </div>
  </>
  ) : '';
  switch (menuItem.type) {
    case 1:
      return (
        <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { navigateTo(menuItem.controlUrl, menuItem.type); }}>
          {getObjectValue(() => props.themeProperties.appHomeButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appHomeButtonImageUrl, 'homeImageId', menuItem.activeLinks.includes(path)) : (<i className="fa fa-home fa-lg" aria-hidden="true" />)}
          <div className="">{LABEL().FOOTER_HOME}</div>
        </div>
      );
    case 2:
      return (
        <div key={index} className={`${menuItem.activeLinks.filter(d => path.includes(d)).length > 0 ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => navigateTo(menuItem.controlUrl, menuItem.type)}>
          {getObjectValue(() => props.themeProperties.appBrowseButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appBrowseButtonImageUrl, 'browImageId', menuItem.activeLinks.filter(d => path.includes(d)).length > 0) : (<i className="fa fa-utensils fa-lg" aria-hidden="true" />)}
          <div>{LABEL().BROWSE}</div>
        </div>
      );
    case 3:
      return (
        <>
          <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { navigateTo(menuItem.controlUrl, menuItem.type); }}>
            {getObjectValue(() => props.themeProperties.appFavoritesButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appFavoritesButtonImageUrl, 'favImageId', menuItem.activeLinks.includes(path)) : (<i className="fas fa-heart fa-lg" aria-hidden="true" />)}
            {wishListCount > 0 && (
            <span className={cn(styles.cartQuantity, wishListCount > 9 ? styles.right8 : '', wishListCount > 0 ? 'od-cart-button badge badge-success text-white' : '')}>
              {wishListCount}
            </span>
            )}
            <div>{LABEL().FAVOURITE}</div>
          </div>
          </>
      );
    case 4:
      return (supportsDisplayCartAsButton ? myBagComp : (
        <>
          <div key={index} className={`${menuItem.activeLinks.filter(d => path.includes(d)).length > 0 ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { onClickCartHandler(); }}>
            {getObjectValue(() => props.themeProperties.appCartButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appCartButtonImageUrl, 'cartImageId', menuItem.activeLinks.filter(d => path.includes(d)).length > 0) : (<i className="fa fa-shopping-bag fa-lg" aria-hidden="true" />)}
            {getCartQuantity() > 0 && (
            <span className={cn(styles.cartQuantity, getCartQuantity() > 9 ? styles.right8 : '', getCartQuantity() > 0 ? 'od-cart-button badge badge-success text-white' : '')}>
              {getCartQuantity()}
            </span>
            )}
            <div className="">{LABEL().FOOTER_MY_BAG}</div>
          </div>
        </>
      ));
    case 5:
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => navigateTo(menuItem.controlUrl)}><i className="fas fa-money-check-alt fa-lg" /><div className="">Tab Name</div></div>;
    case 6:
      return (
        <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { navigateTo(menuItem.controlUrl, menuItem.type); }}>
          {getObjectValue(() => props.themeProperties.appOrdersButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appOrdersButtonImageUrl, 'orderImageId', menuItem.activeLinks.includes(path)) : (<i className="fas fa-utensils fa-lg" aria-hidden="true" />)}
          <div className="">{LABEL().FOOTER_ORDERS}</div>
        </div>
      );
    case 17:
      return (
        getObjectValue(() => popInData.flowType, null) === 2 && (
        <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { navigateTo(menuItem.controlUrl, menuItem.type); }}>
          {getObjectValue(() => props.themeProperties.appMyCheckButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appMyCheckButtonImageUrl, 'checkImageId', menuItem.activeLinks.includes(path)) : (<i className="fas fa-receipt fa-lg" aria-hidden="true" />)}
          <div className="">{LABEL().FOOTER_MY_CHECK}</div>
        </div>
        )
      );
    case 7:
      return (popInData.venue && popInData.stores && popInData.stores.length > 1) ? (
        <>
          <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { props.setShowRestaurants(true); }}>
            {getObjectValue(() => props.themeProperties.appStoresButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appStoresButtonImageUrl, 'storeImageId', menuItem.activeLinks.includes(path)) : (<i className="fa fa-store fa-lg" aria-hidden="true" />)}
            <div className="">{LABEL().FOOTER_STORE}</div>
          </div>
      </>
      ) : null;
    case 8:
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => navigateTo(menuItem.controlUrl)}><i className="fas fa-thumbtack fa-lg" aria-hidden="true" /><div className="">Tab Name</div></div>;
    case 9:
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => navigateTo(menuItem.controlUrl)}><i className="fas fa-map-marker fa-lg" aria-hidden="true" /><div className="">Check In</div></div>;
    case 10:
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => navigateTo(menuItem.controlUrl)}><i className="fas fa-user-clock fa-lg" aria-hidden="true" /><div className="">Tab Name</div></div>;
    case 11:
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => navigateTo(menuItem.controlUrl)}><i className="far fa-user fa-lg" aria-hidden="true" /><div className="">Tab Name</div></div>;
    case 12:
      return (
        menusList.length > 1
          ? (
            <FooterModel id="menuImageId" role="button" tabIndex={0} onKeyDown={ev => onKeyDown(ev, handleMenuPopup, !menuPopup)} handleMenuPopup={handleMenuPopup}>
              <div className="ignoreDivs" role="presentation" key={12} onClick={() => { storeMenu(); }}>
                {getObjectValue(() => props.themeProperties.appMenusButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appMenusButtonImageUrl, 'menuImageId', menuPopup) : (<i className="fa fa-book fa-lg" aria-hidden="true" />)}
                <div className="ignoreDivs" role="presentation" onClick={() => { props.handleSessionTrack(); }}>
                  {LABEL().FOOTER_MENUS}
                </div>
              </div>
              <div className={styles.footerPopUp}>
                <PickMenu />
              </div>
            </FooterModel>
          ) : null
      );
    case 13:
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => navigateTo(window.sessionStorage.getItem('accountPageToRedirect'))}><i className="fas fa-chevron-right" /><div className="">Tab Name</div></div>;
    case 14: // closeOnSamePage
      return <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => closeOnSamePage()}><i className="fas fa-chevron-left fa-lg" /></div>;
    case 15:
      return (supportsDisplayCartAsButton ? myBagComp : (
        <>
          <div key={index} className={`${menuItem.activeLinks.filter(d => path.includes(d)).length > 0 ? 'text-primary od-text-primary' : 'od-tab-icon'} tab-icon`} role="presentation" onClick={() => { onClickCartHandler(); }}>
            {getObjectValue(() => props.themeProperties.appCartButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appCartButtonImageUrl, 'cartImageId', menuItem.activeLinks.filter(d => path.includes(d)).length > 0) : (<i className="fa fa-shopping-bag fa-lg" aria-hidden="true" />)}
            {getCartQuantity() > 0 && (
            <span className={cn(styles.cartQuantity, getCartQuantity() > 9 ? styles.right8 : '', getCartQuantity() > 0 ? 'od-cart-button badge badge-success text-white' : '')}>
              {getCartQuantity()}
            </span>
            )}
            {/* <div className="">{LABEL().FOOTER_MY_BAG}</div> */}
            <div className="">{LABEL().FOOTER_MY_BAG}</div>
          </div>
        </>
      ));
    case 16:
      return ((popInData.flowType === 2 || popInData.flowType === 3 || popInData.flowType === 4 || popInData.flowType === 5 || popInData.flowType === 16) || (popInData.checkHeaderId && popInData.checkHeaderId !== 0)) ? (
        <div key={16} className={`${menuItem.activeLinks.includes(path) ? cn('text-primary od-selected-footer-icon-text-color') : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => { navigateTo(menuItem.controlUrl, menuItem.type); handleMenuPopup(false); }}>
          {getObjectValue(() => props.themeProperties.appHomeButtonImageUrl, false) ? getThemeIcons(props.themeProperties.appHomeButtonImageUrl, 'homeImageId', menuItem.activeLinks.includes(path)) : (<i className="fa fa-home fa-lg" aria-hidden="true" />)}
          <div className="">{LABEL().FOOTER_HOME}</div>
        </div>
      ) : null;
    default:
      return (window.localStorage.getItem('isPreviewMode')) && (
        <div key={index} className={`${menuItem.activeLinks.includes(path) ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color'} tab-icon`} role="presentation" onClick={() => onPreviewSync()}><i className="fas fa-sync-alt" aria-hidden="true" /><div className="">Sync</div></div>
      );
  }
};

const Footer = (props) => {
  const enableRequestAssistance = getObjectValue(() => props.popInData.enableRequestAssistance, false);
  const [isActiveServer, setIsActiveServer] = useState(false);
  const returnPath = () => {
    const path = window.location.pathname.slice(1);
    if (window.location.pathname === '/selected-cart/0' || window.location.pathname === '/upsell') {
      return LABEL().FOOTER_MY_BAG;
    } if (window.sessionStorage.getItem('storeMenu')) {
      return LABEL().FOOTER_MENUS;
    } if (window.location.pathname === '/mycheck') {
      return LABEL().FOOTER_MY_CHECK;
    } if (window.location.pathname === '/home') {
      return LABEL().FOOTER_HOME;
    } if (window.location.pathname === '/orders') {
      return LABEL().FOOTER_ORDERS;
    }
    return path;
  };
  const handleStyle = (id, isActive) => {
    const svg = document.getElementById(id);
    const style = 'od-selected-footer-icon-color';
    const style1 = 'od-unselected-footer-icon-color';
    if (svg && isActive) {
      svg.setAttribute('class', `${style} ${styles.storeImg}`);
    } else if (svg && !isActive) {
      svg.setAttribute('class', `${style1} ${styles.storeImg}`);
    }
  };
  const getThemeIcons = (imageUrl, imageId, activePath) => {
    return (
      <>
        <svg id={imageId} className={cn(styles.storeImg, activePath ? 'od-selected-footer-icon-color' : 'od-unselected-footer-icon-color', handleStyle(imageId, activePath))}>
          <image href={imageUrl} width="48" height="24" alt="store" />
        </svg>
      </>
    );
  };
  const handleCallServer = () => {
    setIsActiveServer(true);
    let dataToSend = {};
    if (props.popInData.checkHeaders && props.popInData.checkHeaders.length > 0 && props.popInData.checkHeaders[0].tab) {
      dataToSend = {
        storeId: props.popInData.store.id,
        sensorName: props.popInData.sensorName ? props.popInData.sensorName : null,
        message: `Guest requires assistance with ${returnPath()}`
      };
    } else {
      dataToSend = {
        storeId: props.popInData.store.id,
        stationId: props.popInData.stationId ? props.popInData.stationId : null,
        message: `Guest requires assistance with ${returnPath()}`
      };
    }
    props.callServer(dataToSend).then(() => {
      window.sessionStorage.getItem('storeMenu');
      props.setNotification({
        type: 'success',
        message: dataToSend.message,
      });
    }).catch((err) => {
      props.setNotification({
        type: 'danger',
        message: err.message
      });
    });
    window.sessionStorage.removeItem('storeMenu');
  };
  const validate = (footerItem) => {
    if (footerItem.type === 1 && getObjectValue(() => props.popInData.actions.length, 0) === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    accessibleDiv('footer-menu');
  }, [props.menuItems]);

  return (
    <>
      {window.innerWidth > 1023 ? '' : (
        <div className={cn(styles.footerFixed, styles.footerList, 'od-footer')} id="footer-menu">
          <div className={cn('container', styles.desktopFooterContainer)}>
            <div className="row">
              <div className={cn('d-flex justify-content-around align-items-center w-100 p-0 m-0', styles.desktopFooterItems)}>
                {props.menuItems.map(menuItem => validate(menuItem) && (menuItem.type !== null) && <RenderMenuItem menuItem={menuItem} {...props} />)}
                {enableRequestAssistance
                && (
                  <div className={`${cn(isActiveServer ? 'text-primary od-selected-footer-icon-text-color' : 'od-unselected-footer-icon-text-color')} tab-icon`} role="presentation" onClick={() => { handleCallServer(); }}>
                    {getObjectValue(() => props.themeProperties.appCallServerButtonImageUrl, isActiveServer) ? getThemeIcons(props.themeProperties.appCallServerButtonImageUrl, 'cartImageId', isActiveServer) : (<i className="fa fa-bell fa-lg" aria-hidden="true" />)}
                    <div className="">{LABEL().FOOTER_CALL_SERVER}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Footer;
