import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeMenuByIndex } from '@reducers/browse';
import { withRouter } from 'react-router-dom';
import {
  changeMenu
} from '@reducers/orders';
import StoreMenuModal from '@components/storeMenuModal';
import { APP_CONFIG } from '@constants';
import { sessionTrack } from '@reducers/controller';
import findIndex from 'lodash/findIndex';

const PickMenu = (props) => {
  const onMenuChangeHandler = (menuIndex) => {
    const menuItemIndex = menuIndex !== props.menuIndex ? 0 : window.sessionStorage.getItem('menuTypeId') && findIndex(props.menusList[menuIndex].screens, { id: Number(window.sessionStorage.getItem('menuTypeId')) });
    const dataToSend = {
      activityType: 'menu_select',
      data: {
        menuId: props.menusList[menuIndex].id
      }
    };
    props.sessionTrack(dataToSend);
    if (props.isBrowse) {
      props.changeMenuByIndex(menuIndex).then(() => {
        props.history.push(`${APP_CONFIG.BASE_URL}/browse-category`);
      });
    } else if (menuIndex !== props.menuIndex || menuIndex === props.menuIndex) {
      window.sessionStorage.removeItem('menuTypeId');
      window.sessionStorage.removeItem('scrol');
      window.sessionStorage.removeItem('isTile');
      window.sessionStorage.removeItem('menuName');
      // props.setMenuIndex(option);
      props.changeMenu(menuIndex, menuItemIndex);
      props.history.push(`${APP_CONFIG.BASE_URL}/orders`);
    } else {
      props.changeMenu(menuIndex, menuItemIndex);
      props.history.push(`${APP_CONFIG.BASE_URL}/orders`);
    }
  };
  return (
    <StoreMenuModal onMenuChangeHandler={onMenuChangeHandler} menuIndex={props.menuIndex} menusListData={props.menusList} />
  );
};
const mapStateToProps = ({ header, browse, orders }) => {
  if (header.footerItemsType === 1) {
    return {
      loading: browse.loading,
      menus: browse.menus,
      menusList: browse.menusList,
      menuIndex: browse.menuIndex,
      isBrowse: true
    };
  }
  return {
    loading: orders.loading,
    menus: orders.menus,
    menusList: orders.menusList,
    menuIndex: orders.menuIndex,
    isBrowse: false
  };
};

const mapDispatchStateToProps = dispatch => bindActionCreators({
  changeMenuByIndex,
  changeMenu,
  sessionTrack
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchStateToProps
)(PickMenu));
