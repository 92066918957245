export const GET_SUBSCRIPTION_REQUESTED = 'subscription/GET_SUBSCRIPTION_REQUESTED';
export const GET_SUBSCRIPTION_SUCCESS = 'subscription/GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'subscription/GET_SUBSCRIPTION_FAILURE';

export const SET_SUBSCRIPTION_REQUESTED = 'subscription/SET_SUBSCRIPTION_REQUESTED';
export const SET_SUBSCRIPTION_SUCCESS = 'subscription/SET_SUBSCRIPTION_SUCCESS';
export const SET_SUBSCRIPTION_FAILURE = 'subscription/SET_SUBSCRIPTION_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  subscriptionData: {
    optIns: [
      {
        brandId: 1,
        brandName: 'Subway',
        preferences: [
          {
            preferenceType: 'New offers',
            phone: true,
            email: false
          },
          {
            preferenceType: 'Promotions',
            phone: false,
            email: true
          }
        ]
      },
      {
        brandId: 2,
        brandName: 'KFC',
        preferences: [
          {
            preferenceType: 'New offers',
            phone: true,
            email: false
          },
          {
            preferenceType: 'Promotions',
            phone: false,
            email: true
          }
        ]
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        subscriptionData: action.result
      };
    }
    case GET_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SET_SUBSCRIPTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SET_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SET_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const getSubscription = () => {
  return {
    types: [GET_SUBSCRIPTION_REQUESTED, GET_SUBSCRIPTION_SUCCESS, GET_SUBSCRIPTION_FAILURE],
    promise: client => client.get('getSubscription')
  };
};

export const setSubscription = (data) => {
  return {
    types: [SET_SUBSCRIPTION_REQUESTED, SET_SUBSCRIPTION_SUCCESS, SET_SUBSCRIPTION_FAILURE],
    promise: client => client.get('setSubscription', {
      data
    })
  };
};
