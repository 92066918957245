export const CALL_SERVER_REQUESTED = 'callServer/CALL_SERVER_REQUEST';
export const CALL_SERVER_SUCCESS = 'callServer/CALL_SERVER_SUCCESS';
export const CALL_SERVER_FAILURE = 'callServer/CALL_SERVER_FAILURE';

export const DISABLE_CALL_SERVER = 'callServer/DISABLE_CALL_SERVER';

const initailValues = {
  loading: false,
  loaded: true,
  error: false,
  isCallServerDisabled: false
};

export default (state = initailValues, action) => {
  switch (action.type) {
    case CALL_SERVER_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case CALL_SERVER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case CALL_SERVER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case DISABLE_CALL_SERVER:
      return {
        ...state,
        isCallServerDisabled: action.payload
      };
    default:
      return state;
  }
};

export const callServer = (data) => {
  return {
    types: [CALL_SERVER_REQUESTED, CALL_SERVER_SUCCESS, CALL_SERVER_FAILURE],
    promise: client => client.post('callServer', {
      data
    })
  };
};

export const disableCallServer = (status) => {
  return (dispatch) => {
    dispatch({
      type: DISABLE_CALL_SERVER,
      payload: status
    });
  };
};
