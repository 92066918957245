export const GET_COMPANY_REQUESTED = 'sensorManagement/GET_COMPANY_REQUESTED';
export const GET_COMPANY_SUCCESS = 'sensorManagement/GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'sensorManagement/GET_COMPANY_FAILURE';

export const GET_COMPANY_BY_ID_REQUESTED = 'sensorManagement/GET_COMPANY_BY_ID_REQUESTED';
export const GET_COMPANY_BY_ID_SUCCESS = 'sensorManagement/GET_COMPANY_BY_ID_SUCCESS';
export const GET_COMPANY_BY_ID_FAILURE = 'sensorManagement/GET_COMPANY_BY_ID_FAILURE';

export const GET_BRANDS_REQUESTED = 'sensorManagement/GET_BRANDS_REQUESTED';
export const GET_BRANDS_SUCCESS = 'sensorManagement/GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILURE = 'sensorManagement/GET_BRANDS_FAILURE';

export const GET_STORES_REQUESTED = 'sensorManagement/GET_STORES_REQUESTED';
export const GET_STORES_SUCCESS = 'sensorManagement/GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'sensorManagement/GET_STORES_FAILURE';

export const GET_STATIONS_REQUESTED = 'sensorManagement/GET_STATIONS_REQUESTED';
export const GET_STATIONS_SUCCESS = 'sensorManagement/GET_STATIONS_SUCCESS';
export const GET_STATIONS_FAILURE = 'sensorManagement/GET_STATIONS_FAILURE';

export const REGISTER_SENSOR_REQUESTED = 'sensorManagement/REGISTER_SENSOR_REQUESTED';
export const REGISTER_SENSOR_SUCCESS = 'sensorManagement/REGISTER_SENSOR_SUCCESS';
export const REGISTER_SENSOR_FAILURE = 'sensorManagement/REGISTER_SENSOR_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  companyData: [],
  brands: [],
  stores: [],
  stationsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_COMPANY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        companyData: action.result
      };
    }
    case GET_COMPANY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_COMPANY_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_COMPANY_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        companyData: action.result
      };
    }
    case GET_COMPANY_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_BRANDS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRANDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        brands: action.result
      };
    }
    case GET_BRANDS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_STORES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        stores: action.result
      };
    }
    case GET_STORES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_STATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_STATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        stationsList: action.result
      };
    }
    case GET_STATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case REGISTER_SENSOR_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case REGISTER_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case REGISTER_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};

export const getCompaniesList = () => {
  return {
    types: [GET_COMPANY_REQUESTED, GET_COMPANY_SUCCESS, GET_COMPANY_FAILURE],
    promise: client => client.get('getCompaniesList')
  };
};

export const getCompanyById = (companyId) => {
  return {
    types: [GET_COMPANY_BY_ID_REQUESTED, GET_COMPANY_BY_ID_SUCCESS, GET_COMPANY_BY_ID_FAILURE],
    promise: client => client.get(`getCompanyById/${companyId}`),
  };
};
export const getBrandsByCompanyId = (companyId) => {
  return {
    types: [GET_BRANDS_REQUESTED, GET_BRANDS_SUCCESS, GET_BRANDS_FAILURE],
    promise: client => client.get(`getBrandsByCompanyId/${companyId}`),
  };
};

export const getStoresByBrandId = (brandId) => {
  return {
    types: [GET_STORES_REQUESTED, GET_STORES_SUCCESS, GET_STORES_FAILURE],
    promise: client => client.get(`getStoresByBrandId/${brandId}`),
  };
};

export const getStationsListByStoreId = (storeId) => {
  return {
    types: [GET_STATIONS_REQUESTED, GET_STATIONS_SUCCESS, GET_STATIONS_FAILURE],
    promise: client => client.get(`getStationsByStoreId/${storeId}`),
  };
};

export const registerSensor = (data) => {
  return {
    types: [REGISTER_SENSOR_REQUESTED, REGISTER_SENSOR_SUCCESS, REGISTER_SENSOR_FAILURE],
    promise: client => client.post('registerSensor', {
      data
    }),
  };
};
