export const RESTAURANT_TABLE_REQUESTED = 'restaurantHome/RESTAURANT_TABLE_REQUESTED';
export const RESTAURANT_TABLE_SUCCESS = 'restaurantHome/RESTAURANT_TABLE_SUCCESS';
export const RESTAURANT_TABLE_FAILURE = 'restaurantHome/RESTAURANT_TABLE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESTAURANT_TABLE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case RESTAURANT_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case RESTAURANT_TABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const getTableData = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [RESTAURANT_TABLE_REQUESTED, RESTAURANT_TABLE_SUCCESS, RESTAURANT_TABLE_FAILURE],
    promise: client => client.post('getTable', {
      data,
      headers,
    }),
  };
};
