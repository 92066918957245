import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { verifyotp, verifyOtpPhone } from '@reducers/otp';
import ResetPasswordForm from '@components/ResetPasswordForm';
import { setNotification } from '@reducers/notification';
import { APP_CONFIG, SUCCESS_MESSAGE } from '@constants';
import { CUSTOM_MESSAGES } from '@veriables';
/*
**** State Description ****
* initialData: for setting default values of form
* email: email of user
*/
const ResetPassword = ({
  initialValues,
  isEmailReadOnly,
  onClickNavigation,
  loading,
  goBack,
  ...props
}) => {
  const validationSchema = () => (
    Yup.object().shape({
      // emailAddress: Yup.string().required('email is required').email('invalid email'),
      code: Yup.string().required(CUSTOM_MESSAGES().RESET_PASSWORD_SCREEN_REQ_CODE),
      password: Yup.string()
        .required(CUSTOM_MESSAGES().RESET_PASSWORD_SCREEN_REQ_NEW_PASSWORD)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
          CUSTOM_MESSAGES().RESET_PASSWORD_SCREEN_INVALID_PASSWORD_REFER
        ),
      confirmPassword: Yup.string().required(CUSTOM_MESSAGES().RESET_PASSWORD_SCREEN_REQ_CONFIRM_PASSWORD).oneOf([Yup.ref(CUSTOM_MESSAGES().RESET_PASSWORD_SCREEN_PASSWORD), null], CUSTOM_MESSAGES().RESET_PASSWORD_SCREEN_PASSWORD_CONFIRMPASSWORD_SAME)
    })
  );

  const onResetPasswordSuccess = () => {
    goBack();
    props.setNotification({
      type: 'success',
      message: SUCCESS_MESSAGE.LOGIN_PASSWORD
    });
    props.history.replace(`${APP_CONFIG.BASE_URL}/login`);
  };

  const onResetPasswordFailure = (err) => {
    props.setNotification({
      type: 'danger',
      message: err.message
    });
  };

  const resetPassword = (data) => {
    if (data && data.emailAddress) {
      props.verifyotp(data).then(onResetPasswordSuccess).catch(onResetPasswordFailure);
    } else {
      props.verifyOtpPhone(data).then(onResetPasswordSuccess).catch(onResetPasswordFailure);
    }
  };

  return (
    <div>
      <Formik
        render={formProps => <ResetPasswordForm props={formProps} onSubmit={resetPassword} onClickNavigation={onClickNavigation} isEmailReadOnly={isEmailReadOnly} loading={loading} />}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={resetPassword}
      />
    </div>
  );
};
const mapStateToProps = ({ otp }) => ({
  loading: otp.loading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  verifyotp,
  setNotification,
  verifyOtpPhone
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
