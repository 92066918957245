/* eslint-disable prefer-arrow-callback */
import { arrayOperations, utills } from '@utility';
import { getObjectValue, getImageUrl } from '@utility/utills';
import moment from 'moment';
import _ from 'lodash';

const GET_MENU_REQUESTED = 'orders/GET_MENU_REQUESTED';
const GET_MENU_SUCCESS = 'orders/GET_MENU_SUCCESS';
const GET_MENU_FAILURE = 'orders/GET_MENU_FAILURE';
const GET_MENU_FROM_SESSION = 'orders/GET_MENU_FROM_SESSION';

const GET_MULTI_STORE_MENU_REQUESTED = 'orders/GET_MULTI_STORE_MENU_REQUESTED';
const GET_MULTI_STORE_MENU_SUCCESS = 'orders/GET_MULTI_STORE_MENU_SUCCESS';
const GET_MULTI_STORE_MENU_FAILURE = 'orders/GET_MULTI_STORE_MENU_FAILURE';
const GET_MULTI_STORE_MENU_FROM_SESSION = 'orders/GET_MULTI_STORE_MENU_FROM_SESSION';

const GET_MENU_ITEMS_BY_SCREEN_REQUEST = 'orders/GET_MENU_ITEMS_BY_SCREEN_REQUEST';
const GET_MENU_ITEMS_BY_SCREEN_SUCCESS = 'orders/GET_MENU_ITEMS_BY_SCREEN_SUCCESS';

const PUSH_MODIFIERS_LEVEL_DATA_REQUEST = 'orders/PUSH_MODIFIERS_LEVEL_DATA_REQUEST';
const PUSH_MODIFIERS_LEVEL_DATA_SUCCESS = 'orders/PUSH_MODIFIERS_LEVEL_DATA_SUCCESS';

const ADD_TO_CART_REQUESTED = 'orders/ADD_TO_CART_REQUESTED';
const ADD_TO_CART_SUCCESS = 'orders/ADD_TO_CART_SUCCESS';

const CLEAR_CART_REQUESTED = 'orders/CLEAR_CART_REQUESTED';
const CLEAR_CART_SUCCESS = 'orders/CLEAR_CART_SUCCESS';

const SEARCH_ITEM_REQUEST = 'orders/SEARCH_ITEM_REQUEST';
const SEARCH_ITEM_SUCCESS = 'orders/SEARCH_ITEM_SUCCESS';

const EDIT_CART_ITEM_REQUESTED = 'orders/EDIT_CART_ITEM_REQUESTED';
const EDIT_CART_ITEM_SUCCESS = 'orders/EDIT_CART_ITEM_SUCCESS';

const REMOVE_UPSELL_ITEMS_REQUESTED = 'orders/REMOVE_UPSELL_ITEMS_REQUESTED';
const REMOVE_UPSELL_ITEMS_SUCCESS = 'orders/REMOVE_UPSELL_ITEMS_SUCCESS';

const PUSH_CART_ITEMS_REQUESTED = 'orders/PUSH_CART_ITEMS_REQUESTED';
const PUSH_CART_ITEMS_SUCCESS = 'orders/PUSH_CART_ITEMS_SUCCESS';


const PUSH_MODIFIER_REQUESTED = 'orders/PUSH_MODIFIER_REQUESTED';
const PUSH_MODIFIER_SUCCESS = 'orders/PUSH_MODIFIER_SUCCESS';
const PUSH_GROUP_MODIFIER_SUCCESS = 'orders/PUSH_GROUP_MODIFIER_SUCCESS';

const APPEND_MODIFIER_SUCCESS = 'orders/APPEND_MODIFIER_SUCCESS';

const UPADTE_MODIFIER_PROMISE_SUCCESS = 'orders/UPADTE_MODIFIER_PROMISE_SUCCESS';

const UPADTE_GROUP_MODIFIER_PROMISE_SUCCESS = 'orders/UPADTE_GROUP_MODIFIER_PROMISE_SUCCESS';

const PUSH_MODIFIER_COUNT_REQUESTED = 'orders/PUSH_MODIFIER_COUNT_REQUESTED';
const PUSH_MODIFIER_COUNT_SUCCESS = 'orders/PUSH_MODIFIER_COUNT_SUCCESS';

const PUSH_NESTED_MODIFIER_REQUESTED = 'orders/PUSH_NESTED_MODIFIER_REQUESTED';
const PUSH_NESTED_MODIFIER_SUCCESS = 'orders/PUSH_NESTED_MODIFIER_SUCCESS';

const PUSH_CART_ITEMS_INFO_REQUEST = 'orders/PUSH_CART_ITEMS_INFO_REQUEST';
const PUSH_CART_ITEMS_INFO_SUCCESS = 'orders/PUSH_CART_ITEMS_INFO_SUCCESS';

const CHANGE_ITEM_QUANTITY_REQUEST = 'orders/CHANGE_ITEM_QUANTITY_REQUEST';
const CHANGE_ITEM_QUANTITY_SUCCESS = 'orders/CHANGE_ITEM_QUANTITY_SUCCESS';

const REMOVE_ITEM_FROM_CART_REQUEST = 'orders/REMOVE_ITEM_FROM_CART_REQUEST';
const REMOVE_ITEM_FROM_CART_SUCCESS = 'orders/REMOVE_ITEM_FROM_CART_SUCCESS';

const TOGGLE_NESTED_MODIFIER_FLAG_REQUEST = 'orders/TOGGLE_NESTED_MODIFIER_FLAG_REQUEST';
const TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS = 'orders/TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS';

const PLACE_ORDER_REQUESTED = 'orders/PLACE_ORDER_REQUESTED';
const PLACE_ORDER_SUCCESS = 'orders/PLACE_ORDER_SUCCESS';
const PLACE_ORDER_FAILURE = 'orders/PLACE_ORDER_FAILURE';

const GET_STORE_PICKUPTIME_REQUESTED = 'orders/GET_STORE_PICKUPTIME_REQUESTED';
const GET_STORE_PICKUPTIME_SUCCESS = 'orders/GET_STORE_PICKUPTIME_SUCCESS';
const GET_STORE_PICKUPTIME_FAILURE = 'orders/GET_STORE_PICKUPTIME_FAILURE';

const GET_STORE_PICKUPTIMEBYDATE_REQUESTED = 'orders/GET_STORE_PICKUPTIMEBYDATE_REQUESTED';
const GET_STORE_PICKUPTIMEBYDATE_SUCCESS = 'orders/GET_STORE_PICKUPTIMEBYDATE_SUCCESS';
const GET_STORE_PICKUPTIMEBYDATE_FAILURE = 'orders/GET_STORE_PICKUPTIMEBYDATE_FAILURE';

const GET_STORE_DELIVERYTIME_REQUESTED = 'orders/GET_STORE_DELIVERYTIME_REQUESTED';
const GET_STORE_DELIVERYTIME_SUCCESS = 'orders/GET_STORE_DELIVERYTIME_SUCCESS';
const GET_STORE_DELIVERYTIME_FAILURE = 'orders/GET_STORE_DELIVERYTIME_FAILURE';

const GET_STORE_DELIVERYTIMEBYDATE_REQUESTED = 'orders/GET_STORE_DELIVERYTIMEBYDATE_REQUESTED';
const GET_STORE_DELIVERYTIMEBYDATE_SUCCESS = 'orders/GET_STORE_DELIVERYTIMEBYDATE_SUCCESS';
const GET_STORE_DELIVERYTIMEBYDATE_FAILURE = 'orders/GET_STORE_DELIVERYTIMEBYDATE_FAILURE';

const GET_STORE_PICKUPDELIVERYTIME_REQUESTED = 'orders/GET_STORE_PICKUPDELIVERYTIME_REQUESTED';
const GET_STORE_PICKUPDELIVERYTIME_SUCCESS = 'orders/GET_STORE_PICKUPDELIVERYTIME_SUCCESS';
const GET_STORE_PICKUPDELIVERYTIME_FAILURE = 'orders/GET_STORE_PICKUPDELIVERYTIME_FAILURE';

const UPSELL_REQUESTED = 'orders/UPSELL_REQUESTED';
const UPSELL_SUCCESS = 'orders/UPSELL_SUCCESS';
const UPSELL_FAILURE = 'orders/UPSELL_FAILURE';

const KDS_SIMULATE_REQUESTED = 'orders/KDS_SIMULATE_REQUESTED';
const KDS_SIMULATE_SUCCESS = 'orders/KDS_SIMULATE_SUCCESS';
const KDS_SIMULATE_FAILURE = 'orders/KDS_SIMULATE_FAILURE';

const CHANGE_MENU_REQUEST = 'orders/CHANGE_MENU_REQUEST';
const CHANGE_MENU_SUCCESS = 'orders/CHANGE_MENU_SUCCESS';

const CLEAR_DELIVERY_TIME = 'CLEAR_DELIVERY_TIME';

const CLEAR_MENUS_REQUESTED = 'orders/CLEAR_MENUS_REQUESTED';
const CLEAR_MENUS_SUCCESS = 'orders/CLEAR_MENUS_SUCCESS';

const CLEAR_ORDER_SCREEN_SUCCESS = 'orders/CLEAR_ORDER_SCREEN_SUCCESS';

const REPLACE_CART_SUCCESS = 'orders/REPLACE_CART_SUCCESS';
const REPLACE_CART_REQUEST = 'orders/REPLACE_CART_REQUEST';

const UPADTE_MULTI_CART_REQUEST = 'orders/UPADTE_MULTI_CART_REQUEST';
const UPADTE_MULTI_CART_SUCCESS = 'orders/UPADTE_MULTI_CART_SUCCESS';

const REMOVE_MODIFIERS_DATA_REQUEST = 'orders/REMOVE_MODIFIERS_DATA_REQUEST';

const SET_MENU_INDEX = 'orders/SET_MENU_INDEX';

const GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED = 'orders/GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED';
const GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS = 'orders/GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS';
const GET_STORE_PICKUPTIME_DATE_BASED_FAILURE = 'orders/GET_STORE_PICKUPTIME_DATE_BASED_FAILURE';
const GET_OTHER_ORDERS_REQUEST = 'orders/GET_OTHER_ORDERS_REQUEST';
const GET_OTHER_ORDERS_SUCCESS = 'orders/GET_OTHER_ORDERS_SUCCESS';
const GET_OTHER_ORDERS_FAILURE = 'orders/GET_OTHER_ORDERS_FAILURE';

const ORDER_ITEM_REQUESTED = 'orders/ORDER_ITEM_REQUESTED';
const ORDER_ITEM_SUCCESS = 'orders/ORDER_ITEM_SUCCESS';
const ORDER_ITEM_FAILURE = 'orders/ORDER_ITEM_FAILURE';

const UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST = 'orders/UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST';
const UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS = 'orders/UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS';

const ADD_TO_FAVOURITE_REQUESTED = 'orders/ADD_TO_FAVOURITE_REQUESTED';
const ADD_TO_FAVOURITE_SUCCESS = 'orders/ADD_TO_FAVOURITE_SUCCESS';
const ADD_TO_FAVOURITE_FAILURE = 'orders/ADD_TO_FAVOURITE_FAILURE';

const REMOVE_FROM_FAVOURITE_REQUESTED = 'orders/REMOVE_FROM_FAVOURITE_REQUESTED';
const REMOVE_FROM_FAVOURITE_SUCCESS = 'orders/REMOVE_FROM_FAVOURITE_SUCCESS';
const REMOVE_FROM_FAVOURITE_FAILURE = 'orders/REMOVE_FROM_FAVOURITE_FAILURE';

const GET_PICKUP_DELIVERY_TIMES_REQUEST = 'orders/GET_PICKUP_DELIVERY_TIMES_REQUEST';
const GET_PICKUP_DELIVERY_TIMES_SUCCESS = 'orders/GET_PICKUP_DELIVERY_TIMES_SUCCESS';
const GET_PICKUP_DELIVERY_TIMES_FAILURE = 'orders/GET_PICKUP_DELIVERY_TIMES_FAILURE';

const GET_VOUCHER_PICKUP_DELIVERY_TIMES_REQUEST = 'orders/GET_VOUCHER_PICKUP_DELIVERY_TIMES_REQUEST';
const GET_VOUCHER_PICKUP_DELIVERY_TIMES_SUCCESS = 'orders/GET_VOUCHER_PICKUP_DELIVERY_TIMES_SUCCESS';
const GET_VOUCHER_PICKUP_DELIVERY_TIMES_FAILURE = 'orders/GET_VOUCHER_PICKUP_DELIVERY_TIMES_FAILURE';

export const VOUCHER_SMS_REQUESTED = 'orders/VOUCHER_SMS_REQUESTED';
export const VOUCHER_SMS_SUCCESS = 'orders/VOUCHER_SMS_SUCCESS';
export const VOUCHER_SMS_FAILURE = 'orders/VOUCHER_SMS_FAILURE';

const PUSH_MENU_SCREEN_DISCLAIMER_IDS_REQUEST = 'orders/PUSH_MENU_SCREEN_DISCLAIMER_IDS_REQUEST';
const PUSH_MENU_SCREEN_DISCLAIMER_IDS_SUCCESS = 'orders/PUSH_MENU_SCREEN_DISCLAIMER_IDS_SUCCESS';

const CONTINUOUS_SEARCH_ITEM_REQUEST = 'orders/CONTINUOUS_SEARCH_ITEM_REQUEST';
const CONTINUOUS_SEARCH_ITEM_SUCCESS = 'orders/CONTINUOUS_SEARCH_ITEM_SUCCESS';

const getModifierData = (stateData, payload) => {
  let dataToSend = [];
  if (Array.isArray(payload)) {
    dataToSend = payload;
  } else if (!stateData.includes(payload)) {
    dataToSend = [...stateData, payload];
  } else {
    dataToSend = stateData.filter(e => e !== payload);
  }
  return dataToSend;
};

const addOrRemoveModifierData = (stateData, payload, isInc) => {
  let dataToSend = [];
  if (isInc) {
    dataToSend = [...stateData, payload];
  } else {
    const index = _.findIndex(stateData, path => path === payload);
    if (index !== -1) {
      let count = 0;
      stateData.forEach((element) => {
        if (element === payload) count += 1;
      });
      if (count > 1) {
        dataToSend = [...stateData];
        _.pullAt(dataToSend, index);
      } else {
        stateData.forEach((data) => {
          if (!data.includes(payload)) dataToSend.push(data);
        });
      }
    }
  }
  return dataToSend;
};

const renderOptions = (options) => {
  const arrayData = _.sortBy(_.chain(options).groupBy('groupName').map((value, key) => ({ groupName: key, items: value, index: _.minBy(value, o => o.index).index })).value(), d => d.index);
  return arrayData.reduce((res, item) => {
    if (item.groupName !== '') {
      res.push({
        groupName: item.groupName
      });
    }
    return res;
  }, []);
};

const renderMenuItems = (result, menuIndex) => {
  let nextState = {};
  try {
    // favouriteScreen changes
    const favouriteMenuItems = _.filter(Object.values(result.menuItems), d => d.favorite).reduce((a, v) => { a.push(v.id); return a; }, []);
    const favouriteScreens = [];
    result.menus.forEach((menu) => {
      menu.screens.forEach((screen) => {
        favouriteScreens.push(..._.filter(screen.menuItemLinks, d => favouriteMenuItems.includes(d.menuItemId)));
      });
    });
    nextState.favouriteScreen = result.menus[menuIndex].screens.length > 0 ? {
      ...result.menus[menuIndex].screens[0],
      id: 0,
      name: 'My favorites',
      menuItemLinks: favouriteScreens
    } : null;
    if (favouriteScreens.length > 0) {
      // result.menus.forEach((menuObj) => {
      //   menuObj.screens.unshift(nextState.favouriteScreen);
      // });
    }
    // favouriteScreen changes ends
    nextState = {
      ...result,
      response: undefined,
      // menus: _.sortBy(result.menus[state.menuIndex].screens, 'sortOrder'),
      menus: result.menus[menuIndex].screens,
      // menusList: result.menus,
      menusList: result.menus.map(menu => ({ ...menu, screens: menu.screens })),
      menuOptions: result.menus.map((menu, i) => ({ label: menu.name, value: i })),
      menusHashTable: arrayOperations.listToHashTable(result.menus[menuIndex].screens),
      menuItemsHashTable: arrayOperations.listToHashTable(result.menuItems),
      modifiersHashTable: arrayOperations.listToHashTable(result.modifiers),
      modifierGroupsHashTable: arrayOperations.listToHashTable(result.modifierGroups),
      suppressUpSell: result && result.suppressUpSell,
    };
    Object.assign(nextState.modifiersHashTable, arrayOperations.listToHashTable(result.menuItems));
    const menuCard = {};
    nextState.menus.forEach((menus) => {
      const returnObj = {};
      menus.menuItemLinks.forEach((item, indexMenu) => {
        returnObj[item.menuItemId] = {
          ...item,
          menuItem: nextState.menuItemsHashTable[item.menuItemId],
          menuTypeId: menus.id,
          index: indexMenu
        };
      });
      menuCard[menus.id] = returnObj;
    });
    nextState.menuCard = menuCard;
    // menu cardList
    const menuCardList = {};
    nextState.menusList.forEach((menuListItem) => {
      menuListItem.screens.forEach((menus) => {
        const returnObj = {};
        menus.menuItemLinks.forEach((item, indexMenu) => {
          returnObj[item.menuItemId] = {
            ...item,
            menuItem: nextState.menuItemsHashTable[item.menuItemId],
            menuTypeId: menus.id,
            index: indexMenu
          };
        });
        menuCardList[menus.id] = returnObj;
      });
    });
    nextState.menuCardList = menuCardList;
    const menusListHashTableTemp = {};
    // menusListHash
    nextState.menusList.forEach((menus) => {
      Object.assign(menusListHashTableTemp, arrayOperations.listToHashTable(menus.screens));
    });
    nextState.menusHashTable = menusListHashTableTemp;
    // Group Names
    nextState.groupNames = _.orderBy(renderOptions(arrayOperations.converHashTableToArray(nextState.menuCard[result.menus[menuIndex].screens[0].id]), ['index'], ['asc']));
    // menucardList
    const screenMenuItemsList = _.orderBy(arrayOperations.converHashTableToArray(nextState.menuCard[result.menus[menuIndex].screens[0].id]), ['index'], ['asc']);
    nextState.screenMenuItemsList = screenMenuItemsList;
    // nextState.groupNames = _.orderBy(renderOptions(arrayOperations.converHashTableToArray(nextState.menuCard[action.payload] || [])), ['index'], ['desc']),
    // ContinuousScrollByScreenItems
    const continuousScrollMenuItemList = nextState.menus.map((menus) => {
      return ({
        menuName: menus.name,
        menuItemsByScreen: menus.menuItemLinks.map((item, indexMenu) => {
          return ({
            ...item,
            menuItem: nextState.menuItemsHashTable[item.menuItemId],
            menuTypeId: menus.id,
            index: indexMenu
          });
        })
      });
    });
    nextState.continuousScrollMenuItemList = continuousScrollMenuItemList;
  } catch (e) {
    console.log(e);
  }
  return nextState;
};

const getInitialMenuData = () => {
  if (window.sessionStorage.getItem('menus')) {
    return { ...renderMenuItems(utills.decriptRespose(JSON.parse(window.sessionStorage.getItem('menus'))), 0) };
  }
  return {};
};

const getInitialMutiMenuData = (storeId) => {
  if (window.localStorage.getItem('catchedMenus') && JSON.parse(window.localStorage.getItem('catchedMenus'))[storeId]) {
    return { ...renderMenuItems(utills.decriptRespose(JSON.parse(window.localStorage.getItem('catchedMenus'))[storeId]), 0) };
  }
  return {};
};

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
  modifierLoder: false,
  addToCartLoader: false,
  cart: JSON.parse(window.sessionStorage.getItem('cart')) || [],
  cartItemsInfo: _.groupBy(JSON.parse(window.sessionStorage.getItem('cart')) || [], d => d.menuItemId) || {},
  menus: [],
  menuItems: [],
  modifiers: [],
  modifierGroups: [],
  menusHashTable: {},
  menuItemsHashTable: {},
  modifiersHashTable: {},
  modifierGroupsHashTable: {},
  menuCard: {},
  menuCardList: {},
  screenMenuItemsList: [],
  modifierLevelData: [],
  menusList: [],
  menuOptions: [],
  menuIndex: 0,
  ...getInitialMenuData(),
  // ...JSON.parse(window.sessionStorage.getItem('menus')),
  modifierData: [],
  isNestedModifier: false,
  pickUpTime: [],
  pickUpDeliveryTime: [],
  multiCartInfo: JSON.parse(window.localStorage.getItem('multiCartInfo')) || [],
  pickupTimeBasedOnDate: [],
  deliveryTime: [],
  upSellItems: JSON.parse(window.sessionStorage.getItem('upSell')) || [],
  multiStoreUpSell: JSON.parse(window.sessionStorage.getItem('multiStoreUpSell')) || [],
  placeOrderLoading: false,
  collapsedModifierArray: [],
  favouriteItems: JSON.parse(window.sessionStorage.getItem('favouriteItems')) || [],
  addToFavouriteLoader: false,
  isGetMenuLoaded: false,
  pickupDeliveryTimes: [],
  voucherPickupDeliveryTimes: [],
  voucherPickupDates: [],
  isUpsellComplete: false,
  groupNames: [],
  disclaimerPopUpMenuIds: JSON.parse(window.sessionStorage.getItem('popUpIds')) || [],
  suppressUpSell: false,
  isSearching: false,
  continuousScrollMenuItemList: []
};

const clearData = {
  loading: false,
  loaded: true,
  error: false,
  modifierLoder: false,
  cart: [],
  cartItemsInfo: {},
  menus: [],
  menuItems: [],
  modifiers: [],
  modifierGroups: [],
  menusHashTable: {},
  menuItemsHashTable: {},
  modifiersHashTable: {},
  modifierGroupsHashTable: {},
  menuCard: {},
  menuCardList: {},
  screenMenuItemsList: [],
  modifierLevelData: [],
  menusList: [],
  menuOptions: [],
  menuIndex: 0,
  modifierData: [],
  isNestedModifier: false,
  pickUpTime: [],
  pickupTimeBasedOnDate: [],
  // multiCartInfo: []
  otherOrdersList: [],
  deliveryTime: [],
  placeOrderLoading: false,
  collapsedModifierArray: [],
  favouriteItems: [],
  addToFavouriteLoader: false,
  // pickupDeliveryTimes: [],
  voucherPickupDeliveryTimes: [],
  voucherPickupDates: [],
  isUpsellComplete: false,
  groupNames: [],
  disclaimerPopUpMenuIds: [],
  suppressUpSell: false,
};

let nextState = {};
let screenMenuItemsList = [];
let continuousScrollMenuItemList = [];
let searchContinuousScrollMenuList = [];
let menuCard = {};
// let menuCardList = {};
let index = -1;
let cartItemsTemp = [];
let searchItemsList = [];
let upSellItems = [];
let catchedMenus = {};
let cartTempArray = [];
let cartItemIndex = -1;
const popUPIds = [];
// let menusListHashTableTemp = {};

export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_MENU_REQUESTED:
      return {
        ...state,
        cart: [],
        menuLoading: true,
        loading: true,
        loaded: false
      };
    case GET_MENU_SUCCESS:
      // nextState = {
      //   ...action.result,
      //   compressedString: undefined,
      //   // menus: _.sortBy(action.result.menus[state.menuIndex].screens, 'sortOrder'),
      //   menus: action.result.menus[state.menuIndex].screens,
      //   menusList: action.result.menus,
      //   menuOptions: action.result.menus.map((menu, i) => ({ label: menu.name, value: i })),
      //   menusHashTable: arrayOperations.listToHashTable(action.result.menus[state.menuIndex].screens),
      //   menuItemsHashTable: arrayOperations.listToHashTable(action.result.menuItems),
      //   modifiersHashTable: arrayOperations.listToHashTable(action.result.modifiers),
      //   modifierGroupsHashTable: arrayOperations.listToHashTable(action.result.modifierGroups),
      // };
      // menuCard = {};
      // nextState.menus.forEach((menus) => {
      //   const returnObj = {};
      //   menus.menuItemLinks.forEach((item, indexMenu) => {
      //     returnObj[item.menuItemId] = {
      //       ...item,
      //       menuItem: nextState.menuItemsHashTable[item.menuItemId],
      //       menuTypeId: menus.id,
      //       index: indexMenu
      //     };
      //   });
      //   menuCard[menus.id] = returnObj;
      // });
      // nextState.menuCard = menuCard;
      // // menu cardList
      // menuCardList = {};
      // nextState.menusList.forEach((menuListItem) => {
      //   menuListItem.screens.forEach((menus) => {
      //     const returnObj = {};
      //     menus.menuItemLinks.forEach((item, indexMenu) => {
      //       returnObj[item.menuItemId] = {
      //         ...item,
      //         menuItem: nextState.menuItemsHashTable[item.menuItemId],
      //         menuTypeId: menus.id,
      //         index: indexMenu
      //       };
      //     });
      //     menuCardList[menus.id] = returnObj;
      //   });
      // });
      // nextState.menuCardList = menuCardList;

      // menusListHashTableTemp = {};
      // // menusListHash
      // nextState.menusList.forEach((menus) => {
      //   Object.assign(menusListHashTableTemp, arrayOperations.listToHashTable(menus.screens));
      // });
      // nextState.menusHashTable = menusListHashTableTemp;
      // // menucardList
      // screenMenuItemsList = arrayOperations.converHashTableToArray(nextState.menuCard[action.result.menus[state.menuIndex].screens[0].id]);
      // nextState.screenMenuItemsList = screenMenuItemsList;
      nextState = renderMenuItems(action.result, state.menuIndex);
      window.sessionStorage.setItem('menus', JSON.stringify(action.result.response.compressedBytes));
      window.sessionStorage.setItem('dissmissableFooter', JSON.stringify([]));
      return {
        ...state,
        menuLoading: false,
        loading: false,
        screenMenuItemsList,
        isGetMenuLoaded: true,
        ...nextState
      };
    case GET_MENU_FROM_SESSION:
      return {
        ...state,
        ...getInitialMenuData(),
        // ...JSON.parse(window.sessionStorage.getItem('menus'))
      };
    case GET_MENU_FAILURE:
      return {
        ...state,
        menuLoading: false,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_REQUESTED:
      return {
        ...state,
        menuLoading: true,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_SUCCESS:
      catchedMenus = JSON.parse(window.localStorage.getItem('catchedMenus')) || {};
      catchedMenus[action.payload.storeId] = action.result.response.compressedBytes;
      window.localStorage.setItem('catchedMenus', JSON.stringify(catchedMenus));
      return {
        ...state,
        menuLoading: false,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_FAILURE:
      return {
        ...state,
        menuLoading: false,
        loading: false,
      };
    case GET_MULTI_STORE_MENU_FROM_SESSION:
      nextState = getInitialMutiMenuData(action.payload.storeId);
      action.resolve();
      return {
        ...state,
        ...nextState,
      };
    case CHANGE_MENU_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CHANGE_MENU_SUCCESS:
      nextState = {
        // menus: _.sortBy(state.menusList[action.payload].screens, 'sortOrder'),
        menus: state.menusList[action.payload.menuIndex].screens
        // menusHashTable: arrayOperations.listToHashTable(state.menusList[action.payload].screens),
        // menuItemsHashTable: state.menuItemsHashTable,
      };
      menuCard = {};
      nextState.menus.forEach((menus) => {
        const returnObj = {};
        menus.menuItemLinks.forEach((item, indexMenu) => {
          returnObj[item.menuItemId] = {
            ...item,
            menuItem: state.menuItemsHashTable[item.menuItemId],
            menuTypeId: menus.id,
            index: indexMenu
          };
        });
        menuCard[menus.id] = returnObj;
      });
      nextState.menuCard = menuCard;
      // screenMenuItemsList = _.orderBy(arrayOperations.converHashTableToArray(nextState.menuCard[state.menusList[action.payload].screens[0].id]), ['index'], ['asc']);
      screenMenuItemsList = state.menusList[action.payload.menuIndex].screens.length > 0 && _.orderBy(arrayOperations.converHashTableToArray(nextState.menuCard[state.menusList[action.payload.menuIndex].screens[action.payload.menuItemIndex || 0].id]), ['index'], ['asc']);
      nextState.screenMenuItemsList = screenMenuItemsList;
      // window.sessionStorage.setItem('menus', JSON.stringify(nextState));
      // console.log('screenMenuItemsList', screenMenuItemsList);
      // ContinousScrollMenu
      continuousScrollMenuItemList = nextState.menus.map((menus) => {
        return ({
          menuName: menus.name,
          menuItemsByScreen: menus.menuItemLinks.map((item, indexMenu) => {
            return ({
              ...item,
              menuItem: state.menuItemsHashTable[item.menuItemId],
              menuTypeId: menus.id,
              index: indexMenu
            });
          })
        });
      });
      return {
        ...state,
        loading: false,
        screenMenuItemsList,
        continuousScrollMenuItemList,
        menuIndex: action.payload.menuIndex,
        ...nextState
      };
    case GET_MENU_ITEMS_BY_SCREEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_MENU_ITEMS_BY_SCREEN_SUCCESS:
      // state.menus.forEach((menus) => {
      //   const returnObj = {};
      //   menus.menuItemLinks.forEach((item) => {
      //     returnObj[item.menuItemId] = {
      //       ...item,
      //       menuItem: state.menuItemsHashTable[item.menuItemId],
      //       menuTypeId: menus.id
      //     };
      //   });
      //   menuCard[menus.id] = returnObj;
      // });
      // nextState.menuCard = menuCard;
      continuousScrollMenuItemList = state.menus.map((menus) => {
        return ({
          menuName: menus.name,
          menuItemsByScreen: menus.menuItemLinks.map((item, indexMenu) => {
            return ({
              ...item,
              menuItem: state.menuItemsHashTable[item.menuItemId],
              menuTypeId: menus.id,
              index: indexMenu
            });
          })
        });
      });
      return {
        ...state,
        isSearching: false,
        screenMenuItemsList: _.orderBy(arrayOperations.converHashTableToArray(state.menuCardList[action.payload] || []), ['index'], ['asc']),
        continuousScrollMenuItemList,
        groupNames: _.orderBy(renderOptions(arrayOperations.converHashTableToArray(state.menuCardList[action.payload] || [])), ['index'], ['desc']),
        loading: false
      };
    case PUSH_MENU_SCREEN_DISCLAIMER_IDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PUSH_MENU_SCREEN_DISCLAIMER_IDS_SUCCESS:
      popUPIds.push(action.payload);
      window.sessionStorage.setItem('popUpIds', JSON.stringify(popUPIds));
      return {
        ...state,
        disclaimerPopUpMenuIds: popUPIds,
        loading: false
      };
    case SEARCH_ITEM_REQUEST:
      return {
        ...state,
        screenMenuItemsList: [],
        continuousScrollMenuItemList: [],
        loading: true
      };
    case SEARCH_ITEM_SUCCESS:
      searchItemsList = [];
      arrayOperations.converHashTableToArray(state.menuCardList).forEach((menuItemList) => {
        arrayOperations.converHashTableToArray(menuItemList).forEach((item) => {
          const linkedItems = item.menuItem && item.menuItem.links && item.menuItem.links.length > 0 ? _.filter(item.menuItem.links, link => link.name.toLowerCase().includes(action.payload.toLowerCase())) : [];
          if (linkedItems && linkedItems.length > 0) {
            linkedItems.forEach((linkedItem) => {
              arrayOperations.converHashTableToArray(state.menuCardList).forEach((linkedMenuItemList) => {
                arrayOperations.converHashTableToArray(linkedMenuItemList).forEach((linkedMenuItem) => {
                  if (linkedItem.linkedMenuItemId === linkedMenuItem.menuItemId) {
                    searchItemsList.push(linkedMenuItem);
                  }
                });
              });
            });
          }
          if (getObjectValue(() => item.menuItem.name.toLowerCase().includes(action.payload.toLowerCase()) || (item.menuItem.description || '').toLowerCase().includes(action.payload.toLowerCase()) || _.filter(item.menuItem.tags, d => d.name.toLowerCase().includes(action.payload.toLowerCase()) || d.shortName.toLowerCase().includes(action.payload.toLowerCase())).length > 0, false)) {
            searchItemsList.push(item);
          }
        });
      });
      action.resolve();
      return {
        ...state,
        isSearching: true,
        screenMenuItemsList: _.uniqBy(searchItemsList, 'menuItemId') || [],
        loading: false
      };
    case CONTINUOUS_SEARCH_ITEM_REQUEST:
      return {
        ...state,
        continuousScrollMenuItemList: [],
        loading: true
      };
    case CONTINUOUS_SEARCH_ITEM_SUCCESS:
      searchContinuousScrollMenuList = [];
      continuousScrollMenuItemList = state.menus.map((menus) => {
        return ({
          id: menus.id,
          name: menus.name,
          menuItemsByScreen: menus.menuItemLinks.map((item, indexMenu) => {
            return ({
              ...item,
              menuItem: state.menuItemsHashTable[item.menuItemId],
              menuTypeId: menus.id,
              index: indexMenu
            });
          })
        });
      });
      arrayOperations.converHashTableToArray(continuousScrollMenuItemList).forEach((menu) => {
        const itemsSearch = [];
        arrayOperations.converHashTableToArray(menu.menuItemsByScreen).forEach((item) => {
          const linkedItems = item.menuItem && item.menuItem.links && item.menuItem.links.length > 0 ? _.filter(item.menuItem.links, link => link.name.toLowerCase().includes(action.payload.toLowerCase())) : [];
          if (linkedItems && linkedItems.length > 0) {
            linkedItems.forEach((linkedItem) => {
              arrayOperations.converHashTableToArray(state.menuCardList).forEach((linkedMenuItemList) => {
                arrayOperations.converHashTableToArray(linkedMenuItemList).forEach((linkedMenuItem) => {
                  if (linkedItem.linkedMenuItemId === linkedMenuItem.menuItemId) {
                    searchItemsList.push(linkedMenuItem);
                  }
                });
              });
            });
          }
          if (getObjectValue(() => item.menuItem.name.toLowerCase().includes(action.payload.toLowerCase()) || (item.menuItem.description || '').toLowerCase().includes(action.payload.toLowerCase()) || _.filter(item.menuItem.tags, d => d.name.toLowerCase().includes(action.payload.toLowerCase()) || d.shortName.toLowerCase().includes(action.payload.toLowerCase())).length > 0, false)) {
            itemsSearch.push(item);
          }
        });
        if (itemsSearch && itemsSearch.length > 0) {
          searchContinuousScrollMenuList.push({
            menuId: menu.id,
            menuName: menu.name,
            menuItemsByScreen: itemsSearch
          });
        }
      });
      action.resolve();
      return {
        ...state,
        isSearching: true,
        continuousScrollMenuItemList: _.uniqBy(searchContinuousScrollMenuList, 'menuId') || [],
        loading: false
      };
    case PUSH_MODIFIERS_LEVEL_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case PUSH_MODIFIERS_LEVEL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        modifierLevelData: action.payload,
        // modifierData: action.condition ? state.modifierData : [] // need to work
      };
    case REMOVE_MODIFIERS_DATA_REQUEST:
      return {
        ...state,
        loading: false,
        modifierData: _.filter(state.modifierData, d => !action.payload.includes(d)),
        // modifierData: action.condition ? state.modifierData : [] // need to work
      };
    case ADD_TO_CART_REQUESTED:
      return {
        ...state,
        addToCartLoader: true,
        // loading: true
      };
    case ADD_TO_CART_SUCCESS:
      window.sessionStorage.setItem('cart', JSON.stringify([...state.cart, action.payload]));
      action.resolve();
      return {
        ...state,
        cart: [...state.cart, action.payload],
        addToCartLoader: false,
        // loading: false
      };
    case UPADTE_MULTI_CART_REQUEST:
      return {
        ...state,
        // loading: true,
        modifierLoder: true,
        multiCartInfo: action.payload
      };
    case UPADTE_MULTI_CART_SUCCESS:
      window.localStorage.setItem('multiCartInfo', JSON.stringify(action.payload));
      action.resolve();
      return {
        ...state,
        modifierLoder: false,
        // loading: false,
        multiCartInfo: action.payload
      };
    case REPLACE_CART_REQUEST:
      return {
        ...state,
        loading: true,
        // loading: true
      };
    case REPLACE_CART_SUCCESS:
      window.sessionStorage.setItem('cart', JSON.stringify(action.payload));
      action.resolve();
      return {
        ...state,
        cart: action.payload,
        cartItemsInfo: _.groupBy(action.payload, d => d.menuItemId),
        loading: false,
        // addToCartLoader: false
      };
    case CLEAR_CART_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case CLEAR_CART_SUCCESS:
      window.sessionStorage.removeItem('cart');
      return {
        ...state,
        cart: [],
        loading: false
      };
    case CLEAR_ORDER_SCREEN_SUCCESS:
      window.sessionStorage.removeItem('cart');
      window.sessionStorage.removeItem('multiCartInfo');
      window.localStorage.removeItem('catchedMenus');
      action.resolve();
      return {
        ...state,
        cart: [],
        multiCartInfo: [],
        upSellItems: [],
        loading: false,
      };
    case CLEAR_MENUS_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case CLEAR_MENUS_SUCCESS:
      action.resolve();
      window.sessionStorage.removeItem('menus');
      window.sessionStorage.removeItem('menuTypeId');
      window.sessionStorage.removeItem('scrol');
      window.sessionStorage.removeItem('cart');
      window.sessionStorage.removeItem('upSell');
      return {
        ...state,
        ...clearData,
      };
    case EDIT_CART_ITEM_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case EDIT_CART_ITEM_SUCCESS:
      index = _.findIndex(state.cart, d => d.internalId === action.payload.internalId);
      cartItemsTemp = [...state.cart];
      cartItemsTemp[index] = action.payload;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartItemsTemp]));
      action.resolve(1);
      return {
        ...state,
        cart: cartItemsTemp,
        loading: false
      };
    case CHANGE_ITEM_QUANTITY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case CHANGE_ITEM_QUANTITY_SUCCESS:
      index = _.findIndex(state.cart, d => d.internalId === action.payload.internalId);
      cartItemsTemp = [...state.cart];
      cartItemsTemp[index].quantity = action.payload.quantity;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartItemsTemp]));
      return {
        ...state,
        cart: cartItemsTemp,
        // loading: false
      };
    case REMOVE_ITEM_FROM_CART_REQUEST:
      return {
        ...state,
        // loading: true
        addToCartLoader: true
      };
    case REMOVE_ITEM_FROM_CART_SUCCESS:
      cartItemsTemp = [...state.cart];
      if (action.payload[0] && action.payload[0].quantity > 0) {
        index = _.findIndex(state.cart, d => d.internalId === action.payload[0].internalId);
        if (action.payload[0].quantity === 1) {
          _.remove(cartItemsTemp, d => d.internalId === action.payload[0].internalId);
        } else {
          cartItemsTemp[index].quantity = action.payload[0].quantity - 1;
        }
      } else {
        _.remove(cartItemsTemp, d => d.internalId === action.payload);
      }
      window.sessionStorage.setItem('cart', JSON.stringify([...cartItemsTemp]));
      return {
        ...state,
        cart: cartItemsTemp,
        // loading: false
        addToCartLoader: false
      };
    case REMOVE_UPSELL_ITEMS_REQUESTED:
      return {
        ...state,
        loading: false
      };
    case REMOVE_UPSELL_ITEMS_SUCCESS:
      cartItemsTemp = _.filter([...state.cart], d => d.upSellStatus !== false);
      window.sessionStorage.setItem('cart', JSON.stringify(cartItemsTemp));
      action.resolve();
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, d => d.menuItemId),
        loading: false
      };
    case PUSH_CART_ITEMS_REQUESTED:
      return {
        ...state,
        loading: false
      };
    case PUSH_CART_ITEMS_SUCCESS:
      cartItemsTemp = [...state.cart, ...action.payload];
      window.sessionStorage.setItem('cart', JSON.stringify(cartItemsTemp));
      action.resolve();
      return {
        ...state,
        cart: cartItemsTemp,
        cartItemsInfo: _.groupBy(cartItemsTemp, d => d.menuItemId),
        loading: false
      };
    case PUSH_MODIFIER_REQUESTED:
      return {
        ...state,
        modifierLoder: true
      };
    case PUSH_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        // modifierData: action.payload.modifierId ? { ...state.modifierData, [action.payload.modifierId]: action.payload } : action.payload,
        modifierData: getModifierData(state.modifierData, action.payload)
      };
    case PUSH_GROUP_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        // modifierData: action.payload ? [..._.filter(state.modifierData, d => !d.includes(action.path)), action.payload] : _.filter(state.modifierData, d => !d.includes(action.path))
        // modifierData: action.payload.modifierId ? { ...state.modifierData, [action.payload.modifierId]: action.payload } : action.payload,
        modifierData: getModifierData(state.modifierData, action.payload)
      };
    case APPEND_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        // modifierData: action.payload.modifierId ? { ...state.modifierData, [action.payload.modifierId]: action.payload } : action.payload,
        modifierData: [...state.modifierData, ...action.payload]
      };
    case UPADTE_MODIFIER_PROMISE_SUCCESS:
      // console.log('action.payload', [..._.filter(state.modifierData, d => !d.startsWith(action.path)), action.payload]);
      action.resolve(1);
      return {
        ...state,
        modifierLoder: false,
        // modifierData: action.payload.modifierId ? { ...state.modifierData, [action.payload.modifierId]: action.payload } : action.payload,
        modifierData: action.payload ? [..._.filter(state.modifierData, d => !d.startsWith(action.path)), action.payload] : _.filter(state.modifierData, d => !d.startsWith(action.path))
      };
    case UPADTE_GROUP_MODIFIER_PROMISE_SUCCESS:
      action.resolve(1);
      return {
        ...state,
        modifierLoder: false,
        modifierData: action.payload ? [..._.filter(state.modifierData, d => !d.endsWith(action.path)), action.payload] : _.filter(state.modifierData, d => !d.endsWith(action.path))
      };
    case PUSH_MODIFIER_COUNT_REQUESTED:
      return {
        ...state,
        modifierLoder: true
      };
    case PUSH_MODIFIER_COUNT_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        // modifierData: action.payload.modifierId ? { ...state.modifierData, [action.payload.modifierId]: action.payload } : action.payload,
        modifierData: addOrRemoveModifierData(state.modifierData, action.payload, action.isInc)
      };
    case PUSH_NESTED_MODIFIER_REQUESTED:
      return {
        ...state,
        modifierLoder: true
      };
    case PUSH_NESTED_MODIFIER_SUCCESS:
      return {
        ...state,
        modifierLoder: false,
        modifierData: getModifierData(state.modifierData, action.payload)
      };
    case PUSH_CART_ITEMS_INFO_REQUEST:
      return {
        ...state,
        // cartItemsInfo: {}
      };
    case PUSH_CART_ITEMS_INFO_SUCCESS:
      return {
        ...state,
        cartItemsInfo: _.groupBy(state.cart, d => d.menuItemId)
      };
    case TOGGLE_NESTED_MODIFIER_FLAG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS:
      return {
        ...state,
        loading: false,
        isNestedModifier: action.payload
      };
    case PLACE_ORDER_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_PICKUPTIME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpTime: action.result.pickupTimes,
      };
    case GET_STORE_PICKUPTIME_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_PICKUPTIMEBYDATE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPTIMEBYDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpTime: action.result.pickupTimes,
      };
    case GET_STORE_PICKUPTIMEBYDATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_DELIVERYTIME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_DELIVERYTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryTime: action.result,
      };
    case GET_STORE_DELIVERYTIME_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_DELIVERYTIMEBYDATE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_DELIVERYTIMEBYDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryTime: action.result,
      };
    case GET_STORE_DELIVERYTIMEBYDATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_STORE_PICKUPDELIVERYTIME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPDELIVERYTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        pickUpDeliveryTime: action.result,
      };
    case GET_STORE_PICKUPDELIVERYTIME_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_MENU_INDEX:
      return {
        ...state,
        menuIndex: action.payload
      };
    case GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS:
      return {
        ...state,
        loading: false,
        pickupTimeBasedOnDate: action.result,
      };
    case GET_STORE_PICKUPTIME_DATE_BASED_FAILURE:
      return {
        ...state,
        loading: false
      };
    case GET_OTHER_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_OTHER_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        otherOrdersList: action.result,
      };
    }
    case GET_OTHER_ORDERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPSELL_REQUESTED: {
      return {
        ...state,
        isUpsellLoading: true
      };
    }
    case UPSELL_SUCCESS: {
      upSellItems = action.result.items.map(item => ({
        ...item,
        menuItemName: getObjectValue(() => state.menuItemsHashTable[item.menuItemId].name, ''),
        defaultMenuItemImageUrl: getObjectValue(() => getImageUrl(state.menuItemsHashTable[item.menuItemId], 'cart', window.screen.width, action.payload.defaultMenuItemImageUrl), action.payload.defaultMenuItemImageUrl),
        price: getObjectValue(() => _.find(state.menusHashTable[item.menuScreenId].menuItemLinks, d => d.menuItemId === item.menuItemId).price, 0),
      }));
      if (action.payload.consolidatePayment) {
        window.sessionStorage.setItem('multiStoreUpSell', JSON.stringify(_.uniqBy(upSellItems, 'menuItemId')));
      }
      window.sessionStorage.setItem('upSell', JSON.stringify(_.uniqBy(upSellItems, 'menuItemId')));
      return {
        ...state,
        upSellItems: _.uniqBy(upSellItems, 'menuItemId') || [],
        multiStoreUpSell: action.payload.consolidatePayment ? _.uniqBy(upSellItems, 'menuItemId') : [],
        isUpsellLoading: false
      };
    }
    case ORDER_ITEM_REQUESTED:
      return {
        ...state,
        loading: true,
        placeOrderLoading: true
      };
    case ORDER_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        placeOrderLoading: false
      };
    case ORDER_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        placeOrderLoading: false
      };
    case KDS_SIMULATE_REQUESTED:
      return {
        ...state,
        loading: true,
        placeOrderLoading: true
      };
    case KDS_SIMULATE_SUCCESS:
      return {
        ...state,
        loading: false,
        placeOrderLoading: false
      };
    case KDS_SIMULATE_FAILURE:
      return {
        ...state,
        loading: false,
        placeOrderLoading: false
      };
    case UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST:
      return {
        ...state,
      };
    case UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS:
      return {
        ...state,
        collapsedModifierArray: action.payload,
      };
    case ADD_TO_FAVOURITE_REQUESTED:
      return {
        ...state,
        addToFavouriteLoader: true
      };
    case ADD_TO_FAVOURITE_SUCCESS:
      cartTempArray = [...state.cart];
      cartItemIndex = _.findIndex(cartTempArray, d => d.menuItemId === action.payload.menuItemId);
      cartTempArray[cartItemIndex].favorite = action.result;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartTempArray]));
      return {
        ...state,
        cart: cartTempArray,
        addToFavouriteLoader: false
      };
    case ADD_TO_FAVOURITE_FAILURE:
      return {
        ...state,
        addToFavouriteLoader: false
      };
    case REMOVE_FROM_FAVOURITE_REQUESTED:
      return {
        ...state,
        addToFavouriteLoader: true
      };
    case REMOVE_FROM_FAVOURITE_SUCCESS:
      cartTempArray = [...state.cart];
      cartItemIndex = _.findIndex(cartTempArray, d => d.favorite !== null && d.favorite.id === action.payload);
      cartTempArray[cartItemIndex].favorite = null;
      window.sessionStorage.setItem('cart', JSON.stringify([...cartTempArray]));
      return {
        ...state,
        cart: cartTempArray,
        addToFavouriteLoader: false
      };
    case REMOVE_FROM_FAVOURITE_FAILURE:
      return {
        ...state,
        addToFavouriteLoader: false
      };
    case GET_PICKUP_DELIVERY_TIMES_REQUEST:
      return {
        ...state,
        pickUpTime: [],
        pickupDeliveryTimes: [],
        loading: true
      };
    case GET_PICKUP_DELIVERY_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        pickupDeliveryTimes: action.result.pickupTimes,
      };
    case GET_PICKUP_DELIVERY_TIMES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case CLEAR_DELIVERY_TIME:
      return {
        ...state,
        pickupDeliveryTimes: []
      };
    case GET_VOUCHER_PICKUP_DELIVERY_TIMES_REQUEST:
      return {
        ...state,
        pickupDeliveryTimes: [],
        loading: true
      };
    case GET_VOUCHER_PICKUP_DELIVERY_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        voucherPickupDates: action.result.voucherPickupDates || [],
        voucherPickupDeliveryTimes: action.result.voucherPickupTimes || [],
      };
    case GET_VOUCHER_PICKUP_DELIVERY_TIMES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case VOUCHER_SMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case VOUCHER_SMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case VOUCHER_SMS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const getMenu = (storeId, flowType) => {
  if (window.localStorage.getItem('catchedMenus') && JSON.parse(window.localStorage.getItem('catchedMenus'))[storeId]) {
    return (dispatch) => {
      return new Promise((resolve) => {
        dispatch({
          type: GET_MULTI_STORE_MENU_FROM_SESSION,
          payload: {
            storeId
          },
          resolve
        });
      });
    };
  }
  if (!window.sessionStorage.getItem('menus') || JSON.parse(window.sessionStorage.getItem('storeId')) !== storeId) {
    window.sessionStorage.removeItem('menuTypeId');
    window.sessionStorage.removeItem('scrol');
    window.sessionStorage.removeItem('cart');
    window.sessionStorage.setItem('storeId', storeId);
    const dataToSend = {};
    if (window.sessionStorage.getItem('pickupTime') && window.sessionStorage.getItem('pickupTime') !== 'null') {
      dataToSend.date = `${window.sessionStorage.getItem('pickupDate') ? getObjectValue(() => window.sessionStorage.getItem('pickupDate').split('T')[0], moment().format('YYYY-MM-DD')) : moment().format('YYYY-MM-DD')}T${window.sessionStorage.getItem('pickupTime')}`;
    }
    dataToSend.storeId = storeId;
    dataToSend.flowType = flowType;
    dataToSend.delivery = window.sessionStorage.getItem('isDeliveryOrder') ? JSON.parse(window.sessionStorage.getItem('isDeliveryOrder')) : null;
    dataToSend.sensorCode = getObjectValue(() => JSON.parse(window.localStorage.getItem('popInTokenRequest')).sensorCode, '');
    if (window.localStorage.getItem('isPreviewMode')) {
      dataToSend.preview = window.localStorage.getItem('isPreviewMode');
      dataToSend.menuId = window.sessionStorage.getItem('MenuId');
    }
    return {
      types: [GET_MENU_REQUESTED, GET_MENU_SUCCESS, GET_MENU_FAILURE],
      promise: client => client.post('getMenu', {
        data: dataToSend
      })
      // promise: client => client.get('getMenu/10000146')
    };
  }
  return dispatch => dispatch({
    type: GET_MENU_FROM_SESSION
  });
};

export const getMultiMenu = (storeId, flowType) => {
  const dataToSend = {};
  if (window.sessionStorage.getItem('pickupTime') && window.sessionStorage.getItem('pickupTime') !== 'null') {
    dataToSend.date = `${window.sessionStorage.getItem('pickupDate') ? getObjectValue(() => window.sessionStorage.getItem('pickupDate').split('T')[0], moment().format('YYYY-MM-DD')) : moment().format('YYYY-MM-DD')}T${window.sessionStorage.getItem('pickupTime')}`;
  }
  dataToSend.storeId = storeId;
  dataToSend.flowType = flowType;
  dataToSend.delivery = window.sessionStorage.getItem('isDeliveryOrder') ? JSON.parse(window.sessionStorage.getItem('isDeliveryOrder')) : null;
  dataToSend.sensorCode = getObjectValue(() => JSON.parse(window.localStorage.getItem('popInTokenRequest')).sensorCode, '');
  return {
    types: [GET_MULTI_STORE_MENU_REQUESTED, GET_MULTI_STORE_MENU_SUCCESS, GET_MULTI_STORE_MENU_FAILURE],
    promise: client => client.post('getMenu', {
      data: dataToSend
    }),
    payload: dataToSend
    // promise: client => client.get('getMenu/10000146')
  };
};

export const changeMenu = (menuIndex, menuItemIndex) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_MENU_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: CHANGE_MENU_SUCCESS,
        payload: {
          menuIndex,
          menuItemIndex
        }
      });
    }, 100);
  };
};

export const onSearchItem = (searchString) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SEARCH_ITEM_REQUEST
      });
      setTimeout(() => {
        dispatch({
          type: SEARCH_ITEM_SUCCESS,
          payload: searchString,
          resolve
        });
      }, 100);
    });
  };
};

export const pushMenuItemsByScreenId = (screenId) => {
  return (dispatch) => {
    dispatch({
      type: GET_MENU_ITEMS_BY_SCREEN_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: GET_MENU_ITEMS_BY_SCREEN_SUCCESS,
        payload: screenId
      });
    }, 100);
  };
};

export const pushDisclaimerPopUpIds = (screenId) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MENU_SCREEN_DISCLAIMER_IDS_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_MENU_SCREEN_DISCLAIMER_IDS_SUCCESS,
        payload: screenId
      });
    }, 100);
  };
};

export const pushModifiersGroupData = (data, isNested) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MODIFIERS_LEVEL_DATA_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_MODIFIERS_LEVEL_DATA_SUCCESS,
        payload: data,
        condition: isNested
      });
    }, 100);
  };
};

export const removeModifiers = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: REMOVE_MODIFIERS_DATA_REQUEST,
        resolve,
        payload: data
      });
    });
  };
};

export const addToCart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TO_CART_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: ADD_TO_CART_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const replaceCart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REPLACE_CART_REQUEST
      });
      setTimeout(() => {
        dispatch({
          type: REPLACE_CART_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const clearCart = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CART_REQUESTED
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_CART_SUCCESS,
      });
    }, 300);
  };
};

export const clearMenu = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CLEAR_MENUS_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_MENUS_SUCCESS,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const clearOrderScreen = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CLEAR_MENUS_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ORDER_SCREEN_SUCCESS,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const pushModifierToItem = (data) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MODIFIER_REQUESTED
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_MODIFIER_SUCCESS,
        payload: data
      });
    }, 100);
  };
};

export const pushGroupModifierToItem = (data) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MODIFIER_REQUESTED
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_GROUP_MODIFIER_SUCCESS,
        payload: data
      });
    }, 100);
  };
};

export const updateMultiCart = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPADTE_MULTI_CART_REQUEST
      });
      setTimeout(() => {
        dispatch({
          type: UPADTE_MULTI_CART_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const updateModifierToItemPromise = (path, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: PUSH_MODIFIER_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: UPADTE_MODIFIER_PROMISE_SUCCESS,
          payload: data,
          path,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const updateGroupModifierToItemPromise = (path, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: PUSH_MODIFIER_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: UPADTE_GROUP_MODIFIER_PROMISE_SUCCESS,
          payload: data,
          path,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const appendModifiersToList = (data) => {
  return (dispatch) => {
    dispatch({
      type: APPEND_MODIFIER_SUCCESS,
      payload: data
    });
  };
};

export const pushModifierToCount = (data, isInc) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_MODIFIER_COUNT_REQUESTED
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_MODIFIER_COUNT_SUCCESS,
        payload: data,
        isInc
      });
    }, 100);
  };
};

export const pushNestedModifierToItem = (data, nestedModifierArray) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_NESTED_MODIFIER_REQUESTED
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_NESTED_MODIFIER_SUCCESS,
        payload: data,
        isNested: nestedModifierArray
      });
    }, 100);
  };
};

export const pushCartItemsInfo = () => {
  return (dispatch) => {
    dispatch({
      type: PUSH_CART_ITEMS_INFO_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: PUSH_CART_ITEMS_INFO_SUCCESS,
      });
    }, 100);
  };
};

export const editCartItem = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: EDIT_CART_ITEM_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: EDIT_CART_ITEM_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const pushCartItems = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: PUSH_CART_ITEMS_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: PUSH_CART_ITEMS_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const removeUpSellItems = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_UPSELL_ITEMS_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_UPSELL_ITEMS_SUCCESS,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const pushItemQuantityToCart = (data) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ITEM_QUANTITY_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: CHANGE_ITEM_QUANTITY_SUCCESS,
        payload: data
      });
    }, 100);
  };
};

export const removeItemFromCart = (internalId) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ITEM_FROM_CART_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: REMOVE_ITEM_FROM_CART_SUCCESS,
        payload: internalId
      });
    }, 100);
  };
};

export const toggleNestedModifierFlag = (data) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_NESTED_MODIFIER_FLAG_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: TOGGLE_NESTED_MODIFIER_FLAG_SUCCESS,
        payload: data
      });
    }, 100);
  };
};

export const placeOrder = (data) => {
  return {
    types: [PLACE_ORDER_REQUESTED, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE],
    promise: client => client.post('placeOrder', {
      data
    })
  };
};

export const orderItems = (data) => {
  return {
    types: [ORDER_ITEM_REQUESTED, ORDER_ITEM_SUCCESS, ORDER_ITEM_FAILURE],
    promise: client => client.post('orderItems', {
      data
    })
  };
};

export const priceCheck = (data) => {
  return {
    types: [PLACE_ORDER_REQUESTED, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE],
    promise: client => client.post('priceCheck', {
      data
    })
  };
};

export const upsellCheck = (data) => {
  // if (!window.sessionStorage.getItem('upSell') || JSON.parse(window.sessionStorage.getItem('storeId')) !== data.store.storeId) {
  //   return {
  //     types: [UPSELL_REQUESTED, UPSELL_SUCCESS, UPSELL_FAILURE],
  //     promise: client => client.post('upsellCheck', {
  //       data
  //     }),
  //     payload: data
  //   };
  // }
  return {
    types: [UPSELL_REQUESTED, UPSELL_SUCCESS, UPSELL_FAILURE],
    promise: client => client.post('upsellCheck', {
      data
    }),
    payload: data
  };
};

export const kdsSimulate = (data) => {
  return {
    types: [KDS_SIMULATE_REQUESTED, KDS_SIMULATE_SUCCESS, KDS_SIMULATE_FAILURE],
    promise: client => client.post('kdsSimulate', {
      data
    })
  };
};

export const storePickUpTime = (storeId) => {
  return {
    types: [GET_STORE_PICKUPTIME_REQUESTED, GET_STORE_PICKUPTIME_SUCCESS, GET_STORE_PICKUPTIME_FAILURE],
    promise: client => client.get(`getStorePickUpTime/${storeId}`),
  };
};

export const storePickUpTimeByDate = (storeId, date) => {
  return {
    types: [GET_STORE_PICKUPTIMEBYDATE_REQUESTED, GET_STORE_PICKUPTIMEBYDATE_SUCCESS, GET_STORE_PICKUPTIMEBYDATE_FAILURE],
    promise: client => client.get(`getStorePickUpTime/${storeId}/date/${date}`),
  };
};

export const storeDeliveryTime = (storeId) => {
  return {
    types: [GET_STORE_DELIVERYTIME_REQUESTED, GET_STORE_DELIVERYTIME_SUCCESS, GET_STORE_DELIVERYTIME_FAILURE],
    promise: client => client.get(`getStoreDeliveryTime/${storeId}`),
  };
};

export const storeDeliveryTimeByDate = (storeId, date) => {
  return {
    types: [GET_STORE_DELIVERYTIMEBYDATE_REQUESTED, GET_STORE_DELIVERYTIMEBYDATE_SUCCESS, GET_STORE_DELIVERYTIMEBYDATE_FAILURE],
    promise: client => client.get(`getStoreDeliveryTime/${storeId}/date/${date}`),
  };
};

export const setMenuIndex = (menuIndex) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_INDEX,
      payload: menuIndex
    });
  };
};

export const storePickUpDeliveryTime = (storeId) => {
  return {
    types: [GET_STORE_PICKUPDELIVERYTIME_REQUESTED, GET_STORE_PICKUPDELIVERYTIME_SUCCESS, GET_STORE_PICKUPDELIVERYTIME_FAILURE],
    promise: client => client.get(`getStorePickupDeliveryTime/${storeId}`),
  };
};

export const storePickUpTimeBasedOnDate = (storeId, date) => {
  return {
    types: [GET_STORE_PICKUPTIME_DATE_BASED_REQUESTED, GET_STORE_PICKUPTIME_DATE_BASED_SUCCESS, GET_STORE_PICKUPTIME_DATE_BASED_FAILURE],
    promise: client => client.get(`getStorePickUpTimeBasedOnDate/${storeId}/date/${date}`),
  };
};

export const getOtherOrdersList = (data) => {
  return {
    types: [GET_OTHER_ORDERS_REQUEST, GET_OTHER_ORDERS_SUCCESS, GET_OTHER_ORDERS_FAILURE],
    promise: client => client.post('daisyChain', {
      data
    }),
  };
};

export const updateCollapsedModifierArray = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPADTE_COLLAPSED_MODIFIER_ARRAY_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: UPADTE_COLLAPSED_MODIFIER_ARRAY_SUCCESS,
        payload: data
      });
    }, 100);
  };
};

export const handleAddFavourite = (data) => {
  const dataToSend = {
    menuItemId: data.menuItemId,
    orderItemJson: JSON.stringify(data)
  };
  return {
    types: [ADD_TO_FAVOURITE_REQUESTED, ADD_TO_FAVOURITE_SUCCESS, ADD_TO_FAVOURITE_FAILURE],
    promise: client => client.post('addFavourite', {
      data: dataToSend
    }),
    payload: data
  };
};

export const handleRemoveFavourite = (data) => {
  return {
    types: [REMOVE_FROM_FAVOURITE_REQUESTED, REMOVE_FROM_FAVOURITE_SUCCESS, REMOVE_FROM_FAVOURITE_FAILURE],
    promise: client => client.post(`removeFavourite/${data}`),
    payload: data
  };
};

export const getPickupDeliveryTimes = (data) => {
  return {
    types: [GET_PICKUP_DELIVERY_TIMES_REQUEST, GET_PICKUP_DELIVERY_TIMES_SUCCESS, GET_PICKUP_DELIVERY_TIMES_FAILURE],
    promise: client => client.post('getPickDeliveryTimes', {
      data
    })
  };
};

export const clearDeliveryTime = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DELIVERY_TIME
    });
  };
};

export const getVoucherPickupDeliveryTimes = (data) => {
  return {
    types: [GET_VOUCHER_PICKUP_DELIVERY_TIMES_REQUEST, GET_VOUCHER_PICKUP_DELIVERY_TIMES_SUCCESS, GET_VOUCHER_PICKUP_DELIVERY_TIMES_FAILURE],
    promise: client => client.post('getVoucherPickupDeliveryTimes', {
      data
    })
  };
};

export const getMenuBasedOnLanguage = (storeId, flowType, languageId) => {
  const dataToSend = {};
  if (window.sessionStorage.getItem('pickupTime') && window.sessionStorage.getItem('pickupTime') !== 'null') {
    dataToSend.date = `${window.sessionStorage.getItem('pickupDate') ? getObjectValue(() => window.sessionStorage.getItem('pickupDate').split('T')[0], moment().format('YYYY-MM-DD')) : moment().format('YYYY-MM-DD')}T${window.sessionStorage.getItem('pickupTime')}`;
  }
  dataToSend.storeId = storeId;
  dataToSend.flowType = flowType;
  dataToSend.languageId = languageId;
  dataToSend.delivery = window.sessionStorage.getItem('isDeliveryOrder') ? JSON.parse(window.sessionStorage.getItem('isDeliveryOrder')) : null;
  dataToSend.sensorCode = getObjectValue(() => JSON.parse(window.localStorage.getItem('popInTokenRequest')).sensorCode, '');
  if (window.localStorage.getItem('isPreviewMode')) {
    dataToSend.preview = window.localStorage.getItem('isPreviewMode');
    dataToSend.menuId = window.sessionStorage.getItem('MenuId');
  }
  return {
    types: [GET_MENU_REQUESTED, GET_MENU_SUCCESS, GET_MENU_FAILURE],
    promise: client => client.post('getMenu', {
      data: dataToSend
    })
  };
};

export const getVoucherBySMS = (data) => {
  return {
    types: [VOUCHER_SMS_REQUESTED, VOUCHER_SMS_SUCCESS, VOUCHER_SMS_FAILURE],
    promise: client => client.post('voucherSMS', {
      data
    })
  };
};

export const onContinousScrollSearchItem = (searchString) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: CONTINUOUS_SEARCH_ITEM_REQUEST
      });
      setTimeout(() => {
        dispatch({
          type: CONTINUOUS_SEARCH_ITEM_SUCCESS,
          payload: searchString,
          resolve
        });
      }, 100);
    });
  };
};
