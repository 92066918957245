import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import style from './notification.module.css';
import Modal from '@components/common/Modal';
import { LABEL } from '@veriables';

const Notification = ({ isOpen, type, message }) => {
  const [modalOpen, setmodalOpen] = useState(false);
  useEffect(() => {
    setmodalOpen(false);
  }, [isOpen]);

  useEffect(() => {
    if (type === 'danger') {
      window.sessionStorage.setItem('setnotification', true);
    }
    return () => window.sessionStorage.removeItem('setnotification');
  }, [isOpen, type, message, modalOpen]);

  return (
    <React.Fragment>
      {(type === 'danger' || type === null) ? (
        <Modal isOpen={!modalOpen} toggleModal={() => { setmodalOpen(!modalOpen); }} retainModal>
          <div className={cn('mx-0 justify-content-center align-items-center text-center p-10')}>
            <div className={cn('text-break-all od-alert-message-font-style od-app-background-color od-text-primary p-10')}>
              {message}
            </div>
            <button type="submit" className="ml-20 btn btn-primary od-btn-primary col-4 p-10" onClick={() => { setmodalOpen(!modalOpen); window.sessionStorage.removeItem('setnotification'); }}>
              {LABEL().OK}
            </button>
          </div>
        </Modal>
      ) : (
        <div className={cn(style.notificationBar, 'od-alert-message-font-style', isOpen ? style.notificationOpen : style.notificationClose, cn(style.alertSuccess, 'od-alert-success-color'))} role="alert">
          {message}
          {/* <button type="button" className={cn('close', style.close)}>
            <span>
              &times;
            </span>
          </button> */}
        </div>
      )}
    </React.Fragment>
  );
};

const mapSateToProps = ({ notification }) => ({
  isOpen: notification.isOpen,
  message: notification.message,
  type: notification.type
});
export default connect(
  mapSateToProps,
  null
)(Notification);
