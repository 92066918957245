import React, {
  useEffect, Fragment, useState, useRef
} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FooterComponent from '@components/common/footer';
import { APP_CONFIG } from '@constants';
// import filter from 'lodash/filter';
// import findIndex from 'lodash/findIndex';
import cn from 'classnames';
import {
  updateMultiCart,
  onSearchItem,
  pushMenuItemsByScreenId,
  clearMenu,
  replaceCart,
  getMultiMenu,
  upsellCheck,
  getMenu,
  getMenuBasedOnLanguage,
  onContinousScrollSearchItem
} from '@reducers/orders';
import { bindActionCreators } from 'redux';
import { toggleFooter, toggleCancelButtonSamePage } from '@reducers/header';
import { callServer } from '@reducers/pingServer';
import { setNotification } from '@reducers/notification';
import styles from './footer.module.css';
import { getObjectValue } from '@utility/utills';
import DesktopMenu from '@components/common/footer/desktopMenu';
import { logout } from '@reducers/oauth';
import { sessionTrack, updatePopInData } from '@reducers/controller';
import Modal from '@components/common/Modal';
import find from 'lodash/find';
import MallMenuModal from '@components/MallMenuModal';
import { CUSTOM_MESSAGES } from '@veriables';
// import Loader from '@components/common/Loader/index';
import { getStoreThemeUrl, getLanguageThemeUrl } from '@reducers/theme';
import { userInfo } from '@utility';

const Footer = (props) => {
  const { menus, menu, closeButton } = props;
  const {
    footer,
    popInData,
    action,
    wishListCount,
    headerBar,
    themeProperties,
    // loading
  } = props;
  const isIos = JSON.parse(window.localStorage.getItem('ios'));
  const favouriteStyle = themeProperties && themeProperties.style && JSON.parse(themeProperties.style);
  const hideNavButtons = getObjectValue(() => favouriteStyle.odHideFooterIcon, false);
  const [search, onChangeSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [showRestaurants, setShowRestaurants] = useState(false);
  // const noFooter = window.location.pathname === '/orders' || window.location.pathname === '/selected-cart/0';

  const getActivityType = (type) => {
    let activityType = '';
    switch (type) {
      case 1:
      case 16:
        activityType = 'nav_home';
        break;
      case 2:
        activityType = 'nav_browse';
        break;
      case 3:
        activityType = 'nav_favorite';
        break;
      case 6:
        activityType = 'nav_order';
        break;
      default:
        activityType = '';
    }
    return activityType;
  };

  const handleSessionTrack = () => {
    const dataToSend = {
      activityType: 'nav_menu',
      data: {}
    };
    props.sessionTrack(dataToSend);
  };

  const isContinuousScroll = getObjectValue(() => props.menusList[props.menuIndex].displayStyle.continuousScroll, false);
  const onSearch = () => {
    if (search.length > 0 && !isContinuousScroll) {
      props.onSearchItem(search);
    } else if (search.length > 0 && isContinuousScroll) {
      props.onContinousScrollSearchItem(search);
    }
  };

  const handleSearchSessionTrack = () => {
    const dataToSend = {
      activityType: 'search_menu_item',
      data: {
        searchText: search,
        // count: props.screenMenuItemsList.length
      }
    };
    props.sessionTrack(dataToSend);
    setIsSearching(false);
  };

  useEffect(() => {
    if (isSearching) {
      handleSearchSessionTrack();
    }
  }, [props.screenMenuItemsList, isSearching]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search && !isContinuousScroll) {
        props.onSearchItem(search).then(() => {
          setIsSearching(true);
        });
      }
      if (search && isContinuousScroll) {
        props.onContinousScrollSearchItem(search).then(() => {
          setIsSearching(true);
        });
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const onSearchChange = (string) => {
    if (!string) {
      if (!isContinuousScroll && window.sessionStorage.getItem('scrol') && window.sessionStorage.getItem('menuTypeId')) {
        props.pushMenuItemsByScreenId(Number(window.sessionStorage.getItem('menuTypeId')));
        window.scrollTo(0, window.sessionStorage.getItem('scrol'));
      }
      if (isContinuousScroll && window.sessionStorage.getItem('menuTypeId')) {
        props.pushMenuItemsByScreenId(Number(window.sessionStorage.getItem('menuTypeId')));
        window.scrollTo(0, 0);
      }
    }
    // else if (string.length > 2) {
    //   props.onSearchItem(string);
    // }
    onChangeSearch(string);
  };

  const navigateTo = (url, type) => {
    if (['/account', '/profile-feature'].includes(url)) {
      window.sessionStorage.setItem('footer', JSON.stringify(props.footer));
      window.sessionStorage.setItem('accountPageToRedirect', window.location.pathname);
    }
    const dataToSend = {
      activityType: getActivityType(type),
      data: {}
    };
    props.sessionTrack(dataToSend);
    if (url === '/mycheck' && type === 17) {
      if (userInfo.isUserLogin()) {
        props.history.push(`${APP_CONFIG.BASE_URL}${url}`);
      } else {
        props.history.push(`${APP_CONFIG.BASE_URL}/sensor-check?backTo=mycheck`);
      }
    } else {
      props.history.push(`${APP_CONFIG.BASE_URL}${url}`);
    }
  };

  // const handleToggleMenus = (status) => {
  //   props.toggleFooter({ type: 'menu', show: status });
  // };

  // const handleToggle = (status) => {
  //   props.toggleFooter({ type: 'store', show: status });
  // };

  useEffect(() => {
    try {
      window.webkit.messageHandlers.submitToiOS.postMessage(JSON.stringify({ cart: props.cart }));
    } catch (err) {
      console.log('error');
    }
    try {
      window.Android.submitToAndroid(JSON.stringify({ cart: props.cart }));
    } catch (err) {
      console.log('error');
    }
  }, [props.cart]);

  const closeOnSamePage = () => {
    props.toggleCancelButtonSamePage(true);
  };

  const getCartQuantity = () => {
    let count = 0;
    props.cart.forEach((item) => {
      count += item.quantity;
    });
    if (props.popInData.venue && !props.popInData.venue.consolidatePayment) {
      (props.multiCartInfo || []).forEach((storeCart) => {
        if (storeCart.storeId !== props.popInData.store.id) {
          storeCart.cartDetails.forEach((item) => {
            count += item.quantity;
          });
        }
      });
    }
    return count;
  };

  const getSelectedLanguage = () => {
    const langId = window.sessionStorage.getItem('languageId') || getObjectValue(() => themeProperties.languageId, null);
    let result = getObjectValue(() => themeProperties.languageId, null);
    if (langId) {
      result = Number(langId);
    }
    return result;
  };

  const [selectedLanguageId, setSelectedLanguageId] = useState(getSelectedLanguage());
  const [prevLang, setPrevLang] = useState();
  const prevLangRef = useRef();

  useEffect(() => {
    prevLangRef.current = selectedLanguageId;
    setPrevLang(prevLangRef.current);
  }, [selectedLanguageId]);

  const handleMenuSelectionBasedOnLanguage = (languageId) => {
    const cartItems = window.sessionStorage.getItem('cart') !== 'null' ? window.sessionStorage.getItem('cart') : [];
    if (prevLang !== languageId) { // condition to prevent call menu again
      props.clearMenu().then(() => {
        props.getMenuBasedOnLanguage(getObjectValue(() => popInData.store.id, null), popInData.flowType, languageId).then(() => {
          if (cartItems && cartItems.length > 0) {
            props.replaceCart(JSON.parse(cartItems));
          }
        });
      });
    }
  };

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguageId(languageId);
    if (languageId) {
      window.sessionStorage.setItem('languageId', languageId);
      props.getLanguageThemeUrl(getObjectValue(() => popInData.store.brandId, null), getObjectValue(() => popInData.store.id, null), languageId).then((res) => {
        if (res.appCustomizationUrl) {
          handleMenuSelectionBasedOnLanguage(languageId);
        } else {
          props.getLanguageThemeUrl(getObjectValue(() => popInData.store.brandId, null), getObjectValue(() => popInData.store.id, null), 1);
          handleMenuSelectionBasedOnLanguage(1);
        }
      });
    }
  };

  const onClickCartHandler = () => {
    const dataToSend = {
      activityType: 'nav_bag',
      data: {}
    };
    props.sessionTrack(dataToSend);
    // if (props.popInData.venue && props.popInData.venue.stores.length > 1 && !props.popInData.venue.consolidatePayment) {
    //   const multiCartInfo = props.multiCartInfo;
    //   const index = findIndex(multiCartInfo, item => item.storeId === props.popInData.store.id);
    //   if (index === -1) {
    //     if (props.cart.length > 0) {
    //       props.updateMultiCart([...multiCartInfo, { storeId: props.popInData.store.id, cartDetails: props.cart }]).then(() => {
    //         props.history.push(`${APP_CONFIG.BASE_URL}/multi-cart`);
    //       });
    //     }
    //   } else if (props.cart.length === 0) {
    //     props.updateMultiCart(filter(multiCartInfo, d => d.storeId !== props.popInData.store.id)).then(() => {
    //       props.history.push(`${APP_CONFIG.BASE_URL}/multi-cart`);
    //     });
    //   } else {
    //     multiCartInfo[index].cartDetails = props.cart;
    //     props.updateMultiCart(multiCartInfo).then(() => {
    //       props.history.push(`${APP_CONFIG.BASE_URL}/multi-cart`);
    //     });
    //   }
    //   props.history.push(`${APP_CONFIG.BASE_URL}/multi-cart`);
    // } else
    if (props.popInData.venue) {
      props.history.push(`${APP_CONFIG.BASE_URL}/selected-cart/0`);
    } else {
      props.history.push(`${APP_CONFIG.BASE_URL}/upsell`);
    }
  };

  const onPreviewSync = () => {
    props.history.push(`${APP_CONFIG.BASE_URL}/preview/orders?storeId=${getObjectValue(() => props.popInData.store.id, 0)}`);
  };

  const onNavigate = (url) => {
    switch (url) {
      case '/logout':
        props.logout();
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
        window.location.href = '/dashboard';
        break;
      case '/tip':
        if (window.location.pathname === '/gift-card') {
          props.history.replace({ pathname: `${APP_CONFIG.BASE_URL}/tip`, search: '?backTo=tip' });
        } else {
          props.history.replace(`${APP_CONFIG.BASE_URL}/tip`);
        }
        break;
      default:
        props.history.replace(`${APP_CONFIG.BASE_URL}${url}`);
        break;
    }
  };

  const footerItemsTypes = [
    [
      {
        type: 16,
        controlUrl: '/home',
        activeLinks: ['/home']
      },
      {
        type: 7,
        controlUrl: '/pick-store',
        activeLinks: ['/pick-store']
      },
      {
        type: 12,
        controlUrl: '/pick-menu',
        activeLinks: []
      },
      {
        type: 6,
        controlUrl: '/orders',
        activeLinks: ['/orders']
      },
      {
        type: 4,
        controlUrl: '',
        activeLinks: ['/selected-cart', '/upsell']
      },
      {
        type: 0,
        controlUrl: '/profile-feature',
        activeLinks: ['/profile-feature']
      },
      {
        type: 17,
        controlUrl: '/mycheck',
        activeLinks: ['/mycheck']
      }
    ],
    [
      {
        type: 1,
        controlUrl: '/home',
        activeLinks: ['/home']
      },
      // {
      //   type: 6,
      //   controlUrl: '/orders',
      //   activeLinks: ['/orders']
      // },
      // {
      //   type: 4,
      //   controlUrl: '',
      //   activeLinks: ['/selected-cart', '/upsell', '/multi-cart']
      // },
      {
        type: props.hideBrowse ? null : 12,
        controlUrl: '/pick-menu',
        activeLinks: []
      },
      {
        type: props.hideBrowse ? null : 2,
        controlUrl: '/browse-category',
        activeLinks: ['/browse-category', '/category-detail']
      },
      {
        type: props.hideBrowse ? null : 3,
        controlUrl: '/wishList',
        activeLinks: ['/wishList']
      },
      {
        type: 0,
        controlUrl: '/profile-feature',
        activeLinks: ['/profile-feature']
      }
    ]
  ];

  const handleMallSelection = (storeId) => {
    const storeData = find(popInData.stores, { id: storeId });
    const consolidateCart = JSON.parse(window.sessionStorage.getItem('cart') || '[]');
    props.updatePopInData({ store: find(popInData.stores, { id: storeId }) }).then(() => {
      props.clearMenu().then(() => {
        window.sessionStorage.removeItem('menuTypeId');
        window.sessionStorage.removeItem('scrol');
        window.sessionStorage.removeItem('isTile');
        window.sessionStorage.removeItem('menuName');
        props.getMenu(storeId, props.popInData.flowType).then(() => {
          props.replaceCart(consolidateCart);
          if (getObjectValue(() => storeData.brandId, null)) {
            props.getStoreThemeUrl(storeData.brandId, storeData.id);
          }
          if (getObjectValue(() => popInData.startOnHomePage, false) && window.innerWidth < 1023) {
            props.history.push(`${APP_CONFIG.BASE_URL}/home`);
          } else if (!getObjectValue(() => popInData.startOnHomePage, false) && window.innerWidth < 1023) {
            props.history.replace(`${APP_CONFIG.BASE_URL}/orders`);
          } else {
            props.history.replace(`${APP_CONFIG.BASE_URL}/orders`);
          }
        });
      });
    });
    setShowRestaurants(false);
  };

  return (
    <Fragment>
      {(headerBar || {}).show && (window.innerWidth > 1023) ? (
        <DesktopMenu
          menuItems={footerItemsTypes[props.footerItemsType]}
          // activeMenuItemType={footer.activeMenuItemType}
          navigateTo={navigateTo}
          menus={menus}
          menu={menu}
          popInData={popInData}
          action={action}
          closeOnSamePage={closeOnSamePage}
          wishListCount={wishListCount}
          menusList={props.menusList}
          getCartQuantity={getCartQuantity}
          onClickCartHandler={onClickCartHandler}
          onPreviewSync={onPreviewSync}
          onNavigate={onNavigate}
          closeButton={closeButton}
          headerBar={headerBar}
          footer={footer}
          themeProperties={themeProperties}
          onSearch={onSearch}
          onSearchChange={onSearchChange}
          searchString={search}
          handleLanguageSelection={handleLanguageSelection}
          setSelectedLanguageId={setSelectedLanguageId}
          selectedLanguageId={selectedLanguageId}
        />
      ) : ''}
      {(footer || {}).show && (
        <div className={cn('footer footerBtm', styles.desktopFooter, (isIos && window.innerWidth < 1024) ? 'd-none' : '', hideNavButtons ? 'd-none' : '')}>
          <FooterComponent
            menuItems={footerItemsTypes[props.footerItemsType]}
            activeMenuItemType={footer.activeMenuItemType}
            menus={menus}
            popInData={popInData}
            action={action}
            closeOnSamePage={closeOnSamePage}
            wishListCount={wishListCount}
            menusList={props.menusList}
            getCartQuantity={getCartQuantity}
            onClickCartHandler={onClickCartHandler}
            onPreviewSync={onPreviewSync}
            onNavigate={onNavigate}
            navigateTo={navigateTo}
            themeProperties={themeProperties}
            handleSessionTrack={handleSessionTrack}
            setShowRestaurants={setShowRestaurants}
            callServer={props.callServer}
            setNotification={props.setNotification}
          />
        </div>
      )}
      <Modal isOpen={showRestaurants} toggleModal={() => { setShowRestaurants(!showRestaurants); }}>
        <div>
          <div className="row mx-0">
            <div className="col-lg-4 offset-lg-4 p-lg-0 col-md-6 offset-md-3 col-12 mb-4">
              <div className="row mx-0">
                <div className="col-12 mt-1">
                  <div className="row mt-2 mb-2 fw-600 d-flex justify-content-between">
                    <span>{CUSTOM_MESSAGES().SELECT_STORE_ORDER_FROM}</span>
                    <div className="text-right btn-as-text color-secondary">
                      <button type="button" className="bg-transparent border-0 icon-od fs-12 icon-od-close" aria-label="close" onClick={() => { setShowRestaurants(false); }} />
                    </div>
                  </div>
                  <div className="row">
                    <MallMenuModal
                      popInData={popInData}
                      handleMallSelection={handleMallSelection}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = ({
  oauth,
  header,
  orders,
  browse,
  theme,
  controller
}) => (
  {
    userdata: oauth.userdata,
    footer: header.footer,
    footerItemsType: header.footerItemsType,
    menus: orders.menus,
    menuIndex: orders.menuIndex,
    popInData: controller.popInData || {},
    action: header.action,
    closeButton: header.closeButton,
    menu: header.menu,
    // wishListCount: browse.wishList.length,
    hideBrowse: browse.hideBrowse,
    menusList: orders.menusList,
    cart: orders.cart,
    multiCartInfo: orders.multiCartInfo,
    headerBar: header.headerBar,
    themeProperties: theme.themeProperties,
    screenMenuItemsList: orders.screenMenuItemsList,
    wishListCount: browse.favouriteBrowseItems && browse.favouriteBrowseItems.length,
  }
);

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleFooter,
  toggleCancelButtonSamePage,
  updateMultiCart,
  logout,
  sessionTrack,
  onSearchItem,
  pushMenuItemsByScreenId,
  clearMenu,
  replaceCart,
  getMultiMenu,
  upsellCheck,
  getMenu,
  updatePopInData,
  setNotification,
  getStoreThemeUrl,
  getLanguageThemeUrl,
  callServer,
  getMenuBasedOnLanguage,
  onContinousScrollSearchItem
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer));
