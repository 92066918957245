import { urlSearchParams, getObjectValue } from '@utility/utills';

const defaultHeaderState = {
  headerBar: {
    show: true,
    tittle: 'OneDineLogo'
  },
  menu: {
    type: null,
    controlUrl: ''
  },
  closeButton: {
    type: null,
    controlUrl: ''
  },
  footer: {
    show: false
  }
};

export default (url) => {
  const backTo = urlSearchParams('backTo') ? `/${urlSearchParams('backTo')}` : null;
  const isGuestUser = urlSearchParams('isGuestUser') ? `${urlSearchParams('isGuestUser')}` : null;
  const splitCheckBackTo = urlSearchParams('isOrder') ? '/selected-cart/0' : '/home';
  const isInvalidSensorCode = window.sessionStorage.getItem('isInvalidSendorCode');
  const sensorCode = getObjectValue(() => JSON.parse(window.localStorage.getItem('popInTokenRequest')).sensorCode, '');
  const scanQrBackTo = urlSearchParams('backTo') ? `/${urlSearchParams('backTo')}` : '/dashboard';
  // console.log('backTo', backTo, scanQrBackTo, sensorCode);
  const getBackToURL = () => {
    let result = backTo;
    if (isGuestUser) {
      result = '/current-location';
    } else if (!backTo) {
      result = '/dashboard';
    }
    return result;
  };
  switch (url.pathname.substring(0, url.pathname.lastIndexOf('/') ? url.pathname.lastIndexOf('/') : url.pathname.length)) {
    case '/':
    case '/dashboard':
      return {
        ...defaultHeaderState,
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 1,
          controlUrl: ''
        },
      };
    case '/login':
    case '/signup':
    case '/forgotpassword':
      return {
        ...defaultHeaderState,
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
      };
    case '/scan-qr':
      return {
        ...defaultHeaderState,
        menu: {
          type: 2,
          controlUrl: '/dashboard'
        },
        closeButton: {
          type: 2,
          controlUrl: isInvalidSensorCode ? `/s/${sensorCode}` : scanQrBackTo
        },
      };
    case '/sensor-check':
      return {
        ...defaultHeaderState,
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: getBackToURL()
        },
      };
    case '/pickup-time':
    case '/guest-spot':
    case '/spot-screen':
    case '/table-confirmation':
    case '/walkup-booking':
      return {
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
      };
    case '/future-order':
      return {
        headerBar: {
          show: true,
          tittle: 'Logo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
      };
    case '/location-confirmation':
      return {
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: false
        }
      };
    case '/orders':
    case '/pick-store':
      return {
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        },
        footerItemsType: 0,
      };
    case '/browse-category':
      return {
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        },
        footerItemsType: 1,
      };
    case '/upsell':
    case '/multi-cart':
      return {
        closeButton: {
          type: 3,
          controlUrl: '/orders'
        },
      };
    case '/wishList':
    case '/category-detail':
      return {
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        }
      };
    case '/refill':
      return {
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: backTo || '/home'
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        }
      };
    case '/selected-cart':
      return {
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/orders'
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        }
      };
    case '/home':
      return {
        menu: {
          type: 1,
          controlUrl: '/account'
        },
        closeButton: {
          type: null,
          controlUrl: '/dashboard'
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        }
      };
    case '/loyalty-offers':
    case '/linked-item':
      return {
        menu: {
          type: 1,
          controlUrl: '/account'
        },
        closeButton: {
          type: 3,
          controlUrl: backTo || '/loyalty'
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: false
        }
      };
    case '/lookup':
      return {
        menu: {
          type: 1,
          controlUrl: '/account'
        },
        closeButton: {
          type: 3,
          controlUrl: '/tip'
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: false
        }
      };
    case '/callserver':
      return {
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: backTo || '/home'
        },
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        footer: {
          show: true
        }
      };
    case '/orders-history':
    case '/subscription':
    case '/profile':
    case '/wallet':
      return {
        ...defaultHeaderState,
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/account'
        },
      };
    case '/account':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 5,
          controlUrl: ''
        },
        footer: {
          show: false
        }
      };
    case '/profile-feature':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 5,
          controlUrl: ''
        },
        footer: {
          show: true
        }
      };
    case '/booking-list':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        footer: {
          show: false
        }
      };
    case '/table-booking':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: backTo || '/dashboard'
        },
        footer: {
          show: false
        }
      };
    case '/edit-booking':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/booking-list'
        },
        footer: {
          show: false
        }
      };
    case '/profile-orders':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/profile-feature'
        },
        footer: {
          show: false
        }
      };
    case '/payment-option':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/home'
        },
        footer: {
          show: true
        }
      };
    case '/specials':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: backTo || '/home'
        },
        footer: {
          show: true
        }
      };
    case '/check-paid':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        footer: {
          show: false
        }
      };
    case '/selected-items':
    case '/split-evenly':
    case '/pay-my-items':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/payment-option'
        },
        footer: {
          show: true
        }
      };
    case '/pay-by-guest':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/payment-option'
        },
        footer: {
          show: true
        }
      };
    case '/pay-an-amount':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/payment-option'
        },
        footer: {
          show: true
        }
      };
    case '/split-check':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: 1,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: splitCheckBackTo
        },
        footer: {
          show: true
        }
      };
    case '/accept-terms':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: 3,
          controlUrl: '/dashboard'
        },
        footer: {
          show: false
        }
      };
    case '/multiCartReceipt':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: false,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        footer: {
          show: false
        }
      };
    case '/kiosk-payment-info':
    case '/kiosk-payment-method':
    case '/kiosk-receipt-page':
    case '/kiosk-receipt-method':
    case '/kiosk-guest-info':
    case '/kiosk-payment-page':
      return {
        ...defaultHeaderState,
        headerBar: {
          show: true,
          tittle: 'StoreLogo'
        },
        menu: {
          type: null,
          controlUrl: ''
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        footer: {
          show: false
        }
      };
    case '/contact-restaurant':
      return {
        headerBar: {
          show: true,
          tittle: 'Logo'
        },
        closeButton: {
          type: 3,
          controlUrl: '/selected-cart/0'
        },
        footer: {
          show: false
        }
      };
    case '/employee-table-reassignment':
      return {
        headerBar: {
          show: true,
          tittle: 'Logo'
        },
        closeButton: {
          type: null,
          controlUrl: ''
        },
        footer: {
          show: false
        }
      };
    default:
      return {};
  }
};
