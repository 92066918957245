import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import VerifyMobileForm from '@components/VerifyMobileForm';
import { withRouter } from 'react-router-dom';
import {
  validateCountryCode,
  validatePhoneNumber
} from '@utility/utills';

/*
**** State Description ****
* initialData: for setting default values of form
* email: email of user
*/
const VerifyMobile = ({
  getotp,
  goBack,
  initialValues,
  loading,
  restaurantInfoConfig
}) => {
  const validate = (values) => {
    const errors = {};

    const countryCodeError = validateCountryCode(values.countryCode);
    if (countryCodeError) {
      errors.countryCode = countryCodeError;
    }

    const mobileNumberError = validatePhoneNumber(values.countryCode, values.mobileNumber);
    if (mobileNumberError) {
      errors.mobileNumber = mobileNumberError;
    }

    return errors;
  };

  return (
    <div>
      <Formik
        render={formProps => (
          <VerifyMobileForm
            formProps={formProps}
            dirty={formProps.dirty}
            onSubmit={getotp}
            onClickNavigation={goBack}
            loading={loading}
            restaurantInfoConfig={restaurantInfoConfig}
          />
        )}
        validate={validate}
        initialValues={initialValues}
        onSubmit={getotp}
      />
    </div>
  );
};

VerifyMobile.propTypes = {
  getotp: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default withRouter(VerifyMobile);
