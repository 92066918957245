const getUserData = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {});
};

export const getUserBasicDetails = () => {
  const user = getUserData().user;
  if (user && !user.countryCode) {
    user.countryCode = '1';
  }
  return user;
};

export const setUserBasicDetails = (data) => {
  const userData = getUserData();
  userData.user = data;
  window.localStorage.setItem('userData', JSON.stringify(userData));
};

export const getToken = () => {
  return getUserData().token;
};

export const getPopInRequestInfo = () => {
  return (JSON.parse(window.localStorage.getItem('popInRequest')) || {});
};

export const isUserLogin = () => {
  return getUserData().token;
};

export const isTempToken = () => {
  return getUserData().temporary;
};

export const isSessionHasToken = () => {
  return !(getUserData().temporary && !window.sessionStorage.getItem('temporary'));
};

export default {
  getUserBasicDetails,
  setUserBasicDetails,
  getToken,
  isUserLogin,
  isTempToken,
  getPopInRequestInfo,
  isSessionHasToken
};
