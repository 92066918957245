import React, { useState, useEffect } from 'react';
import GenerateOtp from '@containers/GenerateOtp';
import ResetPassword from '@containers/ResetPassword';
// import { updateHeaderData } from '@reducers/header';
import Modal from '@components/common/Modal';
import { APP_CONFIG } from '@constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import { getObjectValue } from '@utility/utills';

// import InitialImage from '@assets/images/login.png';
/*
* This is an entry flow for forgot Password Flow.
**** State Description ****
* mobileNumber: mobile number of the user
*/
const ForgotPassword = (props) => {
  const formInitalValue = {
    emailAddress: '',
    countryCode: getObjectValue(() => `+${props.popInData.store.countryCode}`, '+1')
  };

  const [pageName, setPageName] = useState('forgot');
  const [isModalOpen, toggleModal] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitalValue);

  // const showHeaderInfo = {
  //   menu: {
  //     type: null,
  //     controlUrl: ''
  //   },
  //   closeButton: {
  //     type: 3,
  //     controlUrl: '/dashboard'
  //   },
  //   headerBar: {
  //     show: true,
  //     tittle: 'OneDineLogo'
  //   },
  //   restaurantInfo: {
  //     show: true
  //   }
  // };

  useEffect(() => {
    // props.updateHeaderData(showHeaderInfo);
  }, [pageName]);
  const navigation = (e) => {
    if (e.key === 'Escape') {
      props.history.push('/dashboard');
    }
  };
  useEffect(() => {
    if (window.location.pathname === ('/forgotpassword')) {
      document.addEventListener('keydown', navigation);
    }
    return () => document.removeEventListener('keydown', navigation);
  }, []);
  /* Setting state to go to generate otp screen */
  const goBack = () => {
    if (pageName === 'forgot') {
      props.history.replace(`${APP_CONFIG.BASE_URL}/login`);
    } else {
      toggleModal(!isModalOpen);
    }
  };

  const getotp = (data) => {
    setInitialValues(data);
    setPageName('reset');
  };

  /* Setting state to go to generate otp screen */
  // const resetPasswordScreen = () => {
  // }

  return (
    <div className="row mx-0">
      <div className={cn('col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12', window.innerWidth > 1023 ? 'pt-30' : '')}>
        <div className="border mb-10">
          <div className="row m-0 justify-content-md-center h-100">
            <div className="col-md-12  mt_10">
              {pageName === 'forgot' && (
                <GenerateOtp
                  getotp={getotp}
                  goBack={goBack}
                  initialValues={initialValues}
                />
              )}
              { pageName === 'reset' && (
                <ResetPassword
                  initialValues={initialValues}
                  onClickNavigation={goBack}
                  goBack={goBack}
                  history={props.history}
                />
              )}
            </div>
          </div>
          <Modal
            isOpen={isModalOpen}
            toggleModal={() => toggleModal(!isModalOpen)}
            onConfirm={() => {
              toggleModal(!isModalOpen);
              props.history.replace(`${APP_CONFIG.BASE_URL}/`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapdispatchtoprops = dispatch => bindActionCreators({
  // updateHeaderData
}, dispatch);

const mapStateToProps = ({ controller }) => ({
  popInData: controller.popInData
});

export default connect(mapStateToProps, mapdispatchtoprops)(ForgotPassword);
