import React, { Fragment } from 'react';
import { Form } from 'formik';
import Button from '@formElements/Button';
import Textfield from '@formElements/TextFieldFormik';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import cn from 'classnames';
import styles from './verifyemailform.module.css';
import { getObjectValue } from '../../utility/utills';

const verifyEmailForm = ({
  loading,
  onHandleClick,
}) => {
  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  return (
    <Fragment>
      <div className="text-center fs-20 fw-700 p-10 pb-15">
        {CUSTOM_MESSAGES().ENTER_EMAIL_ADDRESS_TO_RECIEVE_CODE}
      </div>
      <Form>
        <div className="row mb-10">
          <div className={cn('col-12 m-0')}>
            <Textfield
              name="emailAddress"
              type="email"
              label={CUSTOM_MESSAGES().ENTER_EMAIL_ADDRESS}
              ariaLabel="emailAddress"
              ariaLabelBy="emailAddress"
              title="emailAddress"
              htmlFor={CUSTOM_MESSAGES().ENTER_EMAIL_ADDRESS}
            />
          </div>
        </div>
        <div className="col-12 p-0 mb-10">
          {btnImgUrl ? (
            <div className="position-relative d-flex justify-content-center">
              <Button aria-label="verifyButton" type="submit" loading={loading} customClass="pl-45" className="border-0 bg-transparent" onClick={() => onHandleClick}>
                <Fragment>
                  <img src={btnImgUrl} className={cn(styles.btnImgUrlSize, 'od-app-btn-primary')} alt="verify" />
                  <span className={cn(styles.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().VERIFY}</span>
                </Fragment>
                <Fragment>
                  Loading
                </Fragment>
              </Button>
            </div>
          ) : (
            <Button aria-label="verifyButton" type="submit" loading={loading} customClass="pl-45" className="btn btn-primary btn-block btn-md od-btn-primary" onClick={() => onHandleClick}>
              <Fragment>
                {LABEL().VERIFY}
              </Fragment>
              <Fragment>
                Loading
              </Fragment>
            </Button>
          )}
        </div>
      </Form>
    </Fragment>
  );
};
export default verifyEmailForm;
