import React from 'react';
// import LoaderImage from '@assets/images/fried@2x.png';
import cn from 'classnames';
import styles from './loader.module.css';

const Loader = (props) => {
  return (
    <div className={cn('mx-sm-0', props.noTransparent ? '' : styles.transparent)}>
      <div className={`row mx-0 ${props.noTransparent ? '' : 'full-height'} justify-content-center align-items-center`}>
        <div className={cn(styles.imgSpin, 'od-spinner')} />
      </div>
    </div>
  );
};
export default Loader;
