import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VerifyMobile from '@containers/VerifyMobile';
import VerifyEmail from '@containers/VerifyEmail';
import VerifyOtp from '@containers/VerifyOtp';
// import RegisterNew from '@containers/RegisterNew';
import Modal from '@components/common/Modal';
// import backSlider from '@assets/images/backSlider.png';
// import OneDineLogo from '@assets/images/oneDineLogoM.png';
import {
  createProfile,
  verifyPhone,
  verifyCode,
  getRestaurantInfoConfig,
  generateUserName,
  checkUniqueUserName
} from '@reducers/signup';
import { updateBasicDeatils } from '@reducers/profile';
import { setNotification } from '@reducers/notification';
import {
  APP_CONFIG,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from '@constants';
import { toggleCancelButtonSamePage } from '@reducers/header';
// import InitialImage from '@assets/images/login.png';
// import EulaForGuest from '@components/EulaForGuest';
// import PrivacyPolicyForGuest from '@components/PrivacyPolicyForGuest';
// import TermsAndCondtionsForGuest from '@components/TermsAndCondtionsForGuest';
import MyData from '@components/MyData';
import Loader from '@components/common/Loader/index';
import cn from 'classnames';
import style from './signUp.module.css';
import {
  getObjectValue,
  getTokenInfoSharedKey,
  randomString,
  md5
} from '@utility/utills';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import { userInfo } from '@utility';

/*
* This is an entry flow for forgot Password Flow.
**** State Description ****
* mobileNumber: mobile number of the user
*/

const SignUp = (props) => {
  const [countryCode, setcountryCode] = useState();
  const newData = JSON.parse((window.sessionStorage.getItem('userSignUpValues')));
  const newCountryCode = getObjectValue(() => newData.countryCode, null);
  const newMobileNumber = getObjectValue(() => newData.phoneNumber, null);
  const formInitalValue = {
    mobileNumber: newMobileNumber || '',
    countryCode: newCountryCode || getObjectValue(() => `+${props.popInData.store.countryCode}`, '+1'),
    emailAddress: '',
    token: ''
  };
  const [restaurantInfoConfig, setRestaurantInfoConfig] = useState(null);
  const [pageName, setPageName] = useState('verifyMobile');
  const [linkName, setLinkName] = useState();
  const [pageState, setPageState] = useState();
  // const [pageName, setPageName] = useState('verifyOtp');
  const [initialValues, setInitialValues] = useState(formInitalValue);
  const [mobileNumber, setmobileNumber] = useState(formInitalValue);
  // const [countryCode, setcountryCode] = useState(formInitalValue);
  const [isModalOpen, toggleModal] = useState(false);
  const [store, setStore] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [requireTermsAcceptance, setRequireTermsAcceptance] = useState(false);
  const isIos = JSON.parse(window.localStorage.getItem('ios'));
  const userData = getObjectValue(() => JSON.parse(window.localStorage.getItem('userData')).user, null);
  const [completed, setCompleted] = useState(false);
  const [email, setEmail] = useState(formInitalValue);
  const [toggleMyData, setToggleMyData] = useState(false);

  /* Setting state to go to generate otp screen */
  const goBack = () => {
    props.history.replace(`${APP_CONFIG.BASE_URL}/login`);
  };

  useEffect(() => {
    props.getRestaurantInfoConfig()
      .then((res) => {
        setRestaurantInfoConfig(res);
      })
      .catch(() => {
        props.setNotification({
          type: 'danger',
          message: ERROR_MESSAGE.SERVER_ERROR
        });
      });
  }, []);

  useEffect(() => {
    if (pageName === 'verifyMobile') {
      setPageState(1);
    } else if (pageName === 'verifyOtp') {
      setPageState(2);
    } else if (pageName === 'verifyEmail') {
      setPageState(3);
    }
  }, [pageName]);

  useEffect(() => {
    if (props.cancelOnPage) {
      if (pageState === 1) {
        setPageName('verifyMobile');
      } else if (pageState === 2) {
        setPageName('verifyOtp');
      } else if (pageState === 3) {
        setPageName('verifyEmail');
      }
      // props.updateHeaderData(showHeaderInfo);
      props.toggleCancelButtonSamePage(false);
    }
  }, [props.cancelOnPage]);

  const onClickNavigation = () => {
    if (pageName === 'signup') {
      setPageName('verifyOtp');
    } else if (pageName === 'verifyOtp') {
      setPageName('verifyMobile');
    } else if (['eula', 'privacyPolicy', 'termsandconditions', 'mydata'].includes(pageName)) {
      setPageName('verifyOtp');
    } else if (pageName === 'verifyEmail') {
      setPageName('verifyMobile');
    } else {
      goBack();
    }
  };

  const handleSuccess = (res, data) => {
    setPageName('verifyOtp');
    setRequireTermsAcceptance(res.requireTermsAcceptance);
    if (data && data.emailAddress) {
      window.sessionStorage.setItem('offlineMode', res.offlineMode);
      props.setNotification({
        type: 'success',
        message: CUSTOM_MESSAGES().OTP_SENT_TO_EMAIL
      });
    } else {
      props.setNotification({
        type: 'success',
        message: SUCCESS_MESSAGE.OTP_SENT_TO_MOBILE
      });
    }
  };

  const VerifySuccess = (res) => {
    setPageName('signup');
    setInitialValues(res.user);
    props.setNotification({
      type: 'success',
      message: SUCCESS_MESSAGE.SMS_CODE
    });
    props.successLogin(res, true);
  };

  const redirectsToOtp = (data) => {
    if (data.code === 'member_login_required') {
      window.localStorage.removeItem('userData');
      setPageName('verifyMobile');
    }
  };

  const handleFailure = (data) => {
    if (pageName === 'verifyMobile') {
      props.setNotification({
        type: 'danger',
        message: ERROR_MESSAGE.MOBILE_NUMBER
      });
      redirectsToOtp(data);
    } else if (pageName === 'verifyOtp') {
      props.setNotification({
        type: 'danger',
        message: data.message
      });
      redirectsToOtp(data);
    } else if (pageName === 'verifyEmail') {
      props.setNotification({
        type: 'danger',
        message: data.message
      });
      redirectsToOtp(data);
    }
  };

  const getotp = (data) => {
    if (!data.emailAddress) {
      setmobileNumber(data.mobileNumber);
    }
    setcountryCode(data.countryCode);
    setEmail(data.emailAddress);

    let restaurantInfo = null;
    if (restaurantInfoConfig && restaurantInfoConfig.enabled) {
      // Note: This code is purposefully obfuscated
      const o = {
        w: getTokenInfoSharedKey(),
        x: data.token,
        y: randomString(32),
        z: md5(data.token)
      };
      const restaurantId = btoa(JSON.stringify(o));
      restaurantInfo = {
        restaurantId,
        restaurantPeriodId: o.z,
        restaurantSessionId: o.y,
        restaurantServiceId: md5(o.w + restaurantId)
      };
    }

    const dataToSend = {
      phoneNumber: newMobileNumber || data.mobileNumber,
      ...(data.emailAddress && { emailAddress: data.emailAddress }),
      code: '',
      countryCode: newCountryCode || data.countryCode,
      storeId: (store && store.id && !store.venue) ? store.id : null,
      memberId: userData && userData.id,
      restaurantInfo
    };

    const jsondata = JSON.stringify(dataToSend);
    window.sessionStorage.setItem('userSignUpValues', jsondata);
    props.verifyPhone(jsondata).then(res => handleSuccess(res, data)).catch(handleFailure);
  };

  const handleVerifyOtp = (data) => {
    setInitialValues(formInitalValue);
    const dataToSend = {
      phoneNumber: `${mobileNumber}`,
      code: data.code,
      countryCode: `${countryCode}`,
      storeId: (store && store.id && !store.venue) ? store.id : null,
      termsAccepted: true
    };
    const jsondata = JSON.stringify(dataToSend);
    props.verifyCode(jsondata).then(VerifySuccess).catch(handleFailure);
  };
  useEffect(() => {
    let storeList = (props.checkInStoreInfo || props.popInData || {}).store || {};
    if ((props.checkInStoreInfo || {}).venue) {
      storeList = props.checkInStoreInfo.venue || {};
      storeList.venue = true;
    }
    setStore(storeList);
  }, []);
  const handleToggle = (status) => {
    setModalOpen(status);
  };

  const timeExipers = (status) => {
    setCompleted(status);
    setPageName('verifyEmail');
  };

  const handleCheckIn = () => {
    props.history.push(`${APP_CONFIG.BASE_URL}/sensor-check?backTo=dashboard&pageName=mydata`);
  };

  return (
    <div className="container page">
      <Modal
        isOpen={modalOpen}
        toggleModal={() => { handleToggle(false); }}
      >
        {/* {linkName === 'eula' && (
          <div>
            <EulaForGuest handleToggle={handleToggle} />
          </div>
        )}
        {linkName === 'termsandconditions' && (
          <div>
            <TermsAndCondtionsForGuest handleToggle={handleToggle} />
          </div>
        )}
        {linkName === 'privacyPolicy' && (
          <div>
            <PrivacyPolicyForGuest handleToggle={handleToggle} />
          </div>
        )} */}
        {linkName === 'mydata' && (
          <div>
            <MyData handleToggle={handleToggle} />
          </div>
        )}
      </Modal>
      <Fragment>
        <div className="row content">
          {props.loading && <Loader />}
          <div className={cn('w-full pt-10 pl-20 pr-20 od-app-background-color', style.signUpOutLook)}>
            {(store !== null && store.name) && (
              <div className="col-12 mt-2 p-0">
                <div className={cn('row mx-0 od-app-location-background', style.backgroundBlue, style.border)}>
                  <div className="col-12 p-0">
                    <div className="row mx-0">
                      <div className="col-2 p-0 d-flex justify-content-center align-items-start">
                        <i className={cn('fas fa-map-marker-alt fs-24 od-color', style.colorWhite)} aria-hidden="true" />
                      </div>
                      <div className="col-10 p-0">
                        <span className={cn('fs-12 od-color mb-1', style.colorWhite)}>
                          {CUSTOM_MESSAGES().YOUR_CHECKED_LOCATION}
                        </span>
                        <div className={cn('fs-14 fw-600 od-color mb-1', style.colorWhite)}>{store !== null && store.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {pageName === 'verifyMobile' && (
              <Fragment>
                <VerifyMobile
                  getotp={getotp}
                  goBack={goBack}
                  initialValues={initialValues}
                  loading={props.loading}
                  restaurantInfoConfig={restaurantInfoConfig}
                />
                {/* <div className={cn('fs-14 my-3  text-center od-text-primary', style.colorBlue)} role="button" tabIndex="0" onKeyDown={(e) => { if (e.key === 'Enter') props.setPageState(1); }} onClick={() => props.setPageState(1)}>
                  {CUSTOM_MESSAGES().LOGIN_WITH_PASSWORD} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} />
                </div> */}
                <div className={cn('fs-14 my-3  text-center od-text-primary', style.colorBlue)}>
                  <a tabIndex="0" href={() => props.setPageState(1)} className={cn('fs-14 my-3  text-center od-text-primary', style.colorBlue)} onClick={() => props.setPageState(1)} onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Spacebar') { props.setPageState(1); } }}>{CUSTOM_MESSAGES().LOGIN_WITH_PASSWORD} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} /></a>
                </div>
              </Fragment>
            )}
            {pageName === 'verifyEmail' && (
              <Fragment>
                {props.offlineMode && completed && (
                <div className={cn('fs-16 fw-700 mt-10 mb-10 text-center text-primary od-text-primary')}>
                  <span>{CUSTOM_MESSAGES().SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_OFFLINE_MODE}</span>
                </div>
                )}
                <VerifyEmail
                  getotp={getotp}
                  goBack={goBack}
                  initialValues={initialValues}
                  loading={props.loading}
                />
                <div className={cn('fs-14 mt-10 mb-10 text-center od-text-primary', style.colorBlue)} role="presentation" onClick={onClickNavigation}>
                  <i className={cn('fa fa-undo od-text-primary', style.colorBlue)} /> {CUSTOM_MESSAGES().USE_DIFFERENT_NUMBER}
                </div>
                {/* <div className={cn('fs-14 mb-15 text-center od-text-primary', style.colorBlue)} role="button" tabIndex="0" onClick={() => props.setPageState(1)} onKeyDown={(e) => { if (e.key === 'Enter') props.setPageState(1); }}>
                  {CUSTOM_MESSAGES().LOGIN_WITH_PASSWORD} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} />
                </div> */}
                <div className={cn('fs-14 mb-15 text-center od-text-primary', style.colorBlue)}>
                  <a tabIndex="0" href={() => props.setPageState(1)} className={cn('fs-14 my-3  text-center od-text-primary', style.colorBlue)} onClick={() => props.setPageState(1)} onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Spacebar') { props.setPageState(1); } }}>{CUSTOM_MESSAGES().LOGIN_WITH_PASSWORD} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} /></a>
                </div>
              </Fragment>
            )}
            {pageName === 'verifyOtp' && (
              <Fragment>
                <VerifyOtp
                  handleVerifyOtp={handleVerifyOtp}
                  goBack={goBack}
                  initialValues={initialValues}
                  loading={props.loading}
                  requireTermsAcceptance={requireTermsAcceptance}
                  offlineMode={props.offlineMode}
                  timeExipers={timeExipers}
                  email={email}
                />
                <div className={cn('fs-14 mt-10 mb-10 text-center od-text-primary', style.colorBlue)} role="presentation" onClick={onClickNavigation}>
                  <i className={cn('fa fa-undo od-text-primary', style.colorBlue)} /> {CUSTOM_MESSAGES().USE_DIFFERENT_NUMBER}
                </div>
                {/* <div className={cn('fs-14 mb-15 text-center od-text-primary', style.colorBlue)} role="button" tabIndex="0" onClick={() => props.setPageState(1)} onKeyDown={(e) => { if (e.key === 'Enter') props.setPageState(1); }}>
                  {CUSTOM_MESSAGES().LOGIN_WITH_PASSWORD} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} />
                </div> */}
                <div className={cn('fs-14 mb-15 text-center od-text-primary', style.colorBlue)}>
                  <a tabIndex="0" href={() => props.setPageState(1)} className={cn('fs-14 my-3  text-center od-text-primary', style.colorBlue)} onClick={() => props.setPageState(1)} onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Spacebar') { props.setPageState(1); } }}>{CUSTOM_MESSAGES().LOGIN_WITH_PASSWORD} <i className={cn('fa fa-arrow-circle-right od-text-primary', style.colorBlue)} /></a>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className={cn('row footer fixed-bottom justify-content-center align-items-center pb-0', style.footerStyle, window.innerWidth > 1023 ? 'container d-flex m-auto' : 'row', isIos ? 'd-none' : '')} role="presentation" onClick={() => setModalOpen(true)}>
          {/* <button type="button" aria-label="eulaBtn" className={cn(style.linksPadding, 'btn-outline-none text-white bg-secondary-color')} onClick={() => setLinkName('eula')}>EULA</button>
          <button type="button" aria-label="termsBtn" className={cn(style.linksPadding, 'btn-outline-none text-white bg-secondary-color')} onClick={() => setLinkName('termsandconditions')}>TERMS</button>
          <button type="button" aria-label="privacyPolicyBtn" className={cn(style.linksPadding, 'btn-outline-none text-white bg-secondary-color')} onClick={() => setLinkName('privacyPolicy')}>PRIVACY POLICY</button>
          <button type="button" aria-label="myDataBtn" className="btn-outline-none border-0 text-white bg-secondary-color" onClick={() => setLinkName('mydata')}>MY DATA</button> */}

          <a href="https://onedine.com/privacy-policy/" rel="noopener noreferrer" target="_blank" style={{ color: 'white' }} className={cn(style.linksPadding)}>{LABEL().PRIVACY_POLICY}</a>
          <a href="https://onedine.com/terms-conditions/" rel="noopener noreferrer" target="_blank" style={{ color: 'white' }} className={cn(style.linksPadding)}>{LABEL().TERMS_AND_CONDITIONS}</a>
          <a href="https://onedine.com/cookies/" rel="noopener noreferrer" target="_blank" style={{ color: 'white' }} className={cn(style.linksPadding)}>{LABEL().COOKIES}</a>
          <button type="button" aria-label="my data" className="btn-outline-none border-0 text-white bg-secondary-color" onClick={() => { if (userInfo.isUserLogin()) { setLinkName('mydata'); } else { setToggleMyData(true); } }}>{LABEL().MY_DATA}</button>
        </div>
      </Fragment>
      <Modal
        isOpen={isModalOpen}
        toggleModal={() => toggleModal(!isModalOpen)}
        onConfirm={() => {
          toggleModal(!isModalOpen);
          props.history.replace(`${APP_CONFIG.BASE_URL}/`);
        }}
      />
      <Modal isOpen={toggleMyData} toggleModal={() => { setToggleMyData(!toggleModal); setModalOpen(false); }}>
        <div className="card mb-0 od-app-background-color">
          <div className="p-10">
            <div className="col text-right btn-as-text color-secondary">
              <button className="bg-transparent btn-outline-none border-0 bg-secondary-light p-0 d-flex icon-od fs-12 icon-od-close pull-right od-color" aria-label="close" type="button" onClick={() => { setToggleMyData(false); setModalOpen(false); }} />
            </div>
          </div>
          <div className="mx-0 justify-content-center align-items-center text-center">
            <div className="fs-18 fw-600 text-center p-1">
              {CUSTOM_MESSAGES().MY_DATA_CHECK_IN_MES}
            </div>
            <div className="row mt-20 mb-20 justify-content-center align-items-center text-center">
              <button className="mr-20 btn btn-secondary od-btn-secondary col-4" type="button" onClick={() => { setToggleMyData(false); setModalOpen(false); }}>
                {LABEL().MYDATA_CANCEL}
              </button>
              <button className="ml-20 btn btn-primary od-btn-primary col-4" type="button" onClick={() => { handleCheckIn(); setModalOpen(false); }}>
                {LABEL().MYDATA_OK}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({
  verifyPhone,
  verifyCode,
  getRestaurantInfoConfig,
  createProfile,
  generateUserName,
  checkUniqueUserName,
  setNotification,
  updateBasicDeatils,
  // updateHeaderData,
  toggleCancelButtonSamePage
}, dispatch);

const mapStateToProps = ({ controller, signup, header }) => ({
  loading: signup.loading,
  popInData: controller.popInData,
  cancelOnPage: header.cancelOnPage,
  checkInStoreInfo: controller.checkInStoreInfo,
  offlineMode: signup.offlineMode,
  requireTermsAcceptance: signup.requireTermsAcceptance
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
