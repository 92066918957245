export default {
  // The URL we're connecting to
  hostname: process.env.REACT_APP_API_SERVER_HOST || 'https://mapi.dev.onedine.com:4443', // DEV
  // hostname: 'http://localhost:4443', // LOCAL
  // hostname: 'https://mapi.onedine.com', // PROD
  googleClientId: '956354809256-gst9njqljhc3t7at25ck33ubqbb0ughj.apps.googleusercontent.com',
  facebookAppId: '169385094336646',
  // Map shortnames to the actual endpoints, so that we can use them in the middleware
  endpoints: {
    users: 'users',
    deleteUser: '/users',
    getUser: '/users',
    sendotp: '/api/accounts/password/forgot',
    sendCode: 'api/token/code/generate',
    verifyCode: '/api/token/code/verify',
    getRestaurantInfoConfig: '/api/token/restaurantInfo/mWeb/config',
    verifyotp: '/api/accounts/password/reset/email',
    verifyOtpPhone: '/api/accounts/password/reset/phone',
    register: '/api/account/register',
    login: '/api/token',
    changePassword: '/api/accounts/password/change',
    updateBasicDeails: '/api/accounts/profile/update',
    // fetchOffers: '/api/loyalties/offers',
    fetchOffers: '/api/loyalties/pointsAndOffers',
    loyaltyOffers: '/api/lookups/offerSystems',
    suscribeToOffers: '/api/offers/accept',
    featchWallet: '/api/accounts/wallet/cards/brandId',
    getOrderHistory: '/api/receipts/brandId',
    getOrderDetails: '/api/receipts/ticketId',
    verifyPhoneNumber: '/api/accounts/verifyPhoneNumber',
    create_profile: '/api/accounts/profile/create',
    getTable: '/api/accounts/popIn',
    assignSpot: '/api/orders/spotAssignment',
    generateUserName: '/api/accounts/generateUsername',
    checkUniqueUserName: '/api/accounts/isUsernameUnique',
    getOauth: 'api/token/create',
    getSurvey: 'api/surveys/store',
    getSmsSurvey: 'api/surveys/check/checkHeaderId',
    // getSurvey: 'api/surveys/ticketId',
    sensorCheckIn: '/api/sensors/popin',
    getPopIn: '/api/users/popIn',
    getReceipt: '/api/receipts/referenceCode',
    updateTable: '/api/orders/stationAssignment',
    callServer: '/api/notifications/callServer',
    submitSurvey: '/api/surveys/guest',
    smsReceipt: '/api/receipts/sms',
    emailReceipt: '/api/receipts/email',
    getPopInblank: '/api/users/popIn',
    checkoutTable: '/api/users/popOut/checkInId',
    getManifest: '/api/accounts/wallet/cardManifest/storeId',
    getShift4Manifest: '/api/accounts/wallet/cardManifest/shift4',
    getPayPalManifest: '/api/accounts/wallet/paypal/createOrder',
    getAmazonPayManifest: '/api/accounts/wallet/cardManifest/amazonPay',
    getCabbagePayManifest: '/api/accounts/wallet/cardManifest/cabbagePay',
    getDojoManifest: '/api/accounts/wallet/cardManifest/dojo',
    getGPManifest: '/api/accounts/wallet/cardManifest/globalPay',
    addCard: '/api/accounts/wallet/addCard',
    // paymentByCard: '/api/payments/sale',
    paymentByCard: '/api/payments/online',
    itemsOrderPay: '/api/orders/orderPayItems',
    getMenuList: '/api/menus/screen/menuId',
    getMenuOrderDetails: '/api/menus/menuItem/menuScreenId',
    appCustomization: '/api/brands/customize/brandId',
    appBrandCustomization: '/api/brands/customize/mobileApp/brandId',
    // getMenu: '/api/orders/menus/guestApp/storeId',
    // getMenu: '/api/menus/guest/order/storeId',
    getMenu: '/api/menus/guest/order/compressed',
    placeOrder: '/api/orders/addItems',
    orderItems: '/api/orders/orderItems',
    priceCheck: '/api/orders/priceCheck',
    upsellCheck: 'api/orders/upSell',
    kdsSimulate: '/api/orders/kdsSimulate',
    selectStage: '/api/checks/checkInSelection',
    getCompaniesList: '/api/companies/list',
    getCompanyById: '/api/companies/companyId',
    getBrandsByCompanyId: '/api/brands/brandId',
    getStoresByBrandId: '/api/stores/list/brandId',
    getStationsByStoreId: '/api/stations/storeId',
    registerSensor: '/api/sensors/register',
    googleSignIn: '/api/token/google',
    googleClientId: '/api/token/google/clientId',
    facebookSignIn: '/api/token/facebook',
    applePayGetSession: '/api/payments/applePay/getSession',
    getToastApplePaySession: '/api/payments/toast/applePay/getSession',
    sendToPaymentGateway: '/api/payments/online',
    getLoyaltyOtp: '/api/loyalties/account/signUp/init',
    loyaltyAccountSignUpComplete: '/api/loyalties/account/signUp/complete',
    loyaltyAccountSignUp: '/api/loyalties/account/signUp',
    loyaltyAccountLookUp: '/api/loyalties/account/lookup',
    loyaltyAccountAssign: '/api/loyalties/guest/assign',
    loyaltyAccountUnAssign: '/api/loyalties/guest/unAssign',
    getGooglePayMerchantInfo: '/api/payments/googlePay/getMerchantInfo',
    deleteCard: '/api/accounts/wallet/deleteCard/cardId',
    getStorePickUpTime: '/api/orders/pickupTimes/storeId',
    getStorePickupDeliveryTime: '/api/orders/deliveryTimes/storeId',
    postMyData: '/api/admin/myData',
    // getMenuCategory: '/api/menus/guest/browse/storeId',
    getMenuCategory: '/api/menus/guest/browse/compressed',
    getProfileOrderHistory: '/api/receipts/storeId',
    getStoreThemeUrl: '/api/brands/theme/brandId',
    getBrandThemeUrl: '/api/brands/theme/brandId',
    getGiftCardBalance: '/api/loyalties/giftCardBalance',
    tableManagment: '/api/tms',
    createWaitList: '/api/tms/waitList/create',
    storeTag: '/api/tms/storeTag/storeId',
    availableTime: '/api/tms/availableTime',
    getStorePickUpTimeBasedOnDate: '/api/orders/deliveryTimes/storeId',
    storeOccasionList: '/api/lookups/occasions/storeId',
    firstAvailableTime: '/api/tms/firstAvailableTime',
    daisyChain: '/api/orders/daisyChain',
    getStoreDeliveryTime: '/api/orders/deliveryTimes/storeId',
    checkReceipt: '/api/receipts/check/referenceCode',
    getPreviewPopIn: 'api/users/popIn/menuPreview',
    renameCard: '/api/accounts/wallet/renameCard',
    featchWalletByStoreID: '/api/accounts/wallet/cards/storeId',
    acceptTerms: '/api/accounts/profile/acceptTerms',
    accrueLoyalty: '/api/loyalties/accrue',
    validateUnchanged: '/api/checks/validateUnchanged',
    sessionTrack: '/api/admin/analytics',
    addFavourite: '/api/accounts/favorite/add',
    removeFavourite: '/api/accounts/favorite/delete/memberFavoriteId',
    fetchLoyaltyAccountsList: '/api/accounts/wallet/loyalties/brandId',
    renameLoyaltyAccountName: '/api/accounts/wallet/loyalties/rename',
    deleteLoyaltyAccountCard: '/api/accounts/wallet/loyalties/delete/memberLoyaltyId',
    getPickDeliveryTimes: '/api/orders/pickupDeliveryTimes',
    fetchAddressBookList: '/api/accounts/address',
    addNewMemberAddress: '/api/accounts/address/add',
    updateMemberAddress: '/api/accounts/address/update',
    deleteMemberAddress: '/api/accounts/address/delete/memberAddressId',
    getRevalidationData: '/api/orders/revalidateSensor',
    tableAssignment: '/api/orders/tableAssignment',
    getVoucherPickupDeliveryTimes: '/api/orders/voucherPickupDeliveryTimes',
    getLoyaltyAccountByLink: '/api/loyalties/pointsAndOffers/brandExternalId',
    voucherSMS: '/api/orders/resendVoucherSms',
    gpsLocationAssignment: '/api/orders/gpsLocationAssignment',
    resetLoyaltyPassword: '/api/loyalties/account/forgotPassword',
    optInLoyalty: '/api/loyalties/account/optIn',
    storeLocator: '/api/stores/locator',
    getBrandInfo: '/api/brands/brandExternalId',
    giftCardOcr: '/api/loyalties/giftCardOcr',
    addWaitList: '/api/bookings/waitList/add',
    deleteWaitList: '/api/bookings/waitList/delete/bookingId',
    updateWaitList: '/api/bookings/waitList/update',
    getOptInInfo: '/api/accounts/optIn/brandId',
    updateOptInfo: '/api/accounts/optIn/update'
  },
};
