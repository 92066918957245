import React, { Fragment } from 'react';
import { Form } from 'formik';
import Textfield from '@formElements/TextFieldFormik';
import Button from '@formElements/Button';
import { CUSTOM_MESSAGES, LABEL } from '@veriables';
import cn from 'classnames';
import style from './resetPassword.module.css';
import { getObjectValue } from '../../utility/utills';

const ResetPasswordForm = ({ loading, props }) => {
  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  console.log('------------------------------!!!!!!!!!!!!!!!', props);
  return (
    <div className="row mx-0 mt-30 full-height justify-content-center align-items-center">
      <div className="card-wrapper">
        <div className="custom-card fat od-app-background-color">
          <div className="card-body">
            {/* <div className="row mx-0 fs-12 mb-2">
              <button
                type="button"
                className="col text-right btn-as-text color-secondary"
                onClick={onClickNavigation}
              >
                <div className="icon-od fs-12 icon-od-close" />
              </button>
            </div> */}
            <Form>
              <div className="row">
                {props.initialValues && props.initialValues.emailAddress && (
                  <div className="col-12">
                    <div className="form-group" id="emailAddressDiv">
                      <Textfield
                        name="emailAddress"
                        type="text"
                        label={LABEL().EMAIL}
                        readOnly
                        ariaLabel="emailAddress"
                        ariaLabelBy="emailAddressDiv"
                        title="emailAddress"
                        htmlFor={LABEL().EMAIL}
                      />
                    </div>
                  </div>
                )}
                {props.initialValues && props.initialValues.phoneNumber && (
                  <div className="col-12">
                    <div className="form-group" id="phoneNumberDiv">
                      <Textfield
                        name="phoneNumber"
                        type="text"
                        label={LABEL().PHONE_NUMBER}
                        readOnly
                        ariaLabel="phoneNumber"
                        ariaLabelBy="phoneNumberDiv"
                        title="phoneNumber"
                        htmlFor={LABEL().PHONE_NUMBER}
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="form-group" id="CodeDiv">
                    <Textfield
                      name="code"
                      type="text"
                      label={LABEL().CODE}
                      ariaLabel="Code"
                      ariaLabelBy="CodeDiv"
                      htmlFor={LABEL().CODE}

                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group" id="PasswordDiv">
                    <Textfield
                      name="password"
                      type="password"
                      label={LABEL().PASSWORD}
                      ariaLabel="Password"
                      ariaLabelBy="PasswordDiv"
                      htmlFor={LABEL().PASSWORD}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group" id="ConfirmPasswordDiv">
                    <Textfield
                      name="confirmPassword"
                      type="password"
                      label={LABEL().CONFIRM_PASSWORD}
                      ariaLabel="confirmPassword"
                      ariaLabelBy="ConfirmPasswordDiv"
                      htmlFor={LABEL().CONFIRM_PASSWORD}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                {btnImgUrl ? (
                  <div className="position-relative d-flex justify-content-center">
                    <Button type="submit" loading={loading} disabled={loading} customClass="pl-20" className="border-0 bg-transparent">
                      <Fragment>
                        <img src={btnImgUrl} className={cn(style.btnImgUrlSize, 'od-app-btn-primary')} alt="appDefaultButtonImage" />
                        <span className={cn(style.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().RESET_PASSWORD}</span>
                      </Fragment>
                      <Fragment>
                        {LABEL().SUBMITING}
                      </Fragment>
                    </Button>
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="btn btn-primary btn-block btn-md od-btn-primary"
                    loading={loading}
                    disabled={loading}
                    customClass="pl-20"
                  >
                    <Fragment>
                      {LABEL().RESET_PASSWORD}
                    </Fragment>
                    <Fragment>
                      {LABEL().SUBMITING}
                    </Fragment>
                  </Button>
                )}
              </div>
            </Form>
            <div className="fs-12 bg-secondary-light p-10 mt-20">
              <p className="fw-600 mb-1">
                {CUSTOM_MESSAGES().PASSWORD_MUST_MEET_THE_FOLLOWING}
              </p>
              <li>
                {CUSTOM_MESSAGES().EIGHT_CHARACTERS_LONG}
              </li>
              <li className={cn(style.lineStyle)}>
                {CUSTOM_MESSAGES().HAVE_BOTH_UPPER_AND_LOWER_CASE}
              </li>
              <li>
                {CUSTOM_MESSAGES().AT_LEAST_ONE_NUMBER}
              </li>
              <li>
                {CUSTOM_MESSAGES().AT_LEAST_ONE_SPECIAL_CHARACTER}
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordForm;
