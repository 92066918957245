const parseFloatToFix = (number, toFix) => {
  try {
    return parseFloat(number).toFixed(toFix);
  } catch (e) {
    console.log(e);
  }
  return 0.00;
};

export default {
  parseFloatToFix
};
