import React, { Fragment } from 'react';
import { Field } from 'formik';
import cn from 'classnames';

const renderField = (props) => {
  const {
    field,
    label,
    type,
    readOnly,
    onClick,
    onChange,
    form: {
      touched,
      errors,
      submitCount,
      values
    },
    uniqueId,
    disabled,
    labelClass,
    isFromSurvey
  } = props;
  return (
    <Fragment>
      <div className={cn('custom-control custom-checkbox', isFromSurvey ? 'fs-15 mx-3 my-3' : '')}>
        <input
          className="custom-control-input"
          {...field}
          type={type}
          readOnly={readOnly}
          id={uniqueId}
          checked={values[field.name] || false}
          disabled={disabled}
          onClick={onClick}
          onChange={onChange}
        />
        <label className={cn('text-muted custom-control-label od-color', labelClass)} htmlFor={uniqueId}>
          {label}
        </label>
        {((submitCount > 0)
        || (touched[field.name]
        && errors[field.name]))
          && (
          <div className="error">
            {errors[field.name]}
          </div>
          )
        }
      </div>
    </Fragment>
  );
};

const CheckBox = (props) => {
  const {
    name,
    errorLabel,
    label,
    readOnly,
    validate,
    customClass,
    defaultChecked,
    onChange,
    onClick,
    externalLabel,
    labelClass,
    disabled,
    isFromSurvey
  } = props;
  return (
    <div>
      <Field
        name={name}
        type="checkbox"
        readOnly={readOnly}
        component={renderField}
        label={label}
        errorLabel={errorLabel}
        validate={validate}
        customClass={customClass}
        uniqueId={`${externalLabel}_${name}_${label}`}
        defaultChecked={defaultChecked}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
        labelClass={labelClass}
        isFromSurvey={isFromSurvey}
      />
    </div>
  );
};
export default CheckBox;
