export const GET_SURVEY_REQUESTED = 'survey/GET_SURVEY_REQUESTED';
export const GET_SURVEY_SUCCESS = 'survey/GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAILURE = 'survey/GET_SURVEY_FAILURE';

export const GET_SMS_SURVEY_REQUESTED = 'survey/GET_SMS_SURVEY_REQUESTED';
export const GET_SMS_SURVEY_SUCCESS = 'survey/GET_SMS_SURVEY_SUCCESS';
export const GET_SMS_SURVEY_FAILURE = 'survey/GET_SMS_SURVEY_FAILURE';

export const SUBMIT_SURVEY_REQUESTED = 'survey/SUBMIT_SURVEY_REQUESTED';
export const SUBMIT_SURVEY_SUCCESS = 'survey/SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_FAILURE = 'survey/SUBMIT_SURVEY_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  surveyData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        surveyData: action.result,
      };
    }
    case GET_SURVEY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GET_SMS_SURVEY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SMS_SURVEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        surveyData: action.result,
      };
    }
    case GET_SMS_SURVEY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SUBMIT_SURVEY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SUBMIT_SURVEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        surveySubmit: action.result,
      };
    }
    case SUBMIT_SURVEY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

// export const getSurvey = (storeId = 37100) => {
//   return {
//     types: [GET_SURVEY_REQUESTED, GET_SURVEY_SUCCESS, GET_SURVEY_FAILURE],
//     promise: client => client.get(`getSurvey/${storeId}`),
//   };
// };
export const getSurvey = (data) => {
  return {
    types: [GET_SURVEY_REQUESTED, GET_SURVEY_SUCCESS, GET_SURVEY_FAILURE],
    promise: client => client.post('getSurvey', {
      data
    }),
  };
};

export const getSmsSurvey = (checkHeaderId) => {
  return {
    types: [GET_SURVEY_REQUESTED, GET_SURVEY_SUCCESS, GET_SURVEY_FAILURE],
    promise: client => client.get(`getSmsSurvey/${checkHeaderId}`),
  };
};

export const submitSurvey = (data) => {
  return {
    types: [SUBMIT_SURVEY_REQUESTED, SUBMIT_SURVEY_SUCCESS, SUBMIT_SURVEY_FAILURE],
    promise: client => client.post('submitSurvey', {
      data,
    }),
  };
};
