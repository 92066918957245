import React from 'react';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import Textfield from '@formElements/TextFieldFormik';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import Checkbox from '@formElements/CheckBoxFormik';
import CountryCode from '@formElements/CountryCode';
import styles from './mydata.module.css';
import TextArea from '../common/formElements/TextAreaFormik/index';
import { postMyData } from '@reducers/mydata';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setNotification } from '@reducers/notification';
import Loader from '@components/common/Loader';
import { updateHeaderData } from '@reducers/header';
import { withRouter } from 'react-router-dom';
import { userInfo } from '@utility';
import { getObjectValue } from '@utility/utills';

const MyData = (props) => {
  // const showHeaderInfo = {
  //   menu: {
  //     type: null,
  //     controlUrl: ''
  //   },
  //   closeButton: {
  //     type: null,
  //     controlUrl: ''
  //   },
  //   headerBar: {
  //     show: true,
  //     tittle: 'StoreLogo'
  //   },
  //   restaurantInfo: {
  //     show: false
  //   },
  // };

  // const showSpotHeaderInfo = {
  //   menu: {
  //     type: null,
  //     controlUrl: ''
  //   },
  //   closeButton: {
  //     type: null,
  //     controlUrl: '/spot-code'
  //   },
  //   headerBar: {
  //     show: true,
  //     tittle: 'StoreLogo'
  //   },
  //   restaurantInfo: {
  //     show: false
  //   }
  // };
  // useEffect(() => {
  //   if (props.isUserLogin) {
  //     if (props.location.pathname === '/spotCode/mydata') {
  //       props.updateHeaderData(showSpotHeaderInfo);
  //     } else {
  //       props.updateHeaderData(showHeaderInfo);
  //     }
  //   }
  // }, []);

  const onsubmit = (data) => {
    const addData = {
      firstName: data && data.firstName ? data.firstName : '',
      lastName: data && data.lastName ? data.lastName : '',
      countryCode: data && data.countryCode ? data.countryCode : '',
      phoneNumber: data && data.phoneNumber ? data.phoneNumber.replace(/[^0-9]/g, '') : '',
      emailAddress: data && data.emailAddress ? data.emailAddress : '',
      requestData: data && data.requestData ? data.requestData : false,
      deleteData: data && data.deleteData ? data.deleteData : false,
      // provideFeedback: data && data.provideFeedback ? data.provideFeedback : false,
      comment: data && data.comment ? data.comment : '',
    };
    props.postMyData(addData).then(() => {
      props.setNotification({
        type: 'success',
        message: 'Your Data saved successfully'
      });
      props.handleToggle(false);
    }).catch((error) => {
      // props.handleToggle(false);
      props.setNotification({
        type: 'danger',
        message: error.message || 'Failed to save data'
      });
    });
  };
  const getCountryCodeData = () => {
    const checkCountryCode = getObjectValue(() => userInfo.getUserBasicDetails().countryCode, '');
    if (checkCountryCode === '1') return '+1';
    return checkCountryCode;
  };
  const getPhoneNumber = () => {
    const x = getObjectValue(() => userInfo.getUserBasicDetails().phoneNumber, '');
    if (x.length === 10 && getObjectValue(() => getCountryCodeData(), '+1') === '+1') return `(${x.slice(0, 3)}) ${x.slice(3, 6)}-${x.slice(6, 10)}`;
    return x;
  };
  const myDataInitialValues = {
    countryCode: getObjectValue(() => getCountryCodeData(), '+1'),
    phoneNumber: getObjectValue(() => getPhoneNumber(), ''),
    emailAddress: getObjectValue(() => userInfo.getUserBasicDetails().emailAddress, ''),
    firstName: getObjectValue(() => userInfo && userInfo.getUserBasicDetails().firstName, ''),
    lastName: getObjectValue(() => userInfo && userInfo.getUserBasicDetails().lastName, ''),
  };

  const RenderForm = (value) => {
    return (
      <div className="container px-0">
        <div className="row mx-0">
          <div className={cn(styles.loginColumns, 'animated fadeInDown text-justify')}>
            <div className="col-12">
              <div className="row modal-header p-0">
                <div className={cn('col-12 p-0 fw-700 fs-18', styles.textRight)}>
                  <button type="button" tabIndex="0" className="bg-white border-0" aria-label="my data Close" onClick={() => props.handleToggle(false)} onKeyDown={(ev) => { if (ev.key === 'Escape') props.handleToggle(false); }}>X</button>
                </div>
                <div className="col-12">
                  <h5>{LABEL().YOUR_DATA}</h5>
                  {/* If you would like to make a request about your data, please complete this form or contact us at <b> (888) 551-3463 </b>. Before we can review your request, we must first verify your identity and ownership of the data you would like to manage. */}
                  {/* <p><small>{CUSTOM_MESSAGES().DATA_REQUEST_MESSAGE}</small></p> */}
                </div>
                <div>
                  <hr />
                </div>
              </div>
              <Form>
                <div className={cn('modal-body px-0', styles.scrollHeight)}>
                  <div className="col-12 px-0">
                    <div className="col-12 form-group mb-0">
                      <div className="row mx-0">
                        <div className={cn(styles.phonenr_input, 'col-4 pl-0')}>
                          <CountryCode
                            name="countryCode"
                          />
                        </div>
                        <div className={cn('col-8 pr-0')}>
                          <div className={cn(styles.inputField, 'form-group')} id="mobileNumberDiv">
                            <Textfield
                              name="phoneNumber"
                              type="text"
                              pattern="[0-9]*"
                              label={LABEL().ENTER_MOBILE_NUMBER_LOGIN}
                              ariaLabel="phoneNumber"
                              ariaLabelBy="mobileNumberDiv"
                              title="phoneNumber"
                              readOnly={!!getObjectValue(() => userInfo.getUserBasicDetails().phoneNumber, null)}
                              htmlFor={LABEL().ENTER_MOBILE_NUMBER_LOGIN}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 form-group mb-3" id="EmailAddress">
                      <Textfield
                        name="emailAddress"
                        type="text"
                        label={LABEL().EMAIL_ADDRESS}
                        ariaLabel="EmailAddress"
                        ariaLabelBy="EmailAddress"
                        htmlFor={LABEL().EMAIL_ADDRESS}
                      />
                    </div>
                    <div className="col-12 form-group mb-3" id="FirstName">
                      <Textfield
                        name="firstName"
                        type="text"
                        label={LABEL().FIRST_NAME}
                        ariaLabel="FirstName"
                        ariaLabelBy="FirstName"
                        htmlFor={LABEL().FIRST_NAME}
                      />
                    </div>
                    <div className="col-12 form-group mb-3" id="lastName">
                      <Textfield
                        name="lastName"
                        type="text"
                        label={LABEL().LAST_NAME}
                        aria-label="lastNamre"
                        aria-labelledby="lastName"
                        htmlFor={LABEL().LAST_NAME}
                      />
                    </div>
                    <div className="col-12 form-group mb-3" aria-hidden="false">
                      {/* WHAT WOULD YOU LIKE TO DO? */}
                      {CUSTOM_MESSAGES().MY_DATA_MESSAGE}
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        <div className="form-group" id="RequestDataDiv">
                          <Checkbox
                            name="requestData"
                            type="checkbox"
                            label={LABEL().REQUEST_DATA}
                            labelClass={cn(styles.overFlowNone, styles.whiteSpace)}
                            onChange={event => value.setFieldValue('requestData', event.target.checked)}
                          />
                          <Checkbox
                            name="deleteData"
                            type="checkbox"
                            label={LABEL().DELETE_DATA}
                            labelClass={cn(styles.overFlowNone, styles.whiteSpace)}
                            onChange={event => value.setFieldValue('deleteData', event.target.checked)}
                          />
                          {/* <Checkbox
                            name="provideFeedback"
                            type="checkbox"
                            label={LABEL().PROVIDE_FEEDBACK}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 form-group mb-3">
                      <TextArea
                        name="comment"
                        type="text"
                        label={LABEL().REQUEST_DETAILS}
                        htmlFor={LABEL().REQUEST_DETAILS}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 pb-2">
                  <button
                    type="submit"
                    className="btn btn-primary od-btn-primary btn-block btn-md"
                    aria-label="mydatasubmit"
                    tabIndex="0"
                  >
                    {LABEL().MYDATA_SUBMIT}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {props.loading && <Loader />}
      <Formik
        render={value => RenderForm(value)}
        initialValues={myDataInitialValues}
        onSubmit={onsubmit}
        enableReinitialize
      />
    </div>
  );
};

const mapStateToProps = ({ mydata, oauth }) => ({
  isUserLogin: oauth.isUserLogin || window.localStorage.getItem('isUserLogin'),
  loading: mydata.loading
});
const mapDispatchToProps = dispatch => bindActionCreators({
  setNotification,
  updateHeaderData,
  postMyData,
}, dispatch);
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyData));
