import React, { Component } from 'react';
import { Field } from 'formik';
import ErrorMessage from '@formElements/ErrorMessage';
import cn from 'classnames';
import styles from './textfield.module.css';

class CustomInputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onFocus() {
    this.setState({
      focused: true
    });
  }

  onBlur() {
    this.setState({
      focused: false
    });
  }

  render() {
    const {
      type,
      title,
      field,
      label,
      customClass,
      readOnly,
      customInputStyle,
      maxLength,
      ariaLabel,
      ariaLabelBy,
      autoComplete,
      htmlFor
    } = this.props;
    let fieldClass = `od-input-font-color od-input-background-color form-control form-control-danger ${styles.inputField}`;
    if (customClass) {
      fieldClass = `${fieldClass} ${customClass}`;
    }
    // console.log('field.value', field.value);
    const inputContainerClass = this.state.focused || (field.value && field.value !== '') ? styles.inputFocused : styles.inputBlur;
    const labelClass = this.state.focused || (field.value && field.value !== '') ? styles.labelFocused : styles.labelBlur;
    return (
      <div className={inputContainerClass}>
        <label htmlFor={htmlFor} className={cn(labelClass, styles.customLabel, 'od-input-font-color')}>
          {label}
        </label>
        <input
          {...field}
          type={type}
          title={title}
          value={field.value || ''}
          className={fieldClass}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={readOnly}
          style={customInputStyle}
          maxLength={maxLength}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelBy}
          autoComplete={autoComplete}
        />
      </div>
    );
  }
}

const Textfield = (props) => {
  const {
    name,
    label,
    type,
    errorLabel,
    readOnly,
    validate,
    customInputStyle,
    maxLength,
    title,
    ariaLabel,
    ariaLabelBy,
    autoComplete,
    customClass,
    errorMessageCustomClass,
    htmlFor,
  } = props;
  return (
    <div>
      <Field
        name={name}
        component={CustomInputComponent}
        label={label}
        type={type}
        errorLabel={errorLabel}
        readOnly={readOnly}
        validate={validate}
        customInputStyle={customInputStyle}
        maxLength={maxLength}
        title={title}
        ariaLabel={ariaLabel}
        ariaLabelBy={ariaLabelBy}
        autoComplete={autoComplete}
        customClass={customClass}
        htmlFor={htmlFor}
      />
      <ErrorMessage name={name} customClass={errorMessageCustomClass} />
    </div>
  );
};

export default Textfield;
