export const PAYMENT_BY_CARD_REQUESTED = 'PAYMENT_BY_CARD/PAYMENT_BY_CARD_REQUESTED';
export const PAYMENT_BY_CARD_SUCCESS = 'PAYMENT_BY_CARD/PAYMENT_BY_CARD_SUCCESS';
export const PAYMENT_BY_CARD_FAILURE = 'PAYMENT_BY_CARD/PAYMENT_BY_CARD_FAILURE';

export const PAYMENT_BY_APPLE_PAY_REQUESTED = 'PAYMENT_BY_APPLE_PAY/PAYMENT_BY_APPLE_PAY_REQUESTED';
export const PAYMENT_BY_APPLE_PAY_SUCCESS = 'PAYMENT_BY_APPLE_PAY/PAYMENT_BY_APPLE_PAY_SUCCESS';
export const PAYMENT_BY_APPLE_PAY_FAILURE = 'PAYMENT_BY_APPLE_PAY/PAYMENT_BY_APPLE_PAY_FAILURE';

export const GET_TOAST_APPLE_PAY_SESSION_REQUESTED = 'PAYMENT_BY_APPLE_PAY/GET_TOAST_APPLE_PAY_SESSION_REQUESTED';
export const GET_TOAST_APPLE_PAY_SESSION_SUCCESS = 'PAYMENT_BY_APPLE_PAY/GET_TOAST_APPLE_PAY_SESSION_SUCCESS';
export const GET_TOAST_APPLE_PAY_SESSION_FAILURE = 'PAYMENT_BY_APPLE_PAY/GET_TOAST_APPLE_PAY_SESSION_FAILURE';

export const GOOGLE_PAY_MERCHANT_INFO_REQUESTED = 'GOOGLE_PAY_MERCHANT_INFO/GOOGLE_PAY_MERCHANT_INFO_REQUESTED';
export const GOOGLE_PAY_MERCHANT_INFO_SUCCESS = 'GOOGLE_PAY_MERCHANT_INFO/GOOGLE_PAY_MERCHANT_INFO_SUCCESS';
export const GOOGLE_PAY_MERCHANT_INFO_FAILURE = 'GOOGLE_PAY_MERCHANT_INFO/GOOGLE_PAY_MERCHANT_INFO_FAILURE';
export const GET_STATE_FROM_SESSION = 'payment/GET_STATE_FROM_SESSION';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  googlePayMerchantInfo: JSON.parse(window.sessionStorage.getItem('googlePayMerchantInfo')) || {},
  isPaymentLoading: false
};

export default (state = initialState, action) => {
  console.log('initialState, action', action);
  switch (action.type) {
    case PAYMENT_BY_CARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        isPaymentLoading: true,
        loaded: false
      };
    }
    case PAYMENT_BY_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        isPaymentLoading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_BY_CARD_FAILURE: {
      return {
        ...state,
        loading: false,
        isPaymentLoading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_BY_APPLE_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: false,
        loaded: false
      };
    }
    case PAYMENT_BY_APPLE_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_BY_APPLE_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case GET_TOAST_APPLE_PAY_SESSION_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: false,
        loaded: false
      };
    }
    case GET_TOAST_APPLE_PAY_SESSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_TOAST_APPLE_PAY_SESSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case GOOGLE_PAY_MERCHANT_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GOOGLE_PAY_MERCHANT_INFO_SUCCESS: {
      window.sessionStorage.setItem('googlePayMerchantInfo', JSON.stringify(action.result));
      return {
        ...state,
        googlePayMerchantInfo: action.result,
        loading: false,
        loaded: true
      };
    }
    case GOOGLE_PAY_MERCHANT_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};


export const paymentBycard = (data) => {
  return {
    types: [PAYMENT_BY_CARD_REQUESTED, PAYMENT_BY_CARD_SUCCESS, PAYMENT_BY_CARD_FAILURE],
    promise: client => client.post('paymentByCard', {
      data
    })
  };
};

export const itemsOrderPay = (data) => {
  return {
    types: [PAYMENT_BY_CARD_REQUESTED, PAYMENT_BY_CARD_SUCCESS, PAYMENT_BY_CARD_FAILURE],
    promise: client => client.post('itemsOrderPay', {
      data
    })
  };
};

export const applePayGetSession = (data) => {
  return {
    types: [PAYMENT_BY_APPLE_PAY_REQUESTED, PAYMENT_BY_APPLE_PAY_SUCCESS, PAYMENT_BY_APPLE_PAY_FAILURE],
    promise: client => client.post('applePayGetSession', {
      data
    })
  };
};

export const getToastApplePaySession = (data) => {
  return {
    types: [GET_TOAST_APPLE_PAY_SESSION_REQUESTED, GET_TOAST_APPLE_PAY_SESSION_SUCCESS, GET_TOAST_APPLE_PAY_SESSION_FAILURE],
    promise: client => client.post('getToastApplePaySession', {
      data
    })
  };
};

export const sendToPaymentGateway = (data) => {
  return {
    types: [PAYMENT_BY_CARD_REQUESTED, PAYMENT_BY_CARD_SUCCESS, PAYMENT_BY_CARD_FAILURE],
    promise: client => client.post('sendToPaymentGateway', {
      data
    })
  };
};

export const getGooglePayMerchantInfo = () => {
  if (!window.sessionStorage.getItem('googlePayMerchantInfo')) {
    return {
      types: [GOOGLE_PAY_MERCHANT_INFO_REQUESTED, GOOGLE_PAY_MERCHANT_INFO_SUCCESS, GOOGLE_PAY_MERCHANT_INFO_FAILURE],
      promise: client => client.post('getGooglePayMerchantInfo')
    };
  }
  return dispatch => dispatch({
    type: GET_STATE_FROM_SESSION
  });
};
