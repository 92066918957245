import React, { useState } from 'react';
import styles from './mallMenuModal.module.scss';
import cn from 'classnames';
import { getObjectValue } from '@utility/utills';

const MallMenuModal = (props) => {
  const [isStoreSelected, setStoreSelected] = useState([]);
  const currStoreId = getObjectValue(() => props.popInData.store.id, 0);
  let storeList = [];
  try {
    storeList = props.popInData && props.popInData.stores ? props.popInData.stores : [];
  } catch (e) {
    console.log(e);
  }

  const storeSelected = (data) => {
    setStoreSelected(data);
  };

  return (
    <div className={cn(styles.menuCard, 'py-3')}>
      {storeList && storeList.map((store) => {
        const selectedStore = (store.id === isStoreSelected.id || store.id === currStoreId) ? styles.selectedStore : styles.normalStore;
        return (
          <div className={cn(styles.menuItem, selectedStore, 'mr-15 ml-15 mb-10 d-flex justify-content-center align-items-center rounded')} role="presentation" onClick={() => { props.handleMallSelection(store.id); storeSelected(store); }} onKeyDown={(e) => { if (e.key === 'Enter') props.handleMallSelection(store.id); storeSelected(store); }}>
            {store.logoImageUrl ? (
              <>
                <div className={cn('col-6 p-1')}>
                  {store.logoImageUrl && <img src={store.logoImageUrl} alt="Store Logo" />}
                </div>
                <div className="col-6 p-2">
                  {store.name.split('-')[0] || ''}
                </div>
              </>
            ) : (
              <div className="col-12">
                {store.name.split('-')[0] || ''}
              </div>
            )
          }
          </div>
        );
      })}
    </div>
  );
};

export default MallMenuModal;
