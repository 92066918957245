export default {
  // buttons
  YES: 'Yes',
  OK: 'OK',
  NO: 'No',
  NOW: 'Now',
  OR: 'OR',
  LATER: 'Later',
  SUBMIT: 'Submit',
  CALL: 'Call',
  DONE: 'Done',
  REMOVE: 'Remove',
  GET_OTP: 'Get Code',
  GO_BACK: 'Go back',
  RETRY: 'Retry',
  GET_EMAIL_OTP: 'Get security code by Email',
  GET_MOBILE_OTP: 'Get security code by Text',
  LOGIN: 'SIGN IN',
  VERIFY_YOUR_PHONE: 'Verify Your Phone',
  CHECK_IN: 'Check In',
  CHECK_IN_QR: 'Check in with a QR code',
  ENTER_TABLE_NUMBER: 'Enter My Table Number',
  SCAN_TABLE_CODE: 'Scan My Table Code',
  LEAVE_TABLE: 'Exit check',
  LOADING: 'Loading',
  RESEND_OTP: 'Resend Code',
  RESEND_IN: 'Resend in',
  SECONDS: 'seconds',
  BACK: 'Back',
  SAVE_CHANGES: 'Save changes',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  YES_CONTINUE: 'Yes, continue',
  TRY_AGAIN: 'Try Again',
  SMS: 'SMS',
  EMAIL: 'Email',
  SIGN_UP: 'SIGN UP',
  RESET_PASSWORD: 'Reset Password',
  SUBMITING: 'Submiting',
  SPLIT_EQUALY: 'Split Equaly',
  SELECT_BY_ITEMS: 'Select Items To Pay',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  SAVE: 'Save',
  SKIP: 'Skip',
  VERIFY: 'VERIFY',
  CONTINUE: 'CONTINUE',
  TEXT_RECEIPT: 'Text Receipt',
  EMAIL_RECEIPT: 'Email Receipt',
  NO_RECEIPT: 'No Receipt',
  YOUR_RECEIPT: 'Your Receipt',
  VIEW_RECEIPT: 'View Receipt',
  GENERATE_OTP: 'Generate Code',
  OTHER: 'Other',
  PAY_NOW: 'Pay Now',
  ADD_TIP: ' ADD TIP',
  CONFIRMATION: 'Confirmation',
  TABLE: 'Table',
  RESTAURANT: 'Restaurant',
  VEHICLE_DESCRIPTION: 'Vehicle Description',
  SPOT_NUMBER: 'Spot Number',
  I_AM_HERE: 'I AM HERE',
  CHECK_IN_WITH_CODE: 'Check in with a 7-digit code',
  ADD_A_TIP: 'Add a tip',
  NO_TIP: 'No Tip',
  CALS: 'cals',
  CALS_SEPARATOR: '-',
  CALS_SEPARATOR_START: '',
  CALS_SEPARATOR_END: '',
  CALS_FROM: 'From',

  // label
  ENTER_EMAILID_MESSAGE: 'Please enter the email you use to sign in to OneDine.',
  SENDING_OTP: 'Sending',
  PLEASE_SELECT_OPTION: 'Please select one of the following options',

  // Form Label
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  NICK_NAME: 'Order Nickname',
  USER_NAME: 'User Name',
  PHONE_NUMBER: 'Phone Number',
  EMAIL_ADDRESS: 'Email Address',
  EMAIL_ADDRESS_PROFILE: 'Email Address',
  ENTER_USERNAME_EMAIL: 'Username',
  ENTER_PASSWORD: 'Password',
  ENTER_OTP: 'Enter Code',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  COUNTRY_CODE: 'Country Code',
  ENTER_ACCOUNT_NUMBER: 'Enter Account Number',
  ENTER_EMAIL_ADDRESS: 'Enter your email address',
  ENTER_FIRST_NAME: 'First Name',
  ENTER_LAST_NAME: 'Last Name',
  ENTER_DESIRED_USERNAME: 'Username',
  ENTER_CONFIRM_PASSWORD: 'Confirm Password',
  ENTER_THE_OTP: 'Verification Code',
  EMAILS: 'Email',
  CODE: 'Code',
  CITY: 'City',
  COMMENTS: 'Comments (Optional)',
  BRANCH: 'Branch',
  PASSWORD: 'Password',
  NAME: 'Name',
  SURNAME: 'Surname',
  CARD_HOLDER: 'Card Holder',
  TABLE_NUMBER: 'Table Number',
  MOBILE_NUMBER: 'Enter Your Phone Number',
  SPOT_CODE: '7-Digit Code',
  CARD_HOLDER_NAME: 'Card Holder Name',
  ADDRESS_TYPE: 'Address type',
  AGREED_CONDITION: 'I agree with the terms and conditions.',
  USE_DIFFERENT_NUMBER: 'Use a different number',
  REQUEST_DETAILS: 'Comment',
  REQUEST_DATA: 'Request data',
  DELETE_DATA: 'Delete data',
  PROVIDE_FEEDBACK: 'PROVIDE FEEDBACK',
  MYDATA_SUBMIT: 'Submit',
  SURVEY_FEEDBACK: 'Feedback',
  ENTER_AMOUNT: 'Enter an amount',

  // Other_tips Placeholders
  ENTER_PERCENTAGE: 'Enter percentage',
  ENTER_TIP_AMOUNT: 'Enter tip amount',

  // Navigations Tab labels
  HOME: 'Check In',
  OFFERS_PROMOTIONS: 'Offers & Promotions',
  WALLET: 'Payments',
  LOYALTY_ACCOUNTS: 'Loyalty Accounts',
  ORDER_HISTORY: 'Past Orders',
  SIGN_OUT: 'Sign Out',
  MY_DATA: 'My Data',
  PROFILE: 'Profile',
  PREFERENCES: 'Email / SMS Preferences',
  LOGOUT: 'Log Out',
  ACCOUNT: 'My Account',
  SUBSCRIPTION: 'Subscription',

  // Others
  YOUR_CHECK_TOTAL: 'Your Check Total',
  SUB_TOTAL: 'Subtotal:',
  TAX: 'Tax',
  TIP: 'Tip',

  // select item page
  SELECT_ALL: 'Select All',
  CONTINUE_WITH_SELECTION: 'Continue with selection',
  SELECT_ALL_ITEMS_AND_CONTINUE: 'Select all items and continue',
  PAY_SELECTED: 'Pay Selected',
  PAY_ALL: 'Pay all',

  // TOTAL_AMOUNT: 'Total Amount',
  TOTAL: 'Total',
  INCLUDING_TIP_NEW_TOTAL: 'Including tip, pay a total of',
  TIPS: 'Tips',
  DISCOUNTS: 'Discounts',
  LOCATION: 'Location',
  ACTIONS: 'Actions',
  EXPIRES_ON: 'Expires on',
  READ_ONLY: '(read only)',
  EACH: ' each',
  YOUR_CHECK_SPLIT: 'Your check has been splited among ',
  MEMBERS: 'members with ',
  YOUR_ITEMS_CHECK_LIST: 'Your items check list',
  SERVICE_CHARGE: 'Service Charge',
  ADDITIONAL_CHARGE: 'Additional Charge',
  DISCOUNT_AMOUNT: 'Discount',
  TIP_AMOUNT: 'Tip',
  DUE: 'Due',
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
  TOTALS: 'TOTAL',
  CHECK_TOTAL: 'Check total',
  STORE_SELECTION: 'Store Selection',
  SENSOR_ASSIGNMENT: 'Sensor Assignment',
  MANAGE: 'Manage',
  SENSOR: 'Sensor',
  TOO_FAST: 'Too Fast',
  TOO_SLOW: 'Too Slow',
  JUST_RIGHT: 'Just Right',
  CONFIRM: 'Confirm',
  TOTAL_DUE: 'Total due',
  PAYMENT_TOTAL: 'Total',
  PAYMENTS: 'Payments',
  TOTAL_AMOUNT: 'TOTAL',


  // Spit Check
  PAY: 'PAY',
  PAY_REMAINING_BALANCE: 'PAY REMAINING BALANCE',


  // Ordering
  ORDER: 'ORDER',
  REORDER: 'Reorder',
  VIEW: 'View',
  MODIFY: 'MODIFY',
  ORDER_ANOTHER: 'ORDER ANOTHER',
  ADD_TO_CART: 'Add to Bag',
  ADD: 'Add',
  UPDATE_CART: 'Update Bag',

  // Payment screen
  OFFERS: 'Offers',
  GIFT_CARD: 'Gift card',
  ADD_GIFT_CARD: 'Add a gift card',
  EDIT_GIFT_CARD: 'Edit a gift card',
  ROUND_UP: 'Round up',
  DELIVERY_FEE: 'Delivery fee',
  DELIVERY: 'Delivery',
  PICKUP: 'Pickup',
  REFRESH: 'Refresh',
  RETRY_PAYMENT: 'Retry Payment',
  SUBMIT_ORDER: 'Submit Order',
  COUPON: 'Coupon',
  LOYALTY_AND_OFFERS: 'Loyalty & Offers',
  EDIT_LOYALTY_AND_OFFERS: 'Edit Loyalty & Offers',

  // Check Number screen
  CHECK_NUMBER: 'Check Number',
  FULL_CHECK: 'Full Check',
  SPLIT_EVENLY: 'Split Evenly',
  SPLIT_ITEMS: 'Select Items',
  PAY_AN_AMOUNT: 'Pay An Amount',
  PAY_MY_ITEMS: 'Pay My Items',
  PAY_BY_GUEST: 'Pay By Guest',

  DINE_IN: 'Dine In',
  TAKE_OUT: 'Take Out',
  ORDER_NOW: 'Order Now',
  DC_NAME_OF_THIS_ORDER: 'Enter a name for this order',

  // Footer Screens
  FOOTER_HOME: 'Home',
  FOOTER_MENUS: 'Menus',
  FOOTER_ORDERS: 'Order',
  FOOTER_MY_BAG: 'My Bag',
  FOOTER_MORE: 'More',
  FAVOURITE: 'Favorite',
  BROWSE: 'Browse',
  FOOTER_STORE: 'Restaurants',
  FOOTER_MY_CHECK: 'My Check',


  // HOME SCREEN, REFILL, DAISY CHAIN
  CLICKTOPAYLABEL: 'CLICK TO PAY',
  ADD_ITEMS_TO_REFILL: 'Add items to refill',
  ADD_TO_BAG: 'Add to Bag',
  REFILL_REMOVE: 'Remove',
  REFILL: 'Refill',
  REFILL_EMPTY_CART_MSG: 'Sorry, your order does not contain any items that are eligible for a refill',

  // DAISY CHAIN BUTTONS
  DAISY_FIRST_PERSON_YES: 'Yes, I’m the only one',
  DAISY_FIRST_PERSON_NO: 'No, I’m not the only one',
  DAISY_LAST_PERSON_YES: 'Yes, I’m the last one',
  DAISY_LAST_PERSON_NO: 'No, I’m not the last one',


  // Walkup Screen
  WHAT_WOULD_YOU_LIKE: 'What would you like to today',
  NO_OF_GUEST: 'Number of Guest In Your Party',
  HOW_MANY_CHILDREN: 'Of your guest,How Many are Children?',
  CHECK_AVAILABILITY: 'Check Availability',
  ADD_TO_WAIT_LIST: 'Add to Wait List',

  // Spot Screens
  ENTER_NAME_BELOW: 'Enter your name below',

  // Up sell
  MAY_WE_SUGGEST: 'May we suggest?',

  // Multicart
  CHECKOUT: 'Check out',
  PLACE_MY_ORDER: 'Place My Order',

  // Pay Later
  PAY_LATER: 'Pay later',

  // Other tips messages
  TIP_THIS_AMOUNT: 'Tip this amount:',

  // Tips Home
  CARD_CHARGED: 'If you proceed, your card will be charged.',
  PROCEED_ORDER_PLACED: 'If you proceed, your order will be placed.',

  DC_ORDER_MSG: 'Are you the last person to add to this order?',
  DC_ORDER_PLACE_MSG: 'Are you the only person sending an order right now?',
  HAS_PLACED_ORDER: 'has placed an order.',
  HAVE_PLACED_ORDER: 'have placed their orders.',

  HOW_MANY_PPL: 'Please select how many people will split this check:',

  RECEIPT_OPTION: 'Choose a receipt option',

  OUT_OF_STOCK: 'Out of Stock',

  ADD_CARD: 'Add Card',
  WALLET_ADD_NEW_CARD: 'Add new card',

  REMOVE_CARD: 'Remove Card',
  SAVE_CARD: 'save card',

  // Coupon Labels
  REMOVE_COUPON: 'Remove Coupon',
  APPLY_COUPON: 'Apply Coupon',
  APPLY_COUPON_OFFER: 'Apply Offer',
  SKIP_COUPON_OFFER: 'Skip Offer',

  // LOYALTY
  LOYALTY_ALREADY_MEMBER: 'Already a member',
  LOYALTY_JOIN_NOW: 'Join now',
  LOYALTY_ACCOUNT_NUMBER: 'Account:',

  LOYALTY_ACC_NUMBER: 'Account',
  LOYALTY_PHONE_NUMBER: 'Phone Number',
  LOYALTY_EMAIL_ADDRESS: 'Email Address',
  LOYALTY_QR_CODE: 'QR code',
  LOYALTY_DISPLAY_ACC_NUMBER: 'Account',
  LOYALTY_DISPLAY_OFFER: 'Offer',
  LOYALTY_OFFER_AMOUNT: 'Amount',

  LOYALTY_SELECT_AN_OFFER: 'Offers available',
  NO_OFFERS_AVALIABLE: 'No offers currently available',
  LOYALTY_SKIP_OFFER: 'Skip Offer',
  LOYALTY_APPLY_OFFER: 'Apply Offer',
  LOYALTY_LOGIN: 'Login or Register for Rewards',
  LOOKUP_SUBMIT: 'Submit',
  LOYALTY_VALID_FROM: 'Valid from: ',
  LOYALTY_EXPIRES_ON: 'Expires on: ',

  // Pre auth
  HOLD: 'Hold',
  AUTHORIZE_CARD: 'Authorize card',
  I_AGREE: 'I Agree',
  KEEP_IT_OPEN: 'Keep it open',
  CONTINUE_AS_GUEST: 'Continue as Guest',

  // Menu Item Badge
  VIEW_ONLY: 'View Only',
  NEW_ITEM: 'New Item',
  LIMITED_TIME: 'Limited Time',
  FEATURED: 'Featured',
  SPECIAL: 'Special',

  CART: 'Cart',
  MY_ORDER: 'My Order',
  // Currency symbol
  CURRENCY_SYMBOL: '$',
  PERCENTAGE_SYMBOL: '%',

  // Date Format
  DATE_FORMAT_LONG: 'MM/DD/YYYY',
  DATE_FORMAT_SHORT: 'MM/DD/YY',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT_UK_LONG: 'DD/MM/YYYY',

  LOYALTY_WALLET_NAME: 'Name',
  LOYALTY_UPDATE_NAME: 'Update Name',

  APARTMENT_NUMBER: 'Apartment or suite number (optional)',

  // Table Grouping Order
  JOIN_CHECK_AND_CONTINUE: 'Join check and continue',
  START_A_NEW_CHECK: 'Start a new check',
  JOIN_EXISTING_CHECK: 'Join existing check',
  JOIN_PARTY_WITH: 'Join a party with',
  PAYING_FOR_MYSELF: 'Paying for myself',
  PAYING_FOR_MYSELF_AND_OTHERS: 'Paying for myself and others',
  SOMEONE_ELSE_IS_PAYING: 'Someone else is paying for me',

  // Address Management
  WALLET_ADDRESS: 'Address Book',
  WALLET_ADDRESS_NAME: 'Name',
  WALLET_LOOK_UP_ADDRESS: 'Lookup Address',
  WALLET_ADDRESS_UPDATE: 'Update',
  WALLET_ADDRESS_SAVE: 'Save',

  CART_BUTTON: 'SUBMIT ORDER',

  SEND_NOW: 'Send now',
  IMMEDIATE_ADD_TO_BAG: 'Add to Bag',
  CHECKOUT_DELIVER_TO: 'Deliver TO',
  CHECKOUT_SPOT_NUMBER: 'Spot Number',

  DATE: 'Date',
  PAY_WITH_STRIPE: 'PAY WITH CREDIT CARD',
  PAY_WITH_SPREEDLY: 'Pay with Spreedly',
  SPECIAL_INSTRUCTIONS: 'SPECIAL INSTRUCTIONS',
  ADD_A_NOTE: 'Add a note',
  ITEM_SEARCH: 'Search',
  PICK_UP_TO: 'Pick up to',
  OPTIONAL: 'optional',

  SELECT_DELIVERY_TIME: 'Select Delivery Time',
  SELECT_DELIVERY_DATE: 'Select Delivery Date',
  SELECT_PICK_UP_TIME: 'Select Pickup Time',
  SELECT_PICK_UP_DATE: 'Select Pickup Date',
  EDIT_ONL_ORDERS_YES: 'YES',
  EDIT_ONL_ORDERS_NO: 'NO',
  EDIT_ONL_ORDERS_OK: 'OK',
  EDIT_CU_ORDERS_SPOT_NUMBER: 'Spot Number',
  EDIT_CU_ORDERS_NAME: 'Name',
  DESKTOP_LOGIN: 'Login',
  TIME: 'Time',
  SKIP_SURVEY: 'Skip Survey',
  SURVEY_NEXT: 'Next',
  SURVEY_DONE: 'Done',
  SURVEY_CONTINUE: 'Continue',
  COMING_SOON: 'Coming soon',
  LIMITED_QUANTITY: 'Limited quantity',
  ADDITIONAL_TIME: 'Addtional Time',
  TIME_RESTRICTED: 'Time Restricted',
  DRIVE_THRU: 'Drive thru',
  TIP_ROUNDUP: 'Use tip to round up total',
  ADDITIONAL_TIP: 'Additional Tip',
  CLICK_TO_CALL: 'Click to call',

  CHANGE_LOCATION: 'Change Location',
  CURRENT_LOCATION: 'Current Location',
  CONFIRM_LOCATION: 'Confirm Location',

  VENUE_RECIEPT: 'Receipt',
  RECIEPT: 'Receipt',

  PLEASE_SELECT_AN_ADDRESS: 'Please select an address:',

  // Past Orders
  ORDER_HISTORY_LABEL: 'Order History',
  AT_CURRENT_LOCATIONS: 'At Current Location',
  AT_ALL_LOCATIONS: 'At All Locations',

  // Payment
  ADD_CREDIT_CARD: 'Add credit card',
  HOLD_WITH: 'Hold with',
  CLICK_TO_PAY: 'CLICK TO PAY',

  // LOCATION HEADER
  HEADER_LOCATION: 'Location',
  HEADER_TABLE: 'Table',
  HEADER_CHECK: 'Check #',

  // Card Wallets
  USE_SAVED_CARD: 'Use a saved card',
  MY_SAVED_CARD: 'My saved card',
  CARD_NAME: 'Card Name',
  EXP: 'Exp',
  ENTER_NEW_CARD: 'Enter new card',
  CHANGE_CARD: 'Change card',
  UPDATE_CARD_NAME: 'Update Card Name',
  ACC_WALLET_SAVE: 'Save',
  ACC_WALLET_CANCEL: 'Cancel',
  TEMPORARY_HOLD: '(temporary hold)',

  // Add Card
  ENTER_CREDIT_CARD: 'Enter Credit Card',
  CARD_NUMBER: 'Card number',
  EXPIRY_DATE: 'Expiry Date',
  CVV: 'Cvv',
  ZIP_CODE: 'Zip code',
  FULL_NAME_CARD: 'Full name on card',
  EXP_DATE: 'Exp date',
  POST_CVV: 'CVV',

  // Payment card keys
  CARD_FIRST_NAME: 'First Name',
  CARD_LAST_NAME: 'Last Name',
  CARD_CREDIT_NUMBER: 'Credit Card Number',
  CARD_CVV: 'CVV',
  CARD_EXP_DATE: 'Expiration Date',
  CARD_ZIPCODE: 'Zip code',
  CARD_ADDRESS1: 'Address1',
  CARD_ADDRESS2: 'Address2',
  CARD_CITY: 'City',
  CARD_STATE: 'State',

  CONTINUE_BTN: 'CONTINUE',
  ENTER_MOBILE_NUMBER_LOGIN: 'Enter Mobile Number',
  USE_EMAIL_ADRESS: 'Use an email address',

  SEARCH_ADDRESS: 'Search address',

  // Survey
  SUR_PREVIOUS: 'Previous',

  // Past Order History
  PAST_ORDER_TOTAL: 'Total',
  PAST_ORDER_SUBTOTAL: 'Subtotal:',
  PAST_ORDER_TIP: 'Tip',
  PAST_ORDER_TAX: 'Tax',

  // USAEPAY,
  USA_CREDIT_CARD_LABEL: 'Enter Credit/Debit Card',
  USA_FULL_NAME: 'Full name on card',
  USA_ZIPCODE: 'Zip code',

  // HEARTLAND,
  HL_CARD_NUMBER_LABEL: 'Enter card number',
  HL_CARD_EXPIRY_LABLE: 'Enter expiry date',
  HL_CARD_CVV_LABLE: 'Enter cvv',
  HL_CARD_FULL_NAME: 'Full name on card',
  HL_ZIP_CODE: 'Enter Zip code',
  HL_CARD_NUMBER_PLACEHOLDER: '•••• •••• •••• ••••',
  HL_CARD_EXPIRY_PLACEHOLDER: 'MM / YYYY',
  HL_CARD_CVV_PLACEHOLDER: 'cvv',
  HL_ZIP_PLACEHOLDER: 'zip code',
  HL_CARD_SUBMIT_BTN: 'Add Card',
  HL_STREET_ADDRESS: 'Street Address',
  HL_STREET_ADDRESS_PLACEHOLDER: 'Street Address',

  // GLOBAL PAYMENT
  GP_ZIPCODE: 'Enter Postal Code',
  GP_ZIPCODE_PLACEHOLDER: 'Postal Code',

  // stripe
  STRIPE_FULL_NAME_ON_CARD: 'Full name on card',

  // GIFT CARD
  GC_BALANCE: 'Check balance',
  GC_BALANCE_AMT: 'Balance Amount: ',
  GC_REDEEM_AMT: 'Redeemed Amount: ',
  GC_TOTAL_DUE: 'Total Due: ',
  GC_AMT_APPLY: 'Amount to apply: ',
  GC_REDEEM: 'Redeem',
  SKIP_GIFTCARD: ' Skip Gift Card',
  APPLY_GIFTCARD: ' Apply Gift Card',

  // OFFER SCREEN
  ACCOUNT_VIEW_OFFER: 'My Offers',
  PAYMENT_VIEW_OFFER: 'Select offer to apply',
  VIEW_REWARDS_AND_OFFERS: 'View Rewards & Offers',
  REMOVE_OFFER: 'Remove Offer',

  YOUR_DATA: 'Your Data',

  RECEIPT_TABLE_NUMBER: 'TBL',
  RECEIPT_DELIVER_TO: 'deliver to',
  RECEIPT_TXN_ID: 'Transaction Id',
  RECEIPT_PAYPAL_EMAIL: 'PayPal Email',

  MODIFIER_REMOVE: 'Remove',
  MODIFIER_DONE: 'Done',

  INVALID_SENSOR_CLICK_TO_SCAN: 'CLICK TO SCAN',
  INVALID_SENSOR_CLICK_TO_SCANQRIO: 'CLICK TO SCAN WITH SCANQR.IO',

  // Charity
  CHARITY: 'Charity',
  CHARITY_EDIT: 'Edit charity',
  CHARITY_AMOUNT: 'Amount',
  CHARITY_TO_DONATE: 'CHARITY TO DONATE',
  CHARITY_DONATE: 'Donate',
  CHARITY_OTHER: 'Other',
  CHARITY_ROUNDUP: 'Round it up',

  SCAN_HOST_QR_CODE: 'Scan host\'s Invite QR',

  PAY_MY_CHECK: 'Pay My Check',

  PAY_ONLY_MODE_RETRY: 'Retry',

  BUDGET: 'Remaining Budget',

  CLICK_TO_CONTINUE: 'Click next to continue',

  LOYALTY_SIGN_IN: 'Loyalty Sign In',
  LOYALTY_SIGN_UP: 'Loyalty Sign Up',
  LOYALTY_OPT_IN: 'Loyalty Opt In',
  LOYALTY_FORGOT_PASSWORD: 'Loyalty Forgot Password',
  LOYALTY_CONFIRM_PASSWORD: 'Confirm Password',
  OPT_IN_TEXT: 'Opt In',
  USE_MY_LOCATION: 'Use My Location',
  TIER_LABEL_TEXT: 'Tier',
  POINTS_LABEL_TEXT: 'Points',
  ESTIMATED_POINTS_EARNED: 'Estimated points earned',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  COOKIES: 'Cookies',
  COMPLETE_PAYMENT: 'COMPLETE PAYMENT',
  TABLE_RETRY: 'Retry',
  ADD_FOR: 'Add for',
  CALL_SERVER: 'CALL SERVER',
  NOTIFY_SERVER: 'Notify Server',
  AGE_RESTRICTED_EDIT_CART: 'Edit Cart',
  AGE_RESTRICTED_CONTINUE: 'Continue',
  PAY_BY_STAGE: 'Pay by stage',
  STAGE: 'Split',
  FOOTER_CALL_SERVER: 'Call Server',
  MYDATA_CANCEL: 'Cancel',
  MYDATA_OK: 'Ok',

  // Wait-list
  WAIT_LIST_NAME: 'Name',
  WAIT_LIST_PARTY_SIZE: 'Party Size',
  WAIT_LIST_COUNTRY_CODE: 'Country Code',
  WAIT_LIST_EMAIL_ADDRESS: 'Email Address (Optional)',
  WAIT_LIST_NOTE: 'Note (Optional)',
  WAIT_LIST_SUBMIT: 'Submit',
  WAIT_LIST_CANCEL: 'Cancel',
  WAIT_LIST_MODIFY: 'Modify',
  WAIT_LIST_YES: 'Yes',
  WAIT_LIST_NO: 'No',
  WAIT_LIST_WAIT_PHONE_NUMBER: 'Phone',
  WAIT_LIST_UPDATE: 'Update',
  WAIT_LIST_EMAIL_INFO: 'Email',
  WAIT_LIST_NOTE_INFO: 'Note',

  // Cabbage-Pay
  CABBAGE_PAY_BTN: 'Pay with Cabbage',
  CABBAGE_SWITCH_BANK: 'Switch bank account',

  // Pre Order
  PRE_SKIP: 'Skip',
  PRE_ORDER: 'Order',
  PRE_ORDER_ORDER_ONE_DRINK: 'Order One Drink',
  PRE_ORDER_ORDER_MORE_THAN_ONE_DRINK: 'Order More Than One Round',
  PRE_EVERY: 'Every',
  PRE_MINUTES: 'minutes',
  PRE_TIMES: 'times',

  DELIVERY_APARTMENT_NUMBER: 'Apartment / Suite (optional)',
  DELIVERY_ORDER_NOTE: 'Order note (optional)',

  SURVEY_CONTACT_FORM_YES: 'Yes',
  SURVEY_CONTACT_FORM_NO: 'No',

  // CARDCONNECT
  CARD_CONNECT_EXPIRY: 'Enter Card Expiry Date',
  CARD_CONNECT_CVV: 'Enter Card CVV',

  ADD_UTENSILS: 'Add Utensils?',

  OPTIN_SAVE_CHANGES: 'Save',

  OPT_IN_RECIEVE_EMAIL: 'Opt in to receive emails',
  OPT_IN_RECIEVE_SMS: 'Opt in to receive SMS',

  SKIP_CHECK_IN: 'Skip check in',
  CHECK_NUMBER_NEXT: 'Check In',
  SKIP_OPT_IN: 'Skip Opt In',
  AMAZON_PAY_UPDATE_ACC: 'Update Account',

  RECEIPT_SUB_TOTAL: 'SUBTOTAL',
  RECEIPT_OFFER: 'OFFER',
  RECEIPT_COUPON: 'COUPON',
  RECEIPT_TOTAL: 'TOTAL',
  RECEIPT_PAYMENT: 'PAYMENT',
  RECEIPT_TAX: 'TAX',
  RECEIPT_TIP: 'TIP',
  RECEIPT_DISCOUNT: 'DISCOUNT',
  RECEIPT_DELIVERY_FEE: 'Delivery Fee',
  RECEIPT_SERVICE_CHARGE_AMOUNT: 'Service Charge Amount',
  RECEIPT_SALE_REFUND: 'Refund',
  RECEIPT_CARD_NUMBER: 'Card Number',
  RECEIPT_BEGIN_SURVEY: 'Begin Survey',
  RECEIPT_LOADING: 'Loading',

  LOCATION_CONFIRM: 'Confirm',
  LOCATION_CANCEL: 'Cancel',

  PICKUP_VEHICLE_DESCRIPTION: 'Vehicle Description (optional)',
  PICKUP_VEHICLE_DESCRIPTION_TEXT: 'Vehicle Description',

  DOJO_PAY_WITH_CREDIT_CARD: 'Pay with Credit Card',
  DOJO_SAVE_CARD: 'Save Card',
  DOJO_PAY_WITH_APPLE_PAY: 'Pay with Apple Pay',
  DOJO_PAY_WITH_GOOGLE_PAY: 'Pay with Google Pay',
};
