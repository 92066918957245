import React from 'react';
import { Field } from 'formik';
import PhoneInput from 'react-phone-input-2';
import cn from 'classnames';

const RenderPhoneNumberInput = (props) => {
  const {
    input,
    field,
    customClass,
    handleCountryCode,
    isFromSurvey,
    form: { errors, setFieldValue, submitCount },
  } = props;
  const widthDeskTop = window.innerWidth > 1023 ? '102px' : '90px';
  console.log('props &&&&', isFromSurvey, props, field, field.value);
  return (
    <div className="pos-r">
      <PhoneInput
        {...input}
        value={field.value}
        disabled={props.readOnly}
        inputClass={cn('od-input-font-color od-input-background-color', props.readOnly ? customClass : '')}
        placeholder=""
        country={field.value === '+1' ? 'us' : field.value}
        inputStyle={{ width: widthDeskTop, height: '52px' }}
        onChange={(option) => { setFieldValue(field.name, option); if (isFromSurvey) { handleCountryCode(option); } }}
        onKeyDown={(e) => { if (e.key === 'Enter') { e.target.click(); } }}
      />
      {((submitCount > 0 && errors[field.name]))
        && (
        <div className="error fs-12">
          {errors[field.name]}
        </div>
        )
      }
    </div>
  );
};

const CountryCode = (props) => {
  const {
    name,
    country,
    value,
    inputStyle,
    inputClass,
    readOnly,
    customClass,
    handleCountryCode,
    isFromSurvey
  } = props;
  return (
    <div>
      <Field
        name={name}
        component={RenderPhoneNumberInput}
        country={country}
        value={value}
        inputStyle={inputStyle}
        inputClass={inputClass}
        readOnly={readOnly}
        customClass={customClass}
        handleCountryCode={handleCountryCode}
        isFromSurvey={isFromSurvey}
      />
    </div>
  );
};
export default CountryCode;
