import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import VerifyOtpForm from '@components/VerifyOtpForm';
import { CUSTOM_MESSAGES } from '@veriables';


const VerifyOtp = ({
  handleVerifyOtp,
  goBack,
  initialValues,
  loading,
  requireTermsAcceptance,
  offlineMode,
  timeExipers,
  email
}) => {
  const validationSchema = () => (

    Yup.object().shape({
      code: Yup.string().required(CUSTOM_MESSAGES().VERIFY_OTP_SCREEN_VAL_VERIFICATION_CODE).min(4, 'Please enter the valid verification code'),
      // agree: Yup
      //   .boolean()
      //   .required('Must Accept Terms and Conditions')
      //   .oneOf([true], 'Must Accept Terms and Conditions'),
    })
  );

  return (
    <div>
      <Formik
        render={formProps => <VerifyOtpForm dirty={formProps.dirty} offlineMode={offlineMode} onSubmit={handleVerifyOtp} onClickNavigation={goBack} requireTermsAcceptance={requireTermsAcceptance} timeExipers={timeExipers} email={email} />}
        initialValues={initialValues}
        onSubmit={handleVerifyOtp}
        validationSchema={validationSchema}
        loading={loading}
      />
    </div>
  );
};

VerifyOtp.propTypes = {
  handleVerifyOtp: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};
export default VerifyOtp;
