export const SAVE_BASIC_DETAILS_REQ = 'profile/SAVE_BASIC_DETAILS_REQ';
export const SAVE_BASIC_DETAILS_SUCCESS = 'profile/SAVE_BASIC_DETAILS_SUCCESS';
export const SAVE_BASIC_DETAILS_FAILURE = 'profile/SAVE_BASIC_DETAILS_FAILURE';

export const UPDATE_PASSWORD_DETAILS_REQUEST = 'profile/UPDATE_PASSWORD_DETAILS_REQUEST';
export const UPDATE_PASSWORD_DETAILS_SUCCESS = 'profile/UPDATE_PASSWORD_DETAILS_SUCCESS';
export const UPDATE_PASSWORD_DETAILS_FAILURE = 'profile/UPDATE_PASSWORD_DETAILS_FAILURE';

export const ACCEPT_TERMS_REQUEST = 'profile/ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'profile/ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'profile/ACCEPT_TERMS_FAILURE';

const initailValues = {
  loading: false,
  loaded: true,
  error: false
};

export default (state = initailValues, action) => {
  switch (action.type) {
    case SAVE_BASIC_DETAILS_REQ:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case SAVE_BASIC_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case SAVE_BASIC_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case UPDATE_PASSWORD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case UPDATE_PASSWORD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_PASSWORD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    case ACCEPT_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case ACCEPT_TERMS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    default:
      return state;
  }
};

export const updateBasicDeatils = (data) => {
  return {
    types: [SAVE_BASIC_DETAILS_REQ, SAVE_BASIC_DETAILS_SUCCESS, SAVE_BASIC_DETAILS_FAILURE],
    promise: client => client.post('updateBasicDeails', {
      data
    })
  };
};

export const changePassword = (data) => {
  return {
    types: [UPDATE_PASSWORD_DETAILS_REQUEST, UPDATE_PASSWORD_DETAILS_SUCCESS, UPDATE_PASSWORD_DETAILS_FAILURE],
    promise: client => client.post('changePassword', {
      data
    })
  };
};

export const acceptTerms = (data) => {
  return {
    types: [ACCEPT_TERMS_REQUEST, ACCEPT_TERMS_SUCCESS, ACCEPT_TERMS_FAILURE],
    promise: client => client.post('acceptTerms', {
      data
    })
  };
};
