import React, { Fragment } from 'react';
import { Form } from 'formik';
import Textfield from '@formElements/TextFieldFormik';
import Button from '@formElements/Button';
import { LABEL } from '@veriables';
import { getObjectValue } from '../../utility/utills';
import styles from './generateOtpForm.module.scss';
import cn from 'classnames';

const GenerateOtpForm = ({ loading }) => {
  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  return (
    <>
      <Form>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-3" id="emailAddressDiv">
              <Textfield
                name="emailAddress"
                type="text"
                label={LABEL().ENTER_EMAIL_ADDRESS}
                ariaLabel="emailAddress"
                ariaLabelBy="emailAddressDiv"
                title="emailAddress"
                htmlFor={LABEL().ENTER_EMAIL_ADDRESS}
              />
            </div>
          </div>
        </div>
        <div className="col-12 p-0">
          {btnImgUrl ? (
            <div className="position-relative d-flex justify-content-center">
              <Button type="submit" loading={loading} disabled={loading} customClass="pl-20" className="border-0 bg-transparent">
                <Fragment>
                  <img src={btnImgUrl} className={cn(styles.btnImgUrlSize, 'od-app-btn-primary')} alt="appDefaultButtonImage" />
                  <span className={cn(styles.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().GET_EMAIL_OTP}</span>
                </Fragment>
                <Fragment>
                  {LABEL().SENDING_OTP}
                </Fragment>
              </Button>
            </div>
          ) : (
            <Button
              type="submit"
              className="btn btn-primary btn-block btn-md od-btn-primary"
              customClass="pl-20"
              loading={loading}
              disabled={loading}
            >
              <Fragment>
                {LABEL().GET_EMAIL_OTP}
              </Fragment>
              <Fragment>
                {LABEL().SENDING_OTP}
              </Fragment>
            </Button>
          )}
        </div>
      </Form>
      <div className="text-center primary od-color fw-400 fs-18 mt-20">
        {LABEL().OR}
      </div>
    </>
  );
};
export default GenerateOtpForm;
