import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import users from './users';
import otp from './otp';
import oauth from './oauth';
import { register } from './register';
import password from './password';
import header from './header';
import profile from './profile';
import notification from './notification';
import offers from './offers';
import wallet from './wallet';
import orderHistory from './orderHistory';
import restaurantHome from './restaurantHome';
import signup from './signup';
import theme from './theme';
import controller from './controller';
import survey from './survey';
import kiosk from './kiosk';
import pingServer from './pingServer';
import receipt from './receipt';
import checkoutTable from './leaveTable';
import payment from './payment';
import orders from './orders';
import browse from './browse';
import loyalty from './loyalty';
import sensorManagement from './sensorManagement';
import subscription from './subscription';
import mydata from './mydata';
import tableManagment from './tableManagment';
import address from './address';
import location from './location';
import brandstore from './brandstore';
import waitlist from './waitlist';

const appReducer = combineReducers({
  router: routerReducer,
  users,
  otp,
  oauth,
  password,
  register,
  header,
  profile,
  notification,
  offers,
  wallet,
  orderHistory,
  restaurantHome,
  browse,
  form: formReducer,
  signup,
  theme,
  survey,
  controller,
  kiosk,
  pingServer,
  receipt,
  checkoutTable,
  payment,
  orders,
  loyalty,
  sensorManagement,
  subscription,
  mydata,
  tableManagment,
  address,
  location,
  brandstore,
  waitlist
});

// Setup root reducer
const rootReducer = (state, action) => {
  if (action.type === 'oauth/LOGOUT_REQUEST') {
    state = undefined; // eslint-disable-line
  }
  return appReducer(state, action);
};

export default rootReducer;
