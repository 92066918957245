/* eslint-disable no-trailing-spaces */
/* eslint-disable react/self-closing-comp */
import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'formik';
import Button from '@formElements/Button';
import Textfield from '@formElements/TextFieldFormik';
// import CheckBox from '@formElements/CheckBoxFormik';
import { LABEL, CUSTOM_MESSAGES } from '@veriables';
import cn from 'classnames';
import style from './verifyOtp.module.scss';
import { getObjectValue } from '@utility/utills';

const VerifyMobileForm = ({
  loading, requireTermsAcceptance, offlineMode, timeExipers, email
}) => {
  const themeProperties = JSON.parse(window.sessionStorage.getItem('themeProperties'));
  const btnImgUrl = getObjectValue(() => themeProperties.appDefaultButtonImageUrl, false);
  const [isChecked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleCheckBox = (e) => {
    setChecked(e.currentTarget.checked);
  };

  let interval;

  useEffect(() => {
    if (offlineMode && !email && progress < 100) {
      interval = setTimeout(() => {
        if (progress < 100) { setProgress(prev => Math.round(prev + 1) + 4); }
      }, 1000);
    } else {
      clearTimeout(interval);
    }
  }, [progress]);

  const getTitles = () => {
    const result = CUSTOM_MESSAGES().ENTER_VERIFICATION_CODE;
    return result;
  };

  const getLabels = () => {
    const result = LABEL().ENTER_THE_OTP;
    return result;
  };

  const isButtonDisbaled = requireTermsAcceptance && !isChecked;
  return (
    <Fragment>
      <div className="text-center fs-20 fw-700 p-10">
        <>
          {getTitles()}
        </>
      </div>
      {offlineMode && !email && (
        <div className={cn(style.ProgressBar, 'my-3')}>
          <div className={cn(style.Progress, 'od-progess-bar-color')} style={{ width: `${progress}%` }}></div>
        </div>
      )}
      <Form>
        <div className="large-input mb-20" id="codeDiv">
          <Textfield
            name="code"
            type="text"
            label={getLabels()}
            ariaLabel="code"
            ariaLabelBy="codeDiv"
            title="code"
            htmlFor={getLabels()}
          />
        </div>
        {/* <div className="py-10 fs-12 fw-500 text-center">
          <i className="fas fa-check-square color-success icon-align" />
          <span className="ml-10">{CUSTOM_MESSAGES().CONFIRM_TERMS_CONDITION}</span>
        </div> */}
        {requireTermsAcceptance && (
          <Fragment>
            <div className="fs-16">{CUSTOM_MESSAGES().TERMS_CONDITION}</div>
            <div className="row mr-0 ml-0 p-10 pl-0 pr-0 w-100 mb-15">
              <div className={cn('col-12 p-0 d-flex', style.termsContainer)}>
                <input type="checkbox" className={cn('w-20 h-20 col-2', style.marginTop3)} onChange={e => handleCheckBox(e)} />
                <span className="fs-14 col-10 pr-0 pl-0">Yes I have read the <span><a href="https://onedine.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms of Service</a></span> & <span><a href="https://onedine.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>. I agree to the <span><a href="https://onedine.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms of Service</a></span> and wish to register</span>
              </div>
            </div>
          </Fragment>
        )}
        <div>
          <>
            {btnImgUrl ? (
              <div className="position-relative d-flex justify-content-center">
                <Button type="submit" aria-label="emailOtpButton" loading={loading} disabled={isButtonDisbaled} customClass="pl-45" className="border-0 bg-transparent">
                  <Fragment>
                    <img src={btnImgUrl} className={cn(style.btnImgUrlSize, 'od-app-btn-primary')} alt="get email otp" />
                    <span className={cn(style.btnImgUrlText, 'position-absolute od-app-btn-text-color')}>{LABEL().GET_EMAIL_OTP}</span>
                  </Fragment>
                  <Fragment>
                    {LABEL().CONTINUE_BTN}
                  </Fragment>
                </Button>
              </div>
            ) : (
              <Button type="submit" aria-label="continue" loading={loading} customClass="pl-45" className="btn btn-primary btn-block btn-md od-btn-primary" disabled={isButtonDisbaled}>
                <Fragment>
                  {LABEL().CONTINUE_BTN}
                </Fragment>
                <Fragment>
              Loading
                </Fragment>
              </Button>
            )}
          </>
          {offlineMode && !email && (
            <button type="button" aria-label="email_address" customClass="pl-45" className="btn btn-primary btn-md od-btn-primary mt-10 w-100" disabled={progress < 100} onClick={() => timeExipers(true)}>
              {LABEL().USE_EMAIL_ADRESS}
            </button>
          )}
        </div>
      </Form>
    </Fragment>
  );
};
export default VerifyMobileForm;
