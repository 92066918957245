export const GPS_LOCATION_ASSIGNMENT_REQUEST = 'location/GPS_LOCATION_ASSIGNMENT_REQUEST';
export const GPS_LOCATION_ASSIGNMENT_SUCCESS = 'location/GPS_LOCATION_ASSIGNMENT_SUCCESS';
export const GPS_LOCATION_ASSIGNMENT_FAILURE = 'location/GPS_LOCATION_ASSIGNMENT_FAILURE';

const initailValues = {
  loading: false,
  loaded: true,
  error: false,
};

export default (state = initailValues, action) => {
  switch (action.type) {
    case GPS_LOCATION_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GPS_LOCATION_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GPS_LOCATION_ASSIGNMENT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    default:
      return state;
  }
};

export const gpsLocationAssignment = (data) => {
  return {
    types: [GPS_LOCATION_ASSIGNMENT_REQUEST, GPS_LOCATION_ASSIGNMENT_SUCCESS, GPS_LOCATION_ASSIGNMENT_FAILURE],
    promise: client => client.post('gpsLocationAssignment', { data })
  };
};
