import filter from 'lodash/filter';

export const GET_ADDRESS_BOOK_LIST_REQUEST = 'address/GET_ADDRESS_BOOK_LIST_REQUEST';
export const GET_ADDRESS_BOOK_LIST_SUCCESS = 'address/GET_ADDRESS_BOOK_LIST_SUCCESS';
export const GET_ADDRESS_BOOK_LIST_FAILURE = 'address/GET_ADDRESS_BOOK_LIST_FAILURE';

export const ADD_NEW_MEMBER_ADDRESS_REQUEST = 'address/ADD_NEW_MEMBER_ADDRESS_REQUEST';
export const ADD_NEW_MEMBER_ADDRESS_SUCCESS = 'address/ADD_NEW_MEMBER_ADDRESS_SUCCESS';
export const ADD_NEW_MEMBER_ADDRESS_FAILURE = 'address/ADD_NEW_MEMBER_ADDRESS_FAILURE';

export const UPDATE_MEMBER_ADDRESS_REQUEST = 'address/UPDATE_MEMBER_ADDRESS_REQUEST';
export const UPDATE_MEMBER_ADDRESS_SUCCESS = 'address/UPDATE_MEMBER_ADDRESS_SUCCESS';
export const UPDATE_MEMBER_ADDRESS_FAILURE = 'address/UPDATE_MEMBER_ADDRESS_FAILURE';

export const DELETE_MEMBER_ADDRESS_REQUEST = 'address/DELETE_MEMBER_ADDRESS_REQUEST';
export const DELETE_MEMBER_ADDRESS_SUCCESS = 'address/DELETE_MEMBER_ADDRESS_SUCCESS';
export const DELETE_MEMBER_ADDRESS_FAILURE = 'address/DELETE_MEMBER_ADDRESS_FAILURE';

const initialValues = {
  loading: false,
  loaded: true,
  error: false,
  addressBookList: []
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_ADDRESS_BOOK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case GET_ADDRESS_BOOK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        addressBookList: action.result
      };
    case GET_ADDRESS_BOOK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case ADD_NEW_MEMBER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case ADD_NEW_MEMBER_ADDRESS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case ADD_NEW_MEMBER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case UPDATE_MEMBER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPDATE_MEMBER_ADDRESS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false
      };
    case UPDATE_MEMBER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    case DELETE_MEMBER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case DELETE_MEMBER_ADDRESS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        addressBookList: filter(state.addressBookList, d => d.memberAddressId !== action.payload)
      };
    case DELETE_MEMBER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    default:
      return state;
  }
};

export const getAddressBookList = () => {
  return {
    types: [GET_ADDRESS_BOOK_LIST_REQUEST, GET_ADDRESS_BOOK_LIST_SUCCESS, GET_ADDRESS_BOOK_LIST_FAILURE],
    promise: client => client.get('fetchAddressBookList')
  };
};

export const addNewMemberAddress = (data) => {
  return {
    types: [ADD_NEW_MEMBER_ADDRESS_REQUEST, ADD_NEW_MEMBER_ADDRESS_SUCCESS, ADD_NEW_MEMBER_ADDRESS_FAILURE],
    promise: client => client.post('addNewMemberAddress', {
      data
    })
  };
};

export const updateMemberAddress = (data) => {
  return {
    types: [UPDATE_MEMBER_ADDRESS_REQUEST, UPDATE_MEMBER_ADDRESS_SUCCESS, UPDATE_MEMBER_ADDRESS_FAILURE],
    promise: client => client.post('updateMemberAddress', {
      data
    })
  };
};

export const deleteMemberAddress = (memberAddressId) => {
  return {
    types: [DELETE_MEMBER_ADDRESS_REQUEST, DELETE_MEMBER_ADDRESS_SUCCESS, DELETE_MEMBER_ADDRESS_FAILURE],
    promise: client => client.post(`deleteMemberAddress/${memberAddressId}`),
    payload: memberAddressId
  };
};
